import { FC } from "react";

import { DatePicker, Form, Modal, Radio } from "antd";
import dayjs, { Dayjs } from "dayjs";

import { FinancialStatementType } from "@teylor-tools/Api";

import { useTranslations } from "../translations/translations";

export interface StatementFormProps {
	statementType: FinancialStatementType;
	statementDate: Dayjs;
}

interface FinancialsModalProps {
	statementType?: FinancialStatementType;
	statementDate?: string;
	loading?: boolean;
	onCancel: () => void;
	onFinish: (data: StatementFormProps) => void;
}

export const FinancialsModal: FC<FinancialsModalProps> = ({
	statementType,
	statementDate,
	loading = false,
	onCancel,
	onFinish,
}) => {
	const t = useTranslations();
	const [form] = Form.useForm();

	const editMode = statementType && statementDate;
	const type = statementType || FinancialStatementType.AnnualStatement;
	const date = statementDate ? dayjs(statementDate) : dayjs();

	return (
		<Modal
			title={editMode ? t.financialsTable.modal.titleEdit : t.financialsTable.modal.titleAddNew}
			open
			onCancel={onCancel}
			onOk={() => void form.validateFields().then(() => form.submit())}
			okText={editMode ? t.ok : t.add}
			cancelText={t.cancel}
			okButtonProps={{
				htmlType: "submit",
				loading,
			}}
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				initialValues={{ statementType: type, statementDate: date }}
				disabled={loading}
			>
				<Form.Item name="statementType" label={t.financialsTable.modal.statementType}>
					<Radio.Group>
						<Radio value={FinancialStatementType.AnnualStatement} data-cy="annual-statement-radio">
							{t.financialsTable.modal.annualStatement}
						</Radio>
						<Radio
							value={FinancialStatementType.InterimFinancials}
							data-cy="interim-statement-radio"
						>
							{t.financialsTable.modal.interimStatement}
						</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="statementDate" label={t.financialsTable.modal.statementAsOf}>
					<DatePicker style={{ width: "100%" }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
