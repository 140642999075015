import React, { FC } from "react";

import {
	Cell,
	CellTemplate,
	Compatible,
	Id,
	Uncertain,
	UncertainCompatible,
	getCellProperty,
} from "@silevis/reactgrid";
import { Tag } from "antd";

import { CompanyFinancialData, FinancialStatementType } from "@teylor-tools/Api";

import { useTranslations } from "../translations/translations";

const OriginTag = ({ origin }: { origin: CompanyFinancialData["origin"] }) => {
	const t = useTranslations();
	return <Tag color="blue">{t.financialsTable.origin[origin]}</Tag>;
};

export interface HorizontalChevronCell extends Cell {
	type: "horizontalChevron";
	text: string | undefined;
	isExpanded?: boolean;
	hasChildren?: boolean;
	columnId: Id;
	statementType: FinancialStatementType;
	origin?: CompanyFinancialData["origin"];
	parentId?: Id;
}

export const HorizontalChevronCellComponent: FC<{
	cell: Compatible<HorizontalChevronCell>;
	isInEditMode: boolean;
	onChange: (data: Compatible<HorizontalChevronCell>) => void;
}> = ({ cell, onChange }) => {
	const handlePointerDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (cell.hasChildren) {
			e.stopPropagation();
		}

		onChange({ ...cell, isExpanded: !cell.isExpanded });
	};

	return (
		<div className={`header-cell ${cell.hasChildren ? "" : "no-child"}`}>
			<span>{cell.text}</span>

			{cell.origin && <OriginTag origin={cell.origin} />}

			{cell.hasChildren && (
				<div
					className={`chevron ${cell.isExpanded ? "expanded" : "collapsed"}`}
					onPointerDown={handlePointerDown}
				>
					<span className="icon">❯</span>
				</div>
			)}
		</div>
	);
};

export class HorizontalChevronCellTemplate implements CellTemplate<HorizontalChevronCell> {
	getCompatibleCell(
		uncertainCell: Uncertain<HorizontalChevronCell>
	): Compatible<HorizontalChevronCell> {
		const value = NaN;

		const text: string = getCellProperty(uncertainCell, "text", "string");

		const columnId: string = getCellProperty(uncertainCell, "columnId", "string");

		const statementType: FinancialStatementType = getCellProperty(
			uncertainCell,
			"statementType",
			"string"
		);

		let origin;
		try {
			origin = getCellProperty(uncertainCell, "origin", "string");
		} catch {
			origin = undefined;
		}

		let isExpanded = true;
		try {
			isExpanded = getCellProperty(uncertainCell, "isExpanded", "boolean");
		} catch {
			isExpanded = true;
		}

		let hasChildren = false;
		try {
			hasChildren = getCellProperty(uncertainCell, "hasChildren", "boolean");
		} catch {
			hasChildren = false;
		}

		return {
			...uncertainCell,
			text,
			value,
			isExpanded,
			hasChildren,
			columnId,
			statementType,
			origin,
		};
	}

	update(
		cell: Compatible<HorizontalChevronCell>,
		cellToMerge: UncertainCompatible<HorizontalChevronCell>
	): Compatible<HorizontalChevronCell> {
		return this.getCompatibleCell({ ...cell, isExpanded: cellToMerge.isExpanded });
	}

	getClassName(cell: Compatible<HorizontalChevronCell>) {
		const isExpanded = cell.hasChildren ? (cell.isExpanded ? "expanded" : "collapsed") : "";
		const className = cell.className || "";
		return `${isExpanded} ${className}`;
	}

	render(
		cell: Compatible<HorizontalChevronCell>,
		isInEditMode: boolean,
		onCellChanged: (cell: Compatible<HorizontalChevronCell>, commit: boolean) => void
	): React.ReactNode {
		return (
			<HorizontalChevronCellComponent
				cell={cell}
				isInEditMode={isInEditMode}
				onChange={(data) => {
					onCellChanged(this.getCompatibleCell(data), true);
				}}
			/>
		);
	}
}
