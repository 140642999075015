import { Document, OcrFileResponse, OcrFileResults } from "../../Api";
import { OcrDoc, finalStates } from "./fincr.types";

export const getOcrFilesById = async ({
	getOcrStatus,
	ocrDocs,
}: {
	getOcrStatus: () => Promise<OcrFileResponse[]>;
	ocrDocs: Document[];
}): Promise<OcrDoc[]> => {
	const ocrStatus = await getOcrStatus();

	return ocrDocs.map((ocrDoc) => {
		const docInOcr = ocrStatus.find((dataItem) => dataItem.document_id === ocrDoc.document_id);

		return {
			...ocrDoc,
			financials_date: docInOcr?.financials_date,
			fincr_file_id: docInOcr?.fincr_file_id,
			status: docInOcr?.status,
			period_type: docInOcr?.period_type,
		};
	});
};

export const getIssues = async ({
	docs,
	showIssuesError,
	getDocOcrResult,
}: {
	docs: OcrDoc[];
	showIssuesError?: () => void;
	getDocOcrResult: (docId: string) => Promise<OcrFileResults>;
}) => {
	let hasError = false;

	const docsToCheck = docs
		.map((doc, idx) => ({ originalIndex: idx, id: doc.document_id, status: doc.status }))
		.filter(({ status }) => status && finalStates.includes(status));

	const issuePromises = docsToCheck.map(async ({ id, originalIndex }) => {
		try {
			const response = await getDocOcrResult(id);
			docs[originalIndex].warnings = response.document_data.warnings;
		} catch (error) {
			hasError = true;
		}
	});

	await Promise.allSettled(issuePromises);

	if (hasError && showIssuesError) {
		showIssuesError();
	}

	return docs;
};
