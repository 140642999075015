var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "antd";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { getStatusShortcut } from "../../../utils/getStatusShortcut";
const getColor = (status) => {
    switch (status) {
        case ApplicationStatuses.PreCheck:
        case ApplicationStatuses.SalesPreCheck:
            return "default";
        case ApplicationStatuses.InternalReview:
        case ApplicationStatuses.UboCheck:
            return "processing";
        case ApplicationStatuses.InternalPricing:
        case ApplicationStatuses.Pricing:
            return "blue";
        case ApplicationStatuses.ExternalPricing:
            return "#69C0FF";
        case ApplicationStatuses.Signature:
            return "#40A9FF";
        case ApplicationStatuses.FinalReview:
            return "#1890FF";
        case ApplicationStatuses.Approved:
            return "#096DD9";
        case ApplicationStatuses.AwaitingPayout:
            return "#0050B3";
        case ApplicationStatuses.PaidOut:
            return "green";
        case ApplicationStatuses.Rejected:
            return "error";
        default:
            "default";
    }
};
const ApplicationStatus = (_a) => {
    var { short, label, value } = _a, props = __rest(_a, ["short", "label", "value"]);
    const statusLabel = label.toUpperCase();
    return (_jsx(Tag, Object.assign({}, props, { color: getColor(value) }, { children: short ? getStatusShortcut(statusLabel) : statusLabel })));
};
export default ApplicationStatus;
