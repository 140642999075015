import { useEffect, useState } from "react";
import { Axios } from "src/utils/Axios";
export const useBoGroupUsers = (groupId) => {
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);
    useEffect(() => {
        if (!groupId)
            return;
        void Axios.get(`admin/bo_user_groups/${groupId}`)
            .then(({ data }) => {
            data.bo_users && setUsers(data.bo_users || []);
        }, (err) => void Axios.error(err))
            .finally(() => setUsersLoading(false));
    }, [groupId]);
    return { users, usersLoading };
};
