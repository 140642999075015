import { HEADER_ROW_ID } from "./rows";

export enum Accounts {
	"accounts" = "accounts",
	"revenue" = "revenue",
	"inventory_change" = "inventory_change",
	"activated_costs" = "activated_costs",
	"other_income" = "other_income",
	"extraordinary_income" = "extraordinary_income",
	"costs_of_materials" = "costs_of_materials",
	"external_services" = "external_services",
	"personnel_costs" = "personnel_costs",
	"depreciations" = "depreciations",
	"other_costs" = "other_costs",
	"extraordinary_expense" = "extraordinary_expense",
	"interest_income" = "interest_income",
	"interest_expense" = "interest_expense",
	"other_finance_income" = "other_finance_income",
	"other_finance_costs" = "other_finance_costs",
	"taxes" = "taxes",
	"other_taxes" = "other_taxes",
	"net_income" = "net_income",
}

export enum Assets {
	"assets" = "assets",
	"intangible_assets" = "intangible_assets",
	"long_term_assets" = "long_term_assets",
	"current_assets" = "current_assets",
	"financial_assets" = "financial_assets",
	"long_term_intangible_assets" = "long_term_intangible_assets",
	"self_made_intangible_assets" = "self_made_intangible_assets",
	"long_term_properties" = "long_term_properties",
	"long_term_securities" = "long_term_securities",
	"investments_in_related_companies" = "investments_in_related_companies",
	"goodwill" = "goodwill",
	"inventories" = "inventories",
	"accounts_receivable" = "accounts_receivable",
	"other_current_assets" = "other_current_assets",
	"loan_to_related_companies" = "loan_to_related_companies",
	"loan_to_shareholders" = "loan_to_shareholders",
	"cash" = "cash",
	"accruals_aktiva" = "accruals_aktiva",
	"deferred_tax_assets" = "deferred_tax_assets",
	"losses_not_covered_by_equity" = "losses_not_covered_by_equity",
	"total_assets_published" = "total_assets_published",
}

export enum EquityAndLiabilities {
	"equity_and_liabilities" = "equity-and-liabilities",
	"equity" = "equity",
	"liabilities" = "liabilities",
	"bank_debts" = "bank_debts",
	"subscribed_capital" = "subscribed_capital",
	"capital_reserve" = "capital_reserve",
	"retained_income" = "retained_income",
	"profit_carried_forward" = "profit_carried_forward",
	"annual_profit" = "annual_profit",
	"other_equity_position" = "other_equity_position",
	"equity_published" = "equity_published",
	"accrued_liabilities_short_term" = "accrued_liabilities_short_term",
	"accrued_liabilities_long_term" = "accrued_liabilities_long_term",
	"bonds" = "bonds",
	"bank_debts_short_term" = "bank_debts_short_term",
	"bank_debts_long_term" = "bank_debts_long_term",
	"deposits_received" = "deposits_received",
	"accounts_payable" = "accounts_payable",
	"other_liabilities_short_term" = "other_liabilities_short_term",
	"other_debts_short_term" = "other_debts_short_term",
	"other_liabilities_long_term" = "other_liabilities_long_term",
	"loan_from_related_companies" = "loan_from_related_companies",
	"loan_from_shareholders" = "loan_from_shareholders",
	"other_debts_long_term" = "other_debts_long_term",
	"accruals_passiva" = "accruals_passiva",
	"deferred_tax_liabilities" = "deferred_tax_liabilities",
	"total_liabilities_published" = "total_liabilities_published",
	"total_liabilities_and_equity" = "total_liabilities_and_equity",
}

export enum Adjustments {
	"adjustments" = "adjustments",
	"total_debt_service" = "total_debt_service",
}

export type RowId = typeof HEADER_ROW_ID | Accounts | Assets | EquityAndLiabilities | Adjustments;

export const rowsWithoutValue: RowId[] = [
	Accounts.accounts,
	Assets.assets,
	Assets.intangible_assets,
	Assets.long_term_assets,
	Assets.current_assets,
	Assets.financial_assets,
	EquityAndLiabilities.equity_and_liabilities,
	EquityAndLiabilities.equity,
	EquityAndLiabilities.liabilities,
	EquityAndLiabilities.bank_debts,
];

export const summaryData: RowId[] = [
	Accounts.net_income,
	Assets.total_assets_published,
	EquityAndLiabilities.equity_published,
	EquityAndLiabilities.total_liabilities_published,
	EquityAndLiabilities.total_liabilities_and_equity,
];
