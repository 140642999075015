import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationStatuses, EntityKind, FeatureName, PendingActionError, } from "@teylor-tools/Api";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { ALL_DOCUMENT_SEARCH_FIELDS, } from "src/pages/modules/Documents/DocumentsSearch";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { ALL_DOCUMENT_EDITABLE_FIELDS } from "src/pages/modules/Documents/documents.types";
import { Axios } from "src/utils/Axios";
const ApplicationDocuments = () => {
    const { application: { applicationId, status }, updatePendingItems, showApproveModal, pendingItems, } = useApplication();
    const isEditable = ![ApplicationStatuses.PaidOut, ApplicationStatuses.Rejected].includes(status);
    const { features } = useSelector((state) => state.configState);
    const showUploadRaising = useMemo(() => {
        var _a;
        return (((_a = features.find((f) => f.name === FeatureName.RaisinUploadButton)) === null || _a === void 0 ? void 0 : _a.is_active) &&
            pendingItems
                .map((item) => item.errors)
                .flat()
                .includes(PendingActionError.ErrExternalActionNotExecuted));
    }, [features, pendingItems]);
    return (_jsx(DocumentsTab, { entityKind: EntityKind.Application, entityId: applicationId, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: (search) => Axios.get(`admin/applications/${applicationId}/documents`, search), apiGetArchivePath: `admin/applications/${applicationId}/files_archive`, onUpdate: updatePendingItems, allowUpload: isEditable, allowDelete: isEditable, showUploadRaising: showUploadRaising, showApproveModal: showApproveModal, editableFields: isEditable ? ALL_DOCUMENT_EDITABLE_FIELDS : [], searchFields: ALL_DOCUMENT_SEARCH_FIELDS }));
};
export default ApplicationDocuments;
