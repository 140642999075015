import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import TimePassed from "@ui/time-passed/TimePassed";
import { Routes } from "../../../../Routes";
import { DataCard } from "../../../../components/DataCard";
import ApplicationStatus from "../../../../components/ui/statuses/ApplicationStatus";
import { Axios } from "../../../../utils/Axios";
const CompanyApplicationsCard = ({ companyId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const [applications, setApplications] = useState();
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            className: "direct-open-column",
            width: 38,
            render: (application) => (_jsx(Button, { type: "link", style: { width: "38px" }, icon: _jsx(SelectOutlined, {}), onClick: (e) => {
                    e.stopPropagation();
                    navigate(generatePath(Routes.Application, { applicationId: application.application_id }));
                } })),
        },
        {
            title: t("application.amount"),
            render(a) {
                return a.amount ? currency(a.amount, { currency: a.currency }) : "-";
            },
        },
        {
            dataIndex: "status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(Tooltip, Object.assign({ title: t(`application.status.${item}`) }, { children: _jsx(ApplicationStatus, { label: t(`application.status.${item}`), value: item }) })));
            },
        },
        {
            dataIndex: "duration",
            title: t("application.duration"),
            render(duration) {
                return duration ? `${duration} ${t("common.months")}` : "-";
            },
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("application.product-name"),
        },
        {
            dataIndex: "created_at",
            title: t("application.created"),
            render(created) {
                return _jsx(TimePassed, { date: created });
            },
        },
    ];
    const getApplications = useCallback(() => {
        setLoading(true);
        Axios.get(`admin/companies/${companyId}/applications`, {
            page: 1,
            page_size: 5,
        })
            .then(({ data }) => setApplications(data), (err) => Axios.error(err))
            .finally(() => setLoading(false));
    }, [companyId]);
    useEffect(getApplications, [getApplications]);
    return (_jsx(DataCard, Object.assign({ title: `${t("application.company.company-applications")} (${(applications === null || applications === void 0 ? void 0 : applications.pagination.total_rows) || 0})`, loading: loading, extra: _jsx(Button, Object.assign({ type: "link", onClick: () => navigate(generatePath(Routes.CompanyApplications, { companyId })) }, { children: t("loans.overview.view-all") })) }, { children: _jsx("div", Object.assign({ style: { overflow: "auto" } }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "application_id", dataSource: (applications === null || applications === void 0 ? void 0 : applications.result) || [], pagination: false }) })) })));
};
export default CompanyApplicationsCard;
