import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

export const InfoRow = styled(Row)`
	margin-bottom: 32px;
`;

export const InfoCol = styled(Col)`
	padding: 0 8px;
`;

export const InfoTitle = styled(Text)`
	display: inline-block;
	font-size: 15px;
	margin-bottom: 8px;
`;

export const InfoCard = styled(Card)`
	max-width: 1440px;
`;
