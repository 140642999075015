import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Row, Typography } from "antd";
import styled from "styled-components";
import { TaskRelatedEntityType } from "@teylor-tools/Api";
import Preview from "@ui/preview/Preview";
import SidebarCreateTask from "src/components/tasks/SidebarCreateTask";
import SidebarTaskDetails from "src/components/tasks/SidebarTaskDetails";
import { SideActions } from "../CompanyTypes";
import CompanyNotes from "./CompanyNotes";
import CompanyTasks from "./CompanyTasks";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const StaticDrawer = styled(Preview) `
	position: relative;

	.ant-drawer-body {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.ant-drawer-content-wrapper {
		position: relative;
		height: 100%;
		box-shadow: none;
		border-left: solid 1px #f0f0f0;
	}
`;
const Header = ({ text, onClose, onBack, }) => {
    return (_jsxs(Row, Object.assign({ align: "middle", justify: "space-between", style: { width: "100%" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: text })), _jsxs("div", { children: [_jsx(CloseOutlined, { onClick: onClose }), onBack && _jsx(RightOutlined, { onClick: onBack, style: { marginLeft: 16 } })] })] })));
};
const CompanyOutlet = ({ company, updateCompany, activeMenu, setActiveMenu, updateTasks, tasks, }) => {
    const { t } = useTranslation();
    const sideMenuElements = {
        tasks: {
            header: (_jsx(Header, { onClose: () => setActiveMenu(undefined), text: t("loans.tasks.sidebar.title") })),
            children: _jsx(CompanyTasks, { tasks: tasks }),
        },
        "create-task": {
            header: (_jsx(Header, { onClose: () => setActiveMenu(undefined), onBack: () => setActiveMenu(SideActions.tasks), text: t("tasks.sidebar.title.create-new-task") })),
            children: (_jsx(SidebarCreateTask, { entityId: company.companyId, entityType: TaskRelatedEntityType.Company, afterSave: () => {
                    void updateTasks();
                    setActiveMenu(SideActions.tasks);
                } })),
        },
        "task-details": {
            header: (_jsx(Header, { onClose: () => setActiveMenu(undefined), onBack: () => setActiveMenu(SideActions.tasks), text: t("tasks.sidebar.title.task-details") })),
            children: (_jsx(SidebarTaskDetails, { data: tasks, afterDelete: () => void updateTasks().finally(() => setActiveMenu(SideActions.tasks)), afterUpdate: () => void updateTasks() })),
        },
        "shared-notes": {
            header: (_jsx(Header, { onClose: () => setActiveMenu(undefined), onBack: () => setActiveMenu(SideActions.tasks), text: t("common.toolbar.shared-notes") })),
            children: _jsx(CompanyNotes, { companyId: company.companyId }),
        },
    };
    return (_jsxs(StyledWrapper, { children: [_jsx(Grow, { children: _jsx(Outlet, { context: { company, updateCompany, setActiveMenu, tasks } }) }), _jsx(StaticDrawer, Object.assign({ entityId: company.companyId, getContainer: false, visible: !!activeMenu, header: activeMenu ? sideMenuElements[activeMenu].header : undefined, lineData: activeMenu ? sideMenuElements[activeMenu].lineData : undefined }, { children: activeMenu ? sideMenuElements[activeMenu].children : undefined }))] }));
};
export default CompanyOutlet;
