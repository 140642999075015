import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined } from "@ant-design/icons";
import { Col, Dropdown, Input, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import MainHeader from "@ui/main-header/MainHeader";
import TimePassed from "@ui/time-passed/TimePassed";
import SearchList from "../../components/SearchList";
import UserPreview from "./UserPreview";
import UsersMenu from "./UsersMenu";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
})(SearchField || (SearchField = {}));
const UsersPage = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState();
    const columns = [
        {
            dataIndex: "email",
            title: t("users.email"),
        },
        {
            dataIndex: "emailVerified",
            title: t("users.email-verified"),
            render(emailVerified) {
                return emailVerified ? (_jsx(Tag, { children: t("users.email-verified") })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: t("users.not-verified") })));
            },
        },
        {
            dataIndex: "hasAcceptedTermsAndConditions",
            title: t("users.terms-accepted"),
            render(hasAcceptedTermsAndConditions) {
                return hasAcceptedTermsAndConditions ? (_jsx(Tag, { children: t("users.accepted") })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: t("users.not-accepted") })));
            },
        },
        {
            dataIndex: "created",
            title: t("application.created"),
            render(created) {
                return created && _jsx(TimePassed, { date: created });
            },
        },
        {
            title: "",
            dataIndex: "user_id",
            key: "operation",
            width: 20,
            render(user_id) {
                return (_jsx(Dropdown, Object.assign({ overlay: _jsx(UsersMenu, { user_id: user_id }), placement: "bottomLeft" }, { children: _jsx(MoreOutlined, { onClick: (evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                        } }) })));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("users.title") }), _jsx(SearchList, Object.assign({ endpoint: "/admin/users", columns: columns, showingOfString: "users.search.search-showing", rowKey: ({ user_id }) => user_id, onRow: (record) => {
                    return {
                        onClick(evt) {
                            if (!evt.target.closest(".ant-dropdown-menu")) {
                                setUser(record);
                            }
                        },
                    };
                } }, { children: _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("users.placeholder"), allowClear: true }) })) })) })), _jsx(UserPreview, { user: user, onClose: () => setUser(undefined) })] }));
};
export default UsersPage;
