import { ChecklistItemTemplateItemType, } from "@teylor-tools/Api";
import { getItemOptionType } from "./utils/mapping-flag-to-option-type";
export const mapFormToTemplate = (form, dndStruct) => {
    return Object.keys(dndStruct).reduce((acc, dndGroupId) => {
        const group = {
            description: form[dndGroupId].desc,
            items: dndStruct[dndGroupId].map((dndItemId) => {
                var _a;
                const formItem = form[dndItemId];
                const item = {
                    item_type: formItem.itemType,
                    description: formItem.desc,
                };
                switch (formItem.itemType) {
                    case ChecklistItemTemplateItemType.Checkbox:
                        item.options = [
                            {
                                description: formItem.desc,
                            },
                        ];
                        break;
                    case ChecklistItemTemplateItemType.Select:
                        item.options = (_a = formItem.options) === null || _a === void 0 ? void 0 : _a.map((option) => ({
                            description: option.desc,
                            // Flag is there, checked by form validation
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            type: getItemOptionType(option.flag),
                        }));
                        break;
                    case ChecklistItemTemplateItemType.Text:
                        item.requirements = {
                            min_length: formItem.minLength,
                        };
                        break;
                }
                return item;
            }),
        };
        acc.push(group);
        return acc;
    }, []);
};
