import { jsx as _jsx } from "react/jsx-runtime";
import { EntityKind } from "@teylor-tools/Api";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { ALL_DOCUMENT_SEARCH_FIELDS, } from "src/pages/modules/Documents/DocumentsSearch";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { ALL_DOCUMENT_EDITABLE_FIELDS } from "src/pages/modules/Documents/documents.types";
import { Axios } from "src/utils/Axios";
const CompanyDocuments = () => {
    const { company: { companyId }, } = useCompany();
    return (_jsx(DocumentsTab, { entityKind: EntityKind.Company, entityId: companyId, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: (search) => Axios.get(`admin/companies/${companyId}/documents`, search), apiGetArchivePath: `admin/companies/${companyId}/files_archive`, allowUpload: true, allowDelete: true, editableFields: ALL_DOCUMENT_EDITABLE_FIELDS, searchFields: ALL_DOCUMENT_SEARCH_FIELDS }));
};
export default CompanyDocuments;
