import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Form, Radio, Select, Tooltip } from "antd";
import styled, { css } from "styled-components";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { FORM_LIST_NAME } from "./RuleEngineConfigurator";
import { GroupType } from "./rule-engine.types";
import { arithmeticOperatorsOptions, groupTypeOptions, logicalOperatorsOptions, } from "./rule-engine.variables";
const parameters = ["loan_amount"];
const BG_COLOURS = ["#fafafa", "#f5f5f5", "#ededed", "#e5e5e5", "#dedede", "#d3d3d3", "#cecece"];
const edgeStyle = css `
	content: "";
	border-color: #b2b2b2;
	position: absolute;
	width: 23px;
	border-style: solid;
`;
const ActionBtnsWrapper = styled.div `
	position: absolute;
	top: 0;
	right: 0;
	display: none;

	button {
		width: 24px !important;
	}
`;
const ActionBtn = styled(Button) `
	width: 26px;
`;
const SyntaxGroup = styled.div `
	padding-bottom: 20px;
	border-radius: 8px;
	border: 1px solid #dadada;
	margin: 10px 0 10px 30px;
	background-color: ${({ $level }) => BG_COLOURS[$level >= BG_COLOURS.length ? -1 : $level]};
	position: relative;

	${({ $level }) => $level
    ? css `
					&:before,
					&:after {
						${() => edgeStyle}
						left: -24px;
					}

					&:before {
						border-width: 0 0 2px 2px;
						top: -1px;
						height: calc(50% + 1px + 1px);
					}

					&:after {
						border-width: 0 0 0 2px;
						top: calc(50% + 1px);
						height: calc(50% - 1px + 10px + 2px);
					}
				`
    : css `
					margin-left: 0;
				`}
	${({ $isLastChild }) => $isLastChild &&
    css `
			&:after {
				display: none;
			}
		`}
`;
const SyntaxGroupHeader = styled.div `
	padding: 20px 20px 0 20px;
	position: relative;

	${({ $hasChildren }) => $hasChildren &&
    css `
			&:after {
				${() => edgeStyle}
				left: 27px;
				border-width: 0 0 0 2px;
				bottom: -10px;
				height: 10px;
			}
		`}
	&:hover > ${ActionBtnsWrapper} {
		display: block;
	}
`;
const SyntaxGroupBody = styled.div `
	padding: 0 20px;
`;
const FormItem = styled(Form.Item) `
	margin: 0;
`;
const RuleEngineConfiguratorGroup = ({ level, isLastChild, formParentPath, onAdd, onDelete, onTypeChange, onTypeWithSubgroupsValueChange, children, disabled, }) => {
    const form = Form.useFormInstance();
    const groupType = Form.useWatch([FORM_LIST_NAME, ...formParentPath, "type"], form);
    const requiredRule = {
        required: true,
        message: "",
    };
    const formItem = useMemo(() => {
        switch (groupType) {
            case GroupType.LOGICAL:
                return (_jsx(Select, { options: logicalOperatorsOptions, style: { width: 200 }, onChange: () => onTypeWithSubgroupsValueChange(formParentPath) }));
            case GroupType.ARITHMETIC:
                return (_jsx(Select, { options: arithmeticOperatorsOptions, style: { width: 200 }, onChange: () => onTypeWithSubgroupsValueChange(formParentPath) }));
            case GroupType.PARAM:
                return (_jsx(Select, { options: parameters.map((v) => ({ label: v, value: v })), style: { width: 200 } }));
            case GroupType.VALUE:
                return _jsx(NumberInputLocalized, { style: { width: 200 }, allowEmptyValue: true, precision: 4 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupType, formParentPath]);
    return (_jsxs(SyntaxGroup, Object.assign({ "$level": level, "$isLastChild": isLastChild }, { children: [_jsxs(SyntaxGroupHeader, Object.assign({ "$level": level, "$hasChildren": !!children }, { children: [_jsx(FormItem, Object.assign({ name: [...formParentPath, "type"], rules: [requiredRule] }, { children: _jsx(Radio.Group, { options: groupTypeOptions, optionType: "button", buttonStyle: "solid", onChange: () => onTypeChange(formParentPath), size: "small" }) })), formItem && (_jsx(FormItem, Object.assign({ name: [...formParentPath, "val"], rules: [requiredRule] }, { children: formItem }))), !disabled && (_jsxs(ActionBtnsWrapper, { children: [_jsx(Tooltip, Object.assign({ title: "Remove subconditions" }, { children: _jsx(ActionBtn, { type: "link", icon: _jsx(MinusSquareOutlined, {}), onClick: () => onDelete(formParentPath), disabled: !children }) })), _jsx(Tooltip, Object.assign({ title: "Add subconditions" }, { children: _jsx(ActionBtn, { type: "link", icon: _jsx(PlusSquareOutlined, {}), onClick: () => onAdd(formParentPath) }) }))] }))] })), children && _jsx(SyntaxGroupBody, { children: children })] })));
};
export default RuleEngineConfiguratorGroup;
