import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Handle, NodeToolbar, Position, useNodeId, useReactFlow } from "reactflow";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Select, Space, Typography } from "antd";
import styled from "styled-components";
import { TEMPORARY_ID_PREFIX } from "./SettingsWorkflowConfigurator";
import { BASE_NODE_WIDTH } from "./workflow-configurator.utils";
const StatusNodeWrapper = styled.div `
	padding: 20px;
	background-color: #fafafa;
	min-width: ${BASE_NODE_WIDTH || 200}px;
	text-align: center;
`;
const SaveBtn = styled(Button) `
	width: 32px;
	height: 32px;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	border-color: ${(props) => props.theme.color_primary} !important;

	span {
		margin-top: 2px;
		color: ${(props) => props.theme.color_primary};
	}
`;
export const WorkflowStatusNode = ({ data: { value, toolbarVisible, possibleStatuses }, update, remove, statuses, }) => {
    const id = useNodeId();
    const { getEdges, getNodes } = useReactFlow();
    const [form] = Form.useForm();
    const [isEditMode, setIsEditMode] = useState(!value);
    const isTemporaryNode = id === null || id === void 0 ? void 0 : id.startsWith(TEMPORARY_ID_PREFIX);
    const nodes = getNodes();
    const edges = getEdges();
    const sourceForNewNode = isTemporaryNode
        ? nodes.find((node) => node.data.id.toString() === edges[edges.length - 1].source)
        : null;
    if (!id)
        return null;
    const onSave = ({ status }) => {
        status && update({ id, status, possibleStatuses, sourceForNewNode });
        setIsEditMode(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Handle, { type: "target", position: Position.Top }), _jsx(StatusNodeWrapper, { children: isEditMode ? (_jsx(Form, Object.assign({ form: form, onFinish: onSave, initialValues: { status: value } }, { children: _jsxs(Space, { children: [_jsx(Form.Item, Object.assign({ style: { marginBottom: 0 }, name: "status" }, { children: _jsx(Select, { style: { width: 200 }, className: "nodrag", options: statuses.map((status) => ({
                                        label: status,
                                        value: status,
                                    })) }) })), _jsx(SaveBtn, Object.assign({ onClick: form.submit }, { children: _jsx(CheckOutlined, {}) }))] }) }))) : (_jsx(Typography.Text, { children: value })) }), _jsxs(NodeToolbar, Object.assign({ isVisible: toolbarVisible, position: Position.Right }, { children: [!isEditMode && (_jsx(Button, { type: "link", icon: _jsx(EditOutlined, {}), onClick: () => setIsEditMode(true) })), _jsx(Popconfirm, Object.assign({ title: "Are you sure to delete this status?", onConfirm: () => remove(id), okText: "Yes", cancelText: "No" }, { children: _jsx(Button, { type: "link", icon: _jsx(DeleteOutlined, {}) }) }))] })), _jsx(Handle, { type: "source", position: Position.Bottom, id: "a" })] }));
};
