var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined, PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { App, Button, Col, Row, Space, Table, Tag, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import ScoringGrade from "@ui/scoring/scoring-grade/ScoringGrade";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import { CompanyRoutes } from "src/Routes";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { Axios } from "src/utils/Axios";
import { CompanyScoringAddModal } from "./CompanyScoringAddModal";
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const CompanyScoring = () => {
    const { t } = useTranslation();
    const { modal, message } = App.useApp();
    const navigate = useNavigate();
    const [addScoring, setAddScoring] = useState(false);
    const { company } = useCompany();
    const [scoringData, setScoringData] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingDeleteId, setLoadingDeleteId] = useState("");
    const { date } = useFormatter();
    const getCreditScores = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`admin/companies/${company.companyId}/scores`).then(({ data }) => setScoringData(data), (err) => {
            void Axios.error(err);
        });
    }), [company.companyId]);
    useEffect(() => {
        setLoading(true);
        getCreditScores().finally(() => setLoading(false));
    }, [getCreditScores]);
    const handleDeleteScoring = (scoreId) => {
        setLoadingDeleteId(scoreId);
        Axios.delete(`admin/companies/${company.companyId}/scores/${scoreId}`)
            .then(() => {
            void getCreditScores();
            void message.success(t("company.scoring.score_delete_success"));
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => {
            setLoadingDeleteId("");
        });
    };
    const showDeleteModal = (scoreId) => {
        void modal.confirm({
            title: t("company.scoring.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("company.scoring.delete_scoring"),
            okText: t("common.delete"),
            okButtonProps: { danger: true, loading: !!loadingDeleteId },
            onOk: () => {
                void handleDeleteScoring(scoreId);
            },
        });
    };
    const columns = [
        {
            dateIndex: "created_at",
            title: t("company.scoring.scoring_date"),
            width: 120,
            render: ({ created_at }) => {
                return _jsx(Typography.Text, { children: date(created_at) });
            },
        },
        {
            dataIndex: "final_mark",
            title: t("company.scoring.score"),
            width: 50,
            render: (final_mark) => {
                return _jsx(ScoringGrade, { score: final_mark });
            },
        },
        {
            dataIndex: "scoring_config_name",
            title: t("company.scoring.scorecard"),
            width: 140,
        },
        {
            dataIndex: "is_approved",
            title: t("company.scoring.status"),
            width: 140,
            render: (is_approved) => {
                return is_approved ? (_jsx(Tag, Object.assign({ color: "green" }, { children: t("company.scoring.status.approved") }))) : (_jsx(Tag, Object.assign({ color: "blue" }, { children: t("company.scoring.status.draft") })));
            },
        },
        {
            dataIndex: "application_id",
            title: t("company.scoring.related_application"),
            width: 140,
            render: (application_id) => {
                if (!application_id)
                    return "-";
                return (_jsx(Button, Object.assign({ type: "link", icon: _jsx(SelectOutlined, {}), onClick: (e) => {
                        e.stopPropagation();
                        navigate(generatePath(Routes.Application, {
                            applicationId: application_id,
                        }));
                    } }, { children: application_id })));
            },
        },
        {
            dataIndex: "created_by_entity_name",
            title: t("company.scoring.created_by"),
            width: 140,
        },
        {
            title: t("company.scoring.actions"),
            width: 140,
            render: (score) => {
                return (_jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "link", onClick: () => {
                                navigate(generatePath(CompanyRoutes.CompanyScoreDetails, {
                                    companyId: company.companyId,
                                    scoreId: score.company_score_id,
                                }));
                            } }, { children: t("common.view") })), !score.is_approved && (_jsx(Button, Object.assign({ type: "link", danger: true, loading: loadingDeleteId === score.company_score_id, onClick: () => showDeleteModal(score.company_score_id) }, { children: t("common.delete") })))] }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("company.menu.scoring_history") }, { children: _jsx(Button, Object.assign({ type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => setAddScoring(true) }, { children: t("common.add") })) })), _jsx(Wrapper, { children: _jsx(Row, { children: _jsx(Col, Object.assign({ sm: 24 }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "company_score_id", dataSource: scoringData === null || scoringData === void 0 ? void 0 : scoringData.result, pagination: false }) })) }) }), addScoring && (_jsx(CompanyScoringAddModal, { onClose: () => setAddScoring(false), getCreditScores: getCreditScores }))] }));
};
export default CompanyScoring;
