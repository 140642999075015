import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card as AntdCard, Button, CardProps, Divider, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import { CountryCode, SignatoryResponse } from "@teylor-tools/Api";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";

import { DataCardItem } from "../data-display/DataCard";
import RepresentativeOwnershipDetails from "../representative-ownership-details/RepresentativeOwnershipDetails";
import { useTranslations } from "../translations/translations";

const { Text } = Typography;

const Card = styled(AntdCard)`
	max-width: 720px;
`;

interface RepresentativeCardProps extends CardProps {
	representative: SignatoryResponse;
	showEditTinButton?: boolean;
	onEditTin?: () => void;
	onEditRepresentative?: (id: string) => void;
	onDeleteRepresentative?: (representative: SignatoryResponse) => void;
	showExtra?: boolean;
	creditCheck?: JSX.Element;
	pepCheck?: JSX.Element;
	netAssetValue?: JSX.Element;
}

const RepresentativeCard = ({
	representative,
	showEditTinButton = false,
	onEditTin,
	onEditRepresentative,
	onDeleteRepresentative,
	showExtra = false,
	creditCheck,
	pepCheck,
	netAssetValue,
	...props
}: RepresentativeCardProps) => {
	const t = useTranslations();

	const representativeName = `${representative.first_name} ${representative.last_name}`;

	return (
		<Card
			title={representativeName}
			extra={
				showExtra && (
					<>
						<Button
							type="link"
							onClick={() =>
								onEditRepresentative && onEditRepresentative(representative.signatory_id)
							}
							icon={<EditOutlined />}
						/>
						<Button
							type="link"
							onClick={() => onDeleteRepresentative && onDeleteRepresentative(representative)}
							icon={<DeleteOutlined />}
						/>
					</>
				)
			}
			{...props}
		>
			<DataCardItem label={t.representativeCard.position} value={representative.position} />
			<Divider />

			<RepresentativeOwnershipDetails representative={representative} />

			<Divider />

			<DataCardItem label={t.representativeCard.phone} value={representative.telephone} />
			<DataCardItem label={t.representativeCard.email} value={representative.email} />

			<Divider />

			<DataCardItem
				label={t.representativeCard.address}
				value={
					<Text
						style={{
							whiteSpace: "pre-line",
						}}
					>
						{`${representative.street} ${representative.street_number},
					${representative.postcode} ${representative.location},
					${t.countries[representative.country as CountryCode]}`}
					</Text>
				}
			/>

			<Divider />

			<DataCardItem
				label={t.representativeCard.nationality}
				value={representative.nationality && t.countries[representative.nationality as CountryCode]}
			/>
			<DataCardItem
				label={t.representativeCard.dateOfBirth}
				value={representative.birthdate ? dayjs(representative.birthdate).format(dayMonthYear) : ""}
			/>
			<DataCardItem
				label={t.representativeCard.tin}
				value={
					representative.taxid ? (
						<>
							<Text>{representative.taxid}</Text>
							{showEditTinButton && (
								<Button
									type="link"
									onClick={onEditTin}
									style={{ padding: 0, height: "auto" }}
									icon={<EditOutlined />}
								/>
							)}
						</>
					) : showEditTinButton ? (
						<Button type="link" onClick={onEditTin} style={{ padding: 0, height: "auto" }}>
							{t.representativeCard.addTin}
						</Button>
					) : (
						"-"
					)
				}
			/>
			{creditCheck && (
				<>
					<Divider />
					{creditCheck}
				</>
			)}
			{pepCheck && (
				<>
					<Divider />
					{pepCheck}
				</>
			)}
			{netAssetValue && (
				<>
					<Divider />
					{netAssetValue}
				</>
			)}
		</Card>
	);
};

export default RepresentativeCard;
