import { Currency, Pricing } from "../Api";
import { useFormatter } from "./formatter";

export type IndicativeMonthlyRatePricing = Pick<
	Pricing,
	| "offer_representative_monthly_rate_high"
	| "offer_representative_monthly_rate_low"
	| "offer_is_range"
>;

export type IndicativeInterestRatePricing = Pick<
	Pricing,
	"offer_rate_high" | "offer_rate_low" | "offer_is_range"
>;

export type IndicativeInterestRate = (pricing: IndicativeInterestRatePricing | undefined) => string;

export const useIndicativeRate = (): {
	indicativeMonthlyRate: (
		pricing: IndicativeMonthlyRatePricing | undefined,
		currency: Currency | undefined,
		showFractions?: boolean
	) => string;
	indicativeInterestRate: IndicativeInterestRate;
} => {
	const { currency: formatCurrency, decimalToPercent } = useFormatter();

	return {
		indicativeMonthlyRate: (pricing, currency, showFractions) => {
			if (!pricing?.offer_representative_monthly_rate_low || !currency) return "";

			const {
				offer_representative_monthly_rate_low: rateLow,
				offer_representative_monthly_rate_high: rateHigh,
			} = pricing;
			const currencyConfig = {
				currency: currency,
				...(showFractions !== undefined && { showFractions }),
			};

			if (pricing.offer_is_range) {
				// backwards compatibility
				if (!rateHigh) return formatCurrency(rateLow, currencyConfig);

				if (rateHigh === rateLow) return formatCurrency(rateLow, currencyConfig);

				return `${formatCurrency(rateLow, currencyConfig)} - ${formatCurrency(
					rateHigh,
					currencyConfig
				)}`;
			}

			return formatCurrency(rateLow, currencyConfig);
		},
		indicativeInterestRate: (pricing) => {
			if (!pricing?.offer_rate_low) return "";

			const rateLow = decimalToPercent({ percent: pricing.offer_rate_low });
			const rateHigh =
				pricing.offer_rate_high && decimalToPercent({ percent: pricing.offer_rate_high });

			if (pricing.offer_is_range) {
				// backwards compatibility
				if (!rateHigh) return `${rateLow}%`;

				if (rateHigh === rateLow) return `${rateLow}%`;

				return `${rateLow}% - ${rateHigh}%`;
			}

			return `${rateLow}%`;
		},
	};
};
