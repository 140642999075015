import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { BOUserFeature, FeatureName, FeatureValueItem } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { Routes } from "src/Routes";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";
const { Content, Sider } = Layout;
const PortfolioLayout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { features } = useSelector(({ configState }) => configState);
    const { features: permissions } = useSelector((state) => state.profileState);
    const showConcentrationLimits = useMemo(() => {
        var _a, _b, _c;
        return (_c = (_b = (_a = features
            .find((f) => f.name === FeatureName.PortfolioMonitoring)) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.includes(FeatureValueItem.PortfolioConcentrationLimits);
    }, [features]);
    const showPortfolioCompanies = permissions === null || permissions === void 0 ? void 0 : permissions.includes(BOUserFeature.PortfolioMonitoring);
    const menuItems = [
        {
            key: Routes.PortfolioOverview,
            label: t("portfolio.overview"),
        },
        ...(showPortfolioCompanies
            ? [
                {
                    key: Routes.PortfolioCompanies,
                    label: t("portfolio.companies"),
                },
            ]
            : []),
        ...(showConcentrationLimits
            ? [
                {
                    key: Routes.PortfolioConcentrationLimits,
                    label: t("portfolio.concentration-limits"),
                },
            ]
            : []),
    ];
    return (_jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(Sider, Object.assign({ theme: "light", width: 200 }, { children: _jsx(Menu, { selectedKeys: [location.pathname], mode: "inline", onClick: (menuItem) => {
                        navigate(menuItem.key);
                    }, items: menuItems, withHeader: true, headerTitle: t("portfolio.title") }) })), _jsx(Layout, { children: _jsx(ErrorBoundaryWrapper, { children: _jsx(Content, { children: _jsx(Outlet, {}) }) }) })] })));
};
export default PortfolioLayout;
