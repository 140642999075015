import { useMemo, useState } from "react";

import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Modal, Select, Space, Tooltip, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { RuleObject } from "rc-field-form/lib/interface";
import styled from "styled-components";

import { IndustryCodeKindEnum } from "@teylor-tools/Api";
import {
	IndustryCodesCreateRequest,
	IndustryCodesCreateRequestItem,
	IndustryCodesResponse,
} from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";

import PercentInput from "../form/inputs/percent-input/PercentInput";
import { useTranslations } from "../translations/translations";

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
`;

interface Props {
	industryCodes?: IndustryCodesResponse;
	onClose: () => void;
	onSave: (values: IndustryCodesCreateRequest) => Promise<void>;
}

const IndustryCodesEditModal = ({ industryCodes, onClose, onSave }: Props) => {
	const t = useTranslations();
	const [form] = useForm<IndustryCodesCreateRequest>();
	const [loading, setLoading] = useState(false);
	const { decimalToPercent, percentToDecimal } = useFormatter();
	const [isCollapseDisplayed, setIsCollapseDisplayed] = useState(!!industryCodes?.codes.length);
	const [activeKeys, setActiveKeys] = useState<string[]>(industryCodes?.codes.length ? ["0"] : []);
	const [panelsWithError, setPanelsWithError] = useState<string[]>([]);
	const [kind, setKind] = useState(industryCodes?.kind);

	const requiredRule = {
		required: true,
		message: t.industryCodesEditModal.fieldRequired,
	};

	const initialCodes: IndustryCodesCreateRequestItem[] = useMemo(
		() =>
			(industryCodes?.codes || []).map(({ code, weight, description }) => ({
				code,
				weight:
					weight && isValidNumericValue(weight)
						? decimalToPercent({ percent: weight, decimalPlaces: 0 })
						: undefined,
				description,
			})),
		[industryCodes]
	);

	const onFinish = (values: IndustryCodesCreateRequest) => {
		setLoading(true);
		onSave({
			...values,
			codes: values.codes.map(({ weight, ...code }) => {
				return {
					...code,
					weight: weight == null ? undefined : percentToDecimal(weight),
				};
			}),
		})
			.then(() => onClose())
			.finally(() => {
				setLoading(false);
			});
	};

	const validatePanels = () => {
		// setTimeout to let the form validations to finish
		setTimeout(() => {
			const panelsWithErrors: string[] = [];
			const fields = form.getFieldsError();

			fields.forEach((field) => {
				if (!field.errors.length) return;
				panelsWithErrors.push(field.name[1]?.toString());
			});

			setPanelsWithError([...new Set(panelsWithErrors)]);
		}, 0);
	};

	const validateCode = (_: RuleObject, code: string): Promise<void | string> => {
		if (!code || !kind) {
			return Promise.resolve();
		}

		if (
			(kind === IndustryCodeKindEnum.Wz2008 && code.match(/^\d{5}$/)) ||
			(kind === IndustryCodeKindEnum.Noga2008 && code.match(/^\d{6}$/))
		) {
			return Promise.resolve();
		}

		return Promise.reject(t.industryCodesEditModal.codeError);
	};

	return (
		<Modal
			title={t.industryCodesEditModal.title}
			open
			okText={t.industryCodesEditModal.update}
			onOk={() => {
				form.submit();
				validatePanels();
			}}
			okButtonProps={{ loading, disabled: !isCollapseDisplayed }}
			onCancel={onClose}
		>
			<Form layout="vertical" onFinish={onFinish} form={form} onChange={validatePanels}>
				{isCollapseDisplayed && (
					<Form.Item
						name="kind"
						label={t.industryCodesEditModal.type}
						initialValue={kind}
						required
						rules={[requiredRule]}
					>
						<Select
							onChange={(value: IndustryCodeKindEnum) => {
								setKind(value);
								void form.validateFields();
							}}
							options={Object.values(IndustryCodeKindEnum).map((value) => {
								return {
									label: t.industryCodesEditModal.codeEnum[value],
									value: value,
								};
							})}
						/>
					</Form.Item>
				)}

				<Form.List initialValue={initialCodes} name="codes">
					{(fields, { add, remove }, { errors }) => {
						return (
							<>
								{!!fields.length && (
									<Collapse activeKey={activeKeys} onChange={(keys) => setActiveKeys([...keys])}>
										{fields.map(({ key, name, ...restField }, idx) => {
											const panelKey = key.toString();
											return (
												<Collapse.Panel
													forceRender
													key={panelKey}
													header={
														<Typography.Text
															type={panelsWithError.includes(panelKey) ? "danger" : undefined}
														>
															{t.industryCodesEditModal.industryCodeNumber(idx + 1)}
														</Typography.Text>
													}
													extra={
														<Button
															type="link"
															disabled={fields.length <= 1}
															onClick={() => remove(name)}
														>
															<DeleteOutlined />
														</Button>
													}
												>
													<Form.Item
														name={[name, "code"]}
														label={
															<>
																{t.industryCodesEditModal.code}
																<Tooltip title={t.industryCodesEditModal.codeTooltip}>
																	<QuestionCircleOutlined style={{ marginLeft: 4 }} />
																</Tooltip>
															</>
														}
														{...restField}
														required
														rules={[requiredRule, { validator: validateCode }]}
													>
														<Input />
													</Form.Item>
													<Form.Item
														name={[name, "description"]}
														label={
															<Typography.Text>
																{t.industryCodesEditModal.description}
															</Typography.Text>
														}
														{...restField}
														required
														rules={[requiredRule]}
													>
														<TextArea />
													</Form.Item>
													<Form.Item
														name={[name, "weight"]}
														label={
															<Space>
																<Typography.Text>{t.industryCodesEditModal.weight}</Typography.Text>
																<Typography.Text type="secondary" style={{ fontSize: 12 }}>
																	{t.industryCodesEditModal.optional}
																</Typography.Text>
															</Space>
														}
														{...restField}
													>
														<PercentInput
															style={{ width: "100%" }}
															max={100}
															min={0}
															allowEmptyValue
														/>
													</Form.Item>
												</Collapse.Panel>
											);
										})}
									</Collapse>
								)}
								<ButtonWrapper>
									<Form.Item>
										<Button
											style={{ margin: "auto" }}
											type="link"
											icon={<PlusOutlined />}
											onClick={() => {
												add();
												setIsCollapseDisplayed(true);
												setActiveKeys((prev) => [...prev, fields.length.toString()]);
											}}
										>
											{t.industryCodesEditModal.addIndustryCode}
										</Button>
									</Form.Item>
								</ButtonWrapper>
								<Form.ErrorList errors={errors} />
							</>
						);
					}}
				</Form.List>
			</Form>
		</Modal>
	);
};

export default IndustryCodesEditModal;
