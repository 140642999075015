import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditOutlined, PlusOutlined, SaveOutlined, ScanOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import styled from "styled-components";
import { FeatureName, } from "@teylor-tools/Api";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import { FinancialsEmpty } from "@ui/financials-table/FinancialsEmpty";
import { FinancialsModal } from "@ui/financials-table/FinancialsModal";
import FinancialsTable from "@ui/financials-table/FinancialsTable";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import Loader from "src/components/ui/Loader";
import { Axios } from "src/utils/Axios";
import "./Financials.css";
const ButtonsWrapper = styled.div `
	display: flex;
	gap: 16px;
`;
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
`;
const ContentWrapper = styled.div `
	overflow: auto;
	height: calc(100vh - 145px);
`;
const Financials = ({ getFinancialsData, updateFinancialsData, afterUpdate, finCrPath, allowEdit, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [financials, setFinancials] = useState([]);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { features } = useSelector((state) => state.configState);
    const tableRef = useRef();
    const ocrEnabled = useMemo(() => { var _a; return (_a = features.find((feature) => feature.name === FeatureName.Ocr)) === null || _a === void 0 ? void 0 : _a.is_active; }, [features]);
    const getFinancials = () => {
        setIsLoading(true);
        getFinancialsData()
            .then((data) => setFinancials(data.financials), (err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    };
    const createFinancialStatement = ({ statementType, statementDate }) => {
        if (!updateFinancialsData)
            return;
        setIsLoading(true);
        const existingStatements = financials.map(({ financials_date, statement_type, input_data }) => ({
            financials_date,
            statement_type,
            input_data,
        }));
        updateFinancialsData({
            financials: [
                ...existingStatements,
                {
                    financials_date: statementDate.format(rawDateFormat),
                    statement_type: statementType,
                    input_data: {},
                },
            ],
        })
            .then(getFinancials, (err) => void Axios.error(err))
            .finally(() => {
            setIsLoading(false);
            setIsAddModalVisible(false);
            afterUpdate && afterUpdate();
        });
    };
    const updateStatements = () => {
        var _a;
        if (!updateFinancialsData)
            return;
        setIsLoadingSave(true);
        const financials = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.getDataToUpdate();
        if (!financials)
            return;
        updateFinancialsData({ financials })
            .then(getFinancials, (err) => void Axios.error(err))
            .finally(() => {
            setIsLoadingSave(false);
            setIsEditing(false);
            afterUpdate && afterUpdate();
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getFinancials, []);
    return (_jsxs(Wrapper, Object.assign({ className: "financials-wrapper" }, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("application.financials.financials") }, { children: _jsxs(ButtonsWrapper, { children: [ocrEnabled && finCrPath && (_jsx(Button, Object.assign({ icon: _jsx(ScanOutlined, {}), onClick: () => navigate(finCrPath) }, { children: t("application.financials.fincr") }))), allowEdit && (_jsx(_Fragment, { children: isEditing ? (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                            var _a;
                                            setIsEditing(false);
                                            (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.resetTable();
                                        } }, { children: t("common.cancel") })), _jsx(Button, Object.assign({ type: "primary", icon: _jsx(SaveOutlined, {}), onClick: updateStatements, loading: isLoadingSave, "data-cy": "save-financial-button" }, { children: t("common.save") }))] })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), onClick: () => setIsAddModalVisible(true), "data-cy": "add-statement-btn" }, { children: t("common.add") })), !!financials.length && (_jsx(Button, Object.assign({ type: "primary", icon: _jsx(EditOutlined, {}), onClick: () => setIsEditing(true), "data-cy": "edit-financial-button" }, { children: t("common.edit") })))] })) }))] }) })), _jsx(ContentWrapper, { children: (isLoading || isLoadingSave) && !financials.length ? (_jsx(Loader, {})) : !(financials === null || financials === void 0 ? void 0 : financials.length) ? (_jsx(FinancialsEmpty, { addFinancials: allowEdit ? () => setIsAddModalVisible(true) : undefined })) : (_jsx(Spin, Object.assign({ spinning: isLoading || isLoadingSave }, { children: _jsx(FinancialsTable, { financials: financials, isEditing: isEditing, ref: tableRef }) }))) }), isAddModalVisible && (_jsx(FinancialsModal, { onCancel: () => {
                    setIsAddModalVisible(false);
                    if (!financials.length) {
                        setIsEditing(false);
                    }
                }, onFinish: createFinancialStatement, loading: isLoading }))] })));
};
export default Financials;
