var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FilePdfOutlined } from "@ant-design/icons";
import { App, Button, Checkbox, Col, Form, Modal, Radio, Row, Select, Typography } from "antd";
import { Divider } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import styled from "styled-components";
import { AllBanksLoanPurposes, FeatureName, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { categorizeDocuments } from "@teylor-tools/utils/documents/categorize-documents";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { LOAN_AMOUNT, LOAN_DURATION } from "src/pages/create-application/constants";
import { Axios } from "src/utils/Axios";
const { Option } = Select;
const { Text } = Typography;
const CategoryTypeWrapper = styled.div `
	padding: 10px 16px;
`;
const DocumentItemsWrapper = styled.div `
	margin-top: 10px;
`;
const DocumentItem = styled.div `
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 10px;
	align-items: center;
`;
const IconFilePdf = styled(FilePdfOutlined) `
	font-size: 18px;
	margin-left: 10px;
	color: ${(props) => props.theme.color_primary};
`;
const FORM_ID = "clone-application-form";
const CloneApplication = ({ application, onClose }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { features, defaultCurrency } = useSelector((rootState) => rootState.configState);
    const [loading, setLoading] = useState(false);
    const [loanTypes, setLoanTypes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [form] = useForm();
    const allBanksLoanPurpose = Form.useWatch("allBanksLoanPurpose", form);
    const requiredRule = {
        required: true,
        message: t("application.create-application.required-field"),
    };
    const { currency } = useFormatter();
    const categorizedDocuments = categorizeDocuments(documents);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const isFactoring = Form.useWatch("allBanksLoanPurpose", form) ===
        AllBanksLoanPurposes.Factoring;
    const getLoanTypes = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const defaultPartner = (_c = features.find((f) => f.name === FeatureName.DefaultPartner)) === null || _c === void 0 ? void 0 : _c.value.partner_id;
        if (!defaultPartner)
            return;
        yield Axios.get(`/admin/partners/${defaultPartner}/config`)
            .then(({ data }) => {
            setLoanTypes(data.application_types);
        })
            .catch((error) => {
            void Axios.error(error);
        });
    }), [features]);
    const getDocuments = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`admin/applications/${application.applicationId}/documents`, { only_cloneable: true })
            .then(({ data }) => {
            setDocuments(data.result);
        })
            .catch((error) => {
            void Axios.error(error);
        });
    }), [application.applicationId]);
    const validateAmount = (rule, value) => {
        if (value === null || !allBanksLoanPurpose) {
            return Promise.resolve();
        }
        const minLoanAmount = LOAN_AMOUNT.min[allBanksLoanPurpose];
        if (minLoanAmount && value < minLoanAmount) {
            return Promise.reject(`${t("application_form.errors.minFinancingAmount")}: ${currency(minLoanAmount, {
                showFractions: false,
            })}`);
        }
        const maxLoanAmount = LOAN_AMOUNT.max[allBanksLoanPurpose];
        if (maxLoanAmount && value > maxLoanAmount) {
            return Promise.reject(`${t("application_form.errors.maxFinancingAmount")}: ${currency(maxLoanAmount, {
                showFractions: false,
            })}`);
        }
        return Promise.resolve();
    };
    const validateLoanDuration = (rule, value) => {
        if (value === null || !allBanksLoanPurpose) {
            return Promise.resolve();
        }
        const minLoanDuration = LOAN_DURATION.min[allBanksLoanPurpose];
        if (minLoanDuration && value < minLoanDuration) {
            return Promise.reject(t("application_form.errors.minLoanDuration", { min: minLoanDuration }));
        }
        const maxLoanDuration = LOAN_DURATION.max[allBanksLoanPurpose];
        if (maxLoanDuration && value > maxLoanDuration) {
            return Promise.reject(t("application_form.errors.maxLoanDuration", { max: maxLoanDuration }));
        }
        return Promise.resolve();
    };
    const onCloneApplication = (values) => {
        setLoading(true);
        Axios.post(`/admin/applications/${application.applicationId}/clone`, Object.assign(Object.assign({}, values), { documentIds: selectedDocuments }))
            .then(() => {
            void message.success(t("application_cloned_message"));
            onClose();
        })
            .catch((error) => Axios.error(error))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getLoanTypes().finally(() => setLoading(false));
    }, [getLoanTypes]);
    useEffect(() => {
        setLoading(true);
        getDocuments().finally(() => setLoading(false));
    }, [getDocuments]);
    const handleSelectDocument = (id) => (e) => {
        if (e.target.checked) {
            setSelectedDocuments([...selectedDocuments, id]);
        }
        else {
            setSelectedDocuments(selectedDocuments.filter((document_id) => document_id !== id));
        }
    };
    const onChangeSelectAllDocs = (e) => {
        if (e.target.checked) {
            setSelectedDocuments(documents.map(({ document_id }) => document_id));
        }
        else {
            setSelectedDocuments([]);
        }
    };
    return (_jsx(Modal, Object.assign({ title: t("application.clone.title"), open: true, footer: _jsxs(Row, Object.assign({ justify: "end", gutter: 16 }, { children: [_jsx(Col, { children: _jsx(Button, Object.assign({ type: "default", onClick: onClose }, { children: t("common.cancel") })) }), _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", form: FORM_ID, loading: loading }, { children: t("common.done") })) })] })), onCancel: onClose }, { children: _jsxs(Form, Object.assign({ id: FORM_ID, form: form, layout: "vertical", initialValues: Object.assign({ allBanksLoanPurpose: application.allBanksLoanPurpose }, (application.allBanksLoanPurpose === AllBanksLoanPurposes.Factoring
                ? {
                    annualSalesPreviousYear: (_a = application.factoring) === null || _a === void 0 ? void 0 : _a.annual_sales_previous_year,
                    hasInternationalCustomers: (_b = application.factoring) === null || _b === void 0 ? void 0 : _b.has_international_customers,
                }
                : {
                    loanSize: application.loanSize,
                    loanDuration: application.loanDuration,
                })), onFinish: onCloneApplication }, { children: [_jsx(Form.Item, Object.assign({ name: "allBanksLoanPurpose", label: t("application_form.loan-type"), rules: [requiredRule] }, { children: _jsx(Select, Object.assign({ onChange: () => void form.validateFields() }, { children: loanTypes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`application.loan_purpose.${key}`) }), key))) })) })), isFactoring ? (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: "annualSalesPreviousYear", label: t("factoring.financials.annual_sales_previous_year"), required: true, "data-cy": "annual-sales-previous-year", rules: [
                                {
                                    validator: (_, value) => {
                                        return value && value > 0
                                            ? Promise.resolve()
                                            : Promise.reject(new Error(t("application.create-application.required-field")));
                                    },
                                },
                            ] }, { children: _jsx(CurrencyInput, { allowEmptyValue: true, currency: defaultCurrency, style: { width: "100%" } }) })), _jsx(Form.Item, Object.assign({ name: "hasInternationalCustomers", label: t("application.create-application.factoring.has_international_customers"), rules: [requiredRule], required: true }, { children: _jsxs(Radio.Group, { children: [_jsx(Radio, Object.assign({ value: true }, { children: t("common.yes") })), _jsx(Radio, Object.assign({ value: false }, { children: t("common.no") }))] }) }))] })) : (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: "loanSize", label: t("application_form.loan-amount"), rules: [
                                requiredRule,
                                {
                                    validator: validateAmount,
                                },
                            ] }, { children: _jsx(CurrencyInput, { style: { width: "100%" } }) })), _jsx(Form.Item, Object.assign({ name: "loanDuration", label: t("application_form.loan-duration"), rules: [
                                {
                                    validator: validateLoanDuration,
                                },
                                requiredRule,
                            ] }, { children: _jsx(NumberInputLocalized, { style: { width: "100%" }, controls: false, addonAfter: t("common.months") }) }))] })), !!documents.length && (_jsxs(Form.Item, Object.assign({ name: "documentIds", label: t("application-clone.documents-to-clone") }, { children: [_jsx(CategoryTypeWrapper, { children: _jsx(Checkbox, Object.assign({ indeterminate: selectedDocuments.length > 0 && selectedDocuments.length < documents.length, checked: documents.length === selectedDocuments.length, onChange: onChangeSelectAllDocs }, { children: t("select_all") })) }), categorizedDocuments.map((category) => {
                            return (_jsxs(Fragment, { children: [_jsxs("div", { children: [_jsx(Text, Object.assign({ strong: true }, { children: t(`documents.category.${category.categoryName}.name`) })), category.types.map(({ typeName, docs }, id) => {
                                                return (_jsxs(CategoryTypeWrapper, { children: [_jsx(Text, Object.assign({ strong: true, style: { fontSize: 12 } }, { children: t(`documents.type.${typeName}.name`) })), _jsx(DocumentItemsWrapper, { children: docs.map((doc) => {
                                                                return (_jsx(Checkbox, Object.assign({ value: doc.document_id, checked: selectedDocuments.includes(doc.document_id), onChange: handleSelectDocument(doc.document_id), style: {
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        marginBottom: 10,
                                                                    } }, { children: _jsxs(DocumentItem, { children: [_jsx(IconFilePdf, {}), _jsx(Text, { children: doc.document_name })] }) }), doc.document_id));
                                                            }) })] }, id));
                                            })] }), _jsx(Divider, {})] }, category.categoryName));
                        })] })))] })) })));
};
export default CloneApplication;
