import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { App, Button, Checkbox, Form, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import { MarkdownEditor } from "@ui/markdown/editor/MarkdownEditor";
import { Routes } from "src/Routes";
import { useLayout } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const StyledForm = styled((Form)) `
	flex: auto;
	padding: 24px;
`;
const ButtonWrapper = styled.div `
	text-align: right;
`;
const ApplicationAnalystReport = ({ applicationId, disabled, updatePendingItems }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const { setActiveMenu } = useLayout();
    useEffect(() => {
        setLoading(true);
        Axios.get(`/admin/applications/${applicationId}/scoring_feedback`)
            .then(({ data }) => {
            form.setFieldsValue(data);
        }, (error) => Axios.error(error))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);
    const save = (values) => {
        setLoading(true);
        Axios.patch(`/admin/applications/${applicationId}/scoring_feedback`, values)
            .then(() => {
            void message.success(t("application.qualitative_analysis.saved"));
            setActiveMenu(undefined);
        }, (error) => {
            void Axios.error(error, t("application.qualitative_analysis.error.be"));
        })
            .finally(updatePendingItems);
    };
    if (loading)
        return _jsx(Spin, {});
    return (_jsxs(StyledForm, Object.assign({ form: form, layout: "vertical", onFinish: save, disabled: disabled || loading }, { children: [_jsx(Form.Item, Object.assign({ label: t("application.qualitative_analysis.report"), name: "scoring_feedback", required: false, valuePropName: "markdown", rules: [
                    {
                        required: true,
                        min: 200,
                        message: t("application_form.errors.fieldMustBeMinChars", { min: 200 }),
                    },
                ], style: { marginBottom: 32 } }, { children: _jsx(MarkdownEditor, {}) })), _jsx(Form.Item, Object.assign({ name: "flags_reviewed", valuePropName: "checked", rules: [
                    {
                        validator: (_, value) => value
                            ? Promise.resolve()
                            : Promise.reject(t("application.qualitative_analysis.checkbox")),
                    },
                ] }, { children: _jsx(Checkbox, { children: _jsx(Trans, { i18nKey: "application.qualitative_analysis.confirm-report", components: [
                            _jsx(Link, { to: generatePath(Routes.ApplicationQuantitative, {
                                    applicationId: applicationId,
                                }) }, 1),
                            _jsx(Link, { to: generatePath(Routes.ApplicationQualitative, {
                                    applicationId: applicationId,
                                }) }, 2),
                        ] }) }) })), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("common.save") })) })] })));
};
export default ApplicationAnalystReport;
