import { EuroCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";

import { useTranslations } from "../translations/translations";

const { Title, Text } = Typography;

const Wrapper = styled.div`
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;

const EuroIcon = styled(EuroCircleOutlined)`
	font-size: 48px;
	color: #d9d9d9;
	margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
	margin-top: 32px;
`;

export const FinancialsEmpty = ({ addFinancials }: { addFinancials?: () => void }) => {
	const t = useTranslations();

	return (
		<Wrapper>
			<EuroIcon />
			<Title level={5}>{t.financialsTable.emptyScreen.title}</Title>
			{addFinancials && (
				<>
					<Text type="secondary">{t.financialsTable.emptyScreen.subtitle}</Text>

					<ButtonWrapper>
						<Button
							type="primary"
							size="large"
							onClick={addFinancials}
							icon={<PlusOutlined />}
							data-cy="empty-screen-add-statement-btn"
						>
							{t.financialsTable.emptyScreen.addStatementBtn}
						</Button>
					</ButtonWrapper>
				</>
			)}
		</Wrapper>
	);
};
