import { AllBanksLoanPurposes } from "@teylor-tools/Api";
export var Steps;
(function (Steps) {
    Steps["CompanyDetails"] = "companyDetails";
    Steps["LoanDetails"] = "loanDetails";
})(Steps || (Steps = {}));
export const fieldNames = {
    [Steps.CompanyDetails]: {
        companyId: "companyId",
        companyName: "companyName",
        companyType: "companyType",
        street: "street",
        houseNumber: "houseNumber",
        companyLocation: "companyLocation",
        companyPostCode: "companyPostCode",
        companyCountry: "companyCountry",
        taxIdNumber: "taxIdNumber",
        businessPurpose: "businessPurpose",
        companyCreditProviderId: "companyCreditProviderId",
    },
    [Steps.LoanDetails]: {
        allBanksLoanPurpose: "allBanksLoanPurpose",
        loanSize: "loanSize",
        loanDuration: "loanDuration",
        purposeSelect: "purposeSelect",
        purposeDescription: "purposeDescription",
        internationalCustomers: "internationalCustomers",
        annualSales: "annualSales",
    },
};
export const stepsArray = Object.values(Steps);
export const LOAN_AMOUNT = {
    min: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 15000,
        [AllBanksLoanPurposes.ObjectFinancing]: 15000,
        [AllBanksLoanPurposes.Other]: 15000,
        [AllBanksLoanPurposes.Whitelabel]: 50000,
    },
    max: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 100000000,
        [AllBanksLoanPurposes.ObjectFinancing]: 100000000,
        [AllBanksLoanPurposes.Other]: 100000000,
        [AllBanksLoanPurposes.Whitelabel]: 500000,
    },
};
export const LOAN_DURATION = {
    min: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 6,
        [AllBanksLoanPurposes.ObjectFinancing]: 6,
        [AllBanksLoanPurposes.Other]: 6,
        [AllBanksLoanPurposes.Whitelabel]: 12,
    },
    max: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 120,
        [AllBanksLoanPurposes.ObjectFinancing]: 240,
        [AllBanksLoanPurposes.Other]: 240,
        [AllBanksLoanPurposes.Whitelabel]: 60,
    },
};
