var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormItem from "antd/es/form/FormItem";
import { GlobalRateName, GlobalRatePeriod, MinRateSourcesEnum, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardItem } from "@ui/data-display/DataCard";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import { DataCardDivider, DataCardItemEditable } from "../../../components/DataCard";
export const GridRate = ({ gridRate, productConfig }) => {
    var _a;
    const { t } = useTranslation();
    const { decimalToPercent } = useFormatter();
    if (!((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _a === void 0 ? void 0 : _a.includes(MinRateSourcesEnum.GridRate))) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(DataCardItem, { label: t("application.pricing.grid-rate"), value: isValidNumericValue(gridRate)
            ? decimalToPercent({
                percent: gridRate,
                showPercentSymbol: true,
            })
            : "" }));
};
export const HedgingRate = ({ isEditable, onUpdate, rates, hedgingRate, productConfig, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { decimalToPercent, percentToDecimal } = useFormatter();
    if (!((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _a === void 0 ? void 0 : _a.includes(MinRateSourcesEnum.HedgingRate))) {
        return _jsx(_Fragment, {});
    }
    const globalHedgingRate = (_b = rates.find(({ global_rate_name, global_rate_period }) => global_rate_name === GlobalRateName.Hedging && global_rate_period === GlobalRatePeriod.NA)) === null || _b === void 0 ? void 0 : _b.global_rate_value;
    const handleUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        onUpdate && onUpdate({ hedging_rate: percentToDecimal(v) });
    };
    return (_jsx(DataCardItemEditable, { label: t("application.pricing.hedging-rate"), displayedValue: isValidNumericValue(hedgingRate)
            ? decimalToPercent({
                percent: hedgingRate,
                showPercentSymbol: true,
            })
            : hedgingRate === null && globalHedgingRate && isValidNumericValue(globalHedgingRate)
                ? decimalToPercent({
                    percent: globalHedgingRate,
                    showPercentSymbol: true,
                })
                : "", isEditable: isEditable, onChange: handleUpdate, customFormItem: (_a) => {
            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
            return (_jsx(FormItem, Object.assign({}, props, { rules: [
                    {
                        type: "number",
                        max: 10,
                        message: t("application_form.errors.fieldMustBeMax", {
                            max: `10%`,
                        }),
                    },
                ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
        }, formItemValue: isValidNumericValue(hedgingRate)
            ? Number(decimalToPercent({
                percent: hedgingRate,
                withLocale: false,
            }))
            : 0 }));
};
export const InterestRate = ({ isEditable, onUpdate, minimumInterestRate, interestRate, productConfig, }) => {
    const { t } = useTranslation();
    const { decimalToPercent, percentToDecimal } = useFormatter();
    const minInterest = useMemo(() => {
        return (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate)
            ? -5
            : (isValidNumericValue(minimumInterestRate) &&
                Number(decimalToPercent({
                    percent: minimumInterestRate,
                    decimalPlaces: 2,
                    withLocale: false,
                }))) ||
                0;
    }, [minimumInterestRate, productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate, decimalToPercent]);
    const minInterestLocalised = useMemo(() => {
        return (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate)
            ? (-5).toFixed(2)
            : (isValidNumericValue(minimumInterestRate) &&
                decimalToPercent({ percent: minimumInterestRate })) ||
                "0";
    }, [minimumInterestRate, productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_negative_internal_rate, decimalToPercent]);
    const handleUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        onUpdate && onUpdate({ interest_rate: percentToDecimal(v) });
    };
    return (_jsx(DataCardItemEditable, { label: t("application.pricing.interest-rate"), displayedValue: isValidNumericValue(interestRate)
            ? `${decimalToPercent({ percent: interestRate })}%`
            : undefined, isEditable: isEditable, onChange: handleUpdate, customFormItem: (_a) => {
            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
            return (_jsx(FormItem, Object.assign({}, props, { rules: [
                    {
                        type: "number",
                        min: minInterest,
                        message: t("application_form.errors.fieldMustBeMin", {
                            min: `${minInterestLocalised}%`,
                        }),
                    },
                    {
                        type: "number",
                        max: 99,
                        message: t("application_form.errors.fieldMustBeMax", {
                            max: `99%`,
                        }),
                    },
                ] }, { children: _jsx(PercentInput, Object.assign({}, inputProps, { "data-cy": "interest-rate-field" })) })));
        }, formItemValue: isValidNumericValue(interestRate)
            ? Number(decimalToPercent({
                percent: interestRate,
                withLocale: false,
            }))
            : 0 }));
};
export const PrepaymentRate = ({ isEditable, onUpdate, prepaymentRate, productConfig, }) => {
    const { t } = useTranslation();
    const { decimalToPercent, percentToDecimal } = useFormatter();
    if (!(productConfig === null || productConfig === void 0 ? void 0 : productConfig.prepayment_rate))
        return _jsx(_Fragment, {});
    const handleUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        onUpdate && onUpdate({ prepayment_rate: percentToDecimal(v) });
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataCardDivider, {}), _jsx(DataCardItemEditable, { label: t("application.pricing.prepayment_rate"), displayedValue: isValidNumericValue(prepaymentRate)
                    ? decimalToPercent({
                        percent: prepaymentRate,
                        showPercentSymbol: true,
                    })
                    : "", isEditable: isEditable, onChange: handleUpdate, customFormItem: (_a) => {
                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                    return (_jsx(FormItem, Object.assign({}, props, { rules: [
                            {
                                type: "number",
                                max: 10,
                                message: t("application_form.errors.fieldMustBeMax", {
                                    max: `10%`,
                                }),
                            },
                        ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
                }, formItemValue: isValidNumericValue(prepaymentRate)
                    ? Number(decimalToPercent({
                        percent: prepaymentRate,
                        withLocale: false,
                    }))
                    : undefined })] }));
};
export const TransactionFee = ({ onUpdate, isEditable, transactionFee, currency, }) => {
    const { t } = useTranslation();
    const { currency: currencyFormatter } = useFormatter();
    const handleUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        onUpdate && onUpdate({ transaction_fee: v.toString() });
    };
    return (_jsx(DataCardItemEditable, { label: t("application.pricing.transaction-fee"), displayedValue: isValidNumericValue(transactionFee)
            ? currencyFormatter(transactionFee, {
                currency: currency,
            })
            : "", isEditable: isEditable, onChange: handleUpdate, customFormItem: (_a) => {
            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
            return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(CurrencyInput, Object.assign({ min: 0, step: 0.01 }, inputProps, { currency: currency })) })));
        }, formItemValue: transactionFee || 0 }));
};
export const MinimumInterestRate = ({ minimumInterestRate }) => {
    const { t } = useTranslation();
    const { decimalToPercent } = useFormatter();
    return (_jsx(DataCardItem, { label: t("application.pricing.minimum-interest-rate"), value: isValidNumericValue(minimumInterestRate)
            ? decimalToPercent({
                percent: minimumInterestRate,
                showPercentSymbol: true,
            })
            : "" }));
};
