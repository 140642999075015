var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { generatePath } from "react-router-dom";
import { PendingActionExternalActionValue, } from "@teylor-tools/Api";
import { Routes } from "../../Routes";
import { Axios } from "../../utils/Axios";
import { getChecklistPendingItemAction, getGeneralPendingItemAction, getSignatoryPendingItemAction, } from "./modules/pending-items/get-pending-item-action";
export const generatePendingItems = ({ data, t, applicationId, navigate, setActiveMenu, uploadRaisingAvailable, setShareApplicationModalOpen, getPendingItems, showRaisinUploadModal, }) => {
    var _a;
    const allErrors = data.pending_actions
        .flatMap((action) => action.errors || [])
        .filter((error, i, errors) => !!error.values || errors.findIndex((currError) => currError.error === error.error) === i);
    const general = allErrors
        .filter((error) => !error.item_type)
        .map((error) => ({
        errors: [error.error],
        title: t(`common.errors.${error.error}`),
        action: getGeneralPendingItemAction(error.error, applicationId, navigate, setActiveMenu, () => setShareApplicationModalOpen(true)),
        actionLabel: t("application.overview.tasks.open-task"),
    }));
    const documents = allErrors
        .filter((error) => error.item_type === "document_type")
        .map((error) => {
        var _a;
        return ({
            errors: [error.error],
            title: t(`common.errors.${error.error}`),
            action: () => navigate(generatePath(Routes.ApplicationDocuments, { applicationId })),
            subItems: (_a = error.values) === null || _a === void 0 ? void 0 : _a.map((val) => ({
                errors: [error.error],
                // @ts-ignore dynamic SL key
                title: t(`documents.type.${val}.name`),
            })),
            actionLabel: t("application.overview.tasks.open-task"),
        });
    });
    const checklists = allErrors
        .filter((error) => error.item_type === "checklist")
        .map((error) => {
        var _a;
        return ((_a = error.values) === null || _a === void 0 ? void 0 : _a.length)
            ? error.values.map((value) => ({
                errors: [error.error],
                // @ts-ignore dynamic SL key
                title: t(`application.pending-items.${error.error}.${value}`),
                action: getChecklistPendingItemAction(value, applicationId, navigate, setActiveMenu),
                actionLabel: t("application.overview.tasks.open-task"),
            }))
            : [];
    })
        .flat();
    const notifications = allErrors
        .filter((error) => error.item_type === "notification")
        .map((error) => { var _a; return (((_a = error.values) === null || _a === void 0 ? void 0 : _a.length) ? error.values : []); })
        .flat()
        .map((value) => ({
        errors: [value],
        // @ts-ignore dynamic SL key
        title: t(`common.errors.${value}`),
        action: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield Axios.post(`/admin/applications/${applicationId}/notifications/${value}`);
                yield getPendingItems();
            }
            catch (err) {
                void Axios.error(err);
            }
        }),
        actionLabel: t(`application.overview.tasks.send`),
    }));
    const externalActions = allErrors
        .filter((error) => error.item_type === "external_action")
        .map((error) => {
        var _a;
        return ((_a = error.values) === null || _a === void 0 ? void 0 : _a.length)
            ? error.values.reduce((acc, value) => {
                // for now we support only Raisin action
                if (value === PendingActionExternalActionValue.CrmApplicationDocumentsUpload &&
                    uploadRaisingAvailable) {
                    acc.push({
                        errors: [error.error],
                        // @ts-ignore dynamic SL key
                        title: t(`common.errors.${value}`),
                        action: () => showRaisinUploadModal(),
                        actionLabel: t(`application.overview.tasks.upload`),
                    });
                }
                return acc;
            }, [])
            : [];
    })
        .flat();
    const signatories = ((_a = data.signatories_errors) === null || _a === void 0 ? void 0 : _a.length)
        ? data.signatories_errors.map((signatory) => ({
            errors: signatory.errors,
            title: t("application.pending-items.pending-items-for", {
                name: `${signatory.first_name} ${signatory.last_name}`,
            }),
            subItems: signatory.errors.map((error) => ({
                errors: [error],
                title: t(`common.errors.${error}`),
                action: getSignatoryPendingItemAction(error, applicationId, navigate),
                actionLabel: t(`application.overview.tasks.open-task`),
            })),
        }))
        : [];
    return [general, documents, checklists, notifications, externalActions, signatories].flat();
};
