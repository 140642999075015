import { FC } from "react";

import {
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	CreateLink,
	InsertTable,
	InsertThematicBreak,
	ListsToggle,
	MDXEditor,
	UndoRedo,
	frontmatterPlugin,
	headingsPlugin,
	linkDialogPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	quotePlugin,
	tablePlugin,
	thematicBreakPlugin,
	toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import styled, { createGlobalStyle } from "styled-components";

interface MarkdownEditorProps {
	markdown?: string;
	onChange?: (value: string) => void;
}

const EditorMDX = styled(MDXEditor)`
	blockquote {
		border-left: 4px solid #ddd;
		padding: 0 15px;
		color: #666;
		margin: 20px 0;
	}
`;

const GlobalMDXStyles = createGlobalStyle`
    .mdxeditor {
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    }
		.mdxeditor-popup-container {
				z-index: 2000;
		}
`;

export const MarkdownEditor: FC<MarkdownEditorProps> = (props) => {
	const { markdown, onChange } = props;
	return (
		<>
			<GlobalMDXStyles />
			<EditorMDX
				markdown={markdown || ""}
				onChange={onChange}
				plugins={[
					headingsPlugin(),
					listsPlugin(),
					quotePlugin(),
					thematicBreakPlugin(),
					markdownShortcutPlugin(),
					tablePlugin(),
					frontmatterPlugin(),
					linkPlugin(),
					linkDialogPlugin(),
					toolbarPlugin({
						toolbarContents: () => (
							<>
								<UndoRedo />
								<BlockTypeSelect />
								<BoldItalicUnderlineToggles />
								<CreateLink />
								<InsertTable />
								<InsertThematicBreak />
								<ListsToggle />
							</>
						),
					}),
				]}
			/>
		</>
	);
};
