import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, Col, Form, Row, Spin, Switch, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { fullDateTime } from "@teylor-tools/utils/dateFormats";
import { Axios } from "../../../utils/Axios";
const { Text } = Typography;
const MfaFormItem = styled(Form.Item) `
	& > div {
		flex-direction: row !important;
		align-items: center;

		.ant-form-item-control {
			width: auto !important;
		}

		.ant-form-item-label {
			padding: 0 !important;
		}
	}
`;
const TeamUserMfa = ({ userId }) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();
    const { modal, message } = App.useApp();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDevices = useCallback(() => {
        if (!userId)
            return;
        setLoading(true);
        Axios.get(`admin/bo_users/${userId}/mfa_devices`)
            .then(({ data }) => setDevices(data), (err) => {
            void Axios.error(err);
            setDevices([]);
        })
            .finally(() => setLoading(false));
    }, [userId]);
    const handleMfaEnabledChange = (mfaEnabled) => {
        !mfaEnabled &&
            void modal.confirm({
                title: t("common.are-you-sure"),
                icon: _jsx(ExclamationCircleOutlined, {}),
                content: t("settings.team.profile.disable-mfa-modal-content"),
                okText: t("common.confirm"),
                onCancel: () => form.setFieldValue("is_mfa_enabled", true),
            });
    };
    const handleDelete = (deviceId) => {
        void modal.confirm({
            title: t("common.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlined, {}),
            content: t("settings.team.profile.delete-device-modal-content"),
            okText: t("common.confirm"),
            onOk: () => {
                Axios.delete(`admin/bo_users/${userId}/mfa_devices/${deviceId}`).then(() => {
                    getDevices();
                    void message.success(t("common.deleted-successfully"));
                }, (err) => Axios.error(err));
            },
        });
    };
    useEffect(getDevices, [getDevices]);
    return (_jsxs(_Fragment, { children: [_jsx(MfaFormItem, Object.assign({ name: "is_mfa_enabled", valuePropName: "checked", label: t("settings.team.mfa.mfa-required") }, { children: _jsx(Switch, { onChange: handleMfaEnabledChange }) })), userId && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { marginBottom: 16 } }, { children: _jsx(Text, Object.assign({ strong: true }, { children: t("settings.team.mfa.devices") })) })), _jsx(Spin, Object.assign({ spinning: loading }, { children: devices.map((device) => (_jsxs(Row, Object.assign({ style: { margin: "8px 0" } }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: device.mfa_device_id })), _jsx(Col, Object.assign({ span: 11 }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: _jsx(Trans, Object.assign({ i18nKey: "settings.team.mfa.device-added-on" }, { children: { date: dayjs(device.created_at).format(fullDateTime) } })) })) })), _jsx(Col, Object.assign({ span: 1 }, { children: _jsx(Button, { type: "link", icon: _jsx(DeleteOutlined, {}), onClick: () => handleDelete(device.mfa_device_id) }) }))] }), device.mfa_device_id))) })), !loading && !devices.length && (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("settings.team.mfa.no-device-added") })))] }))] }));
};
export default TeamUserMfa;
