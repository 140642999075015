import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Input, Popover, Space, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import SearchList from "../../../components/SearchList";
import { Axios } from "../../../utils/Axios";
import { getUserStatusColor } from "../helpers";
import TeamUserPreview from "./TeamUserPreview";
const TeamTab = ({ showPreview, handleShowPreview }) => {
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState();
    const ref = useRef();
    const getUsers = useCallback(() => {
        var _a;
        void ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getTableData().catch((err) => void Axios.error(err)));
    }, []);
    const columns = [
        {
            dataIndex: "name",
            title: t("settings.team.overview.name"),
        },
        {
            dataIndex: "email",
            title: t("settings.team.overview.email"),
        },
        {
            dataIndex: "roles",
            title: t("settings.team.overview.roles"),
            render: (roles) => {
                if (!roles.length)
                    return "-";
                const rolesToDisplay = roles.slice(0, 2);
                const moreRoles = roles.slice(2);
                return (_jsxs(Space, Object.assign({ size: [0, 8], wrap: true }, { children: [rolesToDisplay.map((role) => (_jsx(Tag, { children: role.name }, role.id))), moreRoles.length > 0 && (_jsx(Popover, Object.assign({ content: _jsx(Space, Object.assign({ direction: "vertical", size: [0, 8], wrap: true }, { children: moreRoles.map((role) => (_jsx(Tag, { children: role.name }, role.id))) })) }, { children: _jsx(Tag, { children: `+ ${moreRoles.length}` }) })))] })));
            },
        },
        {
            dataIndex: "status",
            title: t("settings.team.overview.status"),
            render: (status) => {
                return (_jsx(Tag, Object.assign({ color: getUserStatusColor(status) }, { children: t(`settings.team.overview.status.${status}`) })));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { padding: "16px 0px" } }, { children: _jsx(SearchList, Object.assign({ ref: ref, endpoint: "/admin/bo_users", columns: columns, showingOfString: "settings.team.search-showing", rowKey: ({ id }) => id, onRow: (record) => ({
                        onClick: () => {
                            setSelectedUser(record);
                            handleShowPreview(true);
                        },
                    }) }, { children: _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: "text" }, { children: _jsx(Input.Search, { placeholder: t("settings.team.search-placeholder"), allowClear: true }) })) })) })) })), _jsx(TeamUserPreview, { user: selectedUser, open: showPreview, getUsers: getUsers, onClose: () => {
                    setSelectedUser(undefined);
                    handleShowPreview(false);
                } })] }));
};
export default TeamTab;
