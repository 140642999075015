var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined, LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { App, Button, Drawer, Dropdown, Form, Space, Tabs, Typography, theme } from "antd";
import styled from "styled-components";
import { BoUserStatus } from "@teylor-tools/Api";
import { Axios } from "../../../utils/Axios";
import TeamUserMfa from "./TeamUserMfa";
import TeamUserProfile from "./TeamUserProfile";
const DrawerTitle = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const TEAM_USER_FORM_ID = "team_user_form";
var DotsBtnKeys;
(function (DotsBtnKeys) {
    DotsBtnKeys["DISABLE"] = "disable";
    DotsBtnKeys["ENABLE"] = "enable";
    DotsBtnKeys["DELETE"] = "delete";
})(DotsBtnKeys || (DotsBtnKeys = {}));
const TeamUserPreview = ({ user, open, getUsers, onClose }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { message, modal } = App.useApp();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const tabs = [
        {
            key: "profile",
            label: t("settings.team.profile"),
            children: _jsx(TeamUserProfile, { user: user }),
        },
        {
            key: "mfa",
            label: t("settings.team.mfa"),
            children: _jsx(TeamUserMfa, { userId: user === null || user === void 0 ? void 0 : user.id }),
        },
    ];
    const handleDeleteUser = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoading(true);
        void Axios.delete(`/admin/bo_users/${user.id}`)
            .then(() => {
            void message.success(t("settings.team.profile.user_was_deleted"));
            onClose();
            void getUsers();
        }, (error) => void Axios.error(error))
            .finally(() => setIsLoading(false));
    };
    const handleDisableUser = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoading(true);
        void Axios.post(`/admin/bo_users/${user.id}/disable`)
            .then(() => {
            void message.success(t("settings.team.profile.user_was_disabled"));
            onClose();
            void getUsers();
        }, (error) => void Axios.error(error))
            .finally(() => setIsLoading(false));
    };
    const handleEnableUser = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoading(true);
        void Axios.post(`/admin/bo_users/${user.id}/enable`)
            .then(() => {
            void message.success(t("settings.team.profile.user_was_enabled"));
            onClose();
            void getUsers();
        }, (error) => void Axios.error(error))
            .finally(() => setIsLoading(false));
    };
    const confirmDelete = () => {
        void modal.confirm({
            title: t("settings.team.profile.delete_user_modal_title"),
            icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }),
            content: t("settings.team.profile.delete_user_modal_message"),
            okText: t("common.delete"),
            okButtonProps: { danger: true },
            onOk: handleDeleteUser,
        });
    };
    const handleDotsBtnClick = (e) => {
        switch (e.key) {
            case DotsBtnKeys.DISABLE:
                handleDisableUser();
                break;
            case DotsBtnKeys.ENABLE:
                handleEnableUser();
                break;
            case DotsBtnKeys.DELETE:
                confirmDelete();
                break;
        }
    };
    const onSave = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        if (!(user === null || user === void 0 ? void 0 : user.id)) {
            void Axios.post("/admin/bo_users", values)
                .then(() => {
                void message.success(t("settings.team.profile.user_created_success_message"));
                void getUsers();
                form.resetFields();
                onClose();
            }, (error) => {
                void Axios.error(error);
            })
                .finally(() => setIsLoading(false));
            return;
        }
        try {
            (form.isFieldTouched("name") || form.isFieldTouched("email")) &&
                (yield Axios.patch(`/admin/bo_users/${user.id}/profile`, { name: values.name, email: values.email }));
            form.isFieldTouched("roles") &&
                (yield Axios.patch(`/admin/bo_users/${user.id}/roles`, { roles: values.roles }));
            form.isFieldTouched("is_mfa_enabled") &&
                (yield Axios.post(`/admin/bo_users/${user.id}/mfa_enabled`, { is_mfa_enabled: values.is_mfa_enabled }));
            void message.success(t("settings.team.profile.user_updated_success_message"));
            void getUsers();
            onClose();
        }
        catch (err) {
            void Axios.error(err);
        }
        finally {
            setIsLoading(false);
        }
    });
    useEffect(() => {
        var _a;
        form.setFieldsValue({
            name: (user === null || user === void 0 ? void 0 : user.name) || "",
            email: (user === null || user === void 0 ? void 0 : user.email) || "",
            roles: ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.map((role) => role.id)) || [],
            is_mfa_enabled: !!(user === null || user === void 0 ? void 0 : user.is_mfa_enabled),
        });
    }, [user, form]);
    return (_jsx(_Fragment, { children: _jsx(Drawer, Object.assign({ destroyOnClose: true, title: _jsxs(DrawerTitle, { children: [_jsx(Typography.Text, Object.assign({ style: { fontSize: 16 } }, { children: (user === null || user === void 0 ? void 0 : user.name) || t("settings.team.profile.new_user") })), _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", htmlType: "submit", form: TEAM_USER_FORM_ID, loading: isLoading }, { children: t("common.save") })), user && (_jsx(Dropdown, Object.assign({ menu: {
                                    disabled: isLoading,
                                    items: [
                                        ...(user.status === BoUserStatus.Active
                                            ? [
                                                {
                                                    label: t("common.disable"),
                                                    key: DotsBtnKeys.DISABLE,
                                                },
                                            ]
                                            : []),
                                        ...(user.status === BoUserStatus.Disabled
                                            ? [
                                                {
                                                    label: t("common.enable"),
                                                    key: DotsBtnKeys.ENABLE,
                                                },
                                            ]
                                            : []),
                                        {
                                            label: t("common.delete"),
                                            key: DotsBtnKeys.DELETE,
                                        },
                                    ],
                                    onClick: handleDotsBtnClick,
                                } }, { children: _jsx(Button, Object.assign({ "data-cy": "export-portfolio-button" }, { children: _jsx(MoreOutlined, {}) })) })))] })] }), closeIcon: _jsx(LeftOutlined, {}), placement: "right", onClose: onClose, open: open, size: "large" }, { children: _jsx(Form, Object.assign({ form: form, id: TEAM_USER_FORM_ID, layout: "vertical", name: "profile", onFinish: (values) => void onSave(values), disabled: isLoading }, { children: _jsx(Tabs, { defaultActiveKey: "profile", items: tabs }) })) })) }));
};
export default TeamUserPreview;
