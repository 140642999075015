import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath } from "react-router-dom";
import { Routes } from "../../../Routes";
import { useCompany } from "../../../layouts/CompanyLayout/CompanyTypes";
import { Axios } from "../../../utils/Axios";
import Financials from "../../modules/Financials/Financials";
export const CompanyFinancials = () => {
    const { company } = useCompany();
    return (_jsx(Financials, { getFinancialsData: () => Axios.get(`/admin/companies/${company.companyId}/financial_data`).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err)), updateFinancialsData: (payload) => Axios.post(`/admin/companies/${company.companyId}/financial_data`, payload).then(() => Promise.resolve(), (err) => Promise.reject(err)), finCrPath: generatePath(Routes.CompanyFincr, {
            companyId: company.companyId,
        }), allowEdit: true }));
};
