import { ChecklistItemOptionTemplateType } from "@teylor-tools/Api";
import { ChecklistFlag } from "../checklist-form.types";
const optionsToFlagMap = new Map([
    [ChecklistItemOptionTemplateType.Black, ChecklistFlag.red],
    [ChecklistItemOptionTemplateType.Grey, ChecklistFlag.yellow],
    [ChecklistItemOptionTemplateType.White, ChecklistFlag.green],
]);
export const getChecklistFlag = (optionType) => optionsToFlagMap.get(optionType);
export const getItemOptionType = (flag) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [...optionsToFlagMap].find(([_, v]) => v === flag)[0];
};
