import { useLocale } from "@teylor-tools/utils/LocaleProvider";

import NumberInputLocalized, {
	NumberInputLocalizedProps,
} from "../number-input-localized/NumberInputLocalized";

const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", ",", "-"];

const PercentInput = (props: NumberInputLocalizedProps) => {
	const locale = useLocale();
	const decimalSeparator = (1.1).toLocaleString([locale]).substring(1, 2);

	const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		const key = evt.nativeEvent.key;
		const element = evt.target as HTMLInputElement;
		const value = element.value;
		const selectionStart = element.selectionStart === null ? value.length : element.selectionStart;
		const selectionEnd = element.selectionEnd === null ? value.length : element.selectionEnd;
		const dotIndex = value.indexOf(decimalSeparator);
		const dotExists = dotIndex > -1;
		const decimalPlaces = dotExists ? value.length - dotIndex - 1 : 0;
		const decimalPrecision = props.precision || 2;
		const isTextSelected = selectionEnd - selectionStart > 0;

		const allowChange = (() => {
			if (!allowedKeys.includes(key)) return false;

			if (isTextSelected) {
				if (key === decimalSeparator && dotExists) {
					return dotIndex >= selectionStart && dotIndex <= selectionEnd;
				}
				return true;
			}

			if (
				(decimalPlaces === decimalPrecision && selectionStart >= dotIndex) ||
				(key === decimalSeparator && dotExists)
			) {
				return false;
			}

			return true;
		})();

		if (!allowChange) {
			evt.preventDefault();
			evt.stopPropagation();
		}
	};

	return <NumberInputLocalized onKeyPress={onKeyPress} prefix="%" {...props} />;
};

export default PercentInput;
