import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { App, Button, Checkbox, Divider, Form, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	margin: 24px;
`;
const GroupTitle = styled(Typography.Text) `
	display: inline-block;
	margin-bottom: 16px;
	font-weight: 600;
`;
const FormItem = styled(Form.Item) `
	margin-bottom: 12px;
`;
const getChecklist = (url) => {
    return Axios.get(url).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err));
};
const updateChecklist = (url, values) => {
    return Axios.patch(url, values).then(() => Promise.resolve(), (err) => Promise.reject(err));
};
const PayoutChecklistSidebar = ({ applicationId, onCloseMenu, updatePendingItems }) => {
    return (_jsx(ChecklistSidebar, { getChecklist: () => getChecklist(`/admin/applications/${applicationId}/payout_checklist`), updateChecklist: (values, checklistId) => updateChecklist(`/admin/payout_checklists/${checklistId}`, { checklist_data: values }), onCloseMenu: onCloseMenu, updateApplicationTasks: updatePendingItems }));
};
const FinalReviewChecklistSidebar = ({ applicationId, onCloseMenu, updatePendingItems }) => {
    return (_jsx(ChecklistSidebar, { getChecklist: () => getChecklist(`/admin/applications/${applicationId}/four_eye_checklist`), updateChecklist: (values, checklistId) => updateChecklist(`/admin/four_eye_checklists/${checklistId}`, { checklist_data: values }), onCloseMenu: onCloseMenu, updateApplicationTasks: updatePendingItems }));
};
const ChecklistSidebar = ({ getChecklist, updateChecklist, onCloseMenu, updateApplicationTasks, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [form] = useForm();
    const [checklist, setChecklist] = useState();
    const [loading, setLoading] = useState(false);
    const checklistGroups = useMemo(() => { var _a; return ((_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklist_data) === null || _a === void 0 ? void 0 : _a.groups) || []; }, [checklist]);
    const getChecklists = useCallback(() => {
        setLoading(true);
        getChecklist()
            .then((checklist) => setChecklist(checklist), (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setFormValues = useCallback(() => {
        const formData = {};
        checklistGroups.forEach((group) => {
            group.items.forEach((item) => {
                switch (item.item_type) {
                    case "checkbox":
                        formData[item.options[0].id] = item.options[0].selected;
                        break;
                    case "text":
                        formData[item.id] = item.text || "";
                        break;
                }
            });
        });
        form.setFieldsValue(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklistGroups]);
    const handleFormFinish = (values) => {
        if (!(checklist === null || checklist === void 0 ? void 0 : checklist.application_checklist_id))
            return;
        setLoading(true);
        updateChecklist(values, checklist.application_checklist_id)
            .then(() => {
            void message.success(t("common.saved"));
            onCloseMenu();
            updateApplicationTasks();
        }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => {
            setLoading(false);
            getChecklists();
        });
    };
    useEffect(getChecklists, [getChecklists]);
    useEffect(setFormValues, [checklist, setFormValues]);
    return (_jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(Wrapper, { children: !!checklistGroups.length && (_jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: handleFormFinish }, { children: [checklistGroups.map((group, i) => (_jsxs(Fragment, { children: [_jsx(GroupTitle, { children: group.description }), group.items.map((item, j) => {
                                var _a, _b, _c;
                                switch (item.item_type) {
                                    case "text":
                                        return (_jsx(FormItem, Object.assign({ name: item.id, rules: ((_a = item.requirements) === null || _a === void 0 ? void 0 : _a.min_length)
                                                ? [
                                                    {
                                                        min: (_b = item.requirements) === null || _b === void 0 ? void 0 : _b.min_length,
                                                        message: t("application_form.errors.fieldMustBeMinChars", {
                                                            min: (_c = item.requirements) === null || _c === void 0 ? void 0 : _c.min_length,
                                                        }),
                                                    },
                                                ]
                                                : [] }, { children: _jsx(TextArea, { rows: 5, showCount: true }) }), `text-${i}-${j}`));
                                    case "checkbox":
                                        return (_jsx(FormItem, Object.assign({ name: item.options[0].id, valuePropName: "checked" }, { children: _jsx(Checkbox, { children: item.description }) }), `checkbox-${i}-${j}`));
                                }
                            }), i < checklistGroups.length - 1 && _jsx(Divider, {})] }, `group-${i}`))), _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("common.save") }))] }))) }) })));
};
export { PayoutChecklistSidebar, FinalReviewChecklistSidebar };
