import { Currency } from "../Api";
import { useLocale } from "../utils/LocaleProvider";

export enum CurrencyDisplay {
	SYMBOL = "symbol",
}

interface CurrencyConfig {
	showFractions?: boolean;
	currencyDisplay?: CurrencyDisplay;
	currency?: Currency;
}

const DEFAULT_DECIMAL_PLACES = 2;

const getDecimalSeparator = (locale: string) => {
	const numberWithDecimalSeparator = 1.1;
	return Intl.NumberFormat(locale)
		?.formatToParts(numberWithDecimalSeparator)
		?.find((part) => part.type === "decimal")?.value;
};

interface DecimalToPercentFormatterArgs {
	percent: string | number;
	decimalPlaces?: number;
	showPercentSymbol?: boolean;
	withLocale?: boolean;
}

export type DecimalToPercentFormatter = (args: DecimalToPercentFormatterArgs) => string;

type PercentToDecimalFormatter = (decimal: string | number) => string;

type LocalizedNumberFormatter = ({
	value,
	decimalPlaces,
}: {
	value: string | number;
	decimalPlaces?: number;
}) => string;

export type CurrencyFormatter = (number: number | string, config?: CurrencyConfig) => string;
type DateFormatter = (date?: string, options?: Intl.DateTimeFormatOptions) => string;
type NumberFormatter = (value: number | string) => string;

export const useFormatter = (): {
	decimalToPercent: DecimalToPercentFormatter;
	percentToDecimal: PercentToDecimalFormatter;
	localizedNumber: LocalizedNumberFormatter;
	currency: CurrencyFormatter;
	date: DateFormatter;
	compactNumber: NumberFormatter;
} => {
	const locale = useLocale();

	return {
		decimalToPercent: ({
			percent,
			decimalPlaces = DEFAULT_DECIMAL_PLACES,
			showPercentSymbol = false,
			withLocale = true,
		}) => {
			const decimalNumber = typeof percent === "number" ? percent : Number(percent);

			if (!withLocale) {
				return (decimalNumber * 100).toFixed(decimalPlaces);
			}

			return `${(decimalNumber * 100).toLocaleString(locale, {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			})}${showPercentSymbol ? "%" : ""}`;
		},
		localizedNumber: ({ value, decimalPlaces = DEFAULT_DECIMAL_PLACES }) => {
			const valueNumber = typeof value === "number" ? value : Number(value);
			return `${valueNumber.toLocaleString(locale, {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			})}`;
		},
		percentToDecimal: (decimal) => {
			const basicSeparator = ".";
			const decimalSeparator = getDecimalSeparator(locale) || basicSeparator;
			const decimalString = decimal.toString().replace(decimalSeparator, basicSeparator);
			const separatorIndex = decimalString.indexOf(basicSeparator);

			// add 2 to decimal place to compensate division by 100
			const decimalPlaces = decimalString.slice(separatorIndex + 1).length + 2;

			return (Number(decimalString) / 100).toFixed(decimalPlaces);
		},
		currency: (number, config) => {
			const showFractions = config?.showFractions !== undefined ? config.showFractions : true;

			return new Intl.NumberFormat(locale, {
				style: "currency",
				currencyDisplay: CurrencyDisplay.SYMBOL,
				currency: config?.currency || Currency.EUR,
				minimumFractionDigits: showFractions ? 2 : 0,
				maximumFractionDigits: showFractions ? 2 : 0,
			}).format(Number(number));
		},
		date: (date, options = {}) => {
			return date ? new Intl.DateTimeFormat(locale, options).format(new Date(date)) : "-";
		},
		compactNumber: (value: number | string): string => {
			const num = typeof value === "string" ? parseFloat(value) : value;

			if (isNaN(num)) return "Invalid Number";

			const isNegative = num < 0;
			const absoluteValue = Math.abs(num);

			let result = "";

			if (absoluteValue >= 1000000) {
				result = `${num / 1000000}m`;
			} else if (absoluteValue >= 1000) {
				result = `${absoluteValue / 1000}k`;
			} else {
				result = absoluteValue.toString();
			}

			return isNegative ? "-" + result : result;
		},
	};
};
