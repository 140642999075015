import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Table, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "src/utils/Axios";
import SettingsDocumentTemplatesModal from "./SettingsDocumentTemplatesModal";
const StyledTable = styled((Table)) `
	.child-row {
		background-color: #fafafa;
	}

	.action-column {
		padding: 0 !important;
		width: 50px;
	}
`;
const ActionButton = styled(Button) `
	padding: 0;
	margin-right: 16px;
`;
const SettingsDocumentTemplatesPage = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const selectedProductId = Form.useWatch("product_id", form);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [data, setData] = useState();
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState(undefined);
    const [isAddingNewTemplate, setIsAddingNewTemplate] = useState(false);
    const [isLoadingDocumentTemplate, setIsLoadingDocumentTemplate] = useState(false);
    const { date } = useFormatter();
    useEffect(() => {
        setIsLoadingProducts(true);
        Axios.get("admin/products")
            .then(({ data }) => {
            setProducts(data || []);
            setFilteredProducts(data || []);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoadingProducts(false));
    }, []);
    const handleFetchTemplates = useCallback((id) => {
        setIsLoading(true);
        void Axios.get(`/admin/document_templates?product_id=${id}`)
            .then(({ data }) => {
            setData(data);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }, []);
    const handleSearch = useCallback((value) => {
        setFilteredProducts(products.filter((p) => { var _a; return (_a = p.name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase().includes(value.toLocaleLowerCase()); }));
    }, [products]);
    const handleModalOnFinish = (id) => {
        setIsAddingNewTemplate(false);
        setSelectedDocumentTemplate(undefined);
        handleFetchTemplates(id);
    };
    const getDocumentTemplate = (dt) => {
        setIsLoadingDocumentTemplate(true);
        Axios.get(`/admin/document_templates/${dt.document_template_id}/download_url`)
            .then(({ data }) => {
            const newTab = window.open(data.download_url, "_blank");
            if (newTab)
                newTab.focus();
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoadingDocumentTemplate(false);
        });
    };
    const columns = [
        {
            title: t("settings.doctemplates.column.template_type"),
            dataIndex: "document_type",
            key: "document_type",
            render: (dt) => dt && _jsx(Typography.Text, { children: dt }),
        },
        {
            title: t("settings.doctemplates.column.language"),
            dataIndex: "language",
            key: "language",
            render: (dt) => dt && _jsx(Typography.Text, { children: dt }),
        },
        {
            title: t("settings.doctemplates.column.country"),
            dataIndex: "country",
            key: "country",
            render: (dt) => dt && _jsx(Typography.Text, { children: dt }),
        },
        {
            title: t("settings.doctemplates.column.created"),
            dataIndex: "created",
            key: "created",
            render: (dt) => dt && _jsx(Typography.Text, { children: date(dt, { timeZone: "UTC" }) }),
        },
        {
            title: t("settings.doctemplates.column.actions"),
            dataIndex: "document_template_id",
            key: "document_template_id",
            render: (dt) => (_jsxs("div", { children: [_jsx(ActionButton, Object.assign({ disabled: isLoadingDocumentTemplate, type: "link", onClick: () => getDocumentTemplate(data === null || data === void 0 ? void 0 : data.find((s) => s.document_template_id == dt)) }, { children: t("settings.doctemplates.column.actions.download") })), _jsx(ActionButton, Object.assign({ type: "link", onClick: () => {
                            const selected = data === null || data === void 0 ? void 0 : data.find((s) => s.document_template_id == dt);
                            setSelectedDocumentTemplate(selected);
                        }, style: { color: "orange" } }, { children: t("settings.doctemplates.column.actions.update") }))] })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.doctemplates") }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Form, Object.assign({ layout: "vertical", form: form }, { children: _jsx(FormItem, Object.assign({ name: "product_id", label: t("settings.doctemplates.select_product") }, { children: _jsx(Select, { loading: isLoadingProducts, disabled: !products.length || isLoading, placeholder: t("settings.doctemplates.no_product_selected"), maxTagCount: "responsive", optionFilterProp: "value", showSearch: true, onSearch: handleSearch, filterOption: false, value: selectedProductId, onChange: (v) => handleFetchTemplates(v), onSelect: () => setFilteredProducts(products), options: filteredProducts.map(({ product_id, name }) => {
                                            return {
                                                label: name,
                                                value: product_id,
                                            };
                                        }) }) })) })) })), _jsx(Col, Object.assign({ offset: 10, span: 6, style: { display: "flex", justifyContent: "end" } }, { children: _jsx(Button, Object.assign({ disabled: !selectedProductId, onClick: () => setIsAddingNewTemplate(true), icon: _jsx(PlusOutlined, {}) }, { children: t("settings.doctemplates.upload_new_template_btn") })) }))] })) })), _jsx("div", Object.assign({ style: { margin: "0 24px 24px" } }, { children: _jsx(StyledTable, { columns: columns, rowKey: "document_template_id", loading: isLoadingDocumentTemplate || isLoading, dataSource: data, pagination: false, scroll: { x: "100%", y: "calc(100vh - 350px)" } }) })), isAddingNewTemplate && (_jsx(SettingsDocumentTemplatesModal, { selectedProductId: selectedProductId, documentTemplate: undefined, onCancel: () => setIsAddingNewTemplate(false), onFinish: handleModalOnFinish })), selectedDocumentTemplate && (_jsx(SettingsDocumentTemplatesModal, { selectedProductId: selectedProductId, documentTemplate: selectedDocumentTemplate, onCancel: () => setSelectedDocumentTemplate(undefined), onFinish: handleModalOnFinish }))] }));
};
export default SettingsDocumentTemplatesPage;
