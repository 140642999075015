import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { MarkdownEditor } from "@ui/markdown/editor/MarkdownEditor";
import { MarkdownViewer } from "@ui/markdown/viewer/MarkdownViewer";
import FieldEditButtons from "../ui/FieldEditButtons";
export const TaskDescription = ({ task, handleUpdate }) => {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [tempDescription, setTempDescription] = useState((task === null || task === void 0 ? void 0 : task.description) ? task.description : "");
    const handleSave = () => {
        handleUpdate({ description: tempDescription });
        setEditMode(false);
    };
    const handleClose = (value) => {
        setEditMode(value);
        if (!value) {
            setTempDescription((task === null || task === void 0 ? void 0 : task.description) ? task.description : "");
        }
    };
    return (_jsxs("div", { children: [_jsxs(Title, Object.assign({ level: 5 }, { children: [t("tasks.description"), _jsx(FieldEditButtons, { editMode: editMode, setEditMode: handleClose, onSave: handleSave })] })), !editMode ? (_jsx(MarkdownViewer, { content: task.description }, `${task.task_id}-desc`)) : (_jsx(MarkdownEditor, { markdown: tempDescription, onChange: setTempDescription }))] }));
};
