var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Space as AntdSpace, Button, Col, Form, Row, Typography } from "antd";
import styled from "styled-components";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
const { Title, Text } = Typography;
const Space = styled(AntdSpace) `
	width: 100%;
`;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token } = useParams();
    const [form] = Form.useForm();
    const { isPending: loading, mutate, isSuccess, } = useMutation({
        mutationKey: ["resetPassword"],
        mutationFn: ({ password, token }) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.post("/admin/account/recover", {
                password,
                token,
            });
            return response.data;
        }),
        onError: (error) => {
            void Axios.error(error, t("forgot_password.send-reset-email-error"));
        },
    });
    const onFinish = ({ password }) => {
        mutate({ password, token });
    };
    if (isSuccess) {
        return (_jsxs(Wrapper, { children: [_jsx(CheckCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("reset_password.reset-success-title") })), _jsx(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: t("reset_password.reset-success-subtitle") })), _jsx(Button, Object.assign({ type: "primary", size: "large", style: { marginTop: 48 }, onClick: () => navigate(Routes.Login) }, { children: t("reset_password.go-to-login") }))] }));
    }
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsxs("div", { children: [_jsx(Title, Object.assign({ level: 3 }, { children: t("reset_password.reset-password-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("reset_password.reset-password-subtitle") }))] }), _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish }, { children: [_jsx(PasswordFormItem, { name: "password", label: t("reset_password.password") }), _jsx(PasswordFormItem, { name: "passwordRepeat", label: t("reset_password.password-repeat"), rules: [
                            { required: true, message: t("application_form.field-required") },
                            {
                                validator: (_, passwordRepeat) => passwordRepeat === form.getFieldValue("password")
                                    ? Promise.resolve()
                                    : Promise.reject(t("application_form.password-repeat-invalid")),
                            },
                        ] }), _jsx(Row, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", loading: loading }, { children: t("reset_password.reset-password-button") })) })) })] }))] })));
};
export default ResetPasswordPage;
