import { GroupType, } from "./rule-engine.types";
import { arithmeticOperators, logicalOperators } from "./rule-engine.variables";
// TODO Rule["right"] should be unified in the future with Rule["left"] on BE
export const mapRuleToFormValues = (rule) => {
    if (rule.op) {
        const ruleTyped = rule;
        if (logicalOperators.includes(ruleTyped.op)) {
            return {
                type: GroupType.LOGICAL,
                val: ruleTyped.op,
                left: mapRuleToFormValues(ruleTyped.left),
                right: mapRuleToFormValues(ruleTyped.right),
            };
        }
        if (arithmeticOperators.includes(ruleTyped.op)) {
            return {
                type: GroupType.ARITHMETIC,
                val: ruleTyped.op,
                left: mapRuleToFormValues(ruleTyped.left),
                right: mapRuleToFormValues(ruleTyped.right),
            };
        }
    }
    if (typeof rule === "string") {
        return {
            type: GroupType.PARAM,
            val: rule.slice(1, -1),
        };
    }
    return {
        type: GroupType.VALUE,
        val: rule,
    };
};
const createFinalValue = (group) => group.type === GroupType.PARAM ? `{${group.val}}` : group.val;
export const mapFormValuesToRule = (formValues) => {
    const form = formValues;
    if (!form.left || !form.right || !form.val)
        throw new Error();
    const isLeftFinalValue = [GroupType.PARAM, GroupType.VALUE].includes(form.left.type);
    const isRightFinalValue = [GroupType.PARAM, GroupType.VALUE].includes(form.right.type);
    if (
    // @ts-ignore
    (isLeftFinalValue && (form.left["left"] || form.left["right"])) ||
        // @ts-ignore
        (isRightFinalValue && (form.right["left"] || form.right["right"]))) {
        throw new Error();
    }
    const left = (isLeftFinalValue ? createFinalValue(form.left) : mapFormValuesToRule(form.left));
    const right = (isRightFinalValue ? createFinalValue(form.right) : mapFormValuesToRule(form.right));
    return {
        op: form.val,
        left,
        right,
    };
};
