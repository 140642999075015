import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Notes from "src/components/Notes";
import { Axios } from "src/utils/Axios";
const LoanNotes = ({ loanId, showXLatest }) => {
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState([]);
    const { t } = useTranslation();
    const getNotes = () => {
        setLoading(true);
        Axios.get(`/admin/loans/${loanId}/notes`, Object.assign({ include_users: true }, (showXLatest && { page: 1, page_size: showXLatest })))
            .then(({ data }) => {
            var _a;
            setNotes((_a = data === null || data === void 0 ? void 0 : data.result) !== null && _a !== void 0 ? _a : []);
        })
            .catch((error) => {
            void Axios.error(error, t("common.request-failed"));
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        getNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanId]);
    const handleAddNote = ({ newNote, noteForm }) => {
        if (!newNote)
            return;
        setLoading(true);
        Axios.post(`/admin/loans/${loanId}/notes`, {
            message: newNote,
        })
            .then(() => {
            getNotes();
            noteForm.resetFields();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    return _jsx(Notes, { notes: notes, loading: loading, handleAddNote: handleAddNote });
};
export default LoanNotes;
