import { ReactNode, createContext, useContext } from "react";

import { ObjectFinancingType, ObjectRentalState } from "@teylor-tools/Api";
import { CountryList, countriesEN } from "@teylor-tools/utils/i18n/Countries";

import { FactoryDetailsType } from "../information-card/types";
import {
	FinancialsTableRowsTranslation,
	financialTableRowsToTranslate,
} from "./translations-financial-table";

export interface Translations {
	add: string;
	save: string;
	yes: string;
	no: string;
	cancel: string;
	select: string;
	delete: string;
	edit: string;
	approve: string;
	ok: string;
	close: string;

	city: string;
	cityPlaceholder: string;
	street: string;
	streetPlaceholder: string;
	country: string;
	countries: CountryList;
	emailInputErrorMessage: string;
	emailInputLabel: string;
	emailInputPlaceholder: string;
	postcode: string;
	postcodePlaceholder: string;
	tinInvalid: string;
	tinPlaceholder: string;
	hrNumber: string;
	hrNumberPlaceholder: string;
	houseNumber: string;
	houseNumberPlaceholder: string;
	ibanInvalid: string;

	formErrors: {
		fieldRequired: string;
		fieldInvalid: string;
		fieldMustBeBetweenMinMaxNumbers: (min: number, max: number) => string;
		fieldMustBeBetweenMinMaxChars: (min: number, max: number) => string;
		fieldMustBeBetweenMinMax: (min: number | string, max: number | string) => string;
		fieldMustBeMinChars: (min: number) => string;
		passwordShort: string;
		passwordNotComplex: string;
		invalidPhoneNumber: string;
		fieldContainsInvalidCharacters: string;
		personalTinMustBeDifferentThanCompanyTin: string;
		fieldMinLength: (min: string | number | undefined) => string;
		fieldMaxLength: (max: string | number | undefined) => string;
		fieldMinValue: (min: string | number | undefined) => string;
		fieldMaxValue: (max: string | number | undefined) => string;
	};

	object: {
		object: string;
		manufacturer: string;
		type: string;
		yearBuilt: string;
		objectDescription: string;
		netPurchasePrice: string;
		downPayment: string;
		remainingValue: string;
		numberOfObjects: string;
		financingType: string;
		rentalState: string;
		isFixedInterest: string;
		companyName: string;
		street: string;
		houseNumber: string;
		city: string;
		postcode: string;
		country: string;
		retailerCompanyId: string;
		retailerName: string;
		retailerAddress: string;
		expectedDeliveryDate: string;
		createOrderForm: string;
		errors: {
			required: string;
			postcodeInvalid: string;
		};
		placeholders: {
			manufacturer: string;
			type: string;
			yearBuilt: string;
			objectDescription: string;
			objectCompanyName: string;
			street: string;
			houseNumber: string;
			city: string;
			retailerCompanyId: string;
		};
		options: {
			financingTypes: {
				[key in ObjectFinancingType]: string;
			};
			rentalStates: {
				[key in ObjectRentalState]: string;
			};
			isFixedInterest: {
				yes: string;
				no: string;
			};
		};
		sections: {
			retailer: string;
		};
	};

	representativeCard: {
		address: string;
		addTin: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		email: string;
		legalSignatory: string;
		nationality: string;
		ownership: string;
		phone: string;
		tin: string;
		position: string;
		willingToSign: string;
		creditScore: string;
		lastUpdated: string;
	};

	representativeModal: {
		addRepresentative: string;
		allFieldsRequired: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		editRepresentative: string;
		firstName: string;
		houseNumber: string;
		lastName: string;
		legalSignatory: string;
		nationality: string;
		optional: string;
		percentageOwnership: string;
		phoneNumber: string;
		representativeNotContacted: string;
		street: string;
		taxNumber: string;
		willingToSign: string;
		position: string;
	};
	companySelectFormItem: {
		label: string;
		placeholder: string;
		errorCompanyNameInvalid: string;
		errorCompanyNameNotFound: string;
		errorCompanyNameNotFoundLinkText: string;
		existing: string;
	};
	industryCodes: {
		industryCode: string;
		industryCodes: string;
		description: string;
		weight: string;
		lastUpdatedOn: (date: string) => JSX.Element | string;
		source: (source: string) => JSX.Element | string;
		codeType: (type: string) => JSX.Element | string;
		approvedBy: ({
			approvedBy,
			approvedAt,
		}: {
			approvedBy: string;
			approvedAt: string;
		}) => JSX.Element | string;
		updatedManually: string;
		modalApproveTitle: string;
		modalApproveDescription: string;
	};
	industryCodesEditModal: {
		title: string;
		update: string;
		type: string;
		codeEnum: {
			wz_2008: string;
			noga_2008: string;
		};
		industryCodeNumber: (index: number) => string;
		code: string;
		codeTooltip: string;
		codeError: string;
		weight: string;
		optional: string;
		description: string;
		addIndustryCode: string;
		fieldRequired: string;
	};
	scoring: {
		overview: {
			alyze: string;
			alyzeRating: string;
			gridRate: string;
		};
		issues: {
			issues: string;
			critical: string;
			nonCritical: string;
			formula: (formula: string) => string;
			value: (value: string) => string;
		};
		scores: {
			scores: string;
			pdScore: string;
			totalScore: string;
			ratingScore: (year: number) => string;
		};
		benchmarkResults: {
			score: string;
			mark: string;
			noData: string;
		};
		financials: {
			revenue: string;
			activated_costs: string;
			inventory_increase: string;
			other_income_regular: string;
			cogs: string;
			other_cor: string;
			cost_of_revenue: string;
			gross_profit: string;
			sga: string;
			depreciations: string;
			other_opex_costs: string;
			opex: string;
			operating_profit: string;
			ebitda: string;
			other_income_net: string;
			other_finance_income: string;
			other_finance_costs: string;
			interest_income: string;
			interest_expense: string;
			ebt: string;
			taxes_paid: string;
			nopat: string;
			tax_rate: string;
			net_income: string;
			fixed_costs: string;
			cash: string;
			short_term_investments: string;
			accounts_receivable: string;
			inventories: string;
			inventory_net: string;
			prepaid_expenses: string;
			other_current_assets: string;
			loan_to_shareholders: string;
			loan_to_related_companies: string;
			property_equipment_net: string;
			intangible_assets_net: string;
			self_made_intangible: string;
			goodwill: string;
			long_term_investments: string;
			investments_in_related_companies: string;
			other_non_current_assets: string;
			deferred_tax_assets: string;
			current_assets_published: string;
			non_current_assets_published: string;
			total_assets_published: string;
			equity_published: string;
			current_assets: string;
			non_current_assets: string;
			total_assets: string;
			current_portion_of_long_debt: string;
			accounts_payable: string;
			accrued_expenses: string;
			income_taxes_payable: string;
			other_current_liabilities: string;
			current_liabilities_published: string;
			current_liabilities: string;
			long_term_debt: string;
			underfunded_retirement_plans: string;
			deferred_tax_liabilities: string;
			other_long_term_liabilities: string;
			non_current_liabilities_published: string;
			total_liabilities_published: string;
			loan_from_shareholders: string;
			non_current_liabilities: string;
			total_liabilities: string;
			equity: string;
			preferred_stock: string;
			shares_issued: string;
			paid_in_capital: string;
			retained_earnings: string;
			accumulated_other_comprehensive_income: string;
			price_per_share: string;
			total_debt: string;
			net_debt: string;
			capital_employed: string;
			total_invested_capital: string;
			net_fixed_assets: string;
			working_capital: string;
			net_working_capital: string;
			risk_free_rate_of_return: string;
			market_rate_of_return: string;
			classification_by_revenue: string;
			beta: string;
			balance_sheet_sum_check: string;
			debt_service: string;
		};
		kpiData: {
			noData: string;
		};
	};
	timePassed: {
		weeksAgo: (weeks: number) => string;
		daysAgo: (days: number) => string;
		hoursAgo: (hours: number) => string;
		minutesAgo: (minutes: number) => string;
		momentsAgo: string;
	};

	financialsTable: {
		statementContextMenu: {
			settings: string;
			delete: string;
		};
		emptyScreen: {
			title: string;
			subtitle: string;
			addStatementBtn: string;
		};
		modal: {
			titleEdit: string;
			titleAddNew: string;
			statementType: string;
			annualStatement: string;
			interimStatement: string;
			statementAsOf: string;
		};
		origin: {
			manual: string;
			ocr: string;
		};
		rows: FinancialsTableRowsTranslation;
	};
	factoring: {
		add: {
			[key in FactoryDetailsType]: string;
		};
		common: {
			in_land: (value: string | number | undefined) => string;
			abroad: (value: string | number | undefined) => string;
			in_land_text: string;
			abroad_text: string;
			comment: string;
			no_information: string;
			change: string;
		};
		financials: {
			company_portfolio: string;
			annual_sales_previous_year: string;
			planned_annual_sales_following_year: string;
			planned_annual_sales_factoring: string;
		};
		invoices: {
			number_of_outgoing_invoices: string;
			invoice_currencies: string;
			max_invoice_value_previous_year: string;
			payment_deadline_for_outgoing_invoices: string;
		};
		debitors: {
			number_of_debitors: string;
			debitors: string;
			debitors_title: string;
			form_title: {
				company_name: string;
				company_country: string;
				annual_sales: string;
			};
		};
		risk: {
			receivables_default_amount_previous_year: string;
			receivables_default_amount_current_year: string;
			are_receivables_assigned: string;
			has_commercial_credit_insurance: string;
			already_has_factoring: string;
			centralized_settlement_entity: string;
		};
		forms: {
			financials: {
				company_portfolio: string;
				annual_sales_previous_year: string;
				planned_annual_sales_following_year: string;
				planned_annual_sales_factoring: string;
				errors: {
					more: (max: string | number | undefined) => string;
					less: (max: string | number | undefined) => string;
				};
			};
			invoices: {
				number_of_outgoing_invoices: string;
				invoice_currencies: string;
				max_invoice_value_previous_year: string;
				payment_deadline_for_outgoing_invoices: string;
			};
			debitors: {
				number_of_debitors: string;
				debitors: string;
				company_name: string;
				country: string;
				annual_sales_previous_year: string;
			};
			risk: {
				yes: string;
				no: string;
				receivables_default_amount_previous_year: string;
				receivables_default_amount_current_year: string;
				are_receivables_assigned: string;
				has_commercial_credit_insurance: string;
				already_has_factoring: string;
				centralized_settlement_entity: string;
			};
		};
	};
	fincr: {
		critical: string;
		"non-critical": string;
		json: string;
		xlsx: string;
		issues: string;
		download: string;
		rescan: string;
		scan: string;
		"upload-documents": string;
		empty: string;
		rescanModalTitle: string;
		rescanModalContent: string;
		interim: string;
		annual: string;
		issuesModalTitle: string;
		documentCategory: (cat: string) => string;
		documentType: (type: string) => string;
	};
}

export const fallbackTranslations: Translations = {
	add: "Add",
	save: "Save",
	yes: "Yes",
	no: "No",
	cancel: "Cancel",
	select: "Select",
	edit: "Edit",
	delete: "Delete",
	approve: "Approve",
	ok: "Ok",
	close: "Close",

	city: "City",
	cityPlaceholder: "e.g. Berlin",
	street: "Street",
	streetPlaceholder: "e.g. Whateverstreet",
	country: "Country",
	countries: countriesEN,
	emailInputErrorMessage: "Please provide valid e-mail address.",
	emailInputLabel: "E-mail Address",
	emailInputPlaceholder: "E-mail Address",
	postcode: "Postcode",
	postcodePlaceholder: "Eg. 10315",
	tinInvalid: "Please provide valid tax number.",
	tinPlaceholder: "Example: 1234567890",
	hrNumber: "HR Number",
	hrNumberPlaceholder: "e.g. 12345",
	houseNumber: "House number",
	houseNumberPlaceholder: "e.g. 30a",
	ibanInvalid: "Please provide a valid IBAN.",

	formErrors: {
		fieldRequired: "Required field",
		fieldInvalid: "Field invalid",
		fieldMustBeBetweenMinMaxNumbers: (min, max) =>
			`Field must be between ${min} and ${max} numbers`,
		fieldMustBeBetweenMinMaxChars: (min, max) =>
			`Field must be between ${min} and ${max} characters`,
		fieldMustBeBetweenMinMax: (min, max) => `Field must be between ${min} and ${max}`,
		fieldMustBeMinChars: (min) => `Field must be minimum ${min} characters long`,
		passwordShort: "Password is too short",
		passwordNotComplex: "Password is not complex",
		invalidPhoneNumber: 'Must start with "+" and a country code',
		fieldContainsInvalidCharacters: "Field contains invalid characters",
		personalTinMustBeDifferentThanCompanyTin: "TIN must be different than company TIN",
		fieldMinLength: (min: string | number | undefined) =>
			`Field must be minimum ${min} characters long`,
		fieldMaxLength: (max: string | number | undefined) =>
			`Field must be maximum ${max} characters long`,
		fieldMinValue: (min: string | number | undefined) => `Minimum value ${min}`,
		fieldMaxValue: (max) => `Maximum value ${max}`,
	},

	object: {
		object: "Object",
		manufacturer: "Manufacturer",
		type: "Type",
		yearBuilt: "Year built",
		objectDescription: "Detailed Description",
		netPurchasePrice: "Net Purchase Price",
		downPayment: "Down Payment",
		remainingValue: "Remaining Value",
		numberOfObjects: "Number of Objects",
		financingType: "Financing Type",
		rentalState: "Will this machine be rented out?",
		isFixedInterest: "Should the interest  be a fixed rate?",
		companyName: "Company name",
		street: "Street",
		houseNumber: "House number",
		city: "City",
		postcode: "Post code",
		country: "Country",
		retailerCompanyId: "Company register number",
		retailerName: "Name",
		retailerAddress: "Address",
		expectedDeliveryDate: "Deliver date (expected)",
		createOrderForm: "Create Order Form",
		errors: {
			required: "Required",
			postcodeInvalid: "Please provide a valid postcode.",
		},
		placeholders: {
			manufacturer: "Eg. Machine GmbH",
			type: "Eg. Machinery",
			yearBuilt: "Eg. 2020",
			objectDescription: "Describe the object being financed",
			objectCompanyName: "Eg. Metabo GmbH",
			street: "Eg. Example street",
			houseNumber: "Eg. 103",
			city: "Eg. Berlin",
			retailerCompanyId: "Eg. 881234",
		},
		options: {
			isFixedInterest: {
				yes: "Yes",
				no: "No",
			},
			financingTypes: {
				leasing: "Leasing",
				hire_purchase: "Hire-Purchase",
				financing: "Financing",
				sale_leaseback: "Sale & leaseback",
				credit_line: "Credit line",
				other: "Other",
			},
			rentalStates: {
				no: "No",
				internal: "Within company",
				b2c: "To the end customer",
			},
		},
		sections: {
			retailer: "Retailer",
		},
	},

	representativeCard: {
		address: "Address",
		addTin: "Add TIN",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		email: "Email",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		ownership: "Ownership",
		phone: "Phone",
		tin: "TIN",
		position: "Position",
		willingToSign: "Willing to sign",
		creditScore: "Credit Score",
		lastUpdated: "Last updated",
	},

	representativeModal: {
		addRepresentative: "Add representative",
		allFieldsRequired: "All fields are required",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		editRepresentative: "Edit representative",
		firstName: "First name",
		houseNumber: "House number",
		lastName: "Last name",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		optional: "(can be added later)",
		percentageOwnership: "Percentage Ownership",
		phoneNumber: "Phone",
		representativeNotContacted:
			"The representative will not be contacted before the offer is accepted.",
		street: "Street",
		taxNumber: "TIN (Tax ID number or Tax Number)",
		willingToSign: "Willing to sign",
		position: "Position",
	},
	companySelectFormItem: {
		label: "Company name",
		placeholder: "Example GmbH",
		errorCompanyNameInvalid: "Please review your company name and try again.",
		errorCompanyNameNotFound: "Not found? No Problem!",
		errorCompanyNameNotFoundLinkText: "Click here to enter the company details manually.",
		existing: "Existing",
	},
	industryCodes: {
		industryCode: "Industry code",
		industryCodes: "Industry codes",
		description: "Description",
		weight: "Weight",
		lastUpdatedOn: (date) => `last updated on: ${date}`,
		source: (source) => `source: ${source}`,
		codeType: (type) => `code type: ${type}`,
		approvedBy: ({ approvedBy, approvedAt }) =>
			`Manual override approved by: ${approvedBy} on ${approvedAt}`,
		updatedManually: "Industry codes have been updated manually. Review and approve.",
		modalApproveTitle: "Are you sure?",
		modalApproveDescription:
			"This action will approve the manual changes made in the industry codes. It cannot be undone.",
	},
	industryCodesEditModal: {
		title: "Update existing Industry Codes",
		update: "Update",
		type: "Type",
		codeEnum: { wz_2008: "WZ 20008", noga_2008: "NOGA 2008" },
		industryCodeNumber: (index) => `Industry code ${index}`,
		code: "Code",
		codeTooltip: "WZ2008 code example 01.11.2 --> 01112\nNOGA2008 code example 466900",
		codeError: "Please provide valid industry code",
		weight: "Weight",
		optional: "optional",
		description: "Description",
		addIndustryCode: "Add Industry Code",
		fieldRequired: "This field is required",
	},
	scoring: {
		overview: {
			alyze: "Alyze",
			alyzeRating: "Alyze rating",
			gridRate: "Grid rate",
		},
		issues: {
			issues: "Issues",
			critical: "Critical",
			nonCritical: "Non-Critical",
			formula: (formula) => `Formula: ${formula}`,
			value: (value) => `Value: ${value}`,
		},
		scores: {
			scores: "Scores",
			pdScore: "PD Score",
			totalScore: "Total score",
			ratingScore: (year) => `Rating score ${year}`,
		},
		benchmarkResults: {
			score: "Score",
			mark: "Mark",
			noData: "No data",
		},
		financials: {
			revenue: "Revenue",
			activated_costs: "Activated costs",
			inventory_increase: "Inventory increase",
			other_income_regular: "Other income regular",
			cogs: "COGS",
			other_cor: "Other CoR",
			cost_of_revenue: "Cost of revenue",
			gross_profit: "Gross profit",
			sga: "SGA",
			depreciations: "Depreciations",
			other_opex_costs: "Other Opex costs",
			opex: "Opex",
			operating_profit: "Operating profit",
			ebitda: "EBITDA	",
			other_income_net: "Other income net",
			other_finance_income: "Other finance income",
			other_finance_costs: "Other finance costs",
			interest_income: "Interest income",
			interest_expense: "Interest expense",
			ebt: "EBT",
			taxes_paid: "Taxes paid",
			nopat: "NOPAT",
			tax_rate: "Tax rate",
			net_income: "Net income",
			fixed_costs: "Fixed costs",
			cash: "Cash",
			short_term_investments: "Short term investments",
			accounts_receivable: "Accounts receivable",
			inventories: "Inventories	",
			inventory_net: "Inventory net",
			prepaid_expenses: "Prepaid expenses",
			other_current_assets: "Other current assets",
			loan_to_shareholders: "Loan to shareholders",
			loan_to_related_companies: "Loan to related companies",
			property_equipment_net: "Property equipment net",
			intangible_assets_net: "Intangible assets net",
			self_made_intangible: "Thereof: Self made intangible",
			goodwill: "Goodwill",
			long_term_investments: "Long term investments",
			investments_in_related_companies: "Investments in related companies",
			other_non_current_assets: "Other non current assets",
			deferred_tax_assets: "Deferred tax assets",
			current_assets_published: "Current assets published",
			non_current_assets_published: "Non current assets published",
			total_assets_published: "Total Assets published",
			equity_published: "Equity published",
			current_assets: "Current assets",
			non_current_assets: "Non current assets",
			total_assets: "Total Assets",
			current_portion_of_long_debt: "Current portion of long debt",
			accounts_payable: "Accounts payable",
			accrued_expenses: "Accured expenses",
			income_taxes_payable: "Income taxes payable",
			other_current_liabilities: "Other current liabilities",
			current_liabilities_published: "Current liabilities published",
			current_liabilities: "Current liabilities",
			long_term_debt: "Long term debt",
			underfunded_retirement_plans: "Underfunded retirement plans",
			deferred_tax_liabilities: "Deferred tax liabilities",
			other_long_term_liabilities: "Other long term liabilities",
			non_current_liabilities_published: "Non current liabilities published",
			total_liabilities_published: "Total Liabilities Published",
			loan_from_shareholders: "Loan from shareholders",
			non_current_liabilities: "Non current liabilities",
			total_liabilities: "Total Liabilities",
			equity: "Total Equity",
			preferred_stock: "Preferred stock",
			shares_issued: "Shares issued",
			paid_in_capital: "Paid in capital",
			retained_earnings: "Retained earnings",
			accumulated_other_comprehensive_income: "Accumulated Other comprehensive income",
			price_per_share: "Price per share",
			total_debt: "Total debt",
			net_debt: "Net debt",
			capital_employed: "Capital employed",
			total_invested_capital: "Total invested capital",
			net_fixed_assets: "Net fixed assets",
			working_capital: "Working capital",
			net_working_capital: "Net working capital",
			risk_free_rate_of_return: "Risk free rate of return",
			market_rate_of_return: "Market rate of return",
			classification_by_revenue: "Classification by revenue",
			beta: "Beta",
			balance_sheet_sum_check: "Balance sheet sum check",
			debt_service: "Debt service",
		},
		kpiData: {
			noData: "No data",
		},
	},
	timePassed: {
		weeksAgo: (weeks) => `${weeks} weeks ago`,
		daysAgo: (days) => `${days} days ago`,
		hoursAgo: (hours) => `${hours} hours ago`,
		minutesAgo: (minutes) => `${minutes} minutes ago`,
		momentsAgo: "moments ago",
	},
	financialsTable: {
		statementContextMenu: {
			settings: "Settings",
			delete: "Delete",
		},
		emptyScreen: {
			title: "No financial statements added yet.",
			subtitle: "Click the button below to start.",
			addStatementBtn: "Add statement",
		},
		modal: {
			titleEdit: "Edit statement",
			titleAddNew: "Add statement",
			statementType: "Statement type",
			annualStatement: "Annual",
			interimStatement: "Interim",
			statementAsOf: "As of",
		},
		origin: {
			manual: "Manual",
			ocr: "Ocr",
		},
		rows: financialTableRowsToTranslate.reduce<FinancialsTableRowsTranslation>(
			(acc, curr) => ({
				...acc,
				[curr]: curr,
			}),
			{} as FinancialsTableRowsTranslation
		),
	},
	factoring: {
		add: {
			financials: "Add Financial Information",
			invoices: "Add Invoice Information",
			debitors: "Add Debitor Information",
			risk_protection: "Add Risk Protection Information",
		},
		common: {
			in_land: (value: string | number | undefined) => `Inland: ${value}`,
			abroad: (value: string | number | undefined) => `Abroad: ${value}`,
			in_land_text: "Inland",
			abroad_text: "Abroad",
			comment: "Comment",
			no_information: "No  information added",
			change: "Change",
		},
		financials: {
			company_portfolio: "Company portfolio:",
			annual_sales_previous_year: "Annual sales last year:",
			planned_annual_sales_following_year: "Planned annual sales for the following year:",
			planned_annual_sales_factoring: "Planned annual sales intended for factoring:",
		},
		invoices: {
			number_of_outgoing_invoices: "Number of outgoing invoices:",
			invoice_currencies: "Invoice currencies:",
			max_invoice_value_previous_year: "Maximum invoice value in the previous year:",
			payment_deadline_for_outgoing_invoices: "Payment deadline for outgoing invoice:",
		},
		debitors: {
			number_of_debitors: "Number of debitors:",
			debitors: "Provide top 5 largest debtors",
			debitors_title: "Debitors",
			form_title: {
				company_name: "Company Name",
				company_country: "Country",
				annual_sales: "Annual sales last year",
			},
		},
		risk: {
			receivables_default_amount_previous_year: "Bad debts last year:",
			receivables_default_amount_current_year: "Bad debts current year:",
			are_receivables_assigned: "Assignment of receivables:",
			has_commercial_credit_insurance: "Trade credit insurance:",
			already_has_factoring: "Previous Factorer:",
			centralized_settlement_entity: "Central payer:",
		},
		forms: {
			financials: {
				company_portfolio: "Company portfolio:",
				annual_sales_previous_year: "Annual sales last year:",
				planned_annual_sales_following_year: "Planned annual sales for the following year:",
				planned_annual_sales_factoring: "Planned annual sales intended for factoring:",
				errors: {
					more: (max) => `The total cannot exceed ${max}`,
					less: (max) => `The total cannot be less than  ${max}`,
				},
			},
			invoices: {
				number_of_outgoing_invoices: "Number of outgoing invoices:",
				invoice_currencies: "Invoice currencies:",
				max_invoice_value_previous_year: "Maximum invoice value in the previous year:",
				payment_deadline_for_outgoing_invoices: "Payment deadline for outgoing invoices:",
			},
			debitors: {
				number_of_debitors: "Number of debitors:",
				debitors: "Provide top 5 largest debtors:",
				company_name: "Company name",
				country: "Country",
				annual_sales_previous_year: "Annual sales last year:",
			},
			risk: {
				yes: "Yes",
				no: "No",
				receivables_default_amount_previous_year: "Bad debts last year:",
				receivables_default_amount_current_year: "Bad debts current year:",
				are_receivables_assigned: "Assignment of receivables:",
				has_commercial_credit_insurance: "Trade credit insurance:",
				already_has_factoring: "Previous Factorer:",
				centralized_settlement_entity: "Central payer:",
			},
		},
	},
	fincr: {
		critical: "Critical",
		"non-critical": "Non critical",
		json: "Download JSON",
		xlsx: "Download XLSX",
		issues: "Issues",
		download: "Download",
		rescan: "Rescan",
		scan: "Scan",
		"upload-documents": "Upload documents",
		empty: "No Documents added yet",
		rescanModalTitle: "Are you sure?",
		rescanModalContent:
			"OCR documents and financial statements previously generated will be overwritten.",
		interim: "Interim",
		annual: "Manual",
		issuesModalTitle: "OCR Issues",
		documentCategory: (cat) => cat,
		documentType: (type) => type,
	},
};

const TranslationsContext = createContext<Translations>(fallbackTranslations);

export const useTranslations = () => {
	return useContext(TranslationsContext);
};

interface TranslationsProviderProps {
	translations: Partial<Translations>;
	children: ReactNode;
}

export const TranslationsProvider = ({ translations, children }: TranslationsProviderProps) => {
	const t = useTranslations();

	return (
		<TranslationsContext.Provider value={{ ...t, ...translations }}>
			{children}
		</TranslationsContext.Provider>
	);
};
