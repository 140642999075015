import { useState } from "react";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { Axios } from "src/utils/Axios";
export const useQueryClient = () => {
    const [queryClient] = useState(() => new QueryClient({
        queryCache: new QueryCache({
            onError: (error) => {
                void Axios.error(error);
            },
        }),
    }));
    return queryClient;
};
