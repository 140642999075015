import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Tooltip, Typography } from "antd";
import styled, { css } from "styled-components";

import { Warning } from "@teylor-tools/Api";

import { DataCard } from "../../data-display/DataCard";
import { useTranslations } from "../../translations/translations";

const { Title } = Typography;

const ExclamationCircleFilledIcon = styled(ExclamationCircleFilled)<{ $warning?: boolean }>`
	margin-right: 16px;
	${({ $warning, theme }) =>
		$warning
			? css`
					color: ${theme.colorWarning};
				`
			: css`
					color: ${theme.colorError};
				`}
`;

const IssueTitle = styled(Title)`
	margin-bottom: 24px;
`;

const Flag = ({ type, message, expression, replaced_expression }: Warning) => {
	const t = useTranslations();
	return (
		<Tooltip
			trigger="hover"
			overlayInnerStyle={{ maxWidth: 400 }}
			overlayStyle={{ maxWidth: 400 }}
			placement="topLeft"
			title={
				(expression || replaced_expression) && (
					<div>
						{expression && <div>{t.scoring.issues.formula(expression)}</div>}
						{replaced_expression && <div>{t.scoring.issues.value(replaced_expression)}</div>}
					</div>
				)
			}
		>
			<Typography.Paragraph>
				<ExclamationCircleFilledIcon $warning={type === "warning"} />
				{message}
			</Typography.Paragraph>
		</Tooltip>
	);
};

interface Props {
	scoreWarnings?: Warning[] | null;
}

const ScoringOverviewIssues = ({ scoreWarnings = [] }: Props) => {
	const t = useTranslations();

	const redFlags = scoreWarnings?.filter(({ type }) => type === "red_flag");
	const yellowFlags = scoreWarnings?.filter(({ type }) => type === "warning");

	return (
		<DataCard title={`${t.scoring.issues.issues} (${scoreWarnings ? scoreWarnings.length : 0})`}>
			{scoreWarnings && (
				<>
					<IssueTitle level={5}>
						{t.scoring.issues.critical} {`(${redFlags ? redFlags.length : 0})`}
					</IssueTitle>
					{redFlags?.map((flag, idx: number) => {
						return <Flag key={`${flag.type}-${idx}`} {...flag} />;
					})}
					<Divider />
					<IssueTitle level={5}>
						{t.scoring.issues.nonCritical} {`(${yellowFlags ? yellowFlags.length : 0})`}
					</IssueTitle>
					{yellowFlags?.map((flag, idx: number) => {
						return <Flag key={`${flag.type}-${idx}`} {...flag} />;
					})}
				</>
			)}
		</DataCard>
	);
};

export default ScoringOverviewIssues;
