import { useCallback, useMemo } from "react";

import { Form, FormItemProps, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Rule } from "antd/lib/form";
import { RuleObject } from "rc-field-form/lib/interface";

import { Currency, FieldConfig } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";

import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "../form/inputs/number-input-localized/NumberInputLocalized";
import PercentInput from "../form/inputs/percent-input/PercentInput";
import { useTranslations } from "../translations/translations";

enum FieldUiType {
	TextArea,
	RadioButtons,
	NumberInput,
	Currency,
	Percent,
	Select,
}

const getFieldType = (config: FieldConfig): FieldUiType | undefined => {
	if (config.ui.is_radio_button) {
		return FieldUiType.RadioButtons;
	}

	if (config.ui.is_select) {
		return FieldUiType.Select;
	}

	if (config.ui.is_multiline) {
		return FieldUiType.TextArea;
	}

	if (config.ui.is_currency) {
		return FieldUiType.Currency;
	}

	if (config.ui.is_percent) {
		return FieldUiType.Percent;
	}

	if (config.type === "decimal" || config.type === "integer") {
		return FieldUiType.NumberInput;
	}
};

export interface CustomFieldProps {
	config: FieldConfig;

	translations: {
		label: string;
		placeholder?: string;
		addonAfter?: string;
	};
}

interface Props extends CustomFieldProps {
	defaultCurrency?: Currency;
}

const CustomField = ({ config, defaultCurrency = Currency.EUR, translations }: Props) => {
	const t = useTranslations();
	const { currency, decimalToPercent, localizedNumber } = useFormatter();
	const fieldType = useMemo(() => getFieldType(config), [config]);

	// { precision: null } causes issues with input on blur
	const precision = config.ui.precision !== null ? config.ui.precision : undefined;

	const getValidationRules = useMemo((): Rule[] => {
		const rules: Rule[] = [];

		if (config.rules.is_required) {
			rules.push({
				required: true,
				message: t.formErrors.fieldRequired,
			});
		}

		if (config.rules.regex) {
			rules.push({
				pattern: new RegExp(config.rules.regex),
				message: t.formErrors.fieldInvalid,
			});
		}

		if (config.rules.min) {
			switch (config.type) {
				case "string":
					rules.push({
						validator: (_: RuleObject, value: string) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return isValidNumericValue(config.rules.min) && value.length >= config.rules.min!
								? Promise.resolve()
								: Promise.reject(t.formErrors.fieldMinLength(config.rules.min));
						},
					});
					break;
				case "integer":
					rules.push({
						validator: (_: RuleObject, value: number) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return isValidNumericValue(config.rules.min) && value >= config.rules.min!
								? Promise.resolve()
								: Promise.reject(
										t.formErrors.fieldMinValue(localizedNumber({ value: config.rules.min! }))
									);
						},
					});
					break;
				case "decimal":
					rules.push({
						validator: (_: RuleObject, value: number) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							if (config.ui.is_percent) {
								const min = isValidNumericValue(config.rules.min)
									? Number(
											decimalToPercent({
												percent: config.rules.min,
												decimalPlaces: config.ui.precision,
												withLocale: false,
											})
										)
									: undefined;

								return isValidNumericValue(min) && value >= min
									? Promise.resolve()
									: Promise.reject(
											t.formErrors.fieldMinValue(
												decimalToPercent({
													percent: config.rules.min!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
													decimalPlaces: config.ui.precision,
													showPercentSymbol: config.ui.is_percent,
												})
											)
										);
							}

							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return isValidNumericValue(config.rules.min) && value >= config.rules.min!
								? Promise.resolve()
								: Promise.reject(
										t.formErrors.fieldMinValue(
											localizedNumber({
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												value: config.rules.min!,
												decimalPlaces: config.ui.precision,
											})
										)
									);
						},
					});
					break;
			}
		}

		if (config.rules.max) {
			switch (config.type) {
				case "string":
					rules.push({
						validator: (_: RuleObject, value: string) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							return config.rules.max && value.length <= config.rules.max
								? Promise.resolve()
								: Promise.reject(t.formErrors.fieldMaxLength(config.rules.max));
						},
					});
					break;
				case "integer":
					rules.push({
						validator: (_: RuleObject, value: number) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return isValidNumericValue(config.rules.max) && value <= config.rules.max!
								? Promise.resolve()
								: Promise.reject(
										t.formErrors.fieldMaxValue(localizedNumber({ value: config.rules.max! }))
									);
						},
					});
					break;
				case "decimal":
					rules.push({
						validator: (_: RuleObject, value: number) => {
							if (!isValidNumericValue(value)) {
								return Promise.resolve();
							}

							if (config.ui.is_percent) {
								const max =
									isValidNumericValue(config.rules.max) &&
									Number(
										decimalToPercent({
											percent: config.rules.max,
											decimalPlaces: config.ui.precision,
											withLocale: false,
										})
									);

								return max && value <= max
									? Promise.resolve()
									: Promise.reject(
											t.formErrors.fieldMaxValue(
												decimalToPercent({
													percent: config.rules.max!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
													decimalPlaces: config.ui.precision,
													showPercentSymbol: config.ui.is_percent,
												})
											)
										);
							}

							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return isValidNumericValue(config.rules.max) && value <= config.rules.max!
								? Promise.resolve()
								: Promise.reject(
										t.formErrors.fieldMaxValue(
											localizedNumber({
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												value: config.rules.max!,
												decimalPlaces: config.ui.precision,
											})
										)
									);
						},
					});
					break;
			}
		}

		return rules;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config]);

	const formItemProps: FormItemProps = useMemo(
		() => ({
			name: config.name,
			label: translations.label,
			rules: getValidationRules,
			validateFirst: true,
			initialValue: config.ui.default_value,
		}),
		[translations, config, getValidationRules]
	);

	const renderRadioButtons = useCallback(() => {
		if (config.type === "string" && config.ui.possible_values?.length) {
			return (config.ui.possible_values as number[]).map((v) => (
				<Radio value={v} key={v}>
					{config.ui.is_currency && defaultCurrency
						? currency(v, {
								currency: defaultCurrency,
								showFractions: v.toString().includes("."),
							})
						: v}
				</Radio>
			));
		}

		if (config.ui.is_currency && defaultCurrency && config.ui.possible_values?.length) {
			return (
				<>
					{(config.ui.possible_values as number[]).map((v) => (
						<Radio value={v} key={v}>
							{currency(v, {
								currency: defaultCurrency,
								showFractions: false,
							})}
						</Radio>
					))}
				</>
			);
		}

		if (config.type === "boolean") {
			return (
				<>
					<Radio value={true}>{t.yes}</Radio>
					<Radio value={false}>{t.no}</Radio>
				</>
			);
		}
	}, [config, defaultCurrency, t, currency]);

	switch (fieldType) {
		case FieldUiType.RadioButtons:
			return (
				<Form.Item {...formItemProps}>
					<Radio.Group>{renderRadioButtons()}</Radio.Group>
				</Form.Item>
			);
		case FieldUiType.TextArea:
			return (
				<Form.Item {...formItemProps}>
					<TextArea
						showCount
						maxLength={Number(config.rules.max)}
						rows={config.ui.rows || 5}
						placeholder={translations.placeholder}
					/>
				</Form.Item>
			);
		case FieldUiType.NumberInput:
			return (
				<Form.Item {...formItemProps}>
					<NumberInputLocalized
						controls={false}
						style={{ width: "100%" }}
						placeholder={translations.placeholder}
						addonAfter={translations.addonAfter}
						precision={precision}
					/>
				</Form.Item>
			);
		case FieldUiType.Percent:
			return (
				<Form.Item
					{...formItemProps}
					initialValue={
						config.ui.default_value &&
						decimalToPercent({
							percent: config.ui.default_value as number,
							decimalPlaces: config.ui.precision,
							withLocale: false,
						})
					}
				>
					<PercentInput
						style={{ width: "100%" }}
						placeholder={translations.placeholder}
						addonAfter={translations.addonAfter}
						precision={precision}
					/>
				</Form.Item>
			);
		case FieldUiType.Currency:
			return (
				<Form.Item {...formItemProps}>
					<CurrencyInput
						style={{ width: "100%" }}
						placeholder={translations.placeholder}
						addonAfter={translations.addonAfter}
						precision={precision}
						currency={defaultCurrency}
					/>
				</Form.Item>
			);
		case FieldUiType.Select:
			return (
				<Form.Item {...formItemProps}>
					<Select
						options={(config.ui.possible_values as number[]).map((v) => ({
							value: v,
							label: config.ui.is_percent ? v * 100 : v,
						}))}
					/>
				</Form.Item>
			);
		default:
			return null;
	}
};

export default CustomField;
