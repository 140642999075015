import { Admin, Partner, User } from "@teylor-tools/Api";

export enum FactoryDetailsType {
	financials = "financials",
	invoices = "invoices",
	debitors = "debitors",
	risk = "risk_protection",
}

export interface FactoringUserApiTypes {
	financials: {
		request: User.ApplicationsFactoringFinancialsPartialUpdate.RequestBody;
		response: User.ApplicationsFactoringFinancialsPartialUpdate.ResponseBody;
	};
	invoices: {
		request: User.ApplicationsFactoringInvoicesPartialUpdate.RequestBody;
		response: User.ApplicationsFactoringInvoicesPartialUpdate.ResponseBody;
	};
	debitors: {
		request: User.ApplicationsFactoringDebitorsPartialUpdate.RequestBody;
		response: User.ApplicationsFactoringDebitorsPartialUpdate.ResponseBody;
	};
	risk_protection: {
		request: User.ApplicationsFactoringRiskProtectionPartialUpdate.RequestBody;
		response: User.ApplicationsFactoringRiskProtectionPartialUpdate.ResponseBody;
	};
}

export interface FactoringAdminApiTypes {
	financials: {
		request: Admin.ApplicationsFactoringFinancialsPartialUpdate.RequestBody;
		response: Admin.ApplicationsFactoringFinancialsPartialUpdate.ResponseBody;
	};
	invoices: {
		request: Admin.ApplicationsFactoringInvoicesPartialUpdate.RequestBody;
		response: Admin.ApplicationsFactoringInvoicesPartialUpdate.ResponseBody;
	};
	debitors: {
		request: Admin.ApplicationsFactoringDebitorsPartialUpdate.RequestBody;
		response: Admin.ApplicationsFactoringDebitorsPartialUpdate.ResponseBody;
	};
	risk_protection: {
		request: Admin.ApplicationsFactoringRiskProtectionPartialUpdate.RequestBody;
		response: Admin.ApplicationsFactoringRiskProtectionPartialUpdate.ResponseBody;
	};
}

export interface FactoringPartnerApiTypes {
	financials: {
		request: Partner.ApplicationsFactoringFinancialsPartialUpdate.RequestBody;
		response: Partner.ApplicationsFactoringFinancialsPartialUpdate.ResponseBody;
	};
	invoices: {
		request: Partner.ApplicationsFactoringInvoicesPartialUpdate.RequestBody;
		response: Partner.ApplicationsFactoringInvoicesPartialUpdate.ResponseBody;
	};
	debitors: {
		request: Partner.ApplicationsFactoringDebitorsPartialUpdate.RequestBody;
		response: Partner.ApplicationsFactoringDebitorsPartialUpdate.ResponseBody;
	};
	risk_protection: {
		request: Partner.ApplicationsFactoringRiskProtectionPartialUpdate.RequestBody;
		response: Partner.ApplicationsFactoringRiskProtectionPartialUpdate.ResponseBody;
	};
}
