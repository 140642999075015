import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { TranslationsProvider as TranslationsProviderUI, } from "@ui/translations/translations";
import { financialTableRowsToTranslate, } from "@ui/translations/translations-financial-table";
export const TranslationsProvider = ({ children }) => {
    const { t } = useTranslation();
    const translationsUI = useMemo(() => ({
        add: t("common.add"),
        yes: t("common.yes"),
        no: t("common.no"),
        save: t("common.save"),
        select: t("common.select"),
        edit: t("common.edit"),
        delete: t("common.delete"),
        cancel: t("common.cancel"),
        approve: t("common.approve"),
        ok: t("common.ok"),
        close: t("common.close"),
        city: t("application_form.city"),
        country: t("application_form.country"),
        countries: getCountryList(i18n.language),
        emailInputErrorMessage: t("application_form.email-invalid"),
        emailInputLabel: t("application_form.email"),
        emailInputPlaceholder: t("application_form.email-example"),
        postcode: t("application_form.post-code"),
        postcodeInvalid: t("application_form.post-code-invalid"),
        postcodePlaceholder: t("application_form.post-code-placeholder"),
        tinInvalid: t("application_form.tax-number-invalid"),
        tinPlaceholder: t("application_form.tax-number-placeholder"),
        ibanInvalid: t("application_form.iban-invalid"),
        formErrors: {
            fieldRequired: t("application_form.field-required"),
            fieldInvalid: t("application_form.errors.fieldInvalid"),
            fieldMustBeBetweenMinMaxNumbers: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMaxNumbers", { min, max }),
            fieldMustBeBetweenMinMaxChars: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMaxChars", { min, max }),
            fieldMustBeBetweenMinMax: (min, max) => t("application_form.errors.fieldMustBeBetweenMinMax", { min, max }),
            fieldMustBeMinChars: (min) => t("application_form.errors.fieldMustBeMinChars", { min }),
            passwordShort: t("application_form.password-too-short"),
            passwordNotComplex: t("application_form.password-not-complex"),
            invalidPhoneNumber: t("application_form.invalid-phone-number"),
            fieldContainsInvalidCharacters: t("application_form.errors.fieldContainsInvalidCharacters"),
            personalTinMustBeDifferentThanCompanyTin: t("application_form.errors.personalTinMustBeDifferentThanCompanyTin"),
            fieldMinLength: (min) => t("common.fieldMinLength", { min }),
            fieldMaxLength: (max) => t("common.fieldMaxLength", { max }),
            fieldMinValue: (min) => t("common.errors.fieldMinValue", { min }),
            fieldMaxValue: (max) => t("common.errors.fieldMaxValue", { max }),
        },
        representativeCard: {
            address: t("application.company_representatives.address"),
            addTin: t("application.company_representatives.add-tin"),
            beneficialOwner: t("application.company_representatives.beneficial-owner"),
            canSignAlone: t("application.company_representatives.can-sign-alone"),
            dateOfBirth: t("application.company_representatives.dob"),
            email: t("application.company_representatives.email"),
            legalSignatory: t("application.company_representatives.legal-signatory"),
            nationality: t("application.company_representatives.nationality"),
            ownership: t("application.company_representatives.ownership"),
            phone: t("application.company_representatives.phone"),
            tin: t("application.company_representatives.tin"),
            willingToSign: t("application.company_representatives.willing-to-sign"),
            position: t("application.company_representatives.position"),
            creditScore: t("application.company_representatives.credit-score"),
            lastUpdated: t("application.company_representatives.last-updated"),
        },
        representativeModal: {
            addRepresentative: t("application.company_representatives.add-representative"),
            allFieldsRequired: t("application.company_representatives.all-fields-required"),
            beneficialOwner: t("application.company_representatives.beneficial-owner"),
            canSignAlone: t("application.company_representatives.can-sign-alone"),
            dateOfBirth: t("application.company_representatives.dob"),
            editRepresentative: t("application.company_representatives.edit-representative"),
            firstName: t("application.company_representatives.first-name"),
            houseNumber: t("application.company_representatives.house-number"),
            lastName: t("application.company_representatives.last-name"),
            legalSignatory: t("application.company_representatives.legal-signatory"),
            nationality: t("application.company_representatives.nationality"),
            optional: t("application.company_representatives.optional"),
            percentageOwnership: t("application.company_representatives.percentage-ownership"),
            phoneNumber: t("application.company_representatives.phone-number"),
            representativeNotContacted: t("application.company_representatives.representative-not-contacted"),
            street: t("application.company_representatives.street"),
            taxNumber: t("application.company_representatives.tax-number"),
            willingToSign: t("application.company_representatives.willing-to-sign"),
            position: t("application.company_representatives.position"),
        },
        object: {
            object: t("application.objects.object"),
            manufacturer: t("application.objects.object.manufacturer"),
            type: t("application.objects.object.type"),
            yearBuilt: t("application.objects.object.year-built"),
            objectDescription: t("application.objects.object.object-description"),
            netPurchasePrice: t("application.objects.object.net-purchase-price"),
            downPayment: t("application.objects.object.down-payment"),
            remainingValue: t("application.objects.object.remaining-value"),
            numberOfObjects: t("application.objects.object.number-of-objects"),
            financingType: t("application.objects.object.financing-type"),
            rentalState: t("application.objects.object.rental-state"),
            isFixedInterest: t("application.objects.object.is-fixed-interest"),
            companyName: t("application.objects.object.retailer-company-name"),
            street: t("application.objects.object.retailer-street"),
            houseNumber: t("application.objects.object.retailer-house-number"),
            city: t("application.objects.object.retailer-city"),
            postcode: t("application.objects.object.retailer-postcode"),
            country: t("application.objects.object.retailer-country"),
            retailerCompanyId: t("application.objects.object.retailer-company-id"),
            retailerName: t("application.objects.object.retailer-name"),
            retailerAddress: t("application.objects.object.retailer-address"),
            expectedDeliveryDate: t("application.objects.object.expected-delivery-date"),
            createOrderForm: t("application.objects.object.create-order-form"),
            errors: {
                required: t("application.create-application.required"),
                fieldMinLength5: t("application_form.errors.fieldMustBeMin", { min: 5 }),
                postcodeInvalid: t("application_form.post-code-invalid"),
            },
            placeholders: {
                manufacturer: t("application.objects.object.manufacturer.placeholder"),
                type: t("application.objects.object.type.placeholder"),
                yearBuilt: t("application.objects.object.year-built.placeholder"),
                objectDescription: t("application.objects.object.object-description.placeholder"),
                objectCompanyName: t("application.objects.object.retailer-company-name.placeholder"),
                street: t("application.objects.object.retailer-street.placeholder"),
                houseNumber: t("application.objects.object.retailer-house-number.placeholder"),
                city: t("application.objects.object.retailer-city.placeholder"),
                retailerCompanyId: t("application.objects.object.retailer-company-id.placeholder"),
            },
            options: {
                isFixedInterest: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                },
                financingTypes: {
                    leasing: t("application.objects.financing_type.leasing"),
                    hire_purchase: t("application.objects.financing_type.hire_purchase"),
                    financing: t("application.objects.financing_type.financing"),
                    sale_leaseback: t("application.objects.financing_type.sale_leaseback"),
                    credit_line: t("application.objects.financing_type.credit_line"),
                    other: t("application.objects.financing_type.other"),
                },
                rentalStates: {
                    no: t("application.objects.rental_state.no"),
                    internal: t("application.objects.rental_state.internal"),
                    b2c: t("application.objects.rental_state.b2c"),
                },
            },
            sections: {
                retailer: t("application.objects.object.retailer"),
            },
        },
        companySelectFormItem: {
            label: t("application_form.country_select.label"),
            placeholder: t("application_form.country_select.placeholder"),
            errorCompanyNameInvalid: t("application_form.country_select.company_name_invalid"),
            errorCompanyNameNotFound: t("application_form.country_select.company_name_not_found_text"),
            errorCompanyNameNotFoundLinkText: t("application_form.country_select.company_name_not_found_link"),
            existing: t("application_form.company_select.existing"),
        },
        industryCodes: {
            industryCode: t("application.company.industry_code"),
            industryCodes: t("application.company.industry_codes"),
            description: t("application.company.description"),
            weight: t("application.company.weight"),
            lastUpdatedOn: (date) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.last_updated_on" }, { children: { date } }))),
            source: (source) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.source" }, { children: { source } }))),
            codeType: (type) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.code_type" }, { children: { type } }))),
            approvedBy: ({ approvedBy, approvedAt }) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.approved_by" }, { children: { approvedBy, approvedAt } }))),
            updatedManually: t("application.company.industry_code.updated_manually"),
            modalApproveTitle: t("application.company.industry_code.modal_approve_title"),
            modalApproveDescription: t("application.company.industry_code.modal_approve_description"),
        },
        industryCodesEditModal: {
            title: t("industry_codes.title"),
            update: t("industry_codes.update"),
            type: t("industry_codes.type"),
            codeEnum: {
                wz_2008: t("industry_codes.wz_2008"),
                noga_2008: t("industry_codes.noga_2008"),
            },
            industryCodeNumber: (index) => t("industry_codes.industry_code", { index }),
            code: t("industry_codes.code"),
            codeTooltip: t("industry_codes.code.tooltip"),
            codeError: t("industry_codes.code.error"),
            weight: t("industry_codes.weight"),
            optional: t("industry_codes.optional"),
            description: t("industry_codes.description"),
            addIndustryCode: t("industry_codes.add_code"),
            fieldRequired: t("industry_codes.field-required"),
        },
        scoring: {
            overview: {
                alyze: t("application.quantitative_analysis.overview.alyze"),
                alyzeRating: t("application.quantitative_analysis.overview.alyze-rating"),
                gridRate: t("application.quantitative_analysis.overview.grid-rate"),
            },
            issues: {
                issues: t("application.quantitative_analysis.overview.issues"),
                critical: t("application.quantitative_analysis.overview.critical"),
                nonCritical: t("application.quantitative_analysis.overview.non-critical"),
                formula: (formula) => t("scoring_warning.formula", { formula }),
                value: (value) => t("scoring_warning.value", { value }),
            },
            scores: {
                scores: t("application.quantitative_analysis.overview.scores"),
                pdScore: t("application.quantitative_analysis.overview.pd-score"),
                totalScore: t("application.quantitative_analysis.overview.total-score"),
                ratingScore: (year) => t("application.quantitative_analysis.overview.rating-score", { year }),
            },
            benchmarkResults: {
                score: t("application.quantitative_analysis.overview.title-score"),
                mark: t("application.quantitative_analysis.overview.title-mark"),
                noData: t("application.quantitative_analysis.overview.no-data"),
            },
            financials: {
                revenue: t("application.quantitative_analysis.financials.revenue"),
                activated_costs: t("application.quantitative_analysis.financials.activated_costs"),
                inventory_increase: t("application.quantitative_analysis.financials.inventory_increase"),
                other_income_regular: t("application.quantitative_analysis.financials.other_income_regular"),
                cogs: t("application.quantitative_analysis.financials.cogs"),
                other_cor: t("application.quantitative_analysis.financials.other_cor"),
                cost_of_revenue: t("application.quantitative_analysis.financials.cost_of_revenue"),
                gross_profit: t("application.quantitative_analysis.financials.gross_profit"),
                sga: t("application.quantitative_analysis.financials.sga"),
                depreciations: t("application.quantitative_analysis.financials.depreciations"),
                other_opex_costs: t("application.quantitative_analysis.financials.other_opex_costs"),
                opex: t("application.quantitative_analysis.financials.opex"),
                operating_profit: t("application.quantitative_analysis.financials.operating_profit"),
                ebitda: t("application.quantitative_analysis.financials.ebitda"),
                other_income_net: t("application.quantitative_analysis.financials.other_income_net"),
                other_finance_income: t("application.quantitative_analysis.financials.other_finance_income"),
                other_finance_costs: t("application.quantitative_analysis.financials.other_finance_costs"),
                interest_income: t("application.quantitative_analysis.financials.interest_income"),
                interest_expense: t("application.quantitative_analysis.financials.interest_expense"),
                ebt: t("application.quantitative_analysis.financials.ebt"),
                taxes_paid: t("application.quantitative_analysis.financials.taxes_paid"),
                nopat: t("application.quantitative_analysis.financials.nopat"),
                tax_rate: t("application.quantitative_analysis.financials.tax_rate"),
                net_income: t("application.quantitative_analysis.financials.net_income"),
                fixed_costs: t("application.quantitative_analysis.financials.fixed_costs"),
                cash: t("application.quantitative_analysis.financials.cash"),
                short_term_investments: t("application.quantitative_analysis.financials.short_term_investments"),
                accounts_receivable: t("application.quantitative_analysis.financials.accounts_receivable"),
                inventories: t("application.quantitative_analysis.financials.inventories"),
                inventory_net: t("application.quantitative_analysis.financials.inventory_net"),
                prepaid_expenses: t("application.quantitative_analysis.financials.prepaid_expenses"),
                other_current_assets: t("application.quantitative_analysis.financials.other_current_assets"),
                loan_to_shareholders: t("application.quantitative_analysis.financials.loan_to_shareholders"),
                loan_to_related_companies: t("application.quantitative_analysis.financials.loan_to_related_companies"),
                property_equipment_net: t("application.quantitative_analysis.financials.property_equipment_net"),
                intangible_assets_net: t("application.quantitative_analysis.financials.intangible_assets_net"),
                self_made_intangible: t("application.quantitative_analysis.financials.self_made_intangible"),
                goodwill: t("application.quantitative_analysis.financials.goodwill"),
                long_term_investments: t("application.quantitative_analysis.financials.long_term_investments"),
                investments_in_related_companies: t("application.quantitative_analysis.financials.investments_in_related_companies"),
                other_non_current_assets: t("application.quantitative_analysis.financials.other_non_current_assets"),
                deferred_tax_assets: t("application.quantitative_analysis.financials.deferred_tax_assets"),
                current_assets_published: t("application.quantitative_analysis.financials.current_assets_published"),
                non_current_assets_published: t("application.quantitative_analysis.financials.non_current_assets_published"),
                total_assets_published: t("application.quantitative_analysis.financials.total_assets_published"),
                equity_published: t("application.quantitative_analysis.financials.equity_published"),
                current_assets: t("application.quantitative_analysis.financials.current_assets"),
                non_current_assets: t("application.quantitative_analysis.financials.non_current_assets"),
                total_assets: t("application.quantitative_analysis.financials.total_assets"),
                current_portion_of_long_debt: t("application.quantitative_analysis.financials.current_portion_of_long_debt"),
                accounts_payable: t("application.quantitative_analysis.financials.accounts_payable"),
                accrued_expenses: t("application.quantitative_analysis.financials.accrued_expenses"),
                income_taxes_payable: t("application.quantitative_analysis.financials.income_taxes_payable"),
                other_current_liabilities: t("application.quantitative_analysis.financials.other_current_liabilities"),
                current_liabilities_published: t("application.quantitative_analysis.financials.current_liabilities_published"),
                current_liabilities: t("application.quantitative_analysis.financials.current_liabilities"),
                long_term_debt: t("application.quantitative_analysis.financials.long_term_debt"),
                underfunded_retirement_plans: t("application.quantitative_analysis.financials.underfunded_retirement_plans"),
                deferred_tax_liabilities: t("application.quantitative_analysis.financials.deferred_tax_liabilities"),
                other_long_term_liabilities: t("application.quantitative_analysis.financials.other_long_term_liabilities"),
                non_current_liabilities_published: t("application.quantitative_analysis.financials.non_current_liabilities_published"),
                total_liabilities_published: t("application.quantitative_analysis.financials.total_liabilities_published"),
                loan_from_shareholders: t("application.quantitative_analysis.financials.loan_from_shareholders"),
                non_current_liabilities: t("application.quantitative_analysis.financials.non_current_liabilities"),
                total_liabilities: t("application.quantitative_analysis.financials.total_liabilities"),
                equity: t("application.quantitative_analysis.financials.equity"),
                preferred_stock: t("application.quantitative_analysis.financials.preferred_stock"),
                shares_issued: t("application.quantitative_analysis.financials.shares_issued"),
                paid_in_capital: t("application.quantitative_analysis.financials.paid_in_capital"),
                retained_earnings: t("application.quantitative_analysis.financials.retained_earnings"),
                accumulated_other_comprehensive_income: t("application.quantitative_analysis.financials.accumulated_other_comprehensive_income"),
                price_per_share: t("application.quantitative_analysis.financials.price_per_share"),
                total_debt: t("application.quantitative_analysis.financials.total_debt"),
                net_debt: t("application.quantitative_analysis.financials.net_debt"),
                capital_employed: t("application.quantitative_analysis.financials.capital_employed"),
                total_invested_capital: t("application.quantitative_analysis.financials.total_invested_capital"),
                net_fixed_assets: t("application.quantitative_analysis.financials.net_fixed_assets"),
                working_capital: t("application.quantitative_analysis.financials.working_capital"),
                net_working_capital: t("application.quantitative_analysis.financials.net_working_capital"),
                risk_free_rate_of_return: t("application.quantitative_analysis.financials.risk_free_rate_of_return"),
                market_rate_of_return: t("application.quantitative_analysis.financials.market_rate_of_return"),
                classification_by_revenue: t("application.quantitative_analysis.financials.classification_by_revenue"),
                beta: t("application.quantitative_analysis.financials.beta"),
                balance_sheet_sum_check: t("application.quantitative_analysis.financials.balance_sheet_sum_check"),
                debt_service: t("application.quantitative_analysis.financials.debt_service"),
            },
            kpiData: {
                noData: t("application.quantitative_analysis.overview.no-data"),
            },
        },
        timePassed: {
            weeksAgo: (weeks) => t("common.dates.week-ago_other", { count: weeks }),
            daysAgo: (days) => t("common.dates.day-ago_other", { count: days }),
            hoursAgo: (hours) => t("common.dates.hour-ago_other", { count: hours }),
            minutesAgo: (minutes) => t("common.dates.minute-ago_other", { count: minutes }),
            momentsAgo: t("common.dates.moments-ago"),
        },
        financialsTable: {
            statementContextMenu: {
                settings: t("application.financials.settings"),
                delete: t("application.financials.delete"),
            },
            emptyScreen: {
                title: t("financials.empty.no-financials-title"),
                subtitle: t("financials.empty.no-financials-subtitle"),
                addStatementBtn: t("financials.empty.add-statement"),
            },
            modal: {
                titleEdit: t("financials.modal.financial-statement-settings"),
                titleAddNew: t("financials.modal.new-financial-statement"),
                statementType: t("financials.modal.type"),
                annualStatement: t("financials.modal.annual-statement"),
                interimStatement: t("financials.modal.interim-statement"),
                statementAsOf: t("financials.modal.statement-as-of"),
            },
            origin: {
                manual: t("application.financials.manual"),
                ocr: t("application.financials.ocr"),
            },
            rows: financialTableRowsToTranslate.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { 
                // @ts-ignore dynamic keys
                [curr]: t(`application.financials.${curr}`) })), {}),
        },
        factoring: {
            add: {
                financials: t("factoring.add.financials"),
                invoices: t("factoring.add.invoices"),
                debitors: t("factoring.add.debitors"),
                risk_protection: t("factoring.add.risk"),
            },
            common: {
                /* eslint-disable @typescript-eslint/no-unsafe-return */
                in_land: (value) => t("factoring.common.in_land", { value }),
                abroad: (value) => t("factoring.common.abroad", { value }),
                /* eslint-enable @typescript-eslint/no-unsafe-return */
                in_land_text: t("factoring.edit.in_land"),
                abroad_text: t("factoring.edit.abroad"),
                comment: t("factoring.common.comment"),
                no_information: t("factoring.common.no_information"),
                change: t("common.button_label.change"),
            },
            financials: {
                company_portfolio: t("factoring.financials.company_portfolio"),
                annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                planned_annual_sales_following_year: t("factoring.financials.planned_annual_sales_following_year"),
                planned_annual_sales_factoring: t("factoring.financials.planned_annual_sales_factoring"),
            },
            invoices: {
                number_of_outgoing_invoices: t("factoring.invoices.number_of_outgoing_invoices"),
                invoice_currencies: t("factoring.invoices.invoice_currencies"),
                max_invoice_value_previous_year: t("factoring.invoices.max_invoice_value_previous_year"),
                payment_deadline_for_outgoing_invoices: t("factoring.invoices.payment_deadline_for_outgoing_invoices"),
            },
            debitors: {
                number_of_debitors: t("factoring.debitors.number_of_debitors"),
                debitors: t("factoring.debitors.debitors"),
                debitors_title: `${t("factoring.debitors.info_card_debitor_details_title")}:`,
                form_title: {
                    company_name: t("factoring.debitors.fom-title.company-name"),
                    company_country: t("factoring.debitors.fom-title.company-country"),
                    annual_sales: t("factoring.debitor.annual_sales_previous_year"),
                },
            },
            risk: {
                receivables_default_amount_previous_year: t("factoring.risk.receivables_default_amount_previous_year"),
                receivables_default_amount_current_year: t("factoring.risk.receivables_default_amount_current_year"),
                are_receivables_assigned: t("factoring.risk.are_receivables_assigned"),
                has_commercial_credit_insurance: t("factoring.risk.has_commercial_credit_insurance"),
                already_has_factoring: t("factoring.risk.already_has_factoring"),
                centralized_settlement_entity: t("factoring.risk.centralized_settlement_entity"),
            },
            forms: {
                financials: {
                    company_portfolio: t("factoring.financials.company_portfolio"),
                    annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                    planned_annual_sales_following_year: t("factoring.financials.planned_annual_sales_following_year"),
                    planned_annual_sales_factoring: t("factoring.financials.planned_annual_sales_factoring"),
                    errors: {
                        /* eslint-disable @typescript-eslint/no-unsafe-return */
                        more: (max) => t("factoring.financial.data.error_more", { max }),
                        less: (max) => t("factoring.financial.data.error_less", { max }),
                        /* eslint-enable @typescript-eslint/no-unsafe-return */
                    },
                },
                invoices: {
                    number_of_outgoing_invoices: t("factoring.invoices.number_of_outgoing_invoices"),
                    invoice_currencies: t("factoring.invoices.invoice_currencies"),
                    max_invoice_value_previous_year: t("factoring.invoices.max_invoice_value_previous_year"),
                    payment_deadline_for_outgoing_invoices: t("factoring.invoices.payment_deadline_for_outgoing_invoices"),
                },
                debitors: {
                    number_of_debitors: t("factoring.debitors.number_of_debitors"),
                    debitors: t("factoring.debitors.debitors"),
                    company_name: t("factoring.debitors.fom-title.company-name"),
                    country: t("factoring.debitors.fom-title.company-country"),
                    annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                },
                risk: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                    receivables_default_amount_previous_year: t("factoring.risk.receivables_default_amount_previous_year"),
                    receivables_default_amount_current_year: t("factoring.risk.receivables_default_amount_current_year"),
                    are_receivables_assigned: t("factoring.risk.are_receivables_assigned"),
                    has_commercial_credit_insurance: t("factoring.risk.has_commercial_credit_insurance"),
                    already_has_factoring: t("factoring.risk.already_has_factoring"),
                    centralized_settlement_entity: t("factoring.risk.centralized_settlement_entity"),
                },
            },
        },
        fincr: {
            critical: t("application.fincr.critical"),
            "non-critical": t("application.fincr.non-critical"),
            json: t("application.fincr.json"),
            xlsx: t("application.fincr.xlsx"),
            issues: t("application.fincr.issues"),
            download: t("application.fincr.download"),
            rescan: t("application.fincr.rescan"),
            scan: t("application.fincr.scanBtn"),
            "upload-documents": t("fincr.upload-documents"),
            empty: t("fincr.empty"),
            rescanModalTitle: t("application.fincr.modal-title"),
            rescanModalContent: t("application.fincr.modal-content"),
            interim: t("application.fincr.interim"),
            annual: t("application.fincr.annual"),
            issuesModalTitle: t("application.fincr.ocr-issues"),
            // @ts-ignore
            documentCategory: (cat) => t(`documents.category.${cat}.name`),
            // @ts-ignore
            documentType: (type) => t(`documents.type.${type}.name`),
        },
    }), [t]);
    return _jsx(TranslationsProviderUI, Object.assign({ translations: translationsUI }, { children: children }));
};
