import { FC } from "react";

import { Space, Typography } from "antd";

import { CountryCode, FactoringInfoDebitors } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../translations/translations";
import { InfoCol, InfoRow, InfoTitle } from "./InformationCardComponents";

const { Text } = Typography;

interface InformationCardDebitorsInfo {
	info: FactoringInfoDebitors;
	hasInternationalCustomers: boolean;
}

export const InformationCardDebitorsInfo: FC<InformationCardDebitorsInfo> = ({
	info,
	hasInternationalCustomers,
}) => {
	const t = useTranslations();
	const { currency } = useFormatter();

	return (
		<>
			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.debitors.number_of_debitors}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>{t.factoring.common.in_land(info.number_of_debitors || "-")}</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>{t.factoring.common.abroad(info.number_of_debitors_abroad || "-")}</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>{info.number_of_debitors || "-"}</Text>
					</InfoCol>
				)}
			</InfoRow>
			<InfoRow style={{ marginBottom: 0 }}>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.debitors.debitors_title}</InfoTitle>
					</Space>
				</InfoCol>
			</InfoRow>
			<InfoRow>
				<InfoCol span={12}>
					<Text>{t.factoring.debitors.form_title.company_name}</Text>
				</InfoCol>
				<InfoCol span={6}>
					<Text>{t.factoring.debitors.form_title.company_country}</Text>
				</InfoCol>
				<InfoCol span={6}>
					<Text>{t.factoring.debitors.form_title.annual_sales}</Text>
				</InfoCol>
			</InfoRow>
			{info.debitors?.map((debitor, idx) => {
				return (
					<InfoRow key={debitor.debitor_id}>
						<InfoCol span={12}>
							<Text>{`${idx + 1}.`}</Text>
							<Text>{debitor.company_name || "-"}</Text>
						</InfoCol>
						<InfoCol span={6}>
							<Text>{t.countries[debitor.company_country as CountryCode] || "-"}</Text>
						</InfoCol>
						<InfoCol span={6}>
							<Text>
								{debitor.annual_sales_previous_year
									? currency(debitor.annual_sales_previous_year, { showFractions: false })
									: "-"}
							</Text>
						</InfoCol>
					</InfoRow>
				);
			})}
		</>
	);
};
