import { FC, useMemo } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Typography } from "antd";
import styled, { css } from "styled-components";

import { OcrWarning } from "@teylor-tools/Api";

import { useTranslations } from "../translations/translations";

const { Text } = Typography;

const IssueTitle = styled(Text)`
	margin-bottom: 24px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
`;
const ExclamationCircleFilledIcon = styled(ExclamationCircleFilled)<{ $warning?: boolean }>`
	margin-right: 16px;
	${({ $warning }) =>
		$warning
			? css`
					color: ${({ theme }) => theme.colorWarning};
				`
			: css`
					color: ${({ theme }) => theme.colorError};
				`}
`;

interface FinancialIssuesProps {
	warnings: OcrWarning[];
}

const FinancialsIssues: FC<FinancialIssuesProps> = ({ warnings }) => {
	const t = useTranslations();

	const ocrErrors = useMemo(() => warnings.filter(({ level }) => level === "error"), [warnings]);

	const ocrWarnings = useMemo(
		() => warnings.filter(({ level }) => level === "warning"),
		[warnings]
	);

	const renderIssues = (issues: OcrWarning[], type: "error" | "warning") => {
		return (
			<>
				<IssueTitle>
					{type === "error" ? t.fincr.critical : t.fincr["non-critical"]}{" "}
					{`(${issues ? issues.length : 0})`}
				</IssueTitle>

				{issues.map((issue, idx: number) => {
					return (
						<Typography.Paragraph key={`${issue.type}-${idx}`}>
							<ExclamationCircleFilledIcon $warning={issue.level === "warning"} />
							{issue.message}
						</Typography.Paragraph>
					);
				})}
			</>
		);
	};
	return (
		<>
			{renderIssues(ocrErrors, "error")}
			<Divider />
			{renderIssues(ocrWarnings, "warning")}
		</>
	);
};

export default FinancialsIssues;
