import { useTranslation } from "react-i18next";
import { useIndicativeRate, } from "@teylor-tools/hooks/useIndicativeRates";
import { checkIsIndicative } from "src/utils/isIndicative";
export const useInterestRate = (pricing) => {
    const { indicativeInterestRate } = useIndicativeRate();
    const { t } = useTranslation();
    const isIndicative = checkIsIndicative(pricing);
    if (isIndicative) {
        const indicativeIR = indicativeInterestRate(pricing);
        return indicativeIR ? `${indicativeIR} (${t("loans.indicative")})` : "-";
    }
    const interestRate = pricing.total_interest_rate || pricing.external_interest_rate || pricing.interest_rate;
    return interestRate ? `${(Number(interestRate) * 100).toFixed(2)}%` : "-";
};
