var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Switch, message } from "antd";
import SearchList from "../../../components/SearchList";
import { Axios } from "../../../utils/Axios";
import TeamGroupPreview from "./TeamGroupPreview";
const IS_ASSIGNABLE_SWITCH_ID = "is-assignable-switch";
const GroupsTab = ({ showPreview, handleShowPreview }) => {
    const { t } = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState();
    const ref = useRef();
    const getGroups = useCallback(() => {
        var _a;
        void ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getTableData().catch((err) => void Axios.error(err)));
    }, []);
    const updateGroup = useMutation({
        mutationKey: ["updateGroup"],
        mutationFn: (_a) => __awaiter(void 0, void 0, void 0, function* () {
            var { bouser_group_id, bo_users } = _a, rest = __rest(_a, ["bouser_group_id", "bo_users"]);
            const response = yield Axios.patch(`/admin/bo_user_groups/${bouser_group_id}`, Object.assign({ bo_user_ids: bo_users === null || bo_users === void 0 ? void 0 : bo_users.map(({ id }) => id) }, rest));
            return response.data;
        }),
        onSuccess: () => {
            void message.success(t("settings.team.group.group_was_updated"));
            void getGroups();
        },
        onError: (error) => {
            void Axios.error(error);
        },
    });
    const columns = [
        {
            dataIndex: "group_name",
            title: t("settings.team.group"),
        },
        {
            dataIndex: "bouser_group_id",
            title: t("settings.team.group.id"),
        },
        {
            dataIndex: "email",
            title: t("settings.team.group.email"),
        },
        {
            dataIndex: "bo_users",
            title: t("settings.team.groups.members"),
            render: (bo_users) => {
                if (!bo_users.length)
                    return "-";
                return bo_users.length;
            },
        },
        {
            dataIndex: "is_assignable",
            title: t("settings.team.groups.assignable"),
            render: (is_assignable, group) => {
                return (_jsx("div", Object.assign({ id: IS_ASSIGNABLE_SWITCH_ID }, { children: _jsx(Switch, { disabled: !group.email, checked: is_assignable, onChange: (is_assignable) => updateGroup.mutate(Object.assign(Object.assign({}, group), { is_assignable })) }) })));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { padding: "16px 0px" } }, { children: _jsx(SearchList, Object.assign({ ref: ref, endpoint: "/admin/bo_user_groups", columns: columns, showingOfString: "settings.team.groups.search-showing", rowKey: ({ bouser_group_id }) => bouser_group_id, onRow: (record) => {
                        return {
                            onClick(evt) {
                                if (!evt.target.closest(`#${IS_ASSIGNABLE_SWITCH_ID}`)) {
                                    setSelectedGroup(record);
                                    handleShowPreview(true);
                                }
                            },
                        };
                    } }, { children: _jsx("div", {}) })) })), _jsx(TeamGroupPreview, { group: selectedGroup, open: showPreview, getGroups: getGroups, onClose: () => {
                    setSelectedGroup(undefined);
                    handleShowPreview(false);
                } })] }));
};
export default GroupsTab;
