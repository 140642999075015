import { Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

interface HeadTextProps {
	$isRequired?: boolean;
}

const HeadText = styled(Text)<HeadTextProps>`
	display: inline-block;
	margin-bottom: 15px;
	&::before {
		content: "${(props) => (props.$isRequired ? "*" : "")}";
		color: red;
		margin-right: 4px;
		display: "${(props) => (props.$isRequired ? "inline" : "none")}";
	}
`;

export default HeadText;
