import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsAltOutlined, InfoCircleFilled, ShrinkOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Radio, Row, Spin, Switch, Typography, theme } from "antd";
import styled from "styled-components";
import { Axios } from "src/utils/Axios";
var Applicability;
(function (Applicability) {
    Applicability["applicable"] = "applicable";
    Applicability["notApplicable"] = "not_applicable";
})(Applicability || (Applicability = {}));
var PanelGroupToggleMode;
(function (PanelGroupToggleMode) {
    PanelGroupToggleMode["expandAll"] = "expandAll";
    PanelGroupToggleMode["collapseAll"] = "collapseAll";
})(PanelGroupToggleMode || (PanelGroupToggleMode = {}));
const Heading = styled(Typography.Title) `
	font-weight: 400 !important;
`;
const PanelGroupHeader = styled.div `
	padding: 32px 0 24px;
	display: flex;
	justify-content: space-between;
`;
const StyledCollapse = styled(Collapse) `
	border: 0;
	border-radius: 0;

	&.first {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		overflow: hidden;
	}

	&.last {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		overflow: hidden;

		.ant-collapse-item {
			border-bottom: 0;
		}
	}

	.ant-collapse-header {
		background: #fff;
		border-bottom: 0;
		border-radius: 0 !important;
	}

	.ant-collapse-content {
		border-top: 0;
		border-radius: 0 !important;
	}

	.ant-collapse-item {
		border-color: #f0f0f0;
		border-radius: 0 !important;
	}
`;
const StyledLabel = styled.span `
	font-weight: 600;
	margin-right: 8px;
`;
const RadioGroup = styled(Radio.Group) `
	width: 100%;

	.ant-radio-wrapper .ant-radio {
		align-self: baseline !important;
	}

	.ant-radio-inner {
		top: 4px !important;
	}
`;
const CollapsePanel = styled(Collapse.Panel) `
	.ant-collapse-header-text {
		width: 100%;
		flex: auto !important;
	}
`;
const checkShowInfoCircle = (item, option) => {
    if (item.not_applicable_allowed && item.not_applicable)
        return false;
    return (option === null || option === void 0 ? void 0 : option.type) && (option === null || option === void 0 ? void 0 : option.type) !== "white";
};
const QualitativeGroup = ({ groups, readonly, checklistId, update }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [showLoader, setShowLoader] = useState();
    const [openPanelsInGroup, setOpenPanelsInGroup] = useState({});
    useEffect(() => {
        setShowLoader(undefined);
    }, [groups]);
    const expandedPanelIds = useMemo(() => Object.values(openPanelsInGroup).flatMap((val) => val), [openPanelsInGroup]);
    const notExpandablePanelsInGroup = useMemo(() => {
        return groups.reduce((acc, group) => {
            acc[group.id] = group.items
                .filter((item) => item.not_applicable_allowed && item.not_applicable)
                .map((item) => item.id);
            return acc;
        }, {});
    }, [groups]);
    const expandPanelGroup = (groupId, items) => {
        setOpenPanelsInGroup(Object.assign(Object.assign({}, openPanelsInGroup), { [groupId]: items
                .filter((item) => { var _a; return !((_a = notExpandablePanelsInGroup[groupId]) === null || _a === void 0 ? void 0 : _a.includes(item.id)); })
                .map((item) => item.id) }));
    };
    const collapsePanelGroup = (groupId) => {
        setOpenPanelsInGroup(Object.assign(Object.assign({}, openPanelsInGroup), { [groupId]: [] }));
    };
    const expandPanel = (groupId, panelId) => {
        setOpenPanelsInGroup(Object.assign(Object.assign({}, openPanelsInGroup), { [groupId]: openPanelsInGroup[groupId] ? [...openPanelsInGroup[groupId], panelId] : [panelId] }));
    };
    const collapsePanel = (groupId, panelId) => {
        if (!openPanelsInGroup[groupId])
            return;
        setOpenPanelsInGroup(Object.assign(Object.assign({}, openPanelsInGroup), { [groupId]: [
                ...openPanelsInGroup[groupId].filter((openPanelId) => openPanelId !== panelId),
            ] }));
    };
    const togglePanel = (groupId, panelId) => {
        var _a;
        return ((_a = openPanelsInGroup[groupId]) === null || _a === void 0 ? void 0 : _a.includes(panelId))
            ? collapsePanel(groupId, panelId)
            : expandPanel(groupId, panelId);
    };
    const onValuesChange = (value, panelGroupId, panelId) => {
        let payload;
        if (Applicability.notApplicable in value) {
            const val = value[Applicability.notApplicable];
            val ? expandPanel(panelGroupId, panelId) : collapsePanel(panelGroupId, panelId);
            payload = {
                [panelId]: val ? Applicability.applicable : Applicability.notApplicable,
            };
        }
        else {
            payload = value;
        }
        setShowLoader(panelId);
        Axios.patch(`/admin/application_checklists/${checklistId}`, {
            checklist_data: payload,
        }).then(update, (error) => {
            void Axios.error(error, t("application.qualitative_analysis.be-error"));
        });
    };
    const getPanelGroupToggleMode = (group) => {
        var _a, _b, _c;
        // show Collapse All only when all the panels are expanded; otherwise show Expand All
        return !openPanelsInGroup[group.id] ||
            ((_a = openPanelsInGroup[group.id]) === null || _a === void 0 ? void 0 : _a.length) <
                ((_b = group.items) === null || _b === void 0 ? void 0 : _b.length) - (((_c = notExpandablePanelsInGroup[group.id]) === null || _c === void 0 ? void 0 : _c.length) || 0)
            ? PanelGroupToggleMode.expandAll
            : PanelGroupToggleMode.collapseAll;
    };
    const renderItemDesc = (panelGroupId, item, readonly) => {
        var _a;
        if (readonly && item.not_applicable_allowed && item.not_applicable) {
            return (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("application.qualitative_analysis.not-applicable") })));
        }
        if (((_a = openPanelsInGroup[panelGroupId]) === null || _a === void 0 ? void 0 : _a.includes(item.id)) ||
            (item.not_applicable_allowed && item.not_applicable))
            return;
        const selectedOption = item.options.find((opt) => opt.selected);
        return selectedOption ? (selectedOption.description) : (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("application.qualitative_analysis.not-set") })));
    };
    const renderItem = (panelGroupId, items) => {
        return items.map((item, index) => {
            const selectedOption = item.options.filter(({ selected }) => selected)[0];
            const showInfoCircle = checkShowInfoCircle(item, selectedOption);
            return (_jsx(Spin, Object.assign({ size: "small", spinning: item.id === showLoader }, { children: _jsx(Form, Object.assign({ onValuesChange: (changedValues) => onValuesChange(changedValues, panelGroupId, item.id), "data-cy": "qualitative-group-item" }, { children: _jsx(StyledCollapse, Object.assign({ activeKey: expandedPanelIds, className: `${index === 0 ? "first" : index === items.length - 1 ? "last" : ""}`, onChange: () => togglePanel(panelGroupId, item.id) }, { children: _jsx(CollapsePanel, Object.assign({ header: _jsxs(Row, Object.assign({ gutter: [16, 0] }, { children: [_jsxs(Col, Object.assign({ span: 8 }, { children: [_jsx(StyledLabel, { children: item.description }), showInfoCircle && (_jsx(InfoCircleFilled, { style: {
                                                    color: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.type) === "grey"
                                                        ? token.colorWarning
                                                        : token.colorError,
                                                } })), !readonly && item.not_applicable_allowed && (_jsx("div", Object.assign({ onClick: (evt) => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                } }, { children: _jsx(Form.Item, Object.assign({ valuePropName: "checked", name: "not_applicable", initialValue: !item.not_applicable, style: { margin: 0 } }, { children: _jsx(Switch, {}) })) })))] })), _jsx(Col, Object.assign({ span: 16 }, { children: renderItemDesc(panelGroupId, item, readonly) }))] })), collapsible: readonly
                                ? "icon"
                                : item.not_applicable_allowed && item.not_applicable
                                    ? "disabled"
                                    : "header", showArrow: !readonly }, { children: _jsx(Form.Item, Object.assign({ name: item.id, initialValue: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id }, { children: _jsx(RadioGroup, { children: _jsx(Row, Object.assign({ gutter: 16 }, { children: item.options.map((option) => (_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Radio, Object.assign({ value: option.id }, { children: option.description })) }), option.id))) })) }) })) }), item.id) })) })) }), item.id));
        });
    };
    return (_jsx(_Fragment, { children: groups.map((group) => {
            const toggleMode = getPanelGroupToggleMode(group);
            return (_jsxs("div", Object.assign({ style: { maxWidth: 1464 } }, { children: [_jsxs(PanelGroupHeader, { children: [_jsx(Heading, Object.assign({ level: 5 }, { children: group.description })), !readonly && (_jsx(Button, Object.assign({ type: "text", icon: toggleMode === PanelGroupToggleMode.expandAll ? (_jsx(ArrowsAltOutlined, {})) : (_jsx(ShrinkOutlined, {})), onClick: () => toggleMode === PanelGroupToggleMode.expandAll
                                    ? expandPanelGroup(group.id, group.items)
                                    : collapsePanelGroup(group.id), "data-cy": "expand-items" }, { children: toggleMode === PanelGroupToggleMode.expandAll
                                    ? t("application.qualitative_analysis.expand-all")
                                    : t("application.qualitative_analysis.hide-all") })))] }), renderItem(group.id, group.items)] }), group.id));
        }) }));
};
export default QualitativeGroup;
