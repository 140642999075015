import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import MainHeader from "@ui/main-header/MainHeader";
import GroupsTab from "./GroupsTab";
import TeamTab from "./TeamTab";
const SettingsTeamPage = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("team");
    const [showPreviewTeam, setShowPreviewTeam] = useState(false);
    const [showPreviewGroup, setShowPreviewGroup] = useState(false);
    const tabs = [
        {
            key: "team",
            label: t("settings.team"),
            children: (_jsx(TeamTab, { showPreview: showPreviewTeam, handleShowPreview: (open) => {
                    setShowPreviewTeam(open);
                } })),
        },
        {
            key: "group",
            label: t("settings.team.groups"),
            children: (_jsx(GroupsTab, { showPreview: showPreviewGroup, handleShowPreview: (open) => {
                    setShowPreviewGroup(open);
                } })),
        },
    ];
    const onChange = (key) => {
        setActiveTab(key);
    };
    const getExtraButton = (activeTab) => {
        if (activeTab === "team") {
            return (_jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => setShowPreviewTeam(true) }, { children: t("settings.team.invite") })));
        }
        if (activeTab === "group") {
            return (_jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => setShowPreviewGroup(true) }, { children: t("settings.team.new-group") })));
        }
        return;
    };
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.team"), extra: getExtraButton(activeTab) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Tabs, { defaultActiveKey: "team", items: tabs, onChange: onChange }) }))] }));
};
export default SettingsTeamPage;
