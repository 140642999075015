import { useEffect, useMemo, useState } from "react";

import { Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Space } from "antd";
import Text from "antd/es/typography/Text";
import dayjs from "dayjs";
import { RuleObject } from "rc-field-form/lib/interface";
import styled from "styled-components";

import { SignatoryWithId } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import { CountriesList } from "@teylor-tools/utils/getCountries";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";

import CityFormItem from "../form/form-items/city-form-item/CityFormItem";
import CountrySelectFormItem from "../form/form-items/country-select-form-item/CountrySelectFormItem";
import EmailFormItem from "../form/form-items/email-form-item/EmailFormItem";
import PostcodeFormItem from "../form/form-items/postcode-form-item/PostcodeFormItem";
import TelephoneFormItem from "../form/form-items/telephone-form-item/TelephoneFormItem";
import TinFormItem, { parseTin } from "../form/form-items/tin-form-item/TinFormItem";
import PercentInput from "../form/inputs/percent-input/PercentInput";
import { validateValueDifferentThan } from "../form/validateValueDifferentThan";
import { useTranslations } from "../translations/translations";

const SecondaryText = styled(Text)`
	font-size: 12px;
	display: block;
	margin-bottom: 16px;
`;

const FormItemHorizontal = styled(Form.Item)`
	flex-flow: row wrap !important;
	align-items: center;

	.ant-form-item-label {
		padding: 0 16px 0 0;
	}
`;

interface Props {
	save: (v: SignatoryWithId) => void;
	close: () => void;
	representative?: SignatoryWithId;
	countries: CountriesList[];
	companyTin: string;
}

interface RepresentativeForm extends Omit<SignatoryWithId, "birthdate" | "percentage_owned"> {
	birthdate: Date;
	percentage_owned: number;
}

const FORM = "company-representative-form";

const RepresentativeModal = ({ save, close, representative, countries, companyTin }: Props) => {
	const t = useTranslations();
	const { decimalToPercent, percentToDecimal } = useFormatter();
	const [form] = Form.useForm<RepresentativeForm>();
	const [showLegalSignatoryFields, setShowLegalSignatoryAdditionalFields] = useState(false);
	const [isWillingToSign, setIsWillingToSign] = useState(!!representative?.is_contract_signatory);
	const [isBeneficialOwner, setIsBeneficialOwner] = useState(!!representative?.is_beneficial_owner);
	const okButtonText = representative ? t.save : t.add;
	const modalTitle = representative
		? t.representativeModal.editRepresentative
		: t.representativeModal.addRepresentative;

	const requiredFieldRule = { required: true, message: t.formErrors.fieldRequired };

	const yesNoRadioItems = [
		{ label: t.yes, value: true },
		{ label: t.no, value: false },
	];

	const defaultCountry = useMemo(() => countries[0]?.code, [countries]);

	const handleLegalSignatoryChange = (value: boolean) => {
		setShowLegalSignatoryAdditionalFields(value);

		if (!value) {
			handleIsWillingToSignChange(false);
		} else {
			const isWillingToSign = form.getFieldValue("is_contract_signatory");
			isWillingToSign && handleIsWillingToSignChange(true);
		}
	};

	const handleIsWillingToSignChange = (val: boolean) => {
		setIsWillingToSign(val);
		void form.validateFields(["telephone", "email"]);
	};

	const handleIsBeneficialOwnerChange = (val: boolean) => {
		setIsBeneficialOwner(val);
		void form.validateFields(["percentage_owned"]);
	};

	const onFinish = (form: RepresentativeForm) => {
		const sendRepresentative: SignatoryWithId = {
			...form,
			percentage_owned: percentToDecimal(form.percentage_owned),
			birthdate: form.birthdate ? dayjs(form.birthdate).format(rawDateFormat) : "",
			taxid: parseTin(form.taxid),
		};

		if (representative?.signatory_id) {
			sendRepresentative.signatory_id = representative.signatory_id;
		}

		save(sendRepresentative);
	};

	useEffect(() => {
		if (representative) {
			const { percentage_owned, birthdate } = representative;
			form.setFieldsValue({
				...representative,
				percentage_owned: isValidNumericValue(percentage_owned)
					? Number(
							decimalToPercent({ percent: percentage_owned, decimalPlaces: 2, withLocale: false })
						)
					: 0,
				birthdate: birthdate ? dayjs(birthdate) : "",
			});

			setShowLegalSignatoryAdditionalFields(representative.is_legal_signatory);
		}
	}, [representative, form]);

	return (
		<>
			<Modal
				title={modalTitle}
				open
				okButtonProps={{ htmlType: "submit", form: FORM }}
				onCancel={close}
				okText={okButtonText}
				cancelText={t.cancel}
				width={633}
				style={{ top: 24 }}
				maskClosable={false}
			>
				<Form
					id={FORM}
					onFinish={onFinish}
					form={form}
					layout="vertical"
					data-cy="company-representative-form"
					initialValues={{
						country: defaultCountry,
						nationality: defaultCountry,
						percentage_owned: 0,
					}}
				>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.firstName}
								name="first_name"
								rules={[requiredFieldRule]}
							>
								<Input data-cy="first-name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.lastName}
								name="last_name"
								rules={[requiredFieldRule]}
							>
								<Input data-cy="last-name" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={12}>
							<EmailFormItem
								inputProps={{ placeholder: "" }}
								rules={isWillingToSign ? [requiredFieldRule] : []}
							/>
						</Col>
						<Col span={12}>
							<TelephoneFormItem
								label={t.representativeModal.phoneNumber}
								name="telephone"
								rules={isWillingToSign ? [requiredFieldRule] : []}
							/>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={t.representativeModal.position} name="position">
								<Input data-cy="position" />
							</Form.Item>
						</Col>
					</Row>

					<SecondaryText type="secondary">
						{t.representativeModal.representativeNotContacted}
					</SecondaryText>

					<Row gutter={16}>
						<Col span={12}>
							<CountrySelectFormItem
								data-cy="nationality"
								name="nationality"
								label={t.representativeModal.nationality}
								rules={[requiredFieldRule]}
								countries={countries}
							/>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.dateOfBirth}
								name="birthdate"
								rules={[
									{
										required: true,
										message: t.representativeModal.allFieldsRequired,
									},
								]}
							>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={dayMonthYear}
									format={dayMonthYear}
									data-cy="date-of-birth-field"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<TinFormItem
								type="personal"
								name="taxid"
								label={
									<Space size={4}>
										<Text>{t.representativeModal.taxNumber}</Text>
										<Text type="secondary" style={{ fontSize: 12 }}>
											{t.representativeModal.optional}
										</Text>
									</Space>
								}
								inputProps={{ placeholder: t.tinPlaceholder }}
								rules={[
									...(companyTin
										? [
												{
													validator: (rule: RuleObject, value: string) =>
														validateValueDifferentThan({
															value: parseTin(value),
															differentThan: companyTin,
															error: t.formErrors.personalTinMustBeDifferentThanCompanyTin,
														}),
												},
											]
										: []),
								]}
							/>
						</Col>
					</Row>

					<Divider />

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.street}
								name="street"
								rules={[requiredFieldRule]}
							>
								<Input data-cy="street-address-field" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t.representativeModal.houseNumber}
								name="street_number"
								rules={[requiredFieldRule]}
							>
								<Input data-cy="house-number-field" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={12}>
							<CityFormItem label={t.city} name="location" required data-cy="city-address-field" />
						</Col>
						<Col span={12}>
							<PostcodeFormItem name="postcode" required />
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<CountrySelectFormItem
								label={t.country}
								rules={[requiredFieldRule]}
								countries={countries}
								data-cy="country"
							/>
						</Col>
					</Row>

					<Divider />

					<Row gutter={24}>
						<Col span={12}>
							<FormItemHorizontal
								rules={[requiredFieldRule]}
								label={t.representativeModal.beneficialOwner}
								name="is_beneficial_owner"
							>
								<Radio.Group
									data-cy="is_beneficial_owner"
									options={yesNoRadioItems}
									onChange={(e) => handleIsBeneficialOwnerChange(e.target.value as boolean)}
								/>
							</FormItemHorizontal>
						</Col>
						<Col span={12}>
							<FormItemHorizontal
								rules={[
									{ required: isBeneficialOwner, message: t.formErrors.fieldRequired },
									{
										type: "number",
										min: isBeneficialOwner ? 0.01 : 0,
										max: 100,
										message: t.formErrors.fieldMustBeBetweenMinMax(
											isBeneficialOwner ? 0.01 : 0,
											100
										),
									},
								]}
								label={t.representativeModal.percentageOwnership}
								name="percentage_owned"
							>
								<PercentInput data-cy="percentage-owned" />
							</FormItemHorizontal>
						</Col>
					</Row>

					<Divider />

					<Row gutter={24}>
						<Col span={12}>
							<FormItemHorizontal
								rules={[requiredFieldRule]}
								label={t.representativeModal.legalSignatory}
								name="is_legal_signatory"
							>
								<Radio.Group
									options={yesNoRadioItems}
									onChange={(e) => handleLegalSignatoryChange(e.target.value as boolean)}
									data-cy="is_legal_signatory"
								/>
							</FormItemHorizontal>
						</Col>
						<Col span={12}>
							{showLegalSignatoryFields && (
								<>
									<FormItemHorizontal
										rules={[requiredFieldRule]}
										label={t.representativeModal.willingToSign}
										name="is_contract_signatory"
									>
										<Radio.Group
											options={yesNoRadioItems}
											data-cy="is_contract_signatory"
											onChange={(e) => handleIsWillingToSignChange(e.target.value as boolean)}
										/>
									</FormItemHorizontal>
									<FormItemHorizontal
										rules={[requiredFieldRule]}
										label={t.representativeModal.canSignAlone}
										name="is_sole_signatory"
									>
										<Radio.Group options={yesNoRadioItems} data-cy="is_sole_signatory" />
									</FormItemHorizontal>
								</>
							)}
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default RepresentativeModal;
