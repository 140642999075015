import { useCallback, useEffect, useState } from "react";

import { ShopOutlined } from "@ant-design/icons";
import {
	AutoComplete,
	Button,
	Form,
	FormItemProps,
	Input,
	InputProps,
	Spin,
	Tag,
	Typography,
} from "antd";
import styled from "styled-components";

import { CompanyNameSuggestion, CompanyTypes, CountryCode } from "@teylor-tools/Api";
import useDebounce from "@teylor-tools/hooks/debounce";

import { useTranslations } from "../../../translations/translations";

const { Text } = Typography;
const { Search } = Input;

const MIN_CHARS = 4;

const CompanyListItem = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 16px;
	cursor: pointer;
	padding: 12px 4px;
`;

const CompanyNameWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CompanyDetails = styled.div`
	display: flex;
	flex-direction: column;
	white-space: normal;
`;

const NotFound = styled.div`
	padding: 12px 4px;
	text-align: center;
	white-space: normal;
`;

export interface SelectedCompany {
	companyId?: string;
	street: string;
	houseNumber: string;
	companyLocation: string;
	companyCountry?: CountryCode;
	companyPostCode: string;
	companyCreditProviderId?: string;
	companyType?: CompanyTypes;
	businessPurpose?: string;
	isInternal?: boolean;
}

type Option = {
	label: JSX.Element;
	key?: string;
	value?: string;
};

interface Props extends FormItemProps {
	setCompanyData: (companyData: SelectedCompany, isSelected: boolean) => void;
	defaultCountry: CountryCode;
	inputProps?: InputProps;
	linkColour?: string;
	companies: CompanyNameSuggestion[];
	clearCompanies: () => void;
	handleFetch: (searchQuery: string) => Promise<void> | void;
}

const CompanySelectFormItem = ({
	setCompanyData,
	defaultCountry,
	inputProps,
	linkColour,
	companies,
	handleFetch,
	clearCompanies,
	...props
}: Props) => {
	const t = useTranslations();
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const debouncedSearchQuery = useDebounce(searchQuery, 500);
	const form = Form.useFormInstance();

	const formItemName = props.name || "companyName";

	const fetchData = useCallback(async () => {
		if (debouncedSearchQuery.length >= MIN_CHARS) {
			setLoading(true);
			await handleFetch(debouncedSearchQuery);
			setLoading(false);
		}
	}, [debouncedSearchQuery]);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchData]);

	const renderCompanyOption = (option: CompanyNameSuggestion) => ({
		key: option.id || "",
		value: `${option.id}_${option.name}`,
		label: (
			<CompanyListItem key={`item-${option.id}`} data-cy="company-result-row">
				<ShopOutlined
					style={{
						fontSize: 25,
					}}
				/>
				<CompanyDetails>
					<CompanyNameWrapper>
						<Text>{option.name}</Text>
						{option.isInternal && <Tag color="gold">{t.companySelectFormItem.existing}</Tag>}
					</CompanyNameWrapper>
					<Text type="secondary">
						{option.strasseHausnummer}, {option.plz} {option.ort}, {option.land}
					</Text>
				</CompanyDetails>
			</CompanyListItem>
		),
	});

	const renderOptions = (): Option[] => {
		return [
			...companies.map((option) => renderCompanyOption(option)),
			{
				label: (
					<NotFound>
						<Text>
							{t.companySelectFormItem.errorCompanyNameNotFound}
							<Button
								data-cy="company-not-found"
								type="link"
								style={{
									height: "auto",
									whiteSpace: "normal",
									padding: "4px",
									color: linkColour,
								}}
								onClick={handleClear}
							>
								{t.companySelectFormItem.errorCompanyNameNotFoundLinkText}
							</Button>
						</Text>
					</NotFound>
				),
			},
		];
	};

	const handleSelectCompany = (value: string, option: Option) => {
		const selectedCompany = companies.find((o) => o.id === option.key);

		if (!selectedCompany) return handleClear();

		form.setFieldValue(formItemName, selectedCompany.name);

		const streetArray = selectedCompany.strasseHausnummer.split(" ");
		const houseNumber = streetArray.pop() || "";
		const street = streetArray.join().replace(/,/g, " ");

		setCompanyData(
			{
				companyId: selectedCompany.isInternal ? selectedCompany.id : "",
				street,
				houseNumber,
				companyPostCode: selectedCompany.plz,
				companyLocation: selectedCompany.ort,
				companyCountry: selectedCompany.land,
				companyCreditProviderId: selectedCompany.isInternal
					? selectedCompany.identnummer
					: selectedCompany.id,
				companyType: selectedCompany?.companyType,
				businessPurpose: selectedCompany?.businessPurpose,
				isInternal: !!selectedCompany.isInternal,
			},
			true
		);
	};

	const handleInputChange = (value: string) => {
		setSearchQuery(value);

		if (!value) {
			handleClear();
		}
	};

	const handleClear = () => {
		clearCompanies();

		setCompanyData(
			{
				street: "",
				houseNumber: "",
				companyPostCode: "",
				companyLocation: "",
				companyCountry: defaultCountry,
				companyCreditProviderId: undefined,
				companyType: undefined,
				businessPurpose: "",
				isInternal: false,
				companyId: "",
			},
			false
		);
	};

	return (
		<Form.Item
			rules={[
				{
					required: true,
					min: MIN_CHARS,
					max: 255,
					message: t.companySelectFormItem.errorCompanyNameInvalid,
				},
			]}
			label={t.companySelectFormItem.label}
			name={formItemName}
			{...props}
		>
			<AutoComplete
				listHeight={400}
				style={{ width: "100%" }}
				options={renderOptions()}
				onSearch={handleInputChange}
				onSelect={handleSelectCompany}
			>
				<Search
					placeholder={t.companySelectFormItem.placeholder}
					suffix={
						<Spin
							size="small"
							style={{
								// inline-style instead of conditional rendering to prevent loosing input focus when loading state changes
								visibility: loading ? "visible" : "hidden",
								marginTop: 2,
							}}
						/>
					}
				/>
			</AutoComplete>
		</Form.Item>
	);
};

export default CompanySelectFormItem;
