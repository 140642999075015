import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CloseCircleOutlined, RedoOutlined, StopOutlined, } from "@ant-design/icons";
import { Badge, Button, Col, Collapse, Row, Typography } from "antd";
import styled from "styled-components";
import { Divider } from "src/components/CollapsibleMenu";
const { Text } = Typography;
const RecalculateButton = styled(Button) `
	padding: 0;
	height: auto;
`;
const MainCollapsePanel = styled(Collapse.Panel) `
	.ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-collapse-header {
		background-color: #fff;
		border-radius: ${({ $isOpen }) => ($isOpen ? "8px 8px 0 0 !important" : "8px !important")};
	}
`;
const CheckCollapse = styled(Collapse) `
	&,
	.ant-collapse-item {
		border: 0 !important;
	}
`;
const CheckCollapsePanel = styled(Collapse.Panel) `
	.ant-collapse-content {
		border: 0 !important;
	}

	.ant-collapse-header {
		padding-bottom: 20px !important;
		padding-top: 20px !important;
	}
`;
export const CriteriaRow = styled.div `
	display: flex;
	padding: ${({ $type }) => $type === "main"
    ? "20px 20px 20px 40px"
    : $type === "main-collapse"
        ? "0"
        : "20px 20px 20px 70px"};
`;
const NotApplicableIcon = styled(StopOutlined) `
	color: ${(props) => props.theme.colorTextDisabled};
	font-size: 20px;
`;
const OkIcon = styled(CheckCircleOutlined) `
	color: ${(props) => props.theme.colorSuccess};
	font-size: 20px;
`;
const IssueIcon = styled(CloseCircleOutlined) `
	color: ${(props) => props.theme.colorError};
	font-size: 20px;
`;
const getType = (item) => {
    if (!item.not_applicable) {
        const option = item.options.find((option) => !option.not_applicable);
        if (option === null || option === void 0 ? void 0 : option.selected) {
            return "ok";
        }
        // check only for false, undefined is displayed as not applicable
        else if ((option === null || option === void 0 ? void 0 : option.selected) === false) {
            return "issue";
        }
    }
    return "not-applicable";
};
const renderCriteriaRow = (row, type) => (_jsxs(CriteriaRow, Object.assign({ "$type": type }, { children: [_jsx("div", Object.assign({ style: { flex: "0 0 30px" } }, { children: row.type === "ok" ? (_jsx(OkIcon, {})) : row.type === "issue" ? (_jsx(IssueIcon, {})) : (_jsx(NotApplicableIcon, {})) })), _jsx("div", { children: _jsx(Text, { children: row.desc }) })] })));
const EligibilityAutomaticChecklist = ({ checklistGroup, allowRecalculate, onRecalculate, }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const checklistData = checklistGroup === null || checklistGroup === void 0 ? void 0 : checklistGroup.items.map((item) => (Object.assign({ desc: item.description, type: getType(item) }, (item.options.length > 1 && {
        options: item.options.map((option) => ({
            desc: option.description,
            type: option.not_applicable ? "not-applicable" : option.selected ? "ok" : "issue",
        })),
    }))));
    const issuesNumber = checklistData === null || checklistData === void 0 ? void 0 : checklistData.filter((issue) => issue.type === "issue").length;
    if (!checklistGroup || !checklistData)
        return null;
    return (_jsx(Collapse, Object.assign({ activeKey: isOpen ? checklistGroup.id : undefined, onChange: () => setIsOpen(!isOpen) }, { children: _jsx(MainCollapsePanel, Object.assign({ "$isOpen": isOpen, header: _jsxs(Row, Object.assign({ justify: "space-between" }, { children: [_jsxs(Col, { children: [_jsx(Text, Object.assign({ strong: true }, { children: checklistGroup.description })), !!issuesNumber && _jsx(Badge, { count: issuesNumber, style: { marginLeft: 8 } })] }), allowRecalculate && (_jsx(Col, { children: _jsx(RecalculateButton, Object.assign({ type: "link", icon: _jsx(RedoOutlined, {}), onClick: (e) => {
                                e.stopPropagation();
                                onRecalculate();
                            } }, { children: t("application.eligibility_analysis.recalculate") })) }))] })) }, { children: checklistData.map((row, idx) => (_jsxs(Fragment, { children: [row.options ? (_jsx(CheckCollapse, { children: _jsx(CheckCollapsePanel, Object.assign({ header: renderCriteriaRow(row, "main-collapse") }, { children: row.options.map((option) => renderCriteriaRow(option, "collapse-option")) }), idx) })) : (renderCriteriaRow(row, "main")), idx < checklistData.length - 1 && _jsx(Divider, { style: { margin: 0 } })] }, idx))) }), checklistGroup.id) })));
};
export default EligibilityAutomaticChecklist;
