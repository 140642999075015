var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "antd";
import { LoanStatus as LoanStatusType } from "@teylor-tools/Api";
import { getStatusShortcut } from "../../../utils/getStatusShortcut";
const getColor = (status) => {
    switch (status) {
        case LoanStatusType.Open:
            return "#096DD9";
        case LoanStatusType.Delay:
            return "warning";
        case LoanStatusType.Repaid:
            return "green";
        default:
            return "error";
    }
};
const LoanStatus = (_a) => {
    var { label, value, short } = _a, props = __rest(_a, ["label", "value", "short"]);
    return (_jsx(Tag, Object.assign({}, props, { color: getColor(value) }, { children: short ? getStatusShortcut(label) : label })));
};
export default LoanStatus;
