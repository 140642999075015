import { ChecklistItemTemplateItemType } from "@teylor-tools/Api";
export const mapTemplateToDndStruct = (templateGroups) => templateGroups.reduce((acc, group) => {
    acc[group.id] = group.items.flatMap((item) => {
        switch (item.item_type) {
            case ChecklistItemTemplateItemType.Checkbox:
                return item.options[0].id;
            default:
                return item.id;
        }
    });
    return acc;
}, {});
