import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { Button, Card, Divider, Flex, Typography } from "antd";
import axios from "axios";
import styled from "styled-components";
const ExclamationCircleIcon = styled(ExclamationCircleOutlined) `
	display: flex;
	justify-content: center;
	color: rgba(0, 0, 0, 0.25);
	font-size: 56px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Container = styled(Card) `
	width: 567px;
	padding: 40px 64px;
`;
const ErrorBoundaryWrapper = ({ children }) => {
    const { t } = useTranslation();
    return (_jsx(QueryErrorResetBoundary, { children: ({ reset }) => (_jsx(ErrorBoundary, Object.assign({ onReset: reset, fallbackRender: ({ resetErrorBoundary, error }) => {
                var _a;
                const errorCode = axios.isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status);
                return (_jsx(Flex, Object.assign({ align: "center", justify: "center", style: { width: "100%" } }, { children: _jsx(Container, { children: _jsxs(Flex, Object.assign({ vertical: true, align: "center", justify: "center", gap: "small" }, { children: [_jsx(ExclamationCircleIcon, {}), _jsx(Typography.Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("common.error-boundary.error") })), errorCode && (_jsxs(Typography.Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: [t("common.error-code"), ": ", errorCode] }))), _jsx(Divider, {}), _jsx(Button, Object.assign({ onClick: () => resetErrorBoundary() }, { children: t("common.error-boundary.try-again") }))] })) }) })));
            } }, { children: children }))) }));
};
export default ErrorBoundaryWrapper;
