import { jsx as _jsx } from "react/jsx-runtime";
import { EntityKind, LoanStatus } from "@teylor-tools/Api";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { ALL_DOCUMENT_SEARCH_FIELDS, } from "src/pages/modules/Documents/DocumentsSearch";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { Axios } from "src/utils/Axios";
const LoanDocuments = () => {
    const { loan: { loan_id, loan_status }, } = useLoan();
    const isPending = loan_status === LoanStatus.Pending;
    return (_jsx(DocumentsTab, { entityKind: EntityKind.Loan, entityId: loan_id, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: (search) => Axios.get(`admin/loans/${loan_id}/documents_with_application`, search), apiGetArchivePath: `admin/loans/${loan_id}/files_archive_with_application`, allowUpload: !isPending, allowDelete: (doc) => "is_application_document" in doc ? !doc.is_application_document : true, editableFields: [], enableToggleClientVisibility: false, searchFields: ALL_DOCUMENT_SEARCH_FIELDS }));
};
export default LoanDocuments;
