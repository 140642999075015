import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BlockOutlined, FileProtectOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, List, Modal, Row, Space, Tag, Typography } from "antd";
import { App } from "antd";
import { useForm } from "antd/lib/form/Form";
import AvatarBadge from "@ui/data-display/Avatar";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Axios } from "src/utils/Axios";
const ShareApplication = ({ applicationId, close }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const [addingUser, setAddingUser] = useState(false);
    const [users, setUsers] = useState();
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const elements = useMemo(() => {
        return [
            {
                icon: _jsx(TeamOutlined, {}),
                role: t("application.share.team"),
                role_type: t("application.share.team"),
            },
            {
                icon: _jsx(BlockOutlined, {}),
                role: t("application.share.partner"),
                role_type: t("application.share.partner"),
            },
            {
                icon: _jsx(FileProtectOutlined, {}),
                role: t("application.share.signatory"),
                role_type: t("application.share.contract"),
            },
        ];
    }, [t]);
    const showPagination = useMemo(() => {
        return ((pagination === null || pagination === void 0 ? void 0 : pagination.total) || 0) > ((pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 0)
            ? Object.assign(Object.assign({}, pagination), { onChange: setCurrentPage }) : false;
    }, [pagination]);
    const fetchUsers = () => {
        setLoading(true);
        return Axios.get(`/admin/applications/${applicationId}/users?page=${currentPage}&page_size=${10}`)
            .then(({ data }) => {
            setUsers(data.users);
            setPagination({
                current: data.pagination.current_page,
                total: data.pagination.total_rows,
                pageSize: data.pagination.items_per_page,
            });
        }, (error) => {
            void Axios.error(error, t("application.share.error"));
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        void fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId, currentPage]);
    const onAddUser = ({ email }) => {
        setAddingUser(true);
        void Axios.post(`/admin/applications/${applicationId}/invite`, { email }).then(() => {
            void fetchUsers()
                .then(() => {
                void message.success(t("application.share.invitation-sent"));
                form.resetFields();
            }, (error) => Axios.error(error, t("application.share.error-add")))
                .finally(() => {
                setAddingUser(false);
            });
        });
    };
    const onRemoveUser = (userId) => {
        void Axios.delete(`/admin/applications/${applicationId}/users/${userId}`).then(() => {
            void fetchUsers();
            void message.success(t("application.share.user-removed"));
        }, (error) => Axios.error(error, t("application.share.error-add")));
    };
    const modalFooter = (_jsx(Row, Object.assign({ justify: "end" }, { children: _jsx(Col, { children: _jsx(Button, Object.assign({ type: "default", onClick: close }, { children: t("common.done") })) }) })));
    return (_jsxs(Modal, Object.assign({ title: t("application.share.title"), open: true, footer: modalFooter, onCancel: close }, { children: [elements.map(({ icon, role, role_type }, index) => {
                return (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: _jsxs(Space, Object.assign({ direction: "horizontal" }, { children: [icon, _jsx(Trans, Object.assign({ i18nKey: "application.share.access-set-by" }, { children: { role, role_type } }))] })) }), `role-${index}`));
            }), _jsx(Divider, {}), _jsx(Typography.Paragraph, Object.assign({ strong: true }, { children: _jsxs(Space, Object.assign({ direction: "horizontal" }, { children: [_jsx(TeamOutlined, {}), t("application.share.contributors")] })) })), _jsx(Form, Object.assign({ form: form, layout: "horizontal", onFinish: onAddUser }, { children: _jsxs(Row, Object.assign({ gutter: 16 }, { children: [_jsx(Col, Object.assign({ flex: "1" }, { children: _jsx(EmailFormItem, { label: "", rules: [
                                    {
                                        required: true,
                                        message: t("application_form.field-required"),
                                    },
                                ] }) })), _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", loading: addingUser }, { children: t("application.share.invite") })) })] })) })), _jsx(List, { loading: loading, dataSource: users, pagination: showPagination, renderItem: ({ email, user_id, hasAcceptedTermsAndConditions }) => (_jsx(List.Item, Object.assign({ extra: _jsx(Button, Object.assign({ style: { padding: "0 7px" }, type: "link", onClick: () => onRemoveUser(user_id) }, { children: t("common.remove") })) }, { children: _jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx(List.Item.Meta, { avatar: _jsx(AvatarBadge, { name: `${email.charAt(0)} ${email.charAt(1)}`, size: "small" }), title: email }), _jsx(Typography.Text, Object.assign({ type: "secondary", style: { fontSize: 12, marginRight: 8, marginLeft: 40 } }, { children: t("application.share.terms-and-conditions") })), _jsx(Tag, Object.assign({ color: hasAcceptedTermsAndConditions ? "green" : "red" }, { children: hasAcceptedTermsAndConditions
                                    ? t("application.share.terms-and-conditions.accepted")
                                    : t("application.share.terms-and-conditions.not-accepted") }))] })) }), email)) })] })));
};
export default ShareApplication;
