import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, Dropdown, Space, Spin, Tag, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCardItem } from "@ui/data-display/DataCard";
import { Axios } from "src/utils/Axios";
const ResultsTag = styled(Tag) `
	text-transform: uppercase;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorWarning};
`;
const DotsBtnDropdown = styled(Dropdown.Button) `
	button {
		padding: 0;

		&.ant-dropdown-trigger {
			border-start-start-radius: 4px !important;
			border-end-start-radius: 4px !important;
		}

		&:not(.ant-dropdown-trigger) {
			display: none;
			border-radius: 2px;
		}
	}
`;
const LinkButton = styled(Button) `
	padding: 0;
	height: 0;
`;
const PepCheck = ({ signatoryId, pepCheckResult, documentsPath, refetchRepresentatives, readonly, }) => {
    const { t } = useTranslation();
    const { modal } = App.useApp();
    const [checkLoading, setCheckLoading] = useState(false);
    const navigate = useNavigate();
    const { date } = useFormatter();
    const noPepCheckYet = !(pepCheckResult === null || pepCheckResult === void 0 ? void 0 : pepCheckResult.created_at);
    const generateCheck = () => {
        setCheckLoading(true);
        void Axios.post(`/admin/signatories/${signatoryId}/request_pep_check`)
            .then(refetchRepresentatives, (err) => Axios.error(err))
            .finally(() => setCheckLoading(false));
    };
    const handleApprove = () => {
        setCheckLoading(true);
        void Axios.patch(`/admin/signatories/${signatoryId}/approve_pep_check/${pepCheckResult === null || pepCheckResult === void 0 ? void 0 : pepCheckResult.signatory_pep_check_id}`)
            .then(refetchRepresentatives, (err) => Axios.error(err))
            .finally(() => setCheckLoading(false));
    };
    const showGenerateCheckModal = () => {
        void modal.confirm({
            title: t("application.company-representatives.pep-check.generate-modal.title"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: (_jsx(Typography.Text, { children: t("application.company-representatives.pep-check.generate-modal.content") })),
            okText: t("application.company-representatives.pep-check.generate-modal.ok-btn"),
            cancelText: t("common.cancel"),
            onOk: generateCheck,
        });
    };
    const showApproveModal = () => {
        void modal.confirm({
            title: t("application.company-representatives.pep-check.approve-modal.title"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: (_jsx(Typography.Text, { children: t("application.company-representatives.pep-check.approve-modal.content") })),
            okText: t("application.company-representatives.pep-check.approve-modal.ok-btn"),
            cancelText: t("common.cancel"),
            onOk: handleApprove,
        });
    };
    if (noPepCheckYet && !readonly) {
        return (_jsx(DataCardItem, { label: t("application.company-representatives.pep-check"), value: _jsx(LinkButton, Object.assign({ type: "link", onClick: showGenerateCheckModal, loading: checkLoading }, { children: t("application.company-representatives.pep-check.generate-btn") })) }));
    }
    return (_jsxs(Spin, Object.assign({ spinning: checkLoading }, { children: [_jsx(DataCardItem, { label: t("application.company-representatives.pep-check"), value: _jsxs(Space, { children: [(pepCheckResult === null || pepCheckResult === void 0 ? void 0 : pepCheckResult.created_at) && (_jsxs(_Fragment, { children: [_jsx(LinkButton, Object.assign({ type: "link", onClick: () => navigate(documentsPath) }, { children: t("application.company-representatives.pep-check.document-link") })), _jsx(Tag, { children: t("application.company.report-date", {
                                        date: date(pepCheckResult.created_at),
                                    }) })] })), !readonly && (_jsx(DotsBtnDropdown, { size: "small", menu: {
                                items: [
                                    {
                                        label: t("application.company-representatives.pep-check.dropdown.generate"),
                                        key: "generate",
                                    },
                                ],
                                onClick: showGenerateCheckModal,
                            } }))] }) }), _jsx(DataCardItem, { label: t("application.company-representatives.pep-check.results"), value: (pepCheckResult === null || pepCheckResult === void 0 ? void 0 : pepCheckResult.is_result_positive) ? (_jsx(Tag, Object.assign({ color: "error" }, { children: t("application.company-representatives.pep-check.results.positive") }))) : (_jsx(Tag, Object.assign({ color: "success" }, { children: t("application.company-representatives.pep-check.results.negative") }))) }), _jsx(DataCardItem, { label: t("application.company-representatives.pep-check.results.is-approved"), value: (pepCheckResult === null || pepCheckResult === void 0 ? void 0 : pepCheckResult.approved_at) ? (_jsx(ResultsTag, Object.assign({ color: "success" }, { children: t("common.yes") }))) : (_jsxs(_Fragment, { children: [_jsx(ResultsTag, { children: t("common.no") }), !readonly && (_jsx(Button, Object.assign({ onClick: showApproveModal, size: "small", type: "primary", icon: _jsx(CheckOutlined, {}) }, { children: t("application.company-representatives.pep-check.results.is-approved.approve") })))] })) })] })));
};
export default PepCheck;
