import React, { FC } from "react";

import {
	Cell,
	CellStyle,
	CellTemplate,
	Compatible,
	Id,
	Uncertain,
	UncertainCompatible,
	getCellProperty,
} from "@silevis/reactgrid";

export interface VerticalChevronCell extends Cell {
	type: "verticalChevron";
	text: string | undefined;
	isExpanded?: boolean;
	hasChildren?: boolean;
	parentId?: Id;
	columnId: Id;
	indent: number;
}

export const VerticalChevronCellComponent: FC<{
	cell: Compatible<VerticalChevronCell>;
	isInEditMode: boolean;
	onChange: (data: Compatible<VerticalChevronCell>) => void;
}> = ({ cell, onChange }) => {
	const handlePointerDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (cell.hasChildren) {
			e.stopPropagation();
		}

		onChange({ ...cell, isExpanded: !cell.isExpanded });
	};

	return (
		<div className={`header-cell ${cell.hasChildren ? "" : "no-child"}`}>
			<span>{cell.text}</span>

			{cell.hasChildren && (
				<div
					className={`chevron ${cell.isExpanded ? "expanded" : "collapsed"}`}
					onPointerDown={handlePointerDown}
				>
					<span className="icon">❯</span>
				</div>
			)}
		</div>
	);
};

export class VerticalChevronCellTemplate implements CellTemplate<VerticalChevronCell> {
	getCompatibleCell(
		uncertainCell: Uncertain<VerticalChevronCell>
	): Compatible<VerticalChevronCell> {
		const value = NaN;

		const text: string = getCellProperty(uncertainCell, "text", "string");

		const columnId: string = getCellProperty(uncertainCell, "columnId", "string");

		const indent: number = getCellProperty(uncertainCell, "indent", "number");

		let isExpanded = true;
		try {
			isExpanded = getCellProperty(uncertainCell, "isExpanded", "boolean");
		} catch {
			isExpanded = true;
		}

		let hasChildren = false;
		try {
			hasChildren = getCellProperty(uncertainCell, "hasChildren", "boolean");
		} catch {
			hasChildren = false;
		}

		return {
			...uncertainCell,
			text,
			value,
			isExpanded,
			hasChildren,
			columnId,
			indent,
		};
	}

	update(
		cell: Compatible<VerticalChevronCell>,
		cellToMerge: UncertainCompatible<VerticalChevronCell>
	): Compatible<VerticalChevronCell> {
		return this.getCompatibleCell({ ...cell, isExpanded: cellToMerge.isExpanded });
	}

	getClassName(cell: Compatible<VerticalChevronCell>) {
		const isExpanded = cell.hasChildren ? (cell.isExpanded ? "expanded" : "collapsed") : "";
		const className = cell.className || "";
		return `${isExpanded} ${className}`;
	}

	getStyle(cell: Compatible<VerticalChevronCell>): CellStyle {
		const indent = cell.indent ?? 0;
		const elementMarginMultiplier = indent * 1.4;
		return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
	}

	render(
		cell: Compatible<VerticalChevronCell>,
		isInEditMode: boolean,
		onCellChanged: (cell: Compatible<VerticalChevronCell>, commit: boolean) => void
	): React.ReactNode {
		return (
			<VerticalChevronCellComponent
				cell={cell}
				isInEditMode={isInEditMode}
				onChange={(data) => {
					onCellChanged(this.getCompatibleCell(data), true);
				}}
			/>
		);
	}
}
