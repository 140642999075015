import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { App, Menu } from "antd";
import { Axios } from "src/utils/Axios";
const UsersMenu = ({ user_id }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    return (_jsx(Menu, { onClick: ({ key }) => {
            if (user_id) {
                switch (key) {
                    case "reset-password":
                        Axios.post(`/admin/users/${user_id}/reset`).then(() => {
                            void message.success(t("users.actions.reset-user-success"));
                        }, (error) => {
                            void Axios.error(error, t("users.actions.error-reset-user"));
                        });
                        break;
                    case "resend-confirmation-email":
                        Axios.post(`/admin/users/${user_id}/resend_conf_email`).then(() => {
                            void message.success(t("users.actions.resend-confirmation-email-success"));
                        }, (error) => {
                            void Axios.error(error, t("users.actions.error-resend-confirmation-email"));
                        });
                        break;
                }
            }
        }, items: [
            {
                label: t("users.actions.reset-password"),
                key: "reset-password",
            },
            {
                label: t("users.actions.resend-confirmation-email"),
                key: "resend-confirmation-email",
            },
        ] }));
};
export default UsersMenu;
