import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal, Typography } from "antd";
import styled from "styled-components";
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorWarning} !important;
`;
const { TextArea } = Input;
const Wrapper = styled.div `
	display: grid;
	gap: 16px;
`;
const MoveToPreviousStateModal = ({ onFinish, onCancel }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const addComment = Form.useWatch("comment", form);
    const handleFinish = (values) => {
        onFinish(values.description);
    };
    return (_jsx(Modal, Object.assign({ title: _jsxs("div", { children: [_jsx(ExclamationCircleOutlinedIcon, { style: { marginRight: "8px" } }), t("application.are-you-sure")] }), open: true, onCancel: onCancel, okButtonProps: { htmlType: "submit" }, onOk: () => void form.validateFields().then(() => form.submit()), okText: t("common.confirm") }, { children: _jsxs(Wrapper, { children: [_jsx(Typography.Text, { children: t("application.move_status_back") }), _jsxs(Form, Object.assign({ form: form, onFinish: handleFinish, layout: "vertical" }, { children: [_jsx(Form.Item, Object.assign({ name: "comment", valuePropName: "checked" }, { children: _jsx(Checkbox, { children: t("application.move_status_back.add_comment") }) })), addComment && (_jsx(Form.Item, Object.assign({ name: "description" }, { children: _jsx(TextArea, { maxLength: 2000, showCount: true, rows: 6, placeholder: t("application.move_status_back.add_comment") }) })))] }))] }) })));
};
export default MoveToPreviousStateModal;
