import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Col, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import SearchList, { DIRECT_OPEN_COLUMN } from "../../../components/SearchList";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
})(SearchField || (SearchField = {}));
const PortfolioCompanies = () => {
    const { t } = useTranslation();
    const { date, currency, decimalToPercent } = useFormatter();
    const ref = useRef();
    const columns = [
        {
            render: (company) => (_jsx(Link, Object.assign({ to: generatePath(Routes.Company, {
                    companyId: company.company_id,
                }), "data-cy": "view-partner-button" }, { children: _jsx(SelectOutlined, {}) }))),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "company_name",
            title: t("portfolio.companies.company-name"),
            sorter: true,
        },
        {
            dataIndex: "latest_annual_financials_date",
            title: t("portfolio.companies.last-annual"),
            sorter: true,
            render(latest_annual_financials_date) {
                return date(latest_annual_financials_date);
            },
        },
        {
            dataIndex: "latest_interim_financials_date",
            title: t("portfolio.companies.last-interim"),
            sorter: true,
            render(latest_interim_financials_date) {
                return date(latest_interim_financials_date);
            },
        },
        {
            dataIndex: "pd",
            title: t("portfolio.companies.current-pd"),
            render(pd) {
                return pd ? decimalToPercent({ percent: pd, showPercentSymbol: true }) : "-";
            },
        },
        {
            dataIndex: "credit_score",
            title: t("portfolio.companies.current-credit-score"),
        },
        { dataIndex: "number_of_loans", title: t("portfolio.companies.number-of-assets") },
        {
            dataIndex: "total_outstanding_balance",
            title: t("portfolio.companies.outstanding-balance"),
            sorter: true,
            render(total_outstanding_balance) {
                return currency(total_outstanding_balance);
            },
        },
        {
            dataIndex: "total_outstanding_balance_principal",
            title: t("portfolio.companies.outstanding-balance-principal"),
            sorter: true,
            render(total_outstanding_balance_principal) {
                return currency(total_outstanding_balance_principal);
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("portfolio.portfolio-companies") }), _jsx(SearchList, Object.assign({ ref: ref, endpoint: "/admin/portfolios/companies", columns: columns, showingOfString: "companies.search.search-showing", rowKey: ({ company_id }) => company_id }, { children: _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("portfolio.companies.company-name"), allowClear: true }) })) })) }))] }));
};
export default PortfolioCompanies;
