var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { App, Button, Col, Form, Input, Row, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { MFAStep } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Routes } from "src/Routes";
import { login } from "src/store/actions/authActions";
import { Axios } from "src/utils/Axios";
import { MFAView } from "./mfa-view/MFAView";
const LoginPage = () => {
    var _a;
    const { message } = App.useApp();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data, isPending: sending, mutate, } = useMutation({
        mutationKey: ["login"],
        mutationFn: (form) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.post("/admin/account/login", form);
            return response.data;
        }),
        onSuccess: (data) => {
            var _a;
            const isNextStapMfa = ((_a = data === null || data === void 0 ? void 0 : data.next_login_step) === null || _a === void 0 ? void 0 : _a.step) !== MFAStep.LoginStepNone;
            if (!isNextStapMfa) {
                dispatch(login());
            }
        },
        onError: () => {
            void message.error(t("login.error"));
        },
    });
    const isNextStapMfa = ((_a = data === null || data === void 0 ? void 0 : data.next_login_step) === null || _a === void 0 ? void 0 : _a.step) !== MFAStep.LoginStepNone;
    const isMfaEnabled = isNextStapMfa && !!(data === null || data === void 0 ? void 0 : data.is_mfa_enabled);
    const mfaInstruction = isNextStapMfa ? data === null || data === void 0 ? void 0 : data.next_login_step : undefined;
    const email = data === null || data === void 0 ? void 0 : data.email;
    const userData = email ? { email } : undefined;
    const onSubmit = (form) => {
        mutate(form);
    };
    return (_jsx(_Fragment, { children: !isMfaEnabled ? (_jsxs(_Fragment, { children: [_jsx(Typography.Title, Object.assign({ level: 3, style: { marginBottom: "48px" } }, { children: t("login.title") })), _jsxs(Form, Object.assign({ onFinish: onSubmit, layout: "vertical" }, { children: [_jsx(EmailFormItem, { rules: [
                                {
                                    required: true,
                                    message: t("application_form.field-required"),
                                },
                            ] }), _jsx(FormItem, Object.assign({ name: "password", label: t("application_form.password"), labelAlign: "left", rules: [
                                {
                                    required: true,
                                    message: t("application_form.field-required"),
                                },
                            ] }, { children: _jsx(Input.Password, {}) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "48px" } }, { children: _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", loading: sending }, { children: t("login.login") })) }) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "16px" } }, { children: _jsx(Col, { children: _jsxs(Typography.Text, { children: [t("login.forgot_password_hint"), _jsx(Link, Object.assign({ to: Routes.ForgotPassword, style: { marginLeft: "4px" } }, { children: t("login.forgot_password_hint_click_here") }))] }) }) }))] }))] })) : (_jsx(MFAView, { option: mfaInstruction, userData: userData })) }));
};
export default LoginPage;
