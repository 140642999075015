import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { Divider as AntdDivider } from "antd";
import styled from "styled-components";
import PendingItem from "src/layouts/ApplicationLayout/modules/pending-items/PendingItem";
const Divider = styled(AntdDivider) `
	margin: 0;
`;
const PendingItemsList = ({ pendingItems, limit = Number.POSITIVE_INFINITY }) => {
    const pendingItemsToDisplay = useMemo(() => (limit ? pendingItems.slice(0, limit) : pendingItems), [pendingItems, limit]);
    return (_jsx("div", Object.assign({ "data-cy": "pending-items-list" }, { children: pendingItemsToDisplay.map((pendingItem, idx) => (_jsxs(Fragment, { children: [_jsx(PendingItem, { pendingItem: pendingItem }), idx < pendingItemsToDisplay.length - 1 && _jsx(Divider, {})] }, idx))) })));
};
export default PendingItemsList;
