import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, DatePicker, Divider, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { FeatureName, TaskAssigneeType, TaskPriority, } from "@teylor-tools/Api";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import { MarkdownEditor } from "@ui/markdown/editor/MarkdownEditor";
import TaskPriorityIndicator from "src/components/tasks/TaskPriorityIndicator";
import { SUMMARY_MAX_LENGTH } from "src/components/tasks/tasks.helpers";
import { TaskPriorities } from "src/components/tasks/tasks.types";
import { useBoGroupUsers } from "src/hooks/useBoGroupUsers";
import { Axios } from "src/utils/Axios";
import UserSelect from "../form/UserSelect";
const UNASSIGNED_ID = "unassigned";
const Wrapper = styled.div `
	margin: 24px;
`;
const HorizontalFormItem = styled(Form.Item) `
	& > * {
		flex-direction: row !important;
		gap: 20px;
		align-items: center;

		.ant-form-item-label {
			padding: 0;
			min-width: 100px;
		}

		.ant-form-item-control {
			width: auto !important;
		}
	}
`;
var Fields;
(function (Fields) {
    Fields[Fields["summary"] = 0] = "summary";
    Fields[Fields["desc"] = 1] = "desc";
    Fields[Fields["assignee"] = 2] = "assignee";
    Fields[Fields["priority"] = 3] = "priority";
    Fields[Fields["dueDate"] = 4] = "dueDate";
})(Fields || (Fields = {}));
const disabledDate = (current) => current && current < dayjs().subtract(1, "day");
const SidebarCreateTask = ({ entityType, entityId, afterSave }) => {
    var _a;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { features } = useSelector((state) => state.configState);
    const taskAssigneeGroup = (_a = features.find((f) => f.name === FeatureName.Tasks)) === null || _a === void 0 ? void 0 : _a.value.task_bo_user_group_id;
    const { users, usersLoading } = useBoGroupUsers(taskAssigneeGroup);
    const requiredRule = {
        required: true,
        message: t("application_form.field-required"),
    };
    const handleFinish = (v) => {
        const payload = Object.assign(Object.assign({ related_entity_id: entityId, related_entity_type: entityType }, (v[Fields.assignee] === UNASSIGNED_ID
            ? { assignee_id: "", assignee_type: "" }
            : { assignee_id: v[Fields.assignee], assignee_type: TaskAssigneeType.Bouser })), { priority: v[Fields.priority], subject: v[Fields.summary], description: v[Fields.desc], due_date: v[Fields.dueDate] && dayjs(v[Fields.dueDate]).format(rawDateFormat) });
        Axios.post(`admin/tasks`, payload).then(() => afterSave(), (err) => void Axios.error(err));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: { [Fields.priority]: TaskPriority.Medium, [Fields.dueDate]: null }, onFinish: handleFinish }, { children: [_jsxs(Wrapper, { children: [_jsx(Form.Item, Object.assign({ name: Fields.summary, rules: [requiredRule], label: t("tasks.summary") }, { children: _jsx(Input, { maxLength: SUMMARY_MAX_LENGTH }) })), _jsx(Form.Item, Object.assign({ name: Fields.desc, label: t("tasks.description") }, { children: _jsx(MarkdownEditor, { onChange: (value) => form.setFieldsValue({ [Fields.desc]: value }) }) }))] }), _jsx(Divider, {}), _jsx(Wrapper, { children: _jsx(Form.Item, Object.assign({ name: Fields.assignee, rules: [requiredRule], label: t("tasks.assignee") }, { children: _jsx(UserSelect, { users: [{ name: t("application.preview.unassigned"), id: UNASSIGNED_ID }, ...users], usersLoading: usersLoading }) })) }), _jsx(Divider, {}), _jsxs(Wrapper, { children: [_jsx(HorizontalFormItem, Object.assign({ name: Fields.priority, rules: [requiredRule], label: t("tasks.priority") }, { children: _jsx(Select, { options: TaskPriorities.map((priority) => ({
                                        value: priority,
                                        label: _jsx(TaskPriorityIndicator, { priority: priority, showLabel: true }),
                                    })) }) })), _jsx(HorizontalFormItem, Object.assign({ name: Fields.dueDate, label: t("tasks.due-date") }, { children: _jsx(DatePicker, { style: { width: "100%" }, format: dayMonthYear, disabledDate: disabledDate }) }))] })] })), _jsx(Divider, { style: { margin: 0 } }), _jsx(Wrapper, Object.assign({ style: { textAlign: "right" } }, { children: _jsx(Button, Object.assign({ type: "primary", onClick: () => form.submit() }, { children: t("common.add") })) }))] }));
};
export default SidebarCreateTask;
