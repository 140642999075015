var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DownloadOutlined, ExclamationCircleOutlined, FilePdfOutlined, MinusOutlined, PlusOutlined, } from "@ant-design/icons";
import { Collapse as AntdCollapse, Tag as AntdTag, App, Button, Checkbox, Divider, Space, Switch, Typography, } from "antd";
import styled from "styled-components";
import { DocumentSigningMethod, FeatureName, } from "@teylor-tools/Api";
import { categorizeDocuments } from "@teylor-tools/utils/documents/categorize-documents";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import { DocumentModal } from "./DocumentModal";
import SignatoryDetailsPopover from "./SignatoryDetailsPopover";
import { FieldType } from "./types";
const { Text } = Typography;
const ContentWrapper = styled.div `
	padding: 24px;
`;
const Collapse = styled(AntdCollapse) `
	background-color: #ffffff;

	.ant-collapse-header {
		padding: 24px !important;
	}
`;
const SwitchItem = styled(Space) `
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 24px;
`;
const Tag = styled(AntdTag) `
	border: none;
	background: #f5f5f5;
	border-radius: 200px;
`;
const emptyRequirements = {
    is_loan_signature_required: false,
    is_ubo_signature_required: false,
    is_video_id_required: false,
    guarantor_contract_url: "",
    guarantor_contract_filename: "",
    digital_signature_url: "",
    required_other_documents: [],
};
var Field;
(function (Field) {
    Field["kyc"] = "is_video_id_required";
    Field["loan-contract"] = "is_loan_signature_required";
    Field["guarantor-contract"] = "is_ubo_signature_required";
    Field["required_other_documents"] = "required_other_documents";
})(Field || (Field = {}));
const ActionBtnIconAdd = styled(PlusOutlined) `
	font-size: 12px !important;
`;
const ActionBtnIconRemove = styled(MinusOutlined) `
	font-size: 12px !important;
`;
const ActionButton = styled(Button) `
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0;

	&.ant-btn.ant-btn-icon-only {
		width: 16px;
	}
`;
const AddDocumentActionBtn = styled.div `
	display: flex;
	align-items: center;
	margin: 0 24px 0 48px;
	gap: 10px;
`;
const IconFilePdf = styled(FilePdfOutlined) `
	font-size: 18px;
	margin-left: 10px;
	color: ${(props) => props.theme.color_primary};
`;
const IconDownload = styled(DownloadOutlined) `
	font-size: 18px;
	margin-left: 10px;
	color: ${(props) => props.theme.color_primary};
`;
const OtherDocumentMainRow = styled.div `
	display: flex;
	justify-content: space-between;
	margin: 0 24px;
`;
const SwitchItemLabel = styled.div `
	display: flex;
	row-gap: 8px;
	column-gap: 8px;
`;
const DocumentRowWrapper = styled.div `
	display: flex;
	justify-content: space-between;
	margin: 0 24px 0 48px;
	align-items: center;
`;
const DocumentRowActionWrapper = styled.div `
	display: flex;
	align-items: center;
`;
const DocumentRowItemName = styled.div `
	margin-left: 15px;
`;
const BeforeSignatureView = ({ signatory, onUpdate }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { message, modal } = App.useApp();
    const { updatePendingItems, application: { applicationId }, } = useApplication();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState();
    const [updateQueue, setUpdateQueue] = useState([]);
    const [otherToggledStates, setOtherToggledStates] = useState({});
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const { features } = useSelector((state) => state.configState);
    const signatoryDocuments = useMemo(() => {
        var _a;
        return (_a = features.find((feature) => feature.name === FeatureName.SignatoryDocuments)) === null || _a === void 0 ? void 0 : _a.value;
    }, [features]);
    const isAllDigital = (_b = (_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) === null || _b === void 0 ? void 0 : _b.every((doc) => doc.signing_method === DocumentSigningMethod.Online);
    const isSomeDigital = ((_d = (_c = signatory.requirements) === null || _c === void 0 ? void 0 : _c.required_other_documents) === null || _d === void 0 ? void 0 : _d.some((doc) => doc.signing_method === DocumentSigningMethod.Online)) && !isAllDigital;
    const setOtherToggled = (signatoryId, toggled) => {
        setOtherToggledStates((prevStates) => (Object.assign(Object.assign({}, prevStates), { [signatoryId]: toggled })));
    };
    const onCheckAllChange = (e) => {
        var _a, _b;
        const newSigningMethod = e.target.checked
            ? DocumentSigningMethod.Online
            : DocumentSigningMethod.Offline;
        const updatedDocuments = (((_b = (_a = signatory === null || signatory === void 0 ? void 0 : signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) === null || _b === void 0 ? void 0 : _b.map((doc) => {
            return { document_id: doc.document_id, signing_method: newSigningMethod };
        })) || []);
        void handleDocumentToSignature(updatedDocuments);
    };
    const handleOpenDocumentModal = () => {
        setIsDocumentModalOpen(true);
        void handleGetDocs();
    };
    const handleDocumentToSignature = (docs) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield Axios.patch(`/admin/applications/${applicationId}/signatories/${signatory.signatory_id}/requirements`, Object.assign(Object.assign({}, (signatory.requirements || emptyRequirements)), { signatory_id: signatory.signatory_id, application_id: signatory.application_id, required_other_documents: docs }));
            void message.success(t("application.contract.signatory-requirements-update-success"));
            yield onUpdate();
        }
        catch (e) {
            yield Axios.error(e, t("application.contract.signatory-requirements-update-fail"));
        }
        finally {
            updatePendingItems();
            setIsLoadingUpdate(undefined);
            setIsDocumentModalOpen(false);
        }
    });
    const getUpdatedDocs = (docId) => {
        var _a, _b;
        return (((_b = (_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) === null || _b === void 0 ? void 0 : _b.filter((doc) => docId !== doc.document_id).map((doc) => ({ document_id: doc.document_id, signing_method: doc.signing_method }))) || []);
    };
    useEffect(() => {
        const processQueue = () => __awaiter(void 0, void 0, void 0, function* () {
            if (isLoadingUpdate || updateQueue.length === 0)
                return;
            const currentRequest = updateQueue[0];
            setIsLoadingUpdate(currentRequest);
            try {
                yield Axios.patch(`/admin/applications/${applicationId}/signatories/${signatory.signatory_id}/requirements`, Object.assign(Object.assign({}, (signatory.requirements || emptyRequirements)), { signatory_id: signatory.signatory_id, application_id: signatory.application_id, [currentRequest.field]: currentRequest.checked }));
                void message.success(t("application.contract.signatory-requirements-update-success"));
                yield onUpdate();
            }
            catch (e) {
                void Axios.error(e, t("application.contract.signatory-requirements-update-fail"));
            }
            finally {
                setIsLoadingUpdate(undefined);
                setUpdateQueue((prevQueue) => prevQueue.slice(1));
                updatePendingItems();
            }
        });
        void processQueue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateQueue, isLoadingUpdate, signatory.requirements, onUpdate]);
    const updateSignatoryRequirements = ({ field, checked }) => {
        setUpdateQueue((prevQueue) => [
            ...prevQueue,
            { signatoryId: signatory.signatory_id, field, checked },
        ]);
    };
    const signatureRequirements = Object.keys(Field).filter((key) => {
        var _a, _b;
        return !(key === Field.required_other_documents &&
            !((_b = (_a = signatoryDocuments === null || signatoryDocuments === void 0 ? void 0 : signatoryDocuments.signatory_document_types) === null || _a === void 0 ? void 0 : _a.map(({ type }) => type)) === null || _b === void 0 ? void 0 : _b.includes("other")));
    });
    const handleGetDocs = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield Axios.get(`admin/applications/${applicationId}/documents`, values);
            const documents = data.result.filter((document) => {
                var _a, _b;
                return (_b = (_a = signatoryDocuments === null || signatoryDocuments === void 0 ? void 0 : signatoryDocuments.signatory_document_types) === null || _a === void 0 ? void 0 : _a.map(({ type }) => type)) === null || _b === void 0 ? void 0 : _b.includes(document.document_type);
            });
            setDocuments(categorizeDocuments(documents));
        }
        catch (err) {
            return Axios.error(err);
        }
    });
    const isChecked = ({ signatory, field }) => {
        var _a, _b, _c, _d, _e;
        if (field === Field.required_other_documents) {
            return ((_a = otherToggledStates[signatory.signatory_id]) !== null && _a !== void 0 ? _a : (((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.required_other_documents) &&
                ((_d = (_c = signatory.requirements) === null || _c === void 0 ? void 0 : _c.required_other_documents) === null || _d === void 0 ? void 0 : _d.length) > 0));
        }
        return !!((_e = signatory.requirements) === null || _e === void 0 ? void 0 : _e[field]);
    };
    const handleDocumentSignatoryMethod = ({ docId, checked, }) => {
        var _a, _b;
        const updatedDocSignatoryMethod = (_b = (_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) === null || _b === void 0 ? void 0 : _b.map((doc) => {
            if (docId === doc.document_id) {
                return {
                    document_id: doc.document_id,
                    signing_method: checked ? DocumentSigningMethod.Online : DocumentSigningMethod.Offline,
                };
            }
            else {
                return {
                    document_id: doc.document_id,
                    signing_method: doc.signing_method,
                };
            }
        });
        void handleDocumentToSignature(updatedDocSignatoryMethod);
    };
    const handleRemoveDocument = (docId) => () => {
        void modal.confirm({
            title: t("company.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlined, {}),
            content: t("company.signatures.approve-modal.content"),
            okText: t("common.confirm"),
            onOk: () => {
                return handleDocumentToSignature(getUpdatedDocs(docId));
            },
        });
    };
    const handleRemoveAllDocuments = () => {
        void modal.confirm({
            title: t("company.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlined, {}),
            content: t("company.signatures.approve-modal.delete-all.content"),
            okText: t("common.confirm"),
            onOk: () => {
                setOtherToggled(signatory.signatory_id, false);
                return handleDocumentToSignature([]);
            },
        });
    };
    const handleDocumentDownload = (docId) => {
        void Axios.get(`/admin/applications/${applicationId}/documents/${docId}/download_url`)
            .then(({ data }) => {
            const newTab = window.open(data.download_url, "_blank");
            if (newTab)
                newTab.focus();
        })
            .catch((e) => void Axios.error(e));
    };
    const requirementsCount = signatureRequirements.filter((requirement) => { var _a; return (_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a[Field[requirement]]; }).length;
    const isCheckedSignatureType = (field) => {
        switch (field) {
            case FieldType.KYC:
                return true;
            case FieldType.GUARANTOR_CONTRACT:
                return ((signatoryDocuments === null || signatoryDocuments === void 0 ? void 0 : signatoryDocuments.guarantor_contract_signing_method) === DocumentSigningMethod.Online);
            case FieldType.LOAN_CONTRACT:
                return (signatoryDocuments === null || signatoryDocuments === void 0 ? void 0 : signatoryDocuments.loan_contract_signing_method) === DocumentSigningMethod.Online;
        }
    };
    const item = {
        key: signatory.signatory_id,
        label: (_jsxs(Space, Object.assign({ size: 16 }, { children: [_jsx(SignatoryDetailsPopover, { signatory: signatory }), _jsx(Tag, { children: requirementsCount || t("application.contract.none") })] }))),
        children: signatureRequirements.map((field) => {
            var _a, _b, _c, _d;
            return (_jsx(Fragment, { children: Field[field] === Field.required_other_documents ? (_jsxs(_Fragment, { children: [_jsxs(OtherDocumentMainRow, { children: [_jsxs(SwitchItemLabel, { children: [_jsx(Switch, { loading: false, checked: isChecked({ signatory, field: Field[field] }), onChange: (checked) => {
                                                var _a, _b;
                                                if (!checked &&
                                                    ((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) &&
                                                    ((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.required_other_documents.length) > 0) {
                                                    handleRemoveAllDocuments();
                                                }
                                                else {
                                                    setOtherToggled(signatory.signatory_id, checked);
                                                }
                                            }, "data-cy": "switch-item-signature" }), _jsx(Text, Object.assign({ strong: true }, { children: t(`application.signatures.${Field.required_other_documents}`) }))] }), ((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.required_other_documents) &&
                                    ((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.required_other_documents.length) > 0 && (_jsx(Checkbox, Object.assign({ indeterminate: isSomeDigital, onChange: onCheckAllChange, checked: isAllDigital }, { children: t("application.signatures.document.sign-in.type-digital") })))] }), _jsx(Divider, {}), isChecked({ signatory, field: Field[field] }) && (_jsxs(_Fragment, { children: [(_d = (_c = signatory.requirements) === null || _c === void 0 ? void 0 : _c.required_other_documents) === null || _d === void 0 ? void 0 : _d.map((doc) => {
                                    return (_jsxs(Fragment, { children: [_jsxs(DocumentRowWrapper, { children: [_jsxs(DocumentRowActionWrapper, { children: [_jsx(ActionButton, { icon: _jsx(ActionBtnIconRemove, {}), onClick: handleRemoveDocument(doc.document_id) }), _jsx(IconFilePdf, {}), _jsx(DocumentRowItemName, { children: doc.document_name }), _jsx(IconDownload, { onClick: () => handleDocumentDownload(doc.document_id) })] }), _jsx(Checkbox, Object.assign({ onChange: (e) => handleDocumentSignatoryMethod({
                                                            docId: doc.document_id,
                                                            checked: e.target.checked,
                                                        }), checked: doc.signing_method === DocumentSigningMethod.Online }, { children: t("application.signatures.document.sign-in.type-digital") }))] }), _jsx(Divider, {})] }, doc.document_id));
                                }), _jsxs(AddDocumentActionBtn, Object.assign({ onClick: () => void handleOpenDocumentModal() }, { children: [_jsx(ActionButton, { icon: _jsx(ActionBtnIconAdd, {}) }), _jsx(Text, { children: t("application.signatures.add-document") })] }))] }))] })) : (_jsxs(Fragment, { children: [_jsxs(SwitchItem, { children: [_jsxs(SwitchItemLabel, { children: [_jsx(Switch, { loading: (isLoadingUpdate === null || isLoadingUpdate === void 0 ? void 0 : isLoadingUpdate.signatoryId) === signatory.signatory_id &&
                                                (isLoadingUpdate === null || isLoadingUpdate === void 0 ? void 0 : isLoadingUpdate.field) === Field[field], checked: isChecked({ signatory, field: Field[field] }), onChange: (checked) => {
                                                void updateSignatoryRequirements({
                                                    checked,
                                                    field: Field[field],
                                                });
                                            }, "data-cy": "switch-item-signature" }), _jsx(Text, Object.assign({ strong: true }, { children: t(`application.signatures.${field}`) }))] }), _jsx(Checkbox, Object.assign({ checked: isCheckedSignatureType(field), disabled: true }, { children: t("application.signatures.document.sign-in.type-digital") }))] }), _jsx(Divider, {})] })) }, field));
        }),
    };
    return (_jsxs(ContentWrapper, { children: [_jsx(Space, Object.assign({ direction: "vertical", size: 30, style: { width: "100%" } }, { children: _jsx(Collapse, { items: [item], "data-cy": "collapse-signatory" }, signatory.signatory_id) })), _jsx(DocumentModal, { isOpen: isDocumentModalOpen, documents: documents, setIsDocumentModalOpen: setIsDocumentModalOpen, handleGetDocs: handleGetDocs, listSelectedDocuments: (_e = signatory.requirements) === null || _e === void 0 ? void 0 : _e.required_other_documents, handleDocumentToSignature: handleDocumentToSignature })] }));
};
export default BeforeSignatureView;
