var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, DownOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { App, Button, Dropdown, Space, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";
import RepaymentModal from "src/components/RepaymentModal";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { Axios } from "src/utils/Axios";
import CompartmentModal from "./CompartmentModal";
import MoveToPreviousStateModal from "./MoveToPreviousStateModal";
import RejectionModal from "./RejectionModal";
const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary} !important;
`;
const MoveApplication = ({ applicationId, possibleStates, statusFlow, partnerId, update, }) => {
    const { t } = useTranslation();
    const { modal, message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [isModalSaveLoading, setIsModalSaveLoading] = useState(false);
    const [isRepaymentModalVisible, setIsRepaymentModalVisible] = useState(false);
    const [isCompartmentModalVisible, setIsCompartmentModalVisible] = useState(false);
    const [isRejectionModalVisible, setIsRejectionModalVisible] = useState(false);
    const [moveToPreviousState, setMoveToPreviousState] = useState(null);
    const [loan, setLoan] = useState();
    const currentStatusIndex = statusFlow.findIndex(({ is_current_status }) => is_current_status);
    const previousPossibleStatuses = statusFlow.reduce((acc, status, statusIndex) => {
        if (statusIndex < currentStatusIndex &&
            (possibleStates === null || possibleStates === void 0 ? void 0 : possibleStates.includes(status.status_name)) &&
            status.status_name !== ApplicationStatuses.Rejected) {
            acc.push(status.status_name);
        }
        return acc;
    }, []);
    const nextPossibleStatuses = statusFlow.reduce((acc, status, statusIndex) => {
        if (statusIndex > currentStatusIndex &&
            (possibleStates === null || possibleStates === void 0 ? void 0 : possibleStates.includes(status.status_name)) &&
            status.status_name !== ApplicationStatuses.Rejected) {
            acc.push(status.status_name);
        }
        return acc;
    }, []);
    const { mutate: changeStatus, isPending: loadingChangeStatus } = useMutation({
        mutationKey: ["applicationStatusUpdate"],
        mutationFn: ({ status, rejection_reason, reject_description, comment_on_backwards_status, }) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.patch(`/admin/applications/${applicationId}/status`, {
                status,
                rejection_reason,
                reject_description,
                comment_on_backwards_status,
            });
            return response.data;
        }),
        onSuccess: () => {
            update();
        },
        onError: (error) => {
            void Axios.error(error);
        },
        onSettled: () => {
            setIsRejectionModalVisible(false);
            setMoveToPreviousState(null);
        },
    });
    const showPayOutDateConfirmationModal = (loan) => {
        void modal.confirm({
            title: t("application.is-payout-date-correct"),
            icon: _jsx(QuestionCircleOutlinedIcon, {}),
            content: _jsx(Trans, Object.assign({ i18nKey: "application.payout-date-info" }, { children: { date: loan.payout_date } })),
            cancelText: t("application.change-date"),
            okText: t("application.date-is-correct"),
            onCancel: () => {
                setIsRepaymentModalVisible(true);
            },
            onOk: () => {
                if (!partnerId) {
                    void message.error(t("application.missing-partner-id"));
                }
                else {
                    setIsCompartmentModalVisible(true);
                }
            },
        });
    };
    const getLoan = () => {
        setLoading(true);
        Axios.get(`/admin/applications/${applicationId}/loan`)
            .then(({ data }) => {
            setLoan(data);
            showPayOutDateConfirmationModal(data);
        }, (err) => {
            var _a;
            // 404 status means there's no loan associated with the application
            // it's OK for brokered applications
            ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404
                ? changeStatus({ status: ApplicationStatuses.PaidOut })
                : void Axios.error(err);
        })
            .finally(() => setLoading(false));
    };
    const createRepayment = (date) => {
        setIsModalSaveLoading(true);
        Axios.patch(`/admin/loans/${loan === null || loan === void 0 ? void 0 : loan.loan_id}/payout_date`, {
            payout_date: date.format(rawDateFormat),
        })
            .then(() => {
            getLoan();
            setIsRepaymentModalVisible(false);
        }, (err) => Axios.error(err, t("application.repayment.error.error-saving-date")))
            .finally(() => setIsModalSaveLoading(false));
    };
    const confirmCompartment = (compartment) => {
        if (!loan)
            return;
        setLoading(true);
        Axios.patch(`/admin/loans/${loan.loan_id}`, { compartment_id: compartment })
            .then(() => {
            changeStatus({ status: ApplicationStatuses.PaidOut });
        }, (err) => Axios.error(err))
            .finally(() => {
            setLoading(false);
            setIsCompartmentModalVisible(false);
        });
    };
    const menuItems = useMemo(() => {
        if (!(possibleStates === null || possibleStates === void 0 ? void 0 : possibleStates.length))
            return [];
        return [
            ...nextPossibleStatuses.map((status) => ({
                label: (_jsxs(Space, { children: [_jsx(Typography.Text, { children: t("application.forward-to") }), _jsx(ApplicationStatus, { label: t(`application.status.${status}`), value: status })] })),
                key: status,
                icon: _jsx(ArrowRightOutlined, {}),
            })),
            ...previousPossibleStatuses.map((status) => ({
                label: (_jsxs(Space, { children: [_jsx(Typography.Text, { children: t("application.back-to") }), _jsx(ApplicationStatus, { label: t(`application.status.${status}`), value: status })] })),
                key: status,
                icon: _jsx(ArrowLeftOutlined, {}),
            })),
            ...(possibleStates.includes(ApplicationStatuses.Rejected)
                ? [
                    { key: "divider", type: "divider" },
                    {
                        label: t("application.rejected"),
                        key: ApplicationStatuses.Rejected,
                        icon: _jsx(CloseCircleOutlined, {}),
                        danger: true,
                    },
                ]
                : []),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleStates, previousPossibleStatuses, nextPossibleStatuses]);
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown, Object.assign({ placement: "bottomRight", disabled: !possibleStates, trigger: ["click"], menu: {
                    items: menuItems,
                    onClick: ({ key }) => {
                        if (key === ApplicationStatuses.Rejected) {
                            setIsRejectionModalVisible(true);
                        }
                        else if (key === ApplicationStatuses.PaidOut) {
                            getLoan();
                        }
                        else if (previousPossibleStatuses.includes(key)) {
                            setMoveToPreviousState(key);
                        }
                        else {
                            changeStatus({ status: key });
                        }
                    },
                } }, { children: _jsx(Button, Object.assign({ loading: loading || loadingChangeStatus, "data-cy": "move-application" }, { children: _jsxs(Space, { children: [t("application.name"), _jsx(DownOutlined, {})] }) })) })), isRepaymentModalVisible && loan && (_jsx(RepaymentModal, { payoutDate: dayjs(loan.payout_date), onCancel: () => {
                    setIsRepaymentModalVisible(false);
                }, onFinish: createRepayment, loading: isModalSaveLoading })), isCompartmentModalVisible && partnerId && (_jsx(CompartmentModal, { partnerId: partnerId, onCancel: () => {
                    setIsCompartmentModalVisible(false);
                }, onFinish: confirmCompartment, loading: isModalSaveLoading })), isRejectionModalVisible && (_jsx(RejectionModal, { onCancel: () => {
                    setIsRejectionModalVisible(false);
                }, onFinish: (rejectionReason, rejectionDescription) => {
                    void changeStatus({
                        status: ApplicationStatuses.Rejected,
                        rejection_reason: rejectionReason,
                        reject_description: rejectionDescription,
                    });
                } })), moveToPreviousState && (_jsx(MoveToPreviousStateModal, { onCancel: () => {
                    setMoveToPreviousState(null);
                }, onFinish: (description) => {
                    void changeStatus({
                        status: moveToPreviousState,
                        comment_on_backwards_status: description,
                    });
                } }))] }));
};
export default MoveApplication;
