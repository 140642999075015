import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { App, Button, Typography } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { categorizeDocuments } from "@teylor-tools/utils/documents/categorize-documents";
import { mapDocEntityKindToApiPath } from "@teylor-tools/utils/documents/mapDocEntityKindToApiPath";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import { getIssues, getOcrFilesById } from "@teylor-tools/utils/fin-cr/fin-cr";
import { finCrTypes } from "@teylor-tools/utils/fin-cr/fincr.types";
import FinCrPage from "@ui/fin-cr/FinCrPage";
import { Routes } from "src/Routes";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const { Text, Title } = Typography;
const FinancialsLink = styled(Text) `
	font-size: 16px;
	cursor: pointer;
`;
const ApplicationFinCR = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { application } = useApplication();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [categoriesWithDocs, setCategoriesWithDocs] = useState([]);
    const generateDocuments = useCallback((showIssuesError = true) => {
        setIsLoading(true);
        void Axios.get(`admin/applications/${application.applicationId}/documents`, {
            document_type: finCrTypes.join(","),
        })
            .then(({ data }) => getOcrFilesById({
            getOcrStatus: () => Axios.get(`admin/applications/${application.applicationId}/ocr/documents`).then(({ data }) => data),
            ocrDocs: data.result,
        }))
            .then((docs) => getIssues(Object.assign(Object.assign({ docs }, (showIssuesError
            ? {
                showIssuesError: () => void message.error(t("company.fincr.issues-request-error")),
            }
            : {})), { getDocOcrResult: (docId) => Axios.get(`admin/ocr/${docId}/results`).then(({ data }) => data) }))
            .then((docs) => categorizeDocuments(docs))
            .then((docs) => setCategoriesWithDocs(docs))
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoading(false)));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application.applicationId]);
    const navigateToDocuments = useCallback(() => {
        navigate(generatePath(Routes.ApplicationDocuments, {
            applicationId: application.applicationId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.applicationId]);
    useEffect(generateDocuments, [generateDocuments]);
    return (_jsx(FinCrPage, { breadcrumbsItems: [
            {
                title: (_jsx(FinancialsLink, Object.assign({ onClick: () => navigate(generatePath(Routes.ApplicationFinancials, {
                        applicationId: application.applicationId,
                    })) }, { children: t("application.financials.financials") }), "1")),
            },
            {
                title: (_jsx(Title, Object.assign({ level: 5 }, { children: t("application.fincr.scan") }), "2")),
            },
        ], actionBtn: _jsx(Button, Object.assign({ onClick: navigateToDocuments, icon: _jsx(PlusOutlined, {}) }, { children: t("application.fincr.add_document") })), navigateToDocuments: navigateToDocuments, isLoading: isLoading, categoriesWithDocs: categoriesWithDocs, isEditable: application.status !== ApplicationStatuses.PaidOut, getDocs: generateDocuments, download: (docId, format) => downloadFileFromUrl(`${Axios.apiPath}/admin/ocr/${docId}/results?format=${format}`, `FinCR_results_${docId}.${format}`), scan: (payload) => Axios.post("/admin/ocr", payload).then(({ data }) => data), rescan: (docId) => Axios.post(`/admin/ocr/${docId}/rescan`).then(), save: ({ financialsDate, docId, entityKind, entityId, }) => Axios.patch(`/admin/${mapDocEntityKindToApiPath(entityKind)}/${entityId}/documents/${docId}`, {
            as_of_date: financialsDate,
        })
            .catch((error) => void Axios.error(error))
            .then(), pollStatus: (docId) => Axios.get(`/admin/ocr/${docId}`)
            .then(({ data }) => data)
            .catch((error) => void Axios.error(error)) }));
};
export default ApplicationFinCR;
