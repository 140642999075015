import dayjs from "dayjs";

import { CompanyFinancialData } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../../translations/translations";
import { Table } from "../table/Table";
import { roundStringNumber } from "../utils";

interface Props {
	title: string;
	summary?: CompanyFinancialData[];
}

const FinancialSummaryProperties = [
	"revenue",
	"activated_costs",
	"inventory_increase",
	"other_income_regular",
	"cogs",
	"other_cor",
	"cost_of_revenue",
	"gross_profit",
	"sga",
	"depreciations",
	"other_opex_costs",
	"opex",
	"operating_profit",
	"ebitda",
	"other_income_net",
	"other_finance_income",
	"other_finance_costs",
	"interest_income",
	"interest_expense",
	"ebt",
	"taxes_paid",
	"nopat",
	"tax_rate",
	"net_income",
	"fixed_costs",
	"cash",
	"short_term_investments",
	"accounts_receivable",
	"inventories",
	"inventory_net",
	"prepaid_expenses",
	"other_current_assets",
	"loan_to_shareholders",
	"loan_to_related_companies",
	"property_equipment_net",
	"intangible_assets_net",
	"self_made_intangible",
	"goodwill",
	"long_term_investments",
	"investments_in_related_companies",
	"other_non_current_assets",
	"deferred_tax_assets",
	"current_assets_published",
	"non_current_assets_published",
	"total_assets_published",
	"equity_published",
	"current_assets",
	"non_current_assets",
	"total_assets",
	"current_portion_of_long_debt",
	"accounts_payable",
	"accrued_expenses",
	"income_taxes_payable",
	"other_current_liabilities",
	"current_liabilities_published",
	"current_liabilities",
	"long_term_debt",
	"underfunded_retirement_plans",
	"deferred_tax_liabilities",
	"other_long_term_liabilities",
	"non_current_liabilities_published",
	"total_liabilities_published",
	"loan_from_shareholders",
	"non_current_liabilities",
	"total_liabilities",
	"equity",
	"preferred_stock",
	"shares_issued",
	"paid_in_capital",
	"retained_earnings",
	"accumulated_other_comprehensive_income",
	"price_per_share",
	"total_debt",
	"net_debt",
	"capital_employed",
	"total_invested_capital",
	"net_fixed_assets",
	"working_capital",
	"net_working_capital",
	"risk_free_rate_of_return",
	"market_rate_of_return",
	"classification_by_revenue",
	"beta",
	"balance_sheet_sum_check",
	"debt_service",
] as const;

const FinancialSummary = ({ title, summary = [] }: Props) => {
	const t = useTranslations();
	const { localizedNumber } = useFormatter();

	const getFinancialSummaryCols = () => {
		const columns = [
			{
				title: `${title}`,
				dataIndex: "name",
				key: "name",
				width: "280px",
			},
			...summary.map((financialsEntry) => {
				return {
					title: dayjs(financialsEntry.financials_date as string).format("YYYY"),
					dataIndex: dayjs(financialsEntry.financials_date as string).format("YYYY"),
					key: dayjs(financialsEntry.financials_date as string).format("YYYY"),
					width: "160px",
					render: (value: string) => {
						if (!value) return "";
						if (!isNaN(Number(value))) return <span>{localizedNumber({ value })}</span>;
						return value;
					},
				};
			}),
			{
				dataIndex: "invisible",
			},
		];
		return columns;
	};

	const getFinancialSummaryData = () => {
		return FinancialSummaryProperties.map((property) => {
			const row = {
				key: property,
				name: t.scoring.financials[property],
			};

			summary.forEach((financialsEntry) => {
				if (!financialsEntry.financials_date) return;
				// @ts-ignore
				row[dayjs(financialsEntry.financials_date).format("YYYY")] = isNaN(
					Number(financialsEntry.summary_data?.[property])
				)
					? financialsEntry.summary_data && financialsEntry.summary_data[property]
					: roundStringNumber(financialsEntry.summary_data?.[property]);
			});

			return row;
		});
	};

	return (
		<Table
			bordered
			columns={getFinancialSummaryCols()}
			dataSource={getFinancialSummaryData()}
			pagination={false}
			scroll={{ y: `calc(100vh - 300px)` }}
			sticky
		/>
	);
};

export default FinancialSummary;
