var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import styled, { css } from "styled-components";
const { Text } = Typography;
const PendingItemWrapper = styled.div `
	padding: 8px 0;

	${({ $isSubItem }) => $isSubItem &&
    css `
			margin-left: 20px;
		`}
`;
const PendingItemIcon = styled(CheckCircleOutlined) `
	color: rgba(0, 0, 0, 0.25);
	margin-top: 4px;
`;
const SubItemIcon = styled(RightOutlined) `
	color: rgba(0, 0, 0, 0.25);
	margin-top: 4px;
	font-size: 10px;
`;
const PendingItem = ({ pendingItem: { title, action, subItems, actionLabel }, isSubItem = false, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!action)
            return;
        setIsLoading(true);
        yield action();
        setIsLoading(false);
    });
    return (_jsxs(PendingItemWrapper, Object.assign({ "$isSubItem": isSubItem }, { children: [_jsxs(Row, Object.assign({ wrap: false, align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "24px" }, { children: isSubItem ? _jsx(SubItemIcon, {}) : _jsx(PendingItemIcon, {}) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(Text, { children: title }) })), _jsx(Col, Object.assign({ flex: "60px", style: { textAlign: "right" } }, { children: action && actionLabel && (_jsx(Button, Object.assign({ size: "small", onClick: () => void handleClick(), loading: isLoading, disabled: isLoading }, { children: actionLabel }))) }))] })), !!(subItems === null || subItems === void 0 ? void 0 : subItems.length) &&
                subItems.map((subItem, idx) => (_jsx(PendingItem, { pendingItem: subItem, isSubItem: true }, idx)))] })));
};
export default PendingItem;
