import { FC } from "react";

import { PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";

import { useTranslations } from "../translations/translations";

const EmptyContainer = styled.div`
	display: flex;
	margin-top: 48px;
	flex-direction: column;
	place-items: center;
`;

const UploadBtn = styled(Button)`
	display: block;
	margin-inline: auto;
	margin-top: 24px;
`;

interface FinCrEmptyProps {
	goToDocuments: () => void;
}

export const FinCrEmpty: FC<FinCrEmptyProps> = ({ goToDocuments }) => {
	const t = useTranslations();

	return (
		<EmptyContainer>
			<PaperClipOutlined style={{ fontSize: "42px", color: "#d9d9d9" }} />
			<Typography.Title level={5}>{t.fincr.empty}</Typography.Title>
			<UploadBtn
				onClick={goToDocuments}
				type="primary"
				icon={<PlusOutlined style={{ fontSize: "16px" }} />}
			>
				{t.fincr["upload-documents"]}
			</UploadBtn>
		</EmptyContainer>
	);
};
