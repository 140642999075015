import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Col, Form, Modal, Row, message } from "antd";
import Title from "antd/es/typography/Title";
import CustomField from "@ui/custom-field/CustomField";
import { Axios } from "src/utils/Axios";
import { createAkfFields } from "./utils";
const CustomFieldsModal = ({ customFieldsData, customFieldsConfig, modalProps, onSend, applicationCurrency, }) => {
    const { applicationId } = useParams();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [sending, setSending] = useState(false);
    const akfFields = useMemo(() => (customFieldsConfig ? createAkfFields(customFieldsConfig, t) : undefined), [customFieldsConfig, t]);
    const initialValues = useMemo(() => ({
        is_public_tender: customFieldsData.values.is_public_tender,
        employer_rate_subsidy: Number(customFieldsData.values.employer_rate_subsidy),
        employer_service_subsidy: customFieldsData.values.employer_service_subsidy,
        max_bikes_per_employee: customFieldsData.values.max_bikes_per_employee,
        price_limit_bike: Number(customFieldsData.values.price_limit_bike),
        number_of_employees: customFieldsData.values.number_of_employees,
        group_id: customFieldsData.values.group_id,
    }), [customFieldsData]);
    const handleSubmit = (values) => {
        setSending(true);
        Axios.patch(`/admin/applications/${applicationId}/custom_fields`, Object.assign(Object.assign({}, values), { employer_rate_subsidy: values.employer_rate_subsidy.toString(), price_limit_bike: values.price_limit_bike.toString() }))
            .then(() => {
            onSend();
            void message.success(t("common.saved"));
        })
            .catch((error) => void Axios.error(error))
            .finally(() => setSending(false));
    };
    return (_jsx(Modal, Object.assign({ okText: t("common.save"), onOk: () => form.submit(), okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { width: 650 }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: initialValues, onFinish: handleSubmit, preserve: false }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("application.leasing_details.edit_leasing_details") })), akfFields && (_jsx(_Fragment, { children: _jsx(Row, Object.assign({ gutter: 24 }, { children: [
                            akfFields.is_public_tender,
                            akfFields.employer_rate_subsidy,
                            akfFields.employer_service_subsidy,
                            akfFields.max_bikes_per_employee,
                            akfFields.price_limit_bike,
                            akfFields.number_of_employees,
                            akfFields.group_id,
                        ].map((field, i) => (_jsx(Fragment, { children: field && (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CustomField, Object.assign({}, field, { defaultCurrency: applicationCurrency })) }))) }, i))) })) }))] })) })));
};
export default CustomFieldsModal;
