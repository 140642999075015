import { EntityKind } from "../../Api";

export const mapDocEntityKindToApiPath = (entityKind: EntityKind) => {
	switch (entityKind) {
		case EntityKind.Application:
			return "applications";
		case EntityKind.Company:
		case EntityKind.GlobalCompany:
			return "companies";
		case EntityKind.Loan:
			return "loans";
	}
};
