import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Col, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import MainHeader from "@ui/main-header/MainHeader";
import TimePassed from "@ui/time-passed/TimePassed";
import { Routes } from "src/Routes";
import SearchList, { DIRECT_OPEN_COLUMN } from "src/components/SearchList";
import CompanyPreview from "./CompanyPreview";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
})(SearchField || (SearchField = {}));
const CompaniesPage = () => {
    const { t } = useTranslation();
    const [company, setCompany] = useState();
    const columns = [
        {
            render: (company) => (_jsx(Link, Object.assign({ to: generatePath(Routes.Company, {
                    companyId: company.company_id,
                }) }, { children: _jsx(SelectOutlined, {}) }))),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "company_name",
            title: t("application.company"),
        },
        {
            title: t("companies.address"),
            render(company) {
                return `${company.company_street} ${company.company_house_number}, ${company.company_location}, ${company.company_postcode}, ${company.company_country}`;
            },
        },
        {
            dataIndex: "number_of_applications",
            title: t("companies.application.number"),
        },
        {
            dataIndex: "created_at",
            title: t("application.created"),
            render(created_at) {
                return created_at ? _jsx(TimePassed, { date: created_at }) : "-";
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("companies.title") }), _jsx(SearchList, Object.assign({ endpoint: "/admin/companies", columns: columns, showingOfString: "companies.search.search-showing", onRow: (record) => {
                    return {
                        onClick() {
                            setCompany(record);
                        },
                    };
                }, rowKey: "company_id" }, { children: _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("companies.search.search_placeholder"), allowClear: true }) })) })) })), _jsx(CompanyPreview, { company: company, onClose: () => setCompany(undefined) })] }));
};
export default CompaniesPage;
