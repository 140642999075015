import { FC } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

import {
	FactoringInfoDebitors,
	FactoringInfoFinancials,
	FactoringInfoInvoices,
	FactoringInfoRiskProtection,
} from "@teylor-tools/Api";

import { useTranslations } from "../translations/translations";
import { InfoCard } from "./InformationCardComponents";
import { InformationCardDebitorsInfo } from "./InformationCardDebitorsInfo";
import { InformationCardFinancialsInfo } from "./InformationCardFinancialsInfo";
import { InformationCardInvoicesInfo } from "./InformationCardInvoicesInfo";
import { InformationCardRiskInfo } from "./InformationCardRiskInfo";
import { FactoryDetailsType } from "./types";

const { Text } = Typography;

interface InformationCardProps {
	details:
		| FactoringInfoFinancials
		| FactoringInfoInvoices
		| FactoringInfoDebitors
		| FactoringInfoRiskProtection;
	title: string;
	entity: FactoryDetailsType;
	hasInternationalCustomers: boolean;
	handleEditing: (value: FactoryDetailsType) => void;
}

const hasValue = <
	T extends
		| FactoringInfoFinancials
		| FactoringInfoInvoices
		| FactoringInfoDebitors
		| FactoringInfoRiskProtection,
>(
	details: T
): boolean => {
	return Object.values(details).some((value) => value !== undefined && value !== null);
};

export const InformationCard: FC<InformationCardProps> = ({
	details,
	title,
	entity,
	hasInternationalCustomers,
	handleEditing,
}) => {
	const t = useTranslations();
	const isValueProvided = hasValue(details);

	return (
		<InfoCard
			title={title}
			extra={
				isValueProvided && (
					<Button type="link" icon={<EditOutlined />} onClick={() => handleEditing(entity)}>
						{t.factoring.common.change}
					</Button>
				)
			}
		>
			{isValueProvided ? (
				<>
					{entity === FactoryDetailsType.financials && (
						<InformationCardFinancialsInfo
							info={details as FactoringInfoFinancials}
							hasInternationalCustomers={hasInternationalCustomers}
						/>
					)}
					{entity === FactoryDetailsType.invoices && (
						<InformationCardInvoicesInfo
							info={details as FactoringInfoInvoices}
							hasInternationalCustomers={hasInternationalCustomers}
						/>
					)}
					{entity === FactoryDetailsType.debitors && (
						<InformationCardDebitorsInfo
							info={details as FactoringInfoDebitors}
							hasInternationalCustomers={hasInternationalCustomers}
						/>
					)}
					{entity === FactoryDetailsType.risk && (
						<InformationCardRiskInfo
							info={details as FactoringInfoRiskProtection}
							hasInternationalCustomers={hasInternationalCustomers}
						/>
					)}
				</>
			) : (
				<Space direction="vertical" size={16} align="center" style={{ width: "100%" }}>
					<Text type="secondary">{t.factoring.common.no_information}</Text>
					<Button
						type="primary"
						onClick={() => handleEditing(entity)}
						data-cy={`add-${entity}-information-button`}
					>
						{t.factoring.add[entity]}
					</Button>
				</Space>
			)}
		</InfoCard>
	);
};
