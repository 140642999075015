var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, Drawer, Space, Tabs, Typography } from "antd";
import styled from "styled-components";
import WorkflowTransitionTab from "src/pages/settings/WorkflowConfigurator/WorkflowTransitionTab";
import { Axios } from "src/utils/Axios";
const Section = styled.div `
	margin: 24px;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorWarning};
`;
const WorkflowTransitionDrawer = ({ sourceNode, targetNode, productId, close, refetchStatusChangeConfig, }) => {
    const { t } = useTranslation();
    const { modal, message } = App.useApp();
    const sourceStatus = sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.value;
    const targetStatus = targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.value;
    const isOpen = useMemo(() => !!(sourceStatus && targetStatus), [sourceStatus, targetStatus]);
    const handleRemove = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!sourceStatus)
            return;
        // TODO: Need to make sure that when removing the transition backend is removing all constraints and events linked to this transition
        yield Axios.patch(`/admin/products/${productId}/status_change_config/${sourceNode.data.id}`, {
            status: sourceStatus,
            product_id: productId,
            possible_statuses: sourceNode.data.possibleStatuses.filter((status) => status !== targetStatus),
        }).then(() => {
            void refetchStatusChangeConfig();
            void message.success(t("workflow_configurator.transition_removed"));
            close();
        }, (err) => void Axios.error(err));
    });
    const showRemoveConfirmModal = () => {
        void modal.confirm({
            title: t("workflow_configurator.are_you_sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: (_jsx(Space, Object.assign({ direction: "vertical" }, { children: _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("workflow_configurator.remove_transition_warning") })) }))),
            okText: t("common.confirm"),
            cancelText: t("common.cancel"),
            onOk: handleRemove,
        });
    };
    return (_jsx(Drawer, Object.assign({ open: isOpen, title: t("workflow_configurator.transition_options", {
            from: sourceStatus,
            to: targetStatus,
        }), onClose: close, styles: { body: { padding: 0 }, footer: { padding: 24 } }, footer: _jsx(Button, Object.assign({ danger: true, onClick: showRemoveConfirmModal }, { children: t("workflow_configurator.remove_transition") })), destroyOnClose: true }, { children: _jsx(Section, { children: _jsx(Tabs, { defaultActiveKey: "constraints", items: [
                    {
                        label: t("workflow_configurator.constraints"),
                        key: "constraints",
                        children: (_jsx(WorkflowTransitionTab, { sourceStatus: sourceStatus, targetStatus: targetStatus, productId: productId, isDrawerOpen: isOpen, tabType: "constraints" })),
                    },
                    {
                        label: t("workflow_configurator.events"),
                        key: "events",
                        children: (_jsx(WorkflowTransitionTab, { sourceStatus: sourceStatus, targetStatus: targetStatus, productId: productId, isDrawerOpen: isOpen, tabType: "events" })),
                    },
                ] }) }) })));
};
export default WorkflowTransitionDrawer;
