var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Input, Popover, Radio, Row, Select, Space, Typography, } from "antd";
import { AllBanksLoanPurposes, ApplicationStatuses, FeatureName, LoanPurpose, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CityFormItem from "@ui/form/form-items/city-form-item/CityFormItem";
import CompanySelectFormItem from "@ui/form/form-items/company-select-form-item/CompanySelectFormItem";
import CountrySelectFormItem from "@ui/form/form-items/country-select-form-item/CountrySelectFormItem";
import HouseNumberFormItem from "@ui/form/form-items/house-number-form-item/HouseNumberFormItem";
import PostcodeFormItem from "@ui/form/form-items/postcode-form-item/PostcodeFormItem";
import StreetFormItem from "@ui/form/form-items/street-form-item/StreetFormItem";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
import { LOAN_AMOUNT, LOAN_DURATION, Steps, fieldNames, stepsArray } from "../constants";
import { FormContent, RejectedButtons, RejectedWrapper, StyledAvatar, WrappedButtons, } from "./StyledView";
const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
const { Text, Paragraph, Title } = Typography;
export const CreateApplicationView = ({ company, possibleLoanTypes }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [companyOptions, setCompanyOptions] = useState([]);
    const [form] = Form.useForm();
    const { currency } = useFormatter();
    const { features, countries, defaultCurrency } = useSelector((rootState) => rootState.configState);
    const [activeStep, setActiveStep] = useState(Steps.CompanyDetails);
    const [loanType, setLoanType] = useState();
    const [sending, setSending] = useState(false);
    const [rejected, setRejected] = useState(false);
    const defaultCountry = useMemo(() => { var _a; return (_a = countries[0]) === null || _a === void 0 ? void 0 : _a.code; }, [countries]);
    const purposeSelect = Form.useWatch("purposeSelect", form);
    const isFactoring = Form.useWatch("allBanksLoanPurpose", form) ===
        AllBanksLoanPurposes.Factoring;
    const requiredRule = {
        required: true,
        message: t("application.create-application.required-field"),
    };
    const companyTypes = useMemo(() => {
        var _a, _b;
        return (((_b = (_a = features
            .find((f) => f.name === FeatureName.CompanyTypes)) === null || _a === void 0 ? void 0 : _a.value.supported) === null || _b === void 0 ? void 0 : _b.map((companyType) => companyType)) || []);
    }, [features]);
    const loanPurposes = useMemo(() => {
        var _a;
        return (((_a = features.find((f) => f.name === FeatureName.LoanPurposes)) === null || _a === void 0 ? void 0 : _a.value.items) ||
            []);
    }, [features]);
    const handleFetchCompanies = (searchQuery) => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`admin/company_search?name=${encodeURIComponent(searchQuery)}`)
            .then(({ data }) => {
            const internalCompanies = (data.our_companies || []).map(({ company_id, company_name, company_country, credit_provider_company_id, company_street, company_house_number, company_postcode, company_location, company_type, business_purpose, }) => ({
                id: company_id,
                crefonummer: "",
                identnummer: credit_provider_company_id,
                name: company_name,
                handelsName: "",
                land: company_country,
                bundesland: "",
                plz: company_postcode || "",
                ort: company_location || "",
                strasseHausnummer: `${company_street} ${company_house_number}` || "",
                companyType: company_type || "",
                isInternal: true,
                businessPurpose: business_purpose,
            }));
            const externalCompanies = (data.external_companies || []).map((company) => (Object.assign(Object.assign({ id: company.identnummer }, company), { name: company.name || "", land: company.land, plz: company.plz || "", ort: company.ort || "", strasseHausnummer: company.strasseHausnummer || "", isInternal: false })));
            setCompanyOptions([...internalCompanies, ...externalCompanies]);
        })
            .catch((error) => Axios.error(error));
    });
    const createApplication = (values) => {
        setSending(true);
        const companyId = company !== undefined ? company.companyId : values.companyId;
        const newApplication = Object.assign(Object.assign(Object.assign(Object.assign({ companyName: values.companyName }, (companyId && { companyId })), { companyType: values.companyType, street: values.street, houseNumber: values.houseNumber, companyLocation: values.companyLocation, companyPostCode: values.companyPostCode, companyCountry: values.companyCountry, taxIdNumber: parseTin(values.taxIdNumber), businessPurpose: values.businessPurpose, allBanksLoanPurpose: values.allBanksLoanPurpose }), (isFactoring
            ? {
                annual_sales_previous_year: values.annualSales,
                has_international_customers: values.internationalCustomers,
            }
            : {
                loanSize: values.loanSize,
                loanDuration: values.loanDuration,
            })), { purposeDescription: values.purposeSelect && values.purposeSelect !== LoanPurpose.Other
                ? t(`application.purpose_option.${values.purposeSelect}`)
                : loanPurposes.length === 1 && !loanPurposes.includes(LoanPurpose.Other)
                    ? t(`application.purpose_option.${loanPurposes[0]}`)
                    : values.purposeDescription, creator: { onBehalfUserId: "" }, companyCreditProviderId: values.companyCreditProviderId });
        Axios.post("/admin/applications", newApplication)
            .then((resp) => {
            if ([ApplicationStatuses.Rejected].includes(resp.data.application.status)) {
                setRejected(true);
            }
            else {
                navigate(generatePath(Routes.Application, {
                    applicationId: resp.data.application.applicationId,
                }));
            }
        })
            .catch((error) => {
            var _a;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                setRejected(true);
            }
            else {
                void Axios.error(error);
            }
        })
            .finally(() => setSending(false));
    };
    const restartApplication = () => {
        form.resetFields();
        setActiveStep(Steps.CompanyDetails);
        setRejected(false);
    };
    const validateAmount = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanAmount = LOAN_AMOUNT.min[loanType];
        if (minLoanAmount && value < minLoanAmount) {
            return Promise.reject(`${t("application_form.errors.minFinancingAmount")}: ${currency(minLoanAmount, {
                showFractions: false,
            })}`);
        }
        const maxLoanAmount = LOAN_AMOUNT.max[loanType];
        if (maxLoanAmount && value > maxLoanAmount) {
            return Promise.reject(`${t("application_form.errors.maxFinancingAmount")}: ${currency(maxLoanAmount, {
                showFractions: false,
            })}`);
        }
        return Promise.resolve();
    };
    const validateLoanDuration = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanDuration = LOAN_DURATION.min[loanType];
        if (minLoanDuration && value < minLoanDuration) {
            return Promise.reject(t("application_form.errors.minLoanDuration", { min: minLoanDuration }));
        }
        const maxLoanDuration = LOAN_DURATION.max[loanType];
        if (maxLoanDuration && value > maxLoanDuration) {
            return Promise.reject(t("application_form.errors.maxLoanDuration", { max: maxLoanDuration }));
        }
        return Promise.resolve();
    };
    const onLoanTypeChange = (type) => {
        setLoanType(type);
        if (form.getFieldValue(fieldNames[Steps.LoanDetails].loanSize)) {
            void form.validateFields([fieldNames[Steps.LoanDetails].loanSize]);
        }
        if (form.getFieldValue(fieldNames[Steps.LoanDetails].loanDuration)) {
            void form.validateFields([fieldNames[Steps.LoanDetails].loanDuration]);
        }
        if (isFactoring) {
            form.setFieldsValue({
                [fieldNames[Steps.LoanDetails].loanSize]: 0,
                [fieldNames[Steps.LoanDetails].loanDuration]: 0,
                [fieldNames[Steps.LoanDetails].purposeSelect]: "",
                [fieldNames[Steps.LoanDetails].purposeDescription]: "",
            });
        }
        else {
            form.setFieldsValue({
                [fieldNames[Steps.LoanDetails].internationalCustomers]: undefined,
                [fieldNames[Steps.LoanDetails].annualSales]: undefined,
            });
        }
    };
    const goToStep = (newIndex) => {
        const isForward = newIndex > Object.values(Steps).indexOf(activeStep);
        const changeStep = () => setActiveStep(Object.values(Steps)[newIndex]);
        if (isForward) {
            form
                .validateFields()
                .then(() => {
                changeStep();
            })
                .catch((error) => {
                let errorsInCurrentStep = false;
                error.errorFields.forEach((errs) => {
                    if (Object.values(fieldNames[activeStep]).includes(errs.name[0])) {
                        errorsInCurrentStep = true;
                    }
                });
                if (!errorsInCurrentStep) {
                    changeStep();
                }
            });
        }
        else {
            changeStep();
        }
    };
    useEffect(() => {
        if (company !== undefined) {
            form.setFieldsValue({
                [fieldNames[Steps.CompanyDetails].companyName]: company.companyName,
                [fieldNames[Steps.CompanyDetails].companyType]: company.companyType,
                [fieldNames[Steps.CompanyDetails].street]: company.address_street,
                [fieldNames[Steps.CompanyDetails].houseNumber]: company.address_house_number,
                [fieldNames[Steps.CompanyDetails].companyLocation]: company.address_location,
                [fieldNames[Steps.CompanyDetails].companyPostCode]: company.address_post_code,
                [fieldNames[Steps.CompanyDetails].companyCountry]: company.address_country,
                [fieldNames[Steps.CompanyDetails].taxIdNumber]: company.taxIdNumber,
                [fieldNames[Steps.CompanyDetails].businessPurpose]: company.businessPurpose,
                [fieldNames[Steps.CompanyDetails].companyCreditProviderId]: company.companyCreditProviderId,
            });
            goToStep(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);
    const setCompanyData = (selectedCompany) => {
        const { companyId, street, houseNumber, companyPostCode, companyLocation, companyCountry, companyCreditProviderId, companyType, businessPurpose, isInternal, } = selectedCompany;
        form.setFieldsValue({
            [fieldNames[Steps.CompanyDetails].companyId]: companyId,
            [fieldNames[Steps.CompanyDetails].companyType]: companyType,
            [fieldNames[Steps.CompanyDetails].street]: street,
            [fieldNames[Steps.CompanyDetails].houseNumber]: houseNumber,
            [fieldNames[Steps.CompanyDetails].companyPostCode]: companyPostCode,
            [fieldNames[Steps.CompanyDetails].companyLocation]: companyLocation,
            [fieldNames[Steps.CompanyDetails].companyCountry]: companyCountry,
            [fieldNames[Steps.CompanyDetails].businessPurpose]: businessPurpose,
            [fieldNames[Steps.CompanyDetails].companyCreditProviderId]: companyCreditProviderId,
        });
        if (isInternal) {
            goToStep(1);
        }
    };
    const renderPanelHeader = (step) => {
        const index = Object.values(Steps).indexOf(step);
        const isDone = index < Object.values(Steps).indexOf(activeStep);
        return (_jsxs(Space, Object.assign({ size: 14 }, { children: [_jsx(StyledAvatar, Object.assign({ size: "small", "$active": step === activeStep, "$done": isDone }, { children: isDone ? _jsx(CheckOutlined, {}) : index + 1 })), _jsx(Text, { children: t(`application.create-application.${step}`) })] })));
    };
    const showLoanPurposeTextarea = purposeSelect === LoanPurpose.Other ||
        (loanPurposes.length === 1 && loanPurposes.includes(LoanPurpose.Other)) ||
        !loanPurposes.length;
    if (rejected)
        return (_jsxs(RejectedWrapper, Object.assign({ direction: "vertical", size: 20 }, { children: [_jsx(Title, Object.assign({ level: 4 }, { children: t("application.create-application.applicationRejectedHeader") })), _jsx(Paragraph, { children: t("application.create-application.applicationRejectedLine1") }), _jsx(Paragraph, { children: t("application.create-application.applicationRejectedLine2") }), _jsxs(RejectedButtons, Object.assign({ direction: "vertical", align: "center", size: 16 }, { children: [_jsx(Button, Object.assign({ type: "primary", size: "large", onClick: restartApplication }, { children: t("application.create-application.new-application") })), _jsx(Button, Object.assign({ size: "large", onClick: () => navigate(Routes.Applications) }, { children: t("application.create-application.back-to-list") }))] }))] })));
    return (_jsx(_Fragment, { children: _jsx(FormContent, { children: _jsx(Form, Object.assign({ form: form, layout: "vertical", onFinish: createApplication, initialValues: {
                    companyCountry: defaultCountry,
                } }, { children: _jsxs(Collapse, Object.assign({ accordion: true, activeKey: activeStep, onChange: (key) => {
                        !!key[0] && setActiveStep(key[0]);
                    } }, { children: [_jsxs(Panel, Object.assign({ header: renderPanelHeader(Steps.CompanyDetails), collapsible: stepsArray.indexOf(activeStep) >= stepsArray.indexOf(Steps.CompanyDetails)
                                ? undefined
                                : "disabled" }, { children: [_jsxs(_Fragment, { children: [_jsx(CompanySelectFormItem, { name: fieldNames[Steps.CompanyDetails].companyName, setCompanyData: setCompanyData, defaultCountry: defaultCountry, companies: companyOptions, handleFetch: handleFetchCompanies, clearCompanies: () => {
                                                setCompanyOptions([]);
                                            } }), _jsx(Form.Item, Object.assign({ name: "companyId", style: { display: "none" } }, { children: _jsx(Input, {}) })), _jsx(Form.Item, Object.assign({ name: "companyCreditProviderId", style: { display: "none" } }, { children: _jsx(Input, {}) })), _jsx(Form.Item, Object.assign({ name: fieldNames[Steps.CompanyDetails].companyType, label: t("application.create-application.legal-form"), rules: [requiredRule] }, { children: _jsx(Select, { children: companyTypes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`common.company_types.${key}`) }), key))) }) })), _jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(StreetFormItem, { name: fieldNames[Steps.CompanyDetails].street, required: true }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(HouseNumberFormItem, { name: fieldNames[Steps.CompanyDetails].houseNumber, required: true }) }))] })), _jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CityFormItem, { name: fieldNames[Steps.CompanyDetails].companyLocation, required: true }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(PostcodeFormItem, { name: fieldNames[Steps.CompanyDetails].companyPostCode, required: true }) }))] })), _jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CountrySelectFormItem, { label: t("application_form.country"), name: fieldNames[Steps.CompanyDetails].companyCountry, rules: [requiredRule], countries: countries }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(TinFormItem, { name: fieldNames[Steps.CompanyDetails].taxIdNumber, label: _jsx(Space, Object.assign({ size: 4 }, { children: _jsx(Text, Object.assign({ type: "secondary", style: { fontSize: 12 } }, { children: t("application.create-application.tin") })) })), inputProps: {
                                                            suffix: (_jsx(Popover, Object.assign({ content: t("application.create-application.tin-tooltip"), overlayClassName: "global-tooltip-overlay-style" }, { children: _jsx(InfoCircleOutlined, { className: "info-tooltip" }) }))),
                                                            placeholder: t("application.create-application.tin-placeholder"),
                                                        } }) }))] })), _jsx(Form.Item, Object.assign({ name: fieldNames[Steps.CompanyDetails].businessPurpose, label: t("application.create-application.company-purpose"), rules: [
                                                {
                                                    min: 5,
                                                    message: t("application_form.errors.fieldMustBeMinChars", {
                                                        min: 5,
                                                    }),
                                                },
                                                {
                                                    max: 2000000,
                                                    message: t("application_form.errors.fieldMustBeMaxChars", {
                                                        max: 2000000,
                                                    }),
                                                },
                                                requiredRule,
                                            ] }, { children: _jsx(TextArea, { rows: 6, placeholder: t("application.create-application.company-purpose-placeholder") }) }))] }), _jsx(WrappedButtons, { children: _jsx(Button, Object.assign({ type: "primary", onClick: () => goToStep(1), loading: sending, disabled: sending, style: { marginLeft: "auto" }, "data-cy": "next-button" }, { children: t("application.create-application.next") })) })] }), Steps.CompanyDetails), _jsxs(Panel, Object.assign({ header: renderPanelHeader(Steps.LoanDetails), collapsible: stepsArray.indexOf(activeStep) >= stepsArray.indexOf(Steps.LoanDetails)
                                ? undefined
                                : "disabled" }, { children: [_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].allBanksLoanPurpose, label: t("application_form.loan-type"), rules: [requiredRule] }, { children: _jsx(Select, Object.assign({ onChange: (key) => onLoanTypeChange(key) }, { children: possibleLoanTypes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`application.loan_purpose.${key}`) }), key))) })) })), isFactoring && (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].annualSales, label: t("factoring.debitor.annual_sales_previous_year"), required: true, rules: [
                                                        {
                                                            validator: (_, value) => {
                                                                return value && value > 0
                                                                    ? Promise.resolve()
                                                                    : Promise.reject(new Error(t("application.create-application.required-field")));
                                                            },
                                                        },
                                                    ] }, { children: _jsx(CurrencyInput, { currency: defaultCurrency, style: { width: "100%" } }) })), _jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].internationalCustomers, label: t("application.create-application.factoring.has_international_customers"), rules: [requiredRule], required: true }, { children: _jsxs(Radio.Group, { children: [_jsx(Radio, Object.assign({ value: true }, { children: t("common.yes") })), _jsx(Radio, Object.assign({ value: false }, { children: t("common.no") }))] }) }))] })), !isFactoring && (_jsxs(Row, Object.assign({ gutter: 24, style: { marginBottom: 12 } }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 0, style: { width: "100%" } }, { children: _jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].loanSize, label: t("application_form.loan-amount"), style: { margin: 0 }, rules: [
                                                                requiredRule,
                                                                {
                                                                    validator: validateAmount,
                                                                },
                                                            ] }, { children: _jsx(CurrencyInput, { style: { width: "100%" } }) })) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 0, style: { width: "100%" } }, { children: _jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].loanDuration, label: t("application_form.loan-duration"), style: { margin: 0 }, rules: [
                                                                {
                                                                    validator: validateLoanDuration,
                                                                },
                                                                requiredRule,
                                                            ] }, { children: _jsx(NumberInputLocalized, { style: { width: "100%" }, controls: false, addonAfter: t("common.months") }) })) })) }))] }))), !isFactoring && loanPurposes.length > 1 && (_jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].purposeSelect, label: t("application_form.loan-purpose"), rules: [requiredRule] }, { children: _jsx(Select, { children: loanPurposes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`application.purpose_option.${key}`) }), key))) }) }))), !isFactoring && showLoanPurposeTextarea && (_jsx(Form.Item, Object.assign({ name: fieldNames[Steps.LoanDetails].purposeDescription, label: loanPurposes.length > 1 ? undefined : t("application_form.loan-purpose"), rules: [
                                                {
                                                    required: true,
                                                    min: 30,
                                                    message: t("application_form.errors.fieldMustBeMinChars", { min: 30 }),
                                                },
                                            ] }, { children: _jsx(TextArea, { maxLength: 700, showCount: true, rows: 6, placeholder: t("application_form.loan-purpose-placeholder") }) })))] }), _jsxs(WrappedButtons, { children: [_jsx(Button, Object.assign({ onClick: () => goToStep(0) }, { children: t("application.create-application.back") })), _jsx(Button, Object.assign({ type: "primary", onClick: () => form.submit(), loading: sending, disabled: sending, "data-cy": "next-button" }, { children: t("application.create-application.create") }))] })] }), Steps.LoanDetails)] })) })) }) }));
};
