import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { App, Dropdown, Modal, Table, Tag, theme } from "antd";
import { InstallmentStatuses } from "@teylor-tools/Api";
import { Currency } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterDescriptiveMonthDayYear } from "@teylor-tools/utils/dateFormats";
import { Axios } from "src/utils/Axios";
export const getTagColor = (status) => {
    switch (status) {
        case InstallmentStatuses.Canceled:
            return "gold";
        case InstallmentStatuses.Error:
            return "red";
        case InstallmentStatuses.Paid:
            return "green";
        case InstallmentStatuses.PaymentPending:
            return "blue";
        case InstallmentStatuses.Scheduled:
            return "purple";
        default:
            return "default";
    }
};
const PaymentHistoryTable = ({ paymentHistory }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { currency, date } = useFormatter();
    const { token } = theme.useToken();
    const columns = [
        {
            title: t("loans.overview.due-date"),
            dataIndex: "due_date",
            key: "due_date",
            render: (_, { due_date }) => {
                return date(due_date, formatterDescriptiveMonthDayYear);
            },
        },
        { title: t("loans.overview.amount"), dataIndex: "amount", key: "amount" },
        {
            title: t("loans.overview.status"),
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => {
                return (_jsx(Tag, Object.assign({ color: getTagColor(status) }, { children: t(`loans.payment_status.${status}`) }), status));
            },
        },
        {
            dataIndex: "installment_id",
            key: "installment_id",
            render: (_, { installment_id, status }) => {
                return (_jsx(Dropdown, Object.assign({ menu: {
                        items: [
                            {
                                key: "reconcile",
                                label: t("loans.overview.reconcile-payment"),
                                onClick: () => {
                                    Axios.post(`/admin/installments/${installment_id}/reconcile_payments`).then(() => {
                                        void message.success(t("loans.overview.payment-reconciled"));
                                    }, (error) => {
                                        void Axios.error(error);
                                    });
                                },
                            },
                            {
                                key: "reverse",
                                label: t("loans.overview.reverse-payment"),
                                disabled: status !== InstallmentStatuses.Paid,
                                onClick: () => {
                                    Modal.confirm({
                                        icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }),
                                        title: t("loans.overview.reverse-payment"),
                                        content: t("loans.overview.reverse-payment-modal.content"),
                                        centered: true,
                                        cancelText: t("common.cancel"),
                                        okText: t("loans.overview.reverse-payment"),
                                        okButtonProps: { style: { backgroundColor: token.colorError } },
                                        maskClosable: true,
                                        onOk: () => {
                                            Axios.post(`/admin/installments/${installment_id}/reverse_payments`).then(() => {
                                                void message.success(t("loans.overview.payment-reversed"));
                                            }, (error) => {
                                                void Axios.error(error);
                                            });
                                        },
                                    });
                                },
                            },
                        ],
                    } }, { children: _jsx(MoreOutlined, {}) })));
            },
        },
    ];
    const data = useMemo(() => paymentHistory.map(({ due_date, status, amount, currency: installmentCurrency, installment_id }) => ({
        installment_id,
        due_date,
        status,
        amount: amount
            ? currency(amount, {
                currency: installmentCurrency || Currency.EUR,
            })
            : "",
        key: installment_id,
    })), [paymentHistory, currency]);
    return _jsx(Table, { columns: columns, dataSource: data, pagination: false });
};
export default PaymentHistoryTable;
