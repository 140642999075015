var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsAltOutlined, CheckOutlined, PlusOutlined, ShrinkOutlined } from "@ant-design/icons";
import { DndContext, closestCenter, getFirstCollision, pointerWithin, rectIntersection, } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { App, Button, Flex, Form } from "antd";
import styled from "styled-components";
import { ChecklistItemTemplateItemType, } from "@teylor-tools/Api";
import ChecklistGroup from "./checklist-configurator/checklist-items/ChecklistGroup";
import ChecklistGroupItem from "./checklist-configurator/checklist-items/ChecklistGroupItem";
import { displayTemplateErrors } from "./checklist-configurator/display-template-errors";
import { mapFormToTemplate } from "./checklist-configurator/map-form-to-template";
import { mapTemplateToDndStruct } from "./checklist-configurator/map-template-to-dnd-struct";
import { mapTemplateToForm } from "./checklist-configurator/map-template-to-form";
import { filterObject } from "./checklist-configurator/utils/filter-object";
import { generateId } from "./checklist-configurator/utils/generate-id";
const Wrapper = styled.div `
	margin: 24px;
	display: grid;
	gap: 24px;
	max-width: 1464px;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const newChecklist = [
    {
        id: generateId(),
        description: "",
        items: [
            {
                item_type: ChecklistItemTemplateItemType.Checkbox,
                description: "",
                id: generateId(),
                options: [
                    {
                        id: generateId(),
                        description: "",
                        selected: false,
                    },
                ],
            },
        ],
    },
];
const ChecklistConfiguratorTab = forwardRef(({ checklistTemplate = newChecklist }, ref) => {
    const { t } = useTranslation();
    const { modal, message } = App.useApp();
    const [form] = Form.useForm();
    const [items, setItems] = useState(mapTemplateToDndStruct(checklistTemplate));
    const [groups, setGroups] = useState(Object.keys(items));
    const [activeId, setActiveId] = useState(null);
    const recentlyMovedToNewContainer = useRef(false);
    const lastOverId = useRef(null);
    const [dndContextState, setDndContextState] = useState(0);
    const [collapsedGroups, setCollapsedGroups] = useState([]);
    const [collapsedItems, setCollapsedItems] = useState([]);
    const isGroupDragging = !!(activeId && Object.keys(items).includes(activeId));
    const isItemDragging = !!(activeId && !isGroupDragging);
    const allGroupsCollapsed = groups.length === collapsedGroups.length;
    const allItemsCollapsed = Object.keys(items).reduce((acc, group) => (acc += items[group].length), 0) ===
        collapsedItems.length;
    useImperativeHandle(ref, () => ({
        getTemplate: getTemplate,
    }));
    const getTemplateErrors = () => __awaiter(void 0, void 0, void 0, function* () {
        const errors = [];
        const addError = (msg) => errors.push(new Error(msg));
        yield form
            .validateFields()
            .catch(() => addError(t("settings.checklists.errors.config.required-fields")));
        if (!groups.length)
            addError(t("settings.checklists.errors.config.min-groups"));
        groups.forEach((group) => {
            if (!items[group].length)
                addError(t("settings.checklists.errors.config.min-items"));
        });
        Object.values(form.getFieldsValue()).forEach((item) => {
            var _a;
            if ("itemType" in item && item.itemType === "select") {
                if (!((_a = item.options) === null || _a === void 0 ? void 0 : _a.length) || item.options.length < 2)
                    addError(t("settings.checklists.errors.config.min-options"));
            }
        });
        return Promise.resolve(errors);
    });
    const getTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        const templateErrors = yield getTemplateErrors();
        return templateErrors.length
            ? Promise.reject(templateErrors)
            : mapFormToTemplate(form.getFieldsValue(), items);
    });
    const findContainer = (id) => {
        if (id in items) {
            return id;
        }
        return Object.keys(items).find((key) => items[key].includes(id));
    };
    const collisionDetectionStrategy = useCallback((args) => {
        var _a;
        if (activeId && activeId in items) {
            return closestCenter(Object.assign(Object.assign({}, args), { droppableContainers: args.droppableContainers.filter((container) => container.id in items) }));
        }
        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        const intersections = pointerIntersections.length > 0
            ? // If there are droppables intersecting with the pointer, return those
                pointerIntersections
            : rectIntersection(args);
        let overId = getFirstCollision(intersections, "id");
        if (overId != null) {
            if (overId in items) {
                const containerItems = items[overId];
                // If a container is matched and it contains items (columns 'A', 'B', 'C')
                if (containerItems.length > 0) {
                    // Return the closest droppable within that container
                    overId = (_a = closestCenter(Object.assign(Object.assign({}, args), { droppableContainers: args.droppableContainers.filter((container) => container.id !== overId && containerItems.includes(container.id)) }))[0]) === null || _a === void 0 ? void 0 : _a.id;
                }
            }
            lastOverId.current = overId;
            return [{ id: overId }];
        }
        // When a draggable item moves to a new container, the layout may shift
        // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // to the id of the draggable item that was moved to the new container, otherwise
        // the previous `overId` will be returned which can cause items to incorrectly shift positions
        if (recentlyMovedToNewContainer.current) {
            lastOverId.current = activeId;
        }
        // If no droppable is matched, return the last match
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
    }, [activeId, items]);
    const onDragOver = ({ active, over }) => {
        const activeId = active.id;
        const overId = over === null || over === void 0 ? void 0 : over.id;
        if (overId == null || activeId in items) {
            return;
        }
        const overContainer = findContainer(overId);
        const activeContainer = findContainer(activeId);
        if (!overContainer || !activeContainer) {
            return;
        }
        if (activeContainer !== overContainer) {
            setItems((items) => {
                const activeItems = items[activeContainer];
                const overItems = items[overContainer];
                const overIndex = overItems.indexOf(overId);
                const activeIndex = activeItems.indexOf(activeId);
                let newIndex;
                if (overId in items) {
                    newIndex = overItems.length + 1;
                }
                else {
                    const isBelowOverItem = over &&
                        active.rect.current.translated &&
                        active.rect.current.translated.top > over.rect.top + over.rect.height;
                    const modifier = isBelowOverItem ? 1 : 0;
                    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
                }
                recentlyMovedToNewContainer.current = true;
                return Object.assign(Object.assign({}, items), { [activeContainer]: items[activeContainer].filter((item) => item !== activeId), [overContainer]: [
                        ...items[overContainer].slice(0, newIndex),
                        items[activeContainer][activeIndex],
                        ...items[overContainer].slice(newIndex, items[overContainer].length),
                    ] });
            });
        }
    };
    const onDragEnd = ({ active, over }) => {
        const activeId = active.id;
        const overId = over === null || over === void 0 ? void 0 : over.id;
        if (activeId in items && overId) {
            setGroups((containers) => {
                const activeIndex = containers.indexOf(activeId);
                const overIndex = containers.indexOf(overId);
                return arrayMove(containers, activeIndex, overIndex);
            });
        }
        const activeContainer = findContainer(activeId);
        if (!activeContainer) {
            setActiveId(null);
            return;
        }
        if (overId == null) {
            setActiveId(null);
            return;
        }
        const overContainer = findContainer(overId);
        if (overContainer) {
            const activeIndex = items[activeContainer].indexOf(activeId);
            const overIndex = items[overContainer].indexOf(overId);
            if (activeIndex !== overIndex) {
                setItems((items) => (Object.assign(Object.assign({}, items), { [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex) })));
            }
        }
        setActiveId(null);
    };
    const handleRemove = ({ groupId, itemId, }) => {
        void modal.confirm({
            title: t("settings.checklists.config.delete-modal.title"),
            content: t("settings.checklists.config.delete-modal.content"),
            onOk: itemId ? () => removeItem(groupId, itemId) : () => removeGroup(groupId),
        });
    };
    const removeGroup = (groupId) => {
        const idsToDelete = [groupId, ...items[groupId]];
        form.setFieldsValue(filterObject(form.getFieldsValue(), ([k]) => !idsToDelete.includes(k)));
        setItems(filterObject(items, ([k]) => k !== groupId));
        setGroups((prev) => prev.filter((id) => id !== groupId));
    };
    const removeItem = (groupId, itemId) => {
        form.setFieldsValue(filterObject(form.getFieldsValue(), ([k]) => k !== itemId));
        setItems((prev) => {
            const copy = Object.assign({}, prev);
            copy[groupId] = copy[groupId].filter((item) => item !== itemId);
            return copy;
        });
    };
    const handleAddGroup = () => {
        const newGroupId = generateId();
        const newGroup = { desc: "" };
        const newCheckboxId = generateId();
        const newCheckbox = {
            itemType: ChecklistItemTemplateItemType.Checkbox,
            desc: "",
        };
        form.setFieldsValue(Object.assign(Object.assign({}, form.getFieldsValue()), { [newGroupId]: newGroup, [newCheckboxId]: newCheckbox }));
        setItems((prev) => (Object.assign(Object.assign({}, prev), { [newGroupId]: [newCheckboxId] })));
        setGroups((prev) => [...prev, newGroupId]);
    };
    const handleAddItem = (groupId) => {
        const newCheckboxId = generateId();
        const newCheckbox = {
            itemType: ChecklistItemTemplateItemType.Checkbox,
            desc: "",
        };
        form.setFieldsValue(Object.assign(Object.assign({}, form.getFieldsValue()), { [newCheckboxId]: newCheckbox }));
        setItems((prev) => {
            const newItems = Object.assign({}, prev);
            newItems[groupId].push(newCheckboxId);
            return newItems;
        });
        // sorry for that! :( without it dragging does not work on newly added item
        setDndContextState((prev) => prev + 1);
    };
    const handleCollapseGroups = () => setCollapsedGroups(allGroupsCollapsed ? [] : groups);
    const handleCollapseGroup = (groupId) => setCollapsedGroups((prev) => [...prev, groupId]);
    const handleExpandGroup = (groupId) => setCollapsedGroups((prev) => prev.filter((id) => id !== groupId));
    const handleCollapseItems = () => setCollapsedItems(allItemsCollapsed
        ? []
        : Object.keys(items).reduce((acc, group) => {
            acc.push(...items[group]);
            return acc;
        }, []));
    const handleCollapseItem = (itemId) => setCollapsedItems((prev) => [...prev, itemId]);
    const handleExpandItem = (itemId) => setCollapsedItems((prev) => prev.filter((id) => id !== itemId));
    const handleValidateTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        const templateErrors = yield getTemplateErrors();
        templateErrors.length
            ? displayTemplateErrors(templateErrors, message)
            : void message.success(t("settings.checklists.config.config-valid"));
    });
    return (_jsxs(Wrapper, { children: [_jsx(DndContext, Object.assign({ modifiers: [restrictToVerticalAxis], onDragStart: ({ active }) => setActiveId(active.id), onDragOver: onDragOver, onDragEnd: onDragEnd, collisionDetection: collisionDetectionStrategy }, { children: _jsxs(Form, Object.assign({ form: form, initialValues: mapTemplateToForm(checklistTemplate) }, { children: [_jsxs(SortableContext, Object.assign({ items: groups, strategy: verticalListSortingStrategy }, { children: [!!groups.length && (_jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ gap: "small" }, { children: [_jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: handleCollapseItems, icon: allItemsCollapsed ? _jsx(ArrowsAltOutlined, {}) : _jsx(ShrinkOutlined, {}) }, { children: allItemsCollapsed
                                                        ? t("settings.checklists.config.expand-items")
                                                        : t("settings.checklists.config.collapse-items") })), _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: handleCollapseGroups, icon: allGroupsCollapsed ? _jsx(ArrowsAltOutlined, {}) : _jsx(ShrinkOutlined, {}) }, { children: allGroupsCollapsed
                                                        ? t("settings.checklists.config.expand-groups")
                                                        : t("settings.checklists.config.collapse-groups") }))] })), _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: () => void handleValidateTemplate(), icon: _jsx(CheckOutlined, {}) }, { children: t("settings.checklists.config.validate-btn") }))] }))), groups.map((groupId) => (_jsx(ChecklistGroup, Object.assign({ id: groupId, items: items[groupId], remove: () => handleRemove({ groupId }), collapsed: isGroupDragging || collapsedGroups.includes(groupId), onExpand: handleExpandGroup, onCollapse: handleCollapseGroup }, { children: _jsxs(_Fragment, { children: [_jsx(SortableContext, Object.assign({ items: items[groupId], strategy: verticalListSortingStrategy }, { children: items[groupId].map((itemId) => (_jsx(ChecklistGroupItem, { id: itemId, remove: () => handleRemove({ groupId, itemId }), collapsed: isItemDragging || collapsedItems.includes(itemId), onExpand: handleExpandItem, onCollapse: handleCollapseItem }, itemId))) })), _jsx(Flex, Object.assign({ justify: "flex-end" }, { children: _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: () => handleAddItem(groupId), icon: _jsx(PlusOutlined, {}) }, { children: t("settings.checklists.config.add-item-btn") })) }))] }) }), groupId)))] })), _jsx(Flex, Object.assign({ justify: "flex-end" }, { children: _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: handleAddGroup, icon: _jsx(PlusOutlined, {}) }, { children: t("settings.checklists.config.add-group-btn") })) }))] })) }), dndContextState), _jsx("div", {})] }));
});
ChecklistConfiguratorTab.displayName = "ChecklistConfiguratorTab";
export default ChecklistConfiguratorTab;
