var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { App, Checkbox, Space, Spin, Typography } from "antd";
import { Axios } from "src/utils/Axios";
const constraintsList = [
    "all_required_documents_uploaded",
    "application_external_pricing_specified",
    "application_pricing_specified",
    "bank_name_iban_valid",
    "checklist_application_positive",
    "checklist_four_eyes_done",
    "checklist_payout_done",
    "company_industry_codes_allowed",
    "company_tin_specified",
    "default_partner_terms_accepted",
    "finance_objects_added",
    "financials_complete",
    "guarantor_contract_signatories",
    "guarantor_contract_uploaded",
    "language_supported",
    "legal_signature_accepted",
    "loan_contract_exist",
    "pep_check_document",
    "prod_user_not_teylor_employee",
    "repayment_plan_generated",
    "representatives_data_completed",
    "score_mark_ge_threshold",
    "score_red_flags_count_low",
    "score_warnings_count_low",
    "scoring_feedback_done",
    "sepa_mandate_accepted",
    "signatories_ident_completed",
    "signatories_ident_successful",
    "signatories_ident_url_specified",
    "signatories_minimum_data_completed",
    "signatories_shares_le_100",
    "signatories_sign_loan_contract",
    "signatories_sole_or_atleast_2",
    "signatories_specified",
    "signatories_taxid_specified",
    "signatories_ubo_contract_url_specified",
];
const eventsList = [
    "calculate_final_pricing",
    "calculate_minimum_interest_rate",
    "checklist_4eye_generate",
    "checklist_application_generate",
    "checklist_payout_generate",
    "choose_random_destination_product",
    "clear_external_pricing",
    "clear_ident",
    "clear_pricing",
    "document_offer_file_generate",
    "document_offer_notice_generate",
    "document_payment_confirmation_generate",
    "document_payment_plan_generate",
    "loan_contract_generate",
    "loan_create",
    "loan_open",
    "payment_provider_mangopay_connect",
    "recalculate_pricing",
    "report_analyst_generate",
    "report_gtc_generate",
    "report_tin_generate",
    "request_signatories_idents",
    "send_to_ing",
    "signatories_guarantor_contracts_generate",
];
const WorkflowTransitionTab = ({ sourceStatus, targetStatus, productId, isDrawerOpen, tabType, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const itemsList = useMemo(() => (tabType === "constraints" ? constraintsList : eventsList), [tabType]);
    const getItems = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const saveItems = (items) => setItems(items.filter(({ status_from, status_to }) => sourceStatus === status_from && targetStatus === status_to));
        if (tabType === "constraints") {
            yield Axios.get(`/admin/products/${productId}/status_change_constraints`).then(({ data }) => data && saveItems(data), (err) => void Axios.error(err));
        }
        if (tabType === "events") {
            yield Axios.get(`/admin/products/${productId}/status_change_events`).then(({ data }) => data && saveItems(data), (err) => void Axios.error(err));
        }
    }), [productId, setItems, sourceStatus, targetStatus, tabType]);
    const createItem = (action) => __awaiter(void 0, void 0, void 0, function* () {
        const payload = {
            status_from: sourceStatus,
            status_to: targetStatus,
            action,
        };
        setLoading(true);
        if (tabType === "constraints") {
            yield Axios.post(`/admin/products/${productId}/status_change_constraints`, payload).then(() => {
                void getItems();
                void message.success(t("workflow_configurator.constraint_added"));
            }, (err) => void Axios.error(err));
        }
        if (tabType === "events") {
            yield Axios.post(`/admin/products/${productId}/status_change_events`, payload).then(() => {
                void getItems();
                void message.success(t("workflow_configurator.event_added"));
            }, (err) => void Axios.error(err));
        }
        setLoading(false);
    });
    const deleteItem = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        if (tabType === "constraints") {
            yield Axios.delete(`/admin/products/${productId}/status_change_constraints/${id}`).then(() => {
                void getItems();
                void message.success(t("workflow_configurator.constraint_removed"));
            }, (err) => void Axios.error(err));
        }
        if (tabType === "events") {
            yield Axios.delete(`/admin/products/${productId}/status_change_events/${id}`).then(() => {
                void getItems();
                void message.success(t("workflow_configurator.event_removed"));
            }, (err) => void Axios.error(err));
        }
        setLoading(false);
    });
    const handleCheckboxChange = (e, constraint) => {
        if (e.target.checked) {
            return createItem(e.target.value);
        }
        const item = items.find((item) => item.action === constraint);
        const itemId = tabType === "constraints"
            ? item === null || item === void 0 ? void 0 : item.status_change_constraint_id
            : item === null || item === void 0 ? void 0 : item.status_change_event_id;
        itemId && void deleteItem(itemId);
    };
    useEffect(() => {
        if (!isDrawerOpen)
            return;
        setLoading(true);
        void getItems().finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDrawerOpen]);
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: "large" }, { children: [_jsx(Typography.Text, Object.assign({ strong: true }, { children: tabType === "constraints"
                    ? t("workflow_configurator.application_change_constraints")
                    : t("workflow_configurator.application_change_events") })), _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(Space, Object.assign({ direction: "vertical" }, { children: itemsList.map((constraint) => {
                        return (_jsx(Checkbox, Object.assign({ value: constraint, checked: items.some((c) => c.action === constraint), onChange: (e) => void handleCheckboxChange(e, constraint) }, { children: constraint }), constraint));
                    }) })) }))] })));
};
export default WorkflowTransitionTab;
