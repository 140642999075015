import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CloseCircleOutlined, EuroOutlined } from "@ant-design/icons";
import { Alert, App, Button, Popconfirm, Row, Space, Spin, Tabs, Typography, } from "antd";
import styled from "styled-components";
import { ConcentrationLimitsCalculationBase, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterFullDateTime } from "@teylor-tools/utils/dateFormats";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import MainHeader from "@ui/main-header/MainHeader";
import ConcentrationLimitsDrawer from "src/pages/portfolio/ConcentrationLimitsDrawer";
import ConcentrationLimitsSessionApplications from "src/pages/portfolio/ConcentrationLimitsSessionApplications";
import ConcentrationLimitsTable from "src/pages/portfolio/ConcentrationLimitsTable";
import { Axios } from "src/utils/Axios";
const { Text, Title } = Typography;
const MAX_WIDTH = 1464;
export const CloseCircleIcon = styled(CloseCircleOutlined) `
	color: ${(props) => props.theme.colorFillSecondary};
	font-size: 112px;
	margin-bottom: 24px;
`;
const ErrorWrapper = styled.div `
	text-align: center;
	margin: 100px;
	max-width: 500px;
`;
const ContentWrapper = styled.div `
	padding: 16px 24px;
	max-width: ${MAX_WIDTH}px;
`;
const LOCAL_STORAGE_KEY = "analysis_session";
const PortfolioConcentrationLimits = () => {
    var _a;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [concentrationLimitsResult, setConcentrationLimitsResult] = useState();
    const [showDrawer, setShowDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const { currency, date } = useFormatter();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const [showError, setShowError] = useState(false);
    const isSessionInProgress = !!(concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.analysis_session_mode);
    const tabsItems = [
        {
            key: "general",
            label: t("portfolio.concentration-limits.general"),
            children: (_jsx(ConcentrationLimitsTable, { type: "general", data: concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.checks.general, isSessionInProgress: isSessionInProgress })),
        },
        {
            key: "level-1-nace",
            label: t("portfolio.concentration-limits.level-1-nace"),
            children: (_jsx(ConcentrationLimitsTable, { type: "nace", data: concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.checks.nace_level_1, isSessionInProgress: isSessionInProgress })),
        },
        {
            key: "level-2-nace",
            label: t("portfolio.concentration-limits.level-2-nace"),
            children: (_jsx(ConcentrationLimitsTable, { type: "nace", data: concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.checks.nace_level_2, isSessionInProgress: isSessionInProgress })),
        },
    ];
    const startSession = ({ applications, poolType = (concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.calculation_base_type) ||
        ConcentrationLimitsCalculationBase.Default, poolAmount = Number(concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.calculation_base_amount), isNewSession = false, }) => {
        setLoading(true);
        return Axios.post("admin/concentration_limits/calculate", {
            application_data: applications,
            calculation_base_type: poolType,
            calculation_base_amount: poolType === ConcentrationLimitsCalculationBase.Custom && isValidNumericValue(poolAmount)
                ? poolAmount.toString()
                : undefined,
        })
            .then(({ data }) => {
            const sessionToSave = {
                applications,
                poolType,
                poolAmount,
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sessionToSave));
            setConcentrationLimitsResult(data);
            !isNewSession &&
                void message.success(t("portfolio.concentration-limits.session-updated-successfully"));
        }, (err) => {
            void Axios.error(err, t("portfolio.concentration-limits.session-error"));
            clearSession();
        })
            .finally(() => setLoading(false));
    };
    const clearSession = () => {
        getConcentrationLimitChecks();
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };
    const getConcentrationLimitChecks = () => {
        setLoading(true);
        Axios.get("admin/concentration_limits/result")
            .then(({ data }) => setConcentrationLimitsResult(data), (err) => {
            void Axios.error(err);
            setShowError(true);
        })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        const savedSession = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!savedSession)
            return getConcentrationLimitChecks();
        const parsedSession = JSON.parse(savedSession);
        const { applications, poolType, poolAmount } = parsedSession;
        void startSession({ applications, poolType, poolAmount, isNewSession: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (showError) {
        return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("portfolio.concentration-limits") }), _jsxs(ErrorWrapper, { children: [_jsx(CloseCircleIcon, {}), _jsx(Title, Object.assign({ level: 3 }, { children: t("common.request-failed") }))] })] }));
    }
    return (_jsxs(Spin, Object.assign({ spinning: loading }, { children: [_jsx(MainHeader, { title: _jsxs(_Fragment, { children: [t("portfolio.concentration-limits"), (concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.created_at) && (_jsxs(Text, Object.assign({ type: "secondary", style: { marginLeft: 16 } }, { children: [t("portfolio.concentration-limits.last-updated"), ":", " ", date(concentrationLimitsResult.created_at, Object.assign(Object.assign({}, formatterFullDateTime), { timeZone: "UTC" }))] })))] }), extra: _jsxs(_Fragment, { children: [isSessionInProgress && (_jsx(Popconfirm, Object.assign({ title: t("portfolio.concentration-limits.clear-btn-confirm"), okText: t("portfolio.concentration-limits.clear-btn-confirm-ok"), cancelText: t("common.cancel"), onConfirm: clearSession }, { children: _jsx(Button, Object.assign({ type: "link" }, { children: t("portfolio.concentration-limits.clear-btn") })) }))), _jsx(Button, Object.assign({ type: "default", onClick: () => setShowDrawer(true) }, { children: t("portfolio.concentration-limits.analysis-session-btn") }))] }) }), _jsx(ContentWrapper, { children: _jsxs(Space, Object.assign({ size: "middle", direction: "vertical" }, { children: [isSessionInProgress && (_jsx(Alert, { type: "warning", showIcon: true, message: t("portfolio.concentration-limits.analysis-session-in-progress-alert") })), _jsxs(Row, { children: [_jsxs(Text, Object.assign({ type: "secondary", style: { width: 150 } }, { children: [_jsx(EuroOutlined, { style: { marginRight: 4 } }), t("portfolio.concentration-limits.pool-size")] })), _jsx(Text, { children: (concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.pool_size)
                                        ? currency(concentrationLimitsResult.pool_size, {
                                            currency: defaultCurrency,
                                            showFractions: false,
                                        })
                                        : "-" })] }), isSessionInProgress && !!((_a = concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.applications) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ConcentrationLimitsSessionApplications, { applications: concentrationLimitsResult === null || concentrationLimitsResult === void 0 ? void 0 : concentrationLimitsResult.applications, startSession: (applications) => void startSession({ applications }) })), _jsx(Tabs, { items: tabsItems })] })) }), _jsx(ConcentrationLimitsDrawer, { isOpen: showDrawer, close: () => setShowDrawer(false), startSession: (applications, poolType, poolAmount) => startSession({ applications, poolType, poolAmount, isNewSession: true }) })] })));
};
export default PortfolioConcentrationLimits;
