import { FC, useEffect, useState } from "react";

import { Tooltip } from "antd";
import dayjs, { OpUnitType } from "dayjs";

import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../translations/translations";

interface Props {
	date: string;
}

const dateSettings: Intl.DateTimeFormatOptions = {
	month: "2-digit",
	day: "2-digit",
	year: "numeric",
};

let timer: ReturnType<typeof setInterval>;
const TimePassed: FC<Props> = ({ date }: Props) => {
	const t = useTranslations();
	const formatter = useFormatter();

	const stopTicking = () => clearInterval(timer);

	const pickString = (): string => {
		const pickDiff = (mesaurement: OpUnitType) => dayjs(new Date()).diff(date, mesaurement);
		const weeks = pickDiff("weeks");
		const days = pickDiff("days");
		const hours = pickDiff("hours");
		const minutes = pickDiff("minutes");

		if (weeks > 4) {
			// render basic date
			stopTicking();
			return formatter.date(date, dateSettings);
		} else if (weeks <= 4 && days >= 7) {
			return t.timePassed.weeksAgo(weeks);
		} else if (days < 7 && days > 0) {
			return t.timePassed.daysAgo(days);
		} else if (days < 1 && hours > 0) {
			return t.timePassed.hoursAgo(hours);
		} else if (hours < 1 && minutes > 0) {
			return t.timePassed.minutesAgo(minutes);
		} else if (isNaN(weeks)) {
			stopTicking();
			return "-";
		} else {
			return t.timePassed.momentsAgo;
		}
	};

	const [displayDate, setDisplayDate] = useState(pickString());

	const setDate = () => {
		setDisplayDate(pickString());
	};

	useEffect(() => {
		timer = setInterval(setDate, 1000);
		return () => stopTicking();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tooltip placement="top" title={formatter.date(date, dateSettings)}>
			{displayDate}
		</Tooltip>
	);
};

export default TimePassed;
