import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { AccountManagerType } from "@teylor-tools/Api";
import Avatar from "@ui/data-display/Avatar";
import { useBoGroupUsers } from "../../hooks/useBoGroupUsers";
import UserSelect from "../form/UserSelect";
import FieldEditButtons from "./FieldEditButtons";
const { Text } = Typography;
const UNASSIGNED_ID = "unassigned";
const PersonFieldLabel = styled(Text) `
	display: flex;
	align-items: center;
	font-size: 12px;
	height: 32px;
`;
const AssigneeWrapper = styled.div `
	display: flex;
	align-items: center;
	gap: 8px;
	height: 30px;
`;
const EditableUserSelect = ({ userGroupId, extraUsers = [], userId = null, label, onChange, withUnassignOption = true, editable = true, }) => {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const { users, usersLoading } = useBoGroupUsers(userGroupId);
    const resetSelectedUserId = () => userId || (withUnassignOption ? UNASSIGNED_ID : null);
    const [selectedUserId, setSelectedUserId] = useState(resetSelectedUserId());
    const isEditable = editable && onChange;
    const allUsers = useMemo(() => [
        ...(withUnassignOption
            ? [{ name: t("application.preview.unassigned"), id: UNASSIGNED_ID }]
            : []),
        ...extraUsers.filter((extraUser) => !users.map((u) => u.id).includes(extraUser.id)),
        ...users,
    ], [withUnassignOption, extraUsers, users, t]);
    const user = allUsers.find((user) => user.id === userId) ||
        (withUnassignOption ? allUsers.find((user) => user.id === UNASSIGNED_ID) : undefined);
    const handleOnChange = (id) => {
        if (id)
            return setSelectedUserId(id);
        setSelectedUserId(withUnassignOption ? UNASSIGNED_ID : null);
    };
    const handleSave = () => {
        if (!onChange)
            return;
        if (selectedUserId === UNASSIGNED_ID && withUnassignOption) {
            onChange(null);
        }
        else {
            onChange(selectedUserId);
        }
        setEditMode(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(PersonFieldLabel, Object.assign({ type: "secondary" }, { children: [label, isEditable && (_jsx(FieldEditButtons, { editMode: editMode, setEditMode: (v) => {
                            setEditMode(v);
                            setSelectedUserId(resetSelectedUserId());
                        }, onSave: handleSave }))] })), _jsx("div", Object.assign({ style: { height: 32 } }, { children: editMode && isEditable ? (_jsx(UserSelect, { users: allUsers, usersLoading: usersLoading, onChange: handleOnChange, value: selectedUserId })) : user ? (_jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: user.name, size: "small" }), _jsx(Tooltip, Object.assign({ mouseEnterDelay: 1, title: !!user.email && (_jsxs(_Fragment, { children: [_jsx("div", { children: user.name }), _jsx("div", { children: t("user_email", { email: user.email }) })] })) }, { children: _jsxs(AssigneeWrapper, { children: [_jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: user.name })), user.type === AccountManagerType.Puser && _jsx(Tag, { children: t("puser_label") })] }) }))] })) : (_jsx(Avatar, { name: t("application.preview.unassigned"), size: "small" })) }))] }));
};
export default EditableUserSelect;
