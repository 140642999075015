import { RightOutlined } from "@ant-design/icons";
import { Breadcrumb as AntdBreadcrumb, BreadcrumbProps, Card, Spin, Typography } from "antd";
import styled from "styled-components";

import { CategoryData } from "@teylor-tools/utils/documents/documents.types";
import {
	DOCUMENTS_CARD_MAX_WIDTH,
	DOCUMENTS_CARD_MIN_WIDTH,
} from "@teylor-tools/utils/documents/documents.variables";
import { OcrDoc } from "@teylor-tools/utils/fin-cr/fincr.types";

import { useTranslations } from "../translations/translations";
import FinCRDocumentRow, { BaseFinCRDocumentRowProps } from "./FinCRDocumentRow";
import { FinCrEmpty } from "./FinCrEmpty";

const { Text } = Typography;

const Wrapper = styled.div`
	padding: 16px 24px;
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9;

	.ant-typography {
		word-break: keep-all;
	}
`;

const StyledLoader = styled.div`
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;

const Breadcrumb = styled(AntdBreadcrumb)`
	ol {
		align-items: baseline;
	}
`;

const ContentWrapper = styled.div`
	margin-top: 64px;
	padding: 24px;
`;

const CategoryWrapper = styled(Card)`
	margin-bottom: 24px;
	min-width: ${DOCUMENTS_CARD_MIN_WIDTH}px;
	max-width: ${DOCUMENTS_CARD_MAX_WIDTH}px;

	.ant-card-body {
		padding-left: 0;
		padding-right: 0;
	}

	.ant-typography {
		word-break: keep-all;
	}
`;

const DocumentTypeWrapper = styled.div`
	margin: 24px 0;
`;

const DocumentTypeTitle = styled(Typography.Text)`
	padding-left: 24px;
	font-size: 12px;
`;

interface FinCrPageProps extends BaseFinCRDocumentRowProps {
	breadcrumbsItems: BreadcrumbProps["items"];
	actionBtn: JSX.Element;
	navigateToDocuments: () => void;
	isLoading: boolean;
	categoriesWithDocs: CategoryData<OcrDoc>[];
}

const FinCrPage = ({
	breadcrumbsItems,
	actionBtn,
	navigateToDocuments,
	isLoading,
	categoriesWithDocs,
	...props
}: FinCrPageProps) => {
	const t = useTranslations();

	return (
		<>
			<Wrapper>
				<Breadcrumb separator={<RightOutlined />} items={breadcrumbsItems} />
				{actionBtn}
			</Wrapper>
			<ContentWrapper>
				{isLoading && !categoriesWithDocs.length ? (
					<StyledLoader>
						<Spin size="large" />
					</StyledLoader>
				) : !categoriesWithDocs.length ? (
					<FinCrEmpty goToDocuments={navigateToDocuments} />
				) : (
					<Spin spinning={isLoading}>
						{categoriesWithDocs.map((category) => (
							<CategoryWrapper
								key={category.categoryName}
								title={<Text>{t.fincr.documentCategory(category.categoryName)}</Text>}
								styles={{ body: { padding: "24px 0" } }}
							>
								{!!category.types.length &&
									category.types.map(({ typeName, docs }) => (
										<DocumentTypeWrapper key={typeName}>
											<DocumentTypeTitle strong>{t.fincr.documentType(typeName)}</DocumentTypeTitle>
											{docs.map((doc) => (
												<FinCRDocumentRow key={doc.document_id} doc={doc} {...props} />
											))}
										</DocumentTypeWrapper>
									))}
							</CategoryWrapper>
						))}
					</Spin>
				)}
			</ContentWrapper>
		</>
	);
};

export default FinCrPage;
