import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
const ChecklistText = ({ id, collapsed }) => {
    const { t } = useTranslation();
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.description"), name: [id, "desc"], rules: [requiredRule] }, { children: _jsx(TextArea, { rows: 1 }) })), !collapsed && (_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.max-length") }, { children: _jsx(InputNumber, {}) })))] }));
};
export default ChecklistText;
