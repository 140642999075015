import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Space, Switch, Table, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { FinancialStatementType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import EmptyData from "src/components/ui/EmptyData";
const { Text } = Typography;
const OverviewSummaryTables = ({ financials, kpis }) => {
    const { t } = useTranslation();
    const { localizedNumber, decimalToPercent } = useFormatter();
    const [showInMlns, setShowInMlns] = useState(false);
    const decimalPlaces = showInMlns ? 1 : 2;
    const columns = useMemo(() => [
        {
            dataIndex: "title",
            width: 300,
        },
        ...financials.map((financialStatement) => {
            const statementYear = dayjs(financialStatement.financials_date).get("year");
            return {
                dataIndex: financialStatement.financials_date,
                align: "right",
                title: () => {
                    return (_jsx(_Fragment, { children: financialStatement.statement_type === FinancialStatementType.InterimFinancials ? (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ style: { marginRight: 8 } }, { children: `${t("application.financials.interim")} ${statementYear}.${dayjs(financialStatement.financials_date).format("MM")}` })), _jsx(Tooltip, Object.assign({ title: t("application.quantitative_analysis.overview.interim-column-tooltip") }, { children: _jsx(InfoCircleOutlined, { style: { color: "grey" } }) }))] })) : (_jsx(Typography.Text, { children: `${t("application.financials.annual")} ${statementYear}` })) }));
                },
            };
        }),
    ], [t, financials]);
    const dates = useMemo(() => columns.slice(1).map((col) => col.dataIndex), [columns]);
    const riskStandardKpis = useMemo(() => kpis.filter((kpi) => kpi.category === "risk_standard"), [kpis]);
    const riskStandardMultiKpis = useMemo(() => kpis.filter((kpi) => kpi.category === "risk_standard_multi"), [kpis]);
    const getFinancialsColumns = (field) => {
        return financials.reduce((acc, curr) => {
            var _a;
            const colId = curr.financials_date;
            const value = (_a = curr.summary_data) === null || _a === void 0 ? void 0 : _a[field];
            acc[colId] = isValidNumericValue(value)
                ? localizedNumber({
                    value: showInMlns ? Number(value) / 1000000 : value,
                    decimalPlaces,
                })
                : "-";
            return acc;
        }, {});
    };
    const getKpisColumns = (kpiCategory, getValue, type) => {
        const kpis = kpiCategory === "risk_standard" ? riskStandardKpis : riskStandardMultiKpis;
        return dates.reduce((acc, date) => {
            const kpisForYear = kpis.find((kpis) => {
                return kpis.end_date === date;
            });
            const value = getValue(kpisForYear === null || kpisForYear === void 0 ? void 0 : kpisForYear.kpis);
            let valueToDisplay;
            if (!isValidNumericValue(value)) {
                valueToDisplay = "-";
            }
            else {
                if (type === "percent") {
                    valueToDisplay = decimalToPercent({
                        percent: value,
                        decimalPlaces,
                        showPercentSymbol: true,
                    });
                }
                else if (type === "ratio") {
                    valueToDisplay = `${localizedNumber({ value, decimalPlaces })}x`;
                }
                else {
                    localizedNumber({ value, decimalPlaces });
                }
            }
            acc[date] = _jsx(Text, Object.assign({ italic: true }, { children: valueToDisplay }));
            return acc;
        }, {});
    };
    const profitLossData = useMemo(() => [
        Object.assign({ id: "revenue", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.revenue") }))) }, getFinancialsColumns("revenue")),
        Object.assign({ id: "revenue-growth", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.revenue-growth") }))) }, getKpisColumns("risk_standard_multi", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.growth_kpis) === null || _a === void 0 ? void 0 : _a.revenue_growth; }, "percent")),
        Object.assign({ id: "cogs", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.cogs") }))) }, getFinancialsColumns("cost_of_revenue")),
        Object.assign({ id: "cogs-as-percent-of-sales", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.cogs-as-percent-of-sales") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.cost_of_revenue_margin; }, "percent")),
        Object.assign({ id: "gross-profit", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.gross-profit") }))) }, getFinancialsColumns("gross_profit")),
        Object.assign({ id: "gross-margin", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.gross-margin") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.gross_profit_margin; }, "percent")),
        Object.assign({ id: "opex", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.opex") }))) }, getFinancialsColumns("opex")),
        Object.assign({ id: "opex-as-percent-of-sales", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.opex-as-percent-of-sales") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.opex_margin; }, "percent")),
        Object.assign({ id: "ebitda", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.ebitda") }))) }, getFinancialsColumns("ebitda")),
        Object.assign({ id: "ebitda-margin", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.ebitda-margin") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.ebitda_margin; }, "percent")),
        Object.assign({ id: "depreciations", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.deprecation-and-amortisation") }))) }, getFinancialsColumns("depreciations")),
        Object.assign({ id: "operating-profit", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.operating-profit") }))) }, getFinancialsColumns("operating_profit")),
        Object.assign({ id: "operating-profit-margin", title: (_jsx(Text, Object.assign({ italic: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.operating-profit-margin") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.operating_profit_margin; }, "percent")),
        Object.assign({ id: "interest-expense", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.interest-expense") }))) }, getFinancialsColumns("interest_expense")),
        Object.assign({ id: "net-income", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.profit-and-loss-kpis.net-income") }))) }, getFinancialsColumns("net_income")),
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kpis, financials, columns, showInMlns]);
    const balanceSheetData = useMemo(() => [
        Object.assign({ id: "total-debt", title: (_jsx(Text, Object.assign({ strong: true, type: "secondary" }, { children: t("application.quantitative_analysis.overview.balance-sheets.total-debt") }))) }, getFinancialsColumns("total_debt")),
        Object.assign({ id: "cash", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.balance-sheets.cash") }))) }, getFinancialsColumns("cash")),
        Object.assign({ id: "net-debt", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.balance-sheets.net-debt") }))) }, getFinancialsColumns("net_debt")),
        Object.assign({ id: "total-assets-published", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.balance-sheets.total-assets-published") }))) }, getFinancialsColumns("total_assets_published")),
        Object.assign({ id: "equity_published", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.balance-sheets.equity_published") }))) }, getFinancialsColumns("equity_published")),
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kpis, financials, columns, showInMlns]);
    const creditMetricsData = useMemo(() => [
        Object.assign({ id: "debt-to-ebitda-ratio", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.gross-leverage") }))) }, getKpisColumns("risk_standard_multi", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.leverage_kpis) === null || _a === void 0 ? void 0 : _a.debt_to_ebitda_ratio; }, "ratio")),
        Object.assign({ id: "net_debt_to_ebitda_ratio", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.net-leverage") }))) }, getKpisColumns("risk_standard_multi", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.leverage_kpis) === null || _a === void 0 ? void 0 : _a.net_debt_to_ebitda_ratio; }, "ratio")),
        Object.assign({ id: "interest_coverage_ratio", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.interest_coverage_ratio") }))) }, getKpisColumns("risk_standard_multi", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.leverage_kpis) === null || _a === void 0 ? void 0 : _a.interest_coverage_ratio; }, "ratio")),
        Object.assign({ id: "free_cash_flow_to_net_debt", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.free_cash_flow_to_net_debt") }))) }, getKpisColumns("risk_standard_multi", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.leverage_kpis) === null || _a === void 0 ? void 0 : _a.free_cash_flow_to_net_debt; }, "ratio")),
        Object.assign({ id: "debt_to_equity_ratio", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.debt_to_equity_ratio") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.solvency_kpis) === null || _a === void 0 ? void 0 : _a.debt_to_equity_ratio; }, "ratio")),
        Object.assign({ id: "equity_margin", title: (_jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.quantitative_analysis.overview.ratios-and-debts-kpis.equity_margin") }))) }, getKpisColumns("risk_standard", (kpis) => { var _a; return (_a = kpis === null || kpis === void 0 ? void 0 : kpis.margin_kpis) === null || _a === void 0 ? void 0 : _a.equity_margin; }, "percent")),
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kpis, financials, columns]);
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24, style: { width: "100%" } }, { children: [_jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx(Typography.Text, Object.assign({ style: { marginRight: 8 } }, { children: t("application.quantitative_analysis.overview.view-in-mlns") })), _jsx(Switch, { value: showInMlns, onChange: (v) => setShowInMlns(v) })] })), !financials.length ? (_jsxs(_Fragment, { children: [_jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.profit-and-loss-kpis") }, { children: _jsx(EmptyData, {}) })), _jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.balance-sheet") }, { children: _jsx(EmptyData, {}) })), _jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.credit-metrics") }, { children: _jsx(EmptyData, {}) }))] })) : (_jsxs(_Fragment, { children: [_jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.profit-and-loss-kpis") }, { children: _jsx(Table, { columns: columns, dataSource: profitLossData, rowKey: "id", pagination: false }) })), _jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.balance-sheet") }, { children: _jsx(Table, { columns: columns, dataSource: balanceSheetData, rowKey: "id", pagination: false }) })), _jsx(Card, Object.assign({ title: t("application.quantitative_analysis.overview.credit-metrics") }, { children: _jsx(Table, { columns: columns, dataSource: creditMetricsData, rowKey: "id", pagination: false }) }))] }))] })));
};
export default OverviewSummaryTables;
