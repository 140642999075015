var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { App, Form, Input, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import timePassedUntilNow from "@teylor-tools/utils/timePassedUntilNow";
import { DataCardItem } from "@ui/data-display/DataCard";
import TinFormItem from "@ui/form/form-items/tin-form-item/TinFormItem";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "src/Routes";
import CreditScoreReport from "src/components/CreditScoreReport";
import { DataCard, DataCardDivider, DataCardItemEditable } from "src/components/DataCard";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
import { Axios } from "src/utils/Axios";
import { getCompanyAddress } from "../../../utils/get-company-address";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const pickTimePassed = (foundationDate, t) => {
    const { monthsPassed, yearsPassed } = timePassedUntilNow(foundationDate);
    return `${t("application.company.year_other", { count: yearsPassed })} ${t("application.company.month_other", { count: monthsPassed })}`;
};
const CompanyDetails = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { company, updateCompany } = useCompany();
    const [creditScores, setCreditScores] = useState([]);
    const { decimalToPercent } = useFormatter();
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/companies/${company.companyId}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [company.companyId, t]);
    const updateIndustryCodes = (values) => {
        return Axios.patch(`/admin/companies/${company.companyId}/industry_codes`, values).then(() => {
            void updateCompany();
        }, (err) => void Axios.error(err));
    };
    const onHandleManualUpdate = ({ company_industry_code_set_id, approve, }) => {
        if (approve) {
            Axios.patch(`/admin/companies/${company.companyId}/industry_codes_set/${company_industry_code_set_id}/approve`).then(() => {
                void message.success(t("common.saved"));
                void updateCompany();
            }, (err) => void Axios.error(err));
        }
        else {
            Axios.patch(`/admin/companies/${company.companyId}/industry_codes_set/${company_industry_code_set_id}/reject`).then(() => {
                void message.success(t("common.saved"));
                void updateCompany();
            }, (err) => void Axios.error(err));
        }
    };
    const getPdScore = () => {
        var _a;
        const pd = (_a = creditScores[0]) === null || _a === void 0 ? void 0 : _a.pd;
        return isValidNumericValue(pd)
            ? decimalToPercent({ percent: pd, showPercentSymbol: true })
            : "-";
    };
    useEffect(() => {
        getCreditScores();
    }, [getCreditScores]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("company.menu.company") }), _jsxs(Wrapper, { children: [_jsxs(DataCard, Object.assign({ loading: !company }, { children: [_jsx(DataCardItem, { label: t("company.name"), value: company.companyName && _jsx("strong", { children: company.companyName }) }), _jsx(DataCardItemEditable, { label: t("company.purpose"), displayedValue: company.businessPurpose, formItemValue: company.businessPurpose, onChange: (value) => {
                                    void updateCompany({ businessPurpose: value });
                                }, customFormItem: (_a) => {
                                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                    return (_jsx(Form.Item, Object.assign({}, props, { children: _jsx(TextArea, Object.assign({ rows: 6 }, inputProps)) })));
                                }, e2eId: "edit-purpose" }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.address"), value: getCompanyAddress(company, t) }), _jsx(DataCardItem, { label: t("company.country"), value: (company === null || company === void 0 ? void 0 : company.address_country) && t(`common.countries.${company.address_country}`) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.hr-number"), value: company.hrNumber }), _jsx(DataCardItemEditable, { label: t("company.tin"), displayedValue: company.taxIdNumber, onChange: (value) => {
                                    void updateCompany({ taxIdNumber: value });
                                }, customFormItem: (props) => _jsx(TinFormItem, Object.assign({}, props)), formItemValue: company.taxIdNumber, e2eId: "edit-tin" }), _jsx(DataCardItem, { label: t("common.short-company-id"), value: company.company_id_short && (_jsx(Typography.Text, Object.assign({ copyable: true }, { children: company.company_id_short }))) }), _jsx(DataCardItem, { label: t("common.company-id"), value: company.companyId && _jsx(Typography.Text, Object.assign({ copyable: true }, { children: company.companyId })) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.legal-form"), value: company.companyType }), _jsx(DataCardItem, { label: t("company.founded"), value: company.companyFoundationDate && new Date(company.companyFoundationDate).getFullYear() }), _jsx(DataCardItem, { label: t("company.age"), value: company.companyFoundationDate && pickTimePassed(company.companyFoundationDate, t) }), _jsx(DataCardItemEditable, { label: t("application.company.employees"), displayedValue: company.numberOfEmployees, onChange: (value) => void updateCompany({ employees_count: Number(value) }), formItemValue: company.numberOfEmployees || "", customFormItem: (_a) => {
                                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                    return (_jsx(Form.Item, Object.assign({}, props, { rules: [
                                            {
                                                validator: (_, value) => {
                                                    if (!value || /^[0-9]+$/.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t("application_form.errors.fieldContainsInvalidCharacters"));
                                                },
                                            },
                                        ], validateFirst: true }, { children: _jsx(Input, Object.assign({ autoFocus: true }, inputProps)) })));
                                }, e2eId: "edit-employees" })] })), _jsx(IndustryCodesCard, { industryCodes: company === null || company === void 0 ? void 0 : company.industry_codes, showEditButton: true, onSave: updateIndustryCodes, onHandleManualUpdate: onHandleManualUpdate, withManualApprovalWarning: true }), _jsxs(DataCard, { children: [_jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, requestCreditCheck: (credit_type) => Axios.patch(`admin/companies/${company.companyId}/credit_check`, { credit_type }).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err)), documentsPath: generatePath(Routes.CompanyDocuments, {
                                    companyId: company.companyId,
                                }) }), _jsx(DataCardItem, { label: t("company.pd-rate"), value: getPdScore() })] })] })] }));
};
export default CompanyDetails;
