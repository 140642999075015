import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined, } from "@ant-design/icons";
import { Button, Checkbox, Divider, Flex, Form, Select, Tooltip, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import { ChecklistFlag } from "../checklist-form.types";
const OkIcon = styled(CheckCircleOutlined) `
	color: ${(props) => props.theme.colorSuccess};
	font-size: 16px;
`;
const IssueIcon = styled(CloseCircleOutlined) `
	color: ${(props) => props.theme.colorError};
	font-size: 16px;
`;
const WarningIcon = styled(ExclamationCircleOutlined) `
	color: ${(props) => props.theme.colorWarning};
	font-size: 16px;
`;
const FlagLabel = styled.div `
	display: flex;
	justify-items: center;
	gap: 8px;
`;
export const defaultNewRadioOption = () => ({
    desc: "",
    flag: undefined,
});
const flagsOptions = [
    {
        value: ChecklistFlag.green,
        label: (_jsxs(FlagLabel, { children: [_jsx(OkIcon, {}), " ", ChecklistFlag.green] })),
    },
    {
        value: ChecklistFlag.yellow,
        label: (_jsxs(FlagLabel, { children: [_jsx(WarningIcon, {}), " ", ChecklistFlag.yellow] })),
    },
    {
        value: ChecklistFlag.red,
        label: (_jsxs(FlagLabel, { children: [_jsx(IssueIcon, {}), " ", ChecklistFlag.red] })),
    },
];
const RadioItemOption = ({ id }) => {
    const { t } = useTranslation();
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.description"), name: [id, "desc"], rules: [requiredRule] }, { children: _jsx(TextArea, { rows: 1 }) })), _jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.flag"), name: [id, "flag"], rules: [requiredRule] }, { children: _jsx(Select, { options: flagsOptions }) }))] }));
};
const ChecklistRadio = ({ id, collapsed }) => {
    const { t } = useTranslation();
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.description"), name: [id, "desc"], rules: [requiredRule] }, { children: _jsx(TextArea, { rows: 1 }) })), !collapsed && (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.applicable-switch"), name: [id, "notApplicable"], valuePropName: "checked" }, { children: _jsx(Checkbox, {}) })), _jsx(Divider, {}), _jsx(Form.List, Object.assign({ name: [id, "options"] }, { children: (options, { add: addOption, remove: removeOption }) => (_jsxs(_Fragment, { children: [options.map((option, i) => (_jsxs(Fragment, { children: [_jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsxs(Typography.Text, Object.assign({ strong: true }, { children: [t("settings.checklists.config.item.option"), " ", i + 1] })), _jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.remove-option") }, { children: _jsx(Button, { type: "link", onClick: () => removeOption(i), icon: _jsx(DeleteOutlined, {}) }) }))] })), _jsx(RadioItemOption, { id: i }), _jsx(Divider, {})] }, i))), _jsx(Flex, Object.assign({ justify: "flex-end" }, { children: _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: () => addOption(defaultNewRadioOption()), icon: _jsx(PlusOutlined, {}) }, { children: t("settings.checklists.config.item.add-option") })) }))] })) }))] }))] }));
};
export default ChecklistRadio;
