import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserOutlined } from "@ant-design/icons";
import { List, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterFullDateTime } from "@teylor-tools/utils/dateFormats";
const { Text } = Typography;
const SmallText = styled.div `
	margin-top: 8px;
	font-size: 12px;
	word-break: break-word;
`;
const ListItemWrapper = styled.div `
	padding: 12px 24px;
`;
const StyledUserOutlined = styled(UserOutlined) `
	margin-right: 10px;
`;
const Logs = ({ logs }) => {
    const { date } = useFormatter();
    return (_jsx(List, { dataSource: logs, itemLayout: "vertical", renderItem: ({ event_time, actor_id, event_data }) => (_jsx(List.Item, { children: _jsxs(ListItemWrapper, { children: [_jsx("div", { children: _jsx(Text, Object.assign({ strong: true }, { children: event_time
                                ? date(event_time, Object.assign(Object.assign({}, formatterFullDateTime), { timeZone: "UTC", timeZoneName: "short" }))
                                : "-" })) }), _jsx("div", { children: _jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsx(StyledUserOutlined, { size: 12 }), actor_id] })) }), _jsx(SmallText, { children: JSON.stringify(event_data) })] }) })) }));
};
export default Logs;
