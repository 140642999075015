import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsAltOutlined, DeleteOutlined, HolderOutlined, ShrinkOutlined, } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Flex, Form, Radio, Tooltip } from "antd";
import { ChecklistItemTemplateItemType } from "@teylor-tools/Api";
import ChecklistCheckbox from "./ChecklistCheckbox";
import ChecklistRadio, { defaultNewRadioOption } from "./ChecklistRadio";
import ChecklistText from "./ChecklistText";
const ChecklistGroupItem = ({ id, remove, collapsed, onExpand, onCollapse, }) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();
    const [itemType, setItemType] = useState(form.getFieldValue([id, "itemType"]));
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 99 : undefined,
    };
    const handleChange = (itemType) => {
        setItemType(itemType);
        const desc = form.getFieldValue([id, "desc"]);
        if (itemType === ChecklistItemTemplateItemType.Checkbox) {
            const config = { itemType, desc };
            form.setFieldValue(id, config);
        }
        else if (itemType === ChecklistItemTemplateItemType.Select) {
            const config = {
                itemType,
                desc,
                notApplicable: false,
                options: [defaultNewRadioOption(), defaultNewRadioOption()],
            };
            form.setFieldValue(id, config);
        }
        else if (itemType === ChecklistItemTemplateItemType.Text) {
            const config = { itemType, desc, minLength: 5 };
            form.setFieldValue(id, config);
        }
    };
    const renderItem = () => {
        switch (itemType) {
            case ChecklistItemTemplateItemType.Checkbox:
                return _jsx(ChecklistCheckbox, { id: id });
            case ChecklistItemTemplateItemType.Select:
                return _jsx(ChecklistRadio, { id: id, collapsed: isDragging || collapsed });
            case ChecklistItemTemplateItemType.Text:
                return _jsx(ChecklistText, { id: id, collapsed: isDragging || collapsed });
        }
    };
    return (_jsxs(Card, Object.assign({ ref: setNodeRef }, attributes, { style: Object.assign(Object.assign({}, style), { margin: "16px 0" }) }, { children: [_jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(Form.Item, Object.assign({ name: [id, "itemType"] }, { children: _jsxs(Radio.Group, Object.assign({ onChange: (e) => handleChange(e.target.value) }, { children: [_jsx(Radio, Object.assign({ value: ChecklistItemTemplateItemType.Checkbox }, { children: t("settings.checklists.config.item.checkbox") })), _jsx(Radio, Object.assign({ value: ChecklistItemTemplateItemType.Select }, { children: t("settings.checklists.config.item.select") })), _jsx(Radio, Object.assign({ value: ChecklistItemTemplateItemType.Text }, { children: t("settings.checklists.config.item.text") }))] })) })), _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [collapsed ? (_jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.expand-group") }, { children: _jsx(Button, { type: "link", onClick: () => onExpand(id), icon: _jsx(ArrowsAltOutlined, {}) }) }))) : (_jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.collapse-group") }, { children: _jsx(Button, { type: "link", onClick: () => onCollapse(id), icon: _jsx(ShrinkOutlined, {}) }) }))), _jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.remove-item") }, { children: _jsx(Button, { type: "link", onClick: remove, icon: _jsx(DeleteOutlined, {}) }) })), _jsx(Button, Object.assign({ type: "link", icon: _jsx(HolderOutlined, {}), style: { color: "#787878" } }, listeners))] }))] })), renderItem()] })));
};
export default ChecklistGroupItem;
