import { generatePath } from "react-router-dom";
import { PendingActionChecklistValue, PendingActionError } from "@teylor-tools/Api";
import { Routes } from "src/Routes";
import { SideActions } from "src/layouts/ApplicationLayout/application.types";
export const getGeneralPendingItemAction = (error, applicationId, navigate, setActiveMenu, openShareModal) => {
    switch (error) {
        case "err_application_no_bank_name":
        case "err_application_no_iban":
        case "err_application_company_taxid_not_specified":
            return () => navigate(generatePath(Routes.ApplicationCompanies, { applicationId }));
        case "err_application_payout_checklist_not_done":
            return () => setActiveMenu(SideActions.payoutChecklist);
        case "err_scoring_feedback_not_done":
            return () => setActiveMenu(SideActions.analystReport);
        case "err_application_four_eyes_checklist_not_done":
            return () => setActiveMenu(SideActions.finalReviewChecklist);
        case "err_application_no_signatories":
        case "err_application_no_taxid_for_signatory":
        case "err_share_percentage_greater_then_100":
        case "err_pep_approvals_missing":
        case "err_application_pep_check_not_uploaded":
        case "err_application_signatories_sole_or_atleast_2":
        case "err_schufa_score_missing_for_ubo_missing":
            return () => navigate(generatePath(Routes.ApplicationRepresentatives, { applicationId }));
        case "err_application_no_signatories_sign_loan_contract":
        case "err_application_signatory_ident_not_done":
        case "err_sepa_mandate_not_accepted":
        case "err_insufficient_signatories_sign_loan_contract":
        case "err_application_guarantor_signatory_missing":
            return () => navigate(generatePath(Routes.ApplicationSignatures, { applicationId }));
        case "err_application_no_pricing":
        case "err_application_no_pricing_transaction_fee":
        case "err_application_no_pricing_prepayment_rate":
        case "err_application_no_external_pricing":
        case "err_min_interest_rate_not_set":
        case "err_hedging_fee_not_set":
            return () => navigate(generatePath(Routes.ApplicationPricing, { applicationId }));
        case "err_financials_not_exist":
        case "err_missing_scoring_data":
            return () => navigate(generatePath(Routes.ApplicationFinancials, { applicationId }));
        case "err_document_annual_statment_missing":
        case "err_document_bwa_susa_missing":
        case "err_document_debt_registry_missing":
        case "err_document_guarantor_conract_missing":
        case "err_document_bank_statement_missing":
            return () => navigate(generatePath(Routes.ApplicationDocuments, { applicationId }));
        case "err_repayment_plan_not_generated":
            return () => navigate(generatePath(Routes.ApplicationRepayment, { applicationId }));
        case "err_checklist_not_done":
            return () => navigate(generatePath(Routes.ApplicationQualitative, { applicationId }));
        case "err_choose_non_default_product":
        case "err_funding_provider_not_selected":
            return () => navigate(generatePath(Routes.Application, { applicationId }));
        case "err_too_many_red_flags":
        case "err_too_many_warnings":
            return () => navigate(generatePath(Routes.ApplicationQuantitative, { applicationId }));
        case "err_user_not_accepted_terms":
            return () => openShareModal();
        case "err_industry_code_not_set":
        case "err_industry_code_not_allowed":
        case "err_company_industry_codes_set_not_approved":
            return () => navigate(generatePath(Routes.ApplicationCompanies, { applicationId }));
        case "err_application_eligibility_checklist_not_done":
            return () => navigate(generatePath(Routes.ApplicationEligibility, { applicationId }));
        case "err_factoring_details_not_added":
            return () => navigate(generatePath(Routes.ApplicationFactoring, { applicationId }));
        default:
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => { };
    }
};
export const getChecklistPendingItemAction = (checklist, applicationId, navigate, setActiveMenu) => {
    switch (checklist) {
        case PendingActionChecklistValue.EligibilityAutomatic:
        case PendingActionChecklistValue.Eligibility:
            return () => navigate(generatePath(Routes.ApplicationEligibility, { applicationId }));
        case PendingActionChecklistValue.Application:
            return () => navigate(generatePath(Routes.ApplicationQualitative, { applicationId }));
        case PendingActionChecklistValue.Payout:
            return () => setActiveMenu(SideActions.payoutChecklist);
        case PendingActionChecklistValue.FourEyeCheck:
            return () => setActiveMenu(SideActions.finalReviewChecklist);
        default:
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => { };
    }
};
export const getSignatoryPendingItemAction = (error, applicationId, navigate) => {
    switch (error) {
        case PendingActionError.ErrSignatoriesDataIncomplete:
        case PendingActionError.ErrSchufaScoreMissingForUboMissing:
        case PendingActionError.ErrRepresentativeDataRequiredPhoneNumber:
        case PendingActionError.ErrRepresentativeDataRequiredEmail:
            return () => navigate(generatePath(Routes.ApplicationRepresentatives, { applicationId }));
        default:
            return () => navigate(generatePath(Routes.ApplicationSignatures, { applicationId }));
    }
};
