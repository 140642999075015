var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { BankOutlined, BlockOutlined, CarryOutOutlined, ContainerOutlined, FundProjectionScreenOutlined, FunnelPlotOutlined, GoldOutlined, LogoutOutlined, SettingOutlined, TeamOutlined, } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Menu as AntdMenu, Button, Popover, Spin, Tooltip } from "antd";
import AntdSider from "antd/lib/layout/Sider";
import Layout, { Content } from "antd/lib/layout/layout";
import styled, { useTheme } from "styled-components";
import { BOUserFeature, FeatureName } from "@teylor-tools/Api";
import Avatar from "@ui/data-display/Avatar";
import { useTranslations } from "@ui/translations/translations";
import { Routes } from "src/Routes";
import { logout } from "src/store/actions/authActions";
import { clearFeatures, setCountryList, setDocumentCategories, setFeatures, setFilterValues, } from "src/store/actions/configActions";
import { resetProfile, setProfile } from "src/store/actions/profileActions";
import { Axios } from "src/utils/Axios";
import ErrorBoundaryWrapper from "../ErrorBoundaryWrapper";
const SpinWrapper = styled.div `
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const StyledContent = styled(Content) `
	height: 100vh;
	overflow: auto;
`;
const Wrapper = styled.div `
	padding: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100vh;
`;
const Sider = styled(AntdSider) `
	background: ${({ theme }) => theme.main_menu_bg_color} !important;
`;
const Menu = styled(AntdMenu) `
	background: ${({ theme }) => theme.main_menu_bg_color};

	.ant-menu-item {
		color: ${({ theme }) => theme.main_menu_color};
	}

	.ant-menu-item-selected {
		color: ${({ theme }) => theme.color_cta_text};
		background: ${({ theme }) => theme.color_primary};
	}
`;
const AvatarWrapper = styled.div `
	position: absolute;
	bottom: 30px;
	display: flex;
	width: 100%;
	justify-content: center;
`;
const UserAvatar = styled(Avatar) `
	width: 46px;
	height: 46px;
	line-height: 46px;
	font-size: 16px !important;
	cursor: pointer;
	font-weight: 600;
`;
const LogoWrapper = styled.div `
	margin: 24px 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 40px;
	}
`;
const UserActions = styled.div `
	display: flex;
	flex-direction: column;
	align-items: start;
`;
const UserActionButton = styled(Button) `
	color: ${(props) => props.theme.colorPrimary};
`;
const getFeatures = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Axios.get("admin/features");
    return response.data;
});
const getProfile = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Axios.get("admin/profile");
    return response.data;
});
const getFilterValues = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Axios.get("admin/filter_values");
    return response.data;
});
const MenuLayout = () => {
    const { t, i18n } = useTranslation();
    const { countries } = useTranslations();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState();
    const { features, tasksEnabled } = useSelector(({ configState }) => configState);
    const { email, features: boUserFeatures } = useSelector(({ profileState }) => profileState);
    const [loading, setLoading] = useState(true);
    const [lastMainRoute, setLastMainRoute] = useState("");
    const [showPopover, setShowPopover] = useState(false);
    const { data: featuresData, isLoading: loadingFeatures } = useQuery({
        queryKey: ["adminFeatures"],
        queryFn: getFeatures,
        enabled: !features.length,
    });
    const { data: profileData, isLoading: loadingProfile } = useQuery({
        queryKey: ["adminProfile"],
        queryFn: getProfile,
    });
    const { data: filterValues, isLoading: loadingFilterValues } = useQuery({
        queryKey: ["filterValues"],
        queryFn: getFilterValues,
    });
    const mainRoutesToSave = useMemo(() => [Routes.Applications, Routes.Loans, Routes.Partners, Routes.Companies].map((v) => v.toString()), []);
    const showPortfolio = useMemo(() => { var _a; return (_a = features.find((f) => f.name === FeatureName.PortfolioMonitoring)) === null || _a === void 0 ? void 0 : _a.is_active; }, [features]);
    const showPipeline = useMemo(() => { var _a; return (_a = features.find((f) => f.name === FeatureName.Pipeline)) === null || _a === void 0 ? void 0 : _a.is_active; }, [features]);
    const menuItems = useMemo(() => [
        ...(tasksEnabled
            ? [
                {
                    icon: (_jsx(Tooltip, Object.assign({ title: t("menu.tasks"), placement: "right" }, { children: _jsx(CarryOutOutlined, {}) }))),
                    key: Routes.Tasks,
                },
            ]
            : []),
        {
            icon: (_jsx(Tooltip, Object.assign({ title: t("menu.applications"), placement: "right" }, { children: _jsx(ContainerOutlined, {}) }))),
            key: Routes.Applications,
        },
        {
            icon: (_jsx(Tooltip, Object.assign({ title: t("menu.loans"), placement: "right" }, { children: _jsx(GoldOutlined, {}) }))),
            key: Routes.Loans,
        },
        ...(showPortfolio
            ? [
                {
                    icon: (_jsx(Tooltip, Object.assign({ title: t("menu.portfolio"), placement: "right" }, { children: _jsx(FundProjectionScreenOutlined, {}) }))),
                    key: Routes.Portfolio,
                },
            ]
            : []),
        {
            icon: (_jsx(Tooltip, Object.assign({ title: t("menu.users"), placement: "right" }, { children: _jsx(TeamOutlined, {}) }))),
            key: Routes.Users,
        },
        {
            icon: (_jsx(Tooltip, Object.assign({ title: t("menu.company"), placement: "right" }, { children: _jsx(BankOutlined, {}) }))),
            key: Routes.Companies,
        },
        ...((boUserFeatures === null || boUserFeatures === void 0 ? void 0 : boUserFeatures.includes(BOUserFeature.Partner))
            ? [
                {
                    icon: (_jsx(Tooltip, Object.assign({ title: t("menu.partners"), placement: "right" }, { children: _jsx(BlockOutlined, {}) }))),
                    key: Routes.Partners,
                },
            ]
            : []),
        ...(showPipeline
            ? [
                {
                    icon: (_jsx(Tooltip, Object.assign({ title: t("menu.pipeline"), placement: "right" }, { children: _jsx(FunnelPlotOutlined, {}) }))),
                    key: Routes.Pipeline,
                },
            ]
            : []),
        ...((boUserFeatures === null || boUserFeatures === void 0 ? void 0 : boUserFeatures.includes(BOUserFeature.Bouser))
            ? [
                {
                    icon: (_jsx(Tooltip, Object.assign({ title: t("menu.settings"), placement: "right" }, { children: _jsx(SettingOutlined, {}) }))),
                    key: Routes.SettingsTeam,
                },
            ]
            : []),
    ], [t, boUserFeatures, tasksEnabled, showPortfolio, showPipeline]);
    useEffect(() => {
        if (featuresData) {
            dispatch(setFeatures(featuresData));
        }
    }, [dispatch, featuresData]);
    useEffect(() => {
        if (profileData) {
            void i18n.changeLanguage(profileData.interface_language);
            dispatch(setProfile({
                userId: profileData.id,
                email: profileData.email,
                features: profileData.features,
                name: profileData.name,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileData]);
    useEffect(() => {
        if (filterValues) {
            dispatch(setFilterValues(filterValues));
        }
    }, [dispatch, filterValues]);
    const getDocumentCategories = () => Axios.get(`/admin/document_configurations/document_categories`).then(({ data }) => dispatch(setDocumentCategories(data)), (err) => Axios.error(err));
    const logOutCall = () => {
        Axios.post("/admin/account/logout").then(() => {
            dispatch(logout(true));
            dispatch(resetProfile());
            dispatch(clearFeatures());
        }, (error) => Axios.error(error, t("common.request-failed")));
    };
    useEffect(() => {
        const selectedKeyFound = menuItems.filter(({ key }) => location.pathname.includes(key))[0];
        if (selectedKeyFound) {
            setSelectedKey(selectedKeyFound.key);
        }
        if (mainRoutesToSave.includes(location.pathname)) {
            setLastMainRoute(location.pathname + location.search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, menuItems]);
    useEffect(() => {
        setLoading(true);
        Promise.allSettled([getDocumentCategories()]).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // DON'T TOUCH THIS CODE if you want to handle the Company translation list in a proper way
        if (!loadingFeatures) {
            dispatch(setCountryList(features, countries));
        }
    }, [features, countries, dispatch, loadingFeatures]);
    if (loading || loadingFeatures || loadingProfile || loadingFilterValues) {
        return (_jsx(SpinWrapper, { children: _jsx(Spin, { size: "large" }) }));
    }
    const onClick = (e) => {
        navigate(e.key);
    };
    return (_jsxs(Layout, { children: [_jsxs(Sider, Object.assign({ width: 80 }, { children: [theme.logo_collapsed_web_path && (_jsx(LogoWrapper, { children: _jsx("img", { src: theme.logo_collapsed_web_path, alt: "Logo" }) })), _jsx(Menu, { mode: "inline", onClick: onClick, inlineCollapsed: true, items: menuItems, selectedKeys: [selectedKey || ""], "data-cy": "menu-icon" }), _jsx(Popover, Object.assign({ placement: "right", open: showPopover, content: _jsxs(UserActions, Object.assign({ onMouseLeave: () => setShowPopover(false) }, { children: [_jsx(UserActionButton, Object.assign({ type: "link", icon: _jsx(SettingOutlined, {}), onClick: () => {
                                        setSelectedKey(undefined);
                                        navigate(Routes.Profile);
                                    } }, { children: "Profile" })), _jsx(UserActionButton, Object.assign({ type: "link", icon: _jsx(LogoutOutlined, {}), onClick: logOutCall, "data-cy": "logout-btn" }, { children: t("menu.logout") }))] })), trigger: "click" }, { children: email && (_jsx(AvatarWrapper, Object.assign({ onClick: () => setShowPopover(!showPopover), "data-cy": "avatar-btn" }, { children: _jsx(UserAvatar, { name: `${email.charAt(0)} ${email.charAt(1)}` }) }))) }))] })), _jsx(StyledContent, { children: _jsx(Wrapper, { children: _jsx(ErrorBoundaryWrapper, { children: _jsx(Outlet, { context: { lastMainRoute } }) }) }) })] }));
};
export default MenuLayout;
export function useLastMainRoute() {
    return useOutletContext();
}
