import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath } from "react-router-dom";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { Routes } from "../../../Routes";
import { useApplication } from "../../../layouts/ApplicationLayout/application.types";
import { Axios } from "../../../utils/Axios";
import Financials from "../../modules/Financials/Financials";
const ApplicationFinancials = () => {
    const { application, updatePendingItems } = useApplication();
    return (_jsx(Financials, { getFinancialsData: () => Axios.get(`/admin/applications/${application.applicationId}/financial_data`).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err)), updateFinancialsData: (payload) => Axios.post(`/admin/applications/${application.applicationId}/financial_data`, payload).then(() => Promise.resolve(), (err) => Promise.reject(err)), afterUpdate: updatePendingItems, finCrPath: generatePath(Routes.ApplicationFincr, {
            applicationId: application.applicationId,
        }), allowEdit: application.status !== ApplicationStatuses.PaidOut }));
};
export default ApplicationFinancials;
