import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
const ErrorsList = styled.ul `
	list-style-type: none;
	margin-top: 8px;
	padding-left: 24px;
`;
const ErrorIcon = styled(CloseCircleOutlined) `
	font-size: 0.7rem;
	opacity: 0.4;
	margin-right: 4px;
`;
export const displayTemplateErrors = (errors, message) => {
    void message.open({
        type: "error",
        content: (_jsxs(_Fragment, { children: ["Checklist template contains errors:", _jsx(ErrorsList, { children: errors.map((err, i) => (_jsxs("li", { children: [_jsx(ErrorIcon, {}), " ", err.message] }, i))) })] })),
        duration: 5,
    });
};
