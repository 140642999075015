var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Typography, Upload } from "antd";
import pLimit from "p-limit";
import styled from "styled-components";
import { DocumentCategory, DocumentType } from "@teylor-tools/Api";
import useDebounce from "@teylor-tools/hooks/debounce";
import { mapDocEntityKindToApiPath } from "@teylor-tools/utils/documents/mapDocEntityKindToApiPath";
import { handleDocumentUpload } from "src/pages/modules/Documents/documents.helpers";
const { Title, Text } = Typography;
const DragOverlay = styled.div `
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(233, 244, 255, 0.9);
	border: 2px solid ${({ theme }) => theme.colorPrimary};
`;
const DragOverlayIcon = styled(CloudUploadOutlined) `
	color: ${({ theme }) => theme.colorPrimary};
	font-size: 56px;
`;
const HiddenRefButton = styled(Button) `
	visibility: hidden;
	width: 0;
	height: 0;
	padding: 0;
	border: 0;
`;
const DocumentsUpload = forwardRef(({ entityKind, entityId, disabled, setUploadInProgress, addDocsToUpload, updateDocumentInUpload, children, }, ref) => {
    const { t } = useTranslation();
    const [showDragOverlay, setShowDragOverlay] = useState(false);
    const [docsToUpload, setDocsToUpload] = useState([]);
    const debouncedShowDragOverlay = useDebounce(showDragOverlay, 100);
    useEffect(() => {
        if (!docsToUpload.length)
            return;
        setUploadInProgress(true);
        const newDocs = docsToUpload.map((doc) => ({
            file: doc,
            status: "waiting",
            updateId: doc.uid,
            document_name: doc.name,
            document_category: DocumentCategory.None,
            document_type: DocumentType.None,
            entity_kind: entityKind,
            entity_id: entityId,
        }));
        if (!newDocs.length)
            return;
        addDocsToUpload(newDocs);
        const limit = pLimit(3);
        const promises = newDocs.map((doc) => {
            return limit(() => handleDocumentUpload(doc, `admin/${mapDocEntityKindToApiPath(entityKind)}/${entityId}`, updateDocumentInUpload));
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield Promise.allSettled(promises)
                .then()
                .catch()
                .finally(() => {
                setUploadInProgress(false);
                setDocsToUpload([]);
            });
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docsToUpload.length]);
    return (_jsxs("div", Object.assign({ onDragOver: () => !disabled && setShowDragOverlay(true), onDragLeave: () => setShowDragOverlay(false), onDrop: () => setShowDragOverlay(false) }, { children: [_jsxs(Upload, Object.assign({ customRequest: ({ file }) => setDocsToUpload((prev) => [...prev, file]), multiple: true, fileList: [] }, { children: [_jsx(HiddenRefButton, { ref: ref }), debouncedShowDragOverlay && (_jsxs(DragOverlay, { children: [_jsx(DragOverlayIcon, {}), _jsx(Title, Object.assign({ level: 5 }, { children: t("application.documents.drop-files") })), _jsx(Text, { children: t("application.documents.drop-description") })] }))] })), children] })));
});
DocumentsUpload.displayName = "DocumentsUpload";
export default DocumentsUpload;
