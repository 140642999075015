import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	default as axiosModule,
} from "axios";
import { Namespace, TFunction } from "i18next";

import { ErrorCode } from "../../Api";
import { AuthState } from "./AuthState";

export type ErrorResponse = AxiosError<{
	errors?: {
		code: ErrorCode;
		message: string;
	}[];
}>;

interface ConstructorParams {
	apiPath: string;
	t: TFunction<Namespace>;
	message: (text: string, duration?: number) => void;
	beErrorString: (code: ErrorCode) => string;
}

type CypressWindow = Window & {
	Cypress?: unknown;
};

export class AxiosSingleton {
	private static _instance: AxiosSingleton;
	readonly t!: TFunction<Namespace>;
	readonly message!: (text: string, duration?: number) => void;
	readonly beErrorString!: (code: ErrorCode) => string;
	readonly axios!: AxiosInstance;
	readonly apiPath: string = "/localApi";

	private constructor({ t, message, beErrorString, apiPath }: ConstructorParams) {
		axiosModule.defaults.withCredentials = true;
		this.apiPath = apiPath;
		this.t = t;
		this.message = message;
		this.beErrorString = beErrorString;
		this.axios = axiosModule.create({
			baseURL: apiPath,
			timeout: 30000,
		});

		if (process.env.NODE_ENV === "development" || (window as CypressWindow).Cypress) {
			// Manually overwrite s3 upload url from docker container name to localhost
			// Applies if app is being runned in cypress tests or in dev mode
			// https://github.com/localstack/localstack/issues/2427
			this.axios.interceptors.request.use((request) => {
				if ((request.url || "").includes("mocks:4566")) {
					request.url = (request.url || "").replace("http://mocks:4566", "http://localhost:4566");
				}
				return request;
			});

			this.axios.interceptors.response.use((response) => {
				// Manually overwrite s3 download url
				if (Object.keys(response?.data || {}).includes("download_url")) {
					response.data.download_url = response.data.download_url.replace(
						"http://mocks:4566",
						"http://localhost:4566"
					);
				}
				return response;
			});
		}
	}

	public error(
		err: ErrorResponse | unknown,
		errorMessage = this.t("common.request-failed")
	): Promise<never> {
		if (!axios.isAxiosError(err)) {
			void this.message(errorMessage);
			return Promise.reject();
		}

		const authState = AuthState.getInstance();
		const error = err as ErrorResponse;

		if (axios.isAxiosError(err) && authState.getSessionExpiredShown()) {
			return Promise.reject();
		}

		if (error.response?.data?.errors) {
			error.response.data.errors.forEach((error) => {
				if (error.code) {
					const translation = this.beErrorString(error.code);
					void this.message(translation, 7);
				} else {
					void this.message(errorMessage);
				}
			});
			return Promise.reject();
		}

		void this.message(errorMessage);
		return Promise.reject();
	}

	public static Instance(data: ConstructorParams): AxiosSingleton {
		return this._instance || (this._instance = new this({ ...data }));
	}

	private action<Request, Response>(
		method: "get" | "post" | "put" | "patch" | "delete",
		path: string,
		body?: Request | Record<string, never>,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<Response>> {
		return this.axios[method](path, body || {}, config || {});
	}

	public async get<Request, Response>(
		path: string,
		params?: Request
	): Promise<AxiosResponse<Response>> {
		return this.axios.get<Request, AxiosResponse<Response>>(path, { params });
	}

	public async post<Request, Response>(
		path: string,
		body?: Request | never,
		options?: AxiosRequestConfig
	) {
		return this.action<Request, Response>("post", path, body || {}, options);
	}

	public async put<Request, Response>(
		path: string,
		body?: Request | never,
		options?: AxiosRequestConfig
	) {
		return this.action<Request, Response>("put", path, body || {}, options);
	}

	public async patch<Request, Response>(path: string, body?: Request) {
		return this.action<Request, Response>("patch", path, body || {});
	}

	public async delete<Request, Response>(path: string) {
		return this.action<Request, Response>("delete", path);
	}

	public async downloadGeneratedFile(url: string, fileName: string): Promise<void> {
		try {
			const response = await this.axios.get(url, {
				responseType: "blob",
			});

			const blob = response.data;
			const urlObject = URL.createObjectURL(blob);

			const downloadLink = document.createElement("a");
			downloadLink.href = urlObject;
			downloadLink.download = fileName;

			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);

			URL.revokeObjectURL(urlObject);
		} catch (error) {
			console.error("Error downloading the file:", error);
			await this.error(error);
		}
	}
}
