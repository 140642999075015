import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, generatePath } from "react-router-dom";
import { PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { SettingsRoutes } from "src/Routes";
import SearchList, { DIRECT_OPEN_COLUMN } from "src/components/SearchList";
import { NEW_CHECKLIST_ID_URL_QUERY } from "./ChecklistDetailsPage";
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
    SearchField["checklist_type"] = "checklist_type";
    SearchField["product_id"] = "product_id";
    SearchField["is_active"] = "is_active";
})(SearchField || (SearchField = {}));
const SettingsChecklists = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { date: formatDate } = useFormatter();
    const ref = useRef();
    const { filterValues } = useSelector((state) => state.configState);
    const products = (filterValues === null || filterValues === void 0 ? void 0 : filterValues.products) || [];
    const checklistTypes = (filterValues === null || filterValues === void 0 ? void 0 : filterValues.checklist_types) || [];
    const columns = [
        {
            render: ({ id }) => (_jsx(Link, Object.assign({ to: generatePath(SettingsRoutes.SettingsChecklistDetails, {
                    checklistId: id.toString(),
                }) }, { children: _jsx(SelectOutlined, {}) }))),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "checklist_name",
            title: t("settings.checklists.checklist_name"),
        },
        {
            dataIndex: "checklist_type",
            title: t("settings.checklists.type"),
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("settings.checklists.product"),
        },
        {
            dataIndex: "created_at",
            title: t("settings.checklists.created_at"),
            render: (created_at) => formatDate(created_at),
        },
        {
            dataIndex: "is_active",
            title: t("settings.checklists.is_active"),
            render: (isActive) => isActive ? (_jsx(Tag, Object.assign({ color: "success" }, { children: t(`common.yes`) }))) : (_jsx(Tag, Object.assign({ color: "error" }, { children: t(`common.no`) }))),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.checklists"), extra: _jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => navigate(generatePath(SettingsRoutes.SettingsChecklistDetails, {
                        checklistId: NEW_CHECKLIST_ID_URL_QUERY,
                    })) }, { children: t("settings.checklists.add-btn") })) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsxs(SearchList, Object.assign({ ref: ref, endpoint: "/admin/checklists", columns: columns, showingOfString: "common.search.search-showing", rowKey: ({ id }) => id.toString() }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: "text" }, { children: _jsx(Input.Search, { placeholder: t("settings.checklists.search-placeholder"), allowClear: true }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.checklist_type }, { children: _jsx(Select, { disabled: !checklistTypes.length, placeholder: t("settings.checklists.type"), mode: "multiple", allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: checklistTypes.map((type) => ({
                                        label: type,
                                        value: type,
                                    })) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.product_id }, { children: _jsx(Select, { disabled: !products.length, placeholder: t("settings.checklists.product"), mode: "multiple", allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: products.map(({ product_id, product_name }) => ({
                                        label: product_name,
                                        value: product_id,
                                    })) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.is_active }, { children: _jsx(Select, { placeholder: t("settings.checklists.is_active"), allowClear: true, options: [
                                        { label: t("common.yes"), value: "true" },
                                        { label: t("common.no"), value: "false" },
                                    ] }) })) }))] })) }))] }));
};
export default SettingsChecklists;
