import { ComponentPropsWithRef, ReactNode, forwardRef } from "react";

import { Space, Typography } from "antd";
import styled from "styled-components";

const TopBar = styled.div`
	padding: 16px 24px;
	background: white;
	display: flex;
	justify-content: space-between;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9;

	.ant-typography {
		word-break: keep-all;
	}
`;

const Title = styled(Typography.Title)`
	height: 27px; // buttons height of 32px - 5px margin
	margin: 5px 0 0 0 !important;
`;

interface Props extends ComponentPropsWithRef<"div"> {
	title: string;
	titleExtra?: ReactNode;
}

const SecondaryHeader = forwardRef<HTMLDivElement, Props>(
	({ title, titleExtra, children, ...props }, ref) => {
		return (
			<TopBar ref={ref} className="secondary-header-wrapper" {...props}>
				<Space>
					<Title level={5}>{title}</Title>
					{titleExtra}
				</Space>
				{children}
			</TopBar>
		);
	}
);

SecondaryHeader.displayName = "SecondaryHeader";
export default SecondaryHeader;
