import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import {
	CellChange,
	CellLocation,
	Id,
	MenuOption,
	ReactGrid,
	SelectionMode,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { theme } from "antd";
import dayjs, { Dayjs } from "dayjs";

import {
	CompanyFinancialData,
	CompanyFinancialDataRequest,
	FinancialStatementType,
} from "@teylor-tools/Api";
import { rawDateFormat } from "@teylor-tools/utils/dateFormats";

import { useTranslations } from "../translations/translations";
import { FinancialsModal, StatementFormProps } from "./FinancialsModal";
import "./FinancialsTable.css";
import {
	HorizontalChevronCell,
	HorizontalChevronCellTemplate,
} from "./HorizontalChevronCellTemplate";
import { VerticalChevronCell, VerticalChevronCellTemplate } from "./VerticalChevronCellTemplate";
import { buildColumns } from "./columns";
import { HistoryChange } from "./history-changes.types";
import { HEADER_ROW_ID, ROW_DESCRIPTION_COLUMN_ID, buildRows } from "./rows";
import { RowId } from "./rows.types";
import { AllCellTypes, CustomNumberCell } from "./table.types";

interface Props {
	financials: CompanyFinancialData[];
	isEditing: boolean;
}

export type FinancialsTableHandle =
	| {
			resetTable: () => void;
			getDataToUpdate: () => CompanyFinancialDataRequest[];
	  }
	| undefined;

const isMacOs = () => window.navigator.appVersion.indexOf("Mac") !== -1;

const MAX_HISTORY_CHANGES = 30;

const handleFinancialInputCellChange = (
	financials: CompanyFinancialData[],
	rowId: Id,
	columnId: Id,
	newValue: number | undefined
) => {
	const financialIdx = financials.findIndex((f) => f.company_financial_data_id === columnId);
	if (financialIdx < 0) return financials;

	const newFinancials = [...financials];
	// @ts-ignore
	newFinancials[financialIdx].input_data[rowId] = newValue;

	return newFinancials;
};

const FinancialsTable = forwardRef<FinancialsTableHandle, Props>(
	({ financials, isEditing }, ref) => {
		const t = useTranslations();
		const {
			token: { colorPrimary },
		} = theme.useToken();
		const [financialsToRender, setFinancialsToRender] = useState<CompanyFinancialData[]>([]);
		const [collapsedRows, setCollapsedRows] = useState<Id[]>([]);
		const [collapsedColumns, setCollapsedColumns] = useState<Id[]>([]);
		const [deletedColumns, setDeletedColumns] = useState<Id[]>([]);
		const [editStatementData, setEditStatementData] = useState<{
			statementType: FinancialStatementType;
			statementDate: string;
			statementId: string;
		}>();
		const [changeHistory, setChangeHistory] = useState<HistoryChange[]>([]);
		const [changeHistoryIndex, setChangeHistoryIndex] = useState(0);
		const [descColumnWidth, setDescColumnWidth] = useState(300);

		useImperativeHandle(ref, () => ({
			resetTable: renderFromFinancials,
			getDataToUpdate: () =>
				financialsToRender.filter((f) => !deletedColumns.includes(f.company_financial_data_id)),
		}));

		const columns = useMemo(() => {
			if (!financialsToRender.length) return [];

			return buildColumns({
				financials: financialsToRender,
				collapsedColumns,
				deletedColumns,
				descColumnWidth,
			});
		}, [financialsToRender, collapsedColumns, deletedColumns, descColumnWidth]);

		const rows = useMemo(() => {
			if (!financialsToRender.length) return [];

			return buildRows({
				financials: financialsToRender,
				columns,
				collapsedRows,
				collapsedColumns,
				deletedColumns,
				isEditing,
				primaryColor: colorPrimary,
				t,
			});
		}, [
			financialsToRender,
			collapsedColumns,
			deletedColumns,
			columns,
			collapsedRows,
			isEditing,
			colorPrimary,
			t,
		]);

		const renderFromFinancials = () => {
			setFinancialsToRender(financials);
			setDeletedColumns([]);
			setChangeHistoryIndex(-1);
			setChangeHistory([]);
		};

		const addChangeHistory = (change: HistoryChange) => {
			setChangeHistory((prev) => [
				change,
				...prev.slice(changeHistoryIndex === -1 ? 0 : changeHistoryIndex + 1, MAX_HISTORY_CHANGES),
			]);
			setChangeHistoryIndex(-1);
		};

		const handleChanges = (changes: CellChange<AllCellTypes>[]) => {
			if (changes.length === 1) {
				const change = changes[0];

				switch (change.newCell.type) {
					case "verticalChevron":
						setCollapsedRows((prev) =>
							(change.newCell as VerticalChevronCell).isExpanded
								? prev.filter((rowId) => rowId !== change.rowId)
								: [...prev, change.rowId]
						);
						addChangeHistory({
							type: change.newCell.isExpanded ? "expandRow" : "collapseRow",
							rowId: change.rowId as RowId,
						});
						break;
					case "horizontalChevron":
						setCollapsedColumns((prev) =>
							(change.newCell as HorizontalChevronCell).isExpanded
								? prev.filter((colId) => colId !== change.columnId)
								: [...prev, change.columnId]
						);
						addChangeHistory({
							type: change.newCell.isExpanded ? "expandColumn" : "collapseColumn",
							columnId: change.columnId,
						});
						break;
					default:
						setFinancialsToRender((prev) =>
							handleFinancialInputCellChange(
								prev,
								change.rowId,
								change.columnId,
								(change.newCell as CustomNumberCell).value
							)
						);
						addChangeHistory({
							type: "changeInputData",
							rowId: change.rowId as RowId,
							columnId: change.columnId,
							previousValue: (change.previousCell as CustomNumberCell).value,
							newValue: (change.newCell as CustomNumberCell).value,
						});
				}

				return;
			}

			setFinancialsToRender((prev) =>
				changes.reduce(
					(acc, change) =>
						handleFinancialInputCellChange(
							prev,
							change.rowId,
							change.columnId,
							(change.newCell as CustomNumberCell).value
						),
					[...prev]
				)
			);
			addChangeHistory({
				type: "bulkChangeInputData",
				changes: changes.map((change) => ({
					rowId: change.rowId as RowId,
					columnId: change.columnId,
					previousValue: (change.previousCell as CustomNumberCell).value,
					newValue: (change.newCell as CustomNumberCell).value,
				})),
			});
		};

		const simpleHandleContextMenu = (
			selectedRowIds: Id[],
			selectedColIds: Id[],
			selectionMode: SelectionMode,
			menuOptions: MenuOption[],
			selectedRanges: Array<CellLocation[]>
		): MenuOption[] => {
			if (!selectedRanges.length) return [];

			if (
				isEditing &&
				selectedRanges.length === 1 &&
				selectedRanges[0][0].rowId === HEADER_ROW_ID &&
				selectedRanges[0][0].columnId !== ROW_DESCRIPTION_COLUMN_ID
			) {
				return [
					...menuOptions,
					{
						id: "editStatement",
						label: t.financialsTable.statementContextMenu.settings,
						handler: () => {
							const financial = financialsToRender.find(
								(f) => f.company_financial_data_id === selectedRanges[0][0].columnId
							);

							if (!financial) return;

							setEditStatementData({
								statementType: financial.statement_type,
								statementDate: financial.financials_date,
								statementId: financial.company_financial_data_id,
							});
						},
					},
					{
						id: "removeStatement",
						label: t.financialsTable.statementContextMenu.delete,
						handler: () => {
							setDeletedColumns((prev) => [...prev, selectedRanges[0][0].columnId]);
							addChangeHistory({
								type: "deleteStatement",
								columnId: selectedRanges[0][0].columnId,
							});
						},
					},
				];
			}

			if (!isEditing) {
				return menuOptions.filter((option) => !["cut", "paste"].includes(option.id));
			}

			return menuOptions;
		};

		const changeFinancialSettings = (id: Id, type: FinancialStatementType, date: Dayjs) => {
			const newFinancials = [...financialsToRender];

			const financialIdx = newFinancials.findIndex((f) => f.company_financial_data_id === id);
			if (financialIdx < 0) return newFinancials;

			newFinancials[financialIdx].statement_type = type;
			newFinancials[financialIdx].financials_date = date.format(rawDateFormat);

			newFinancials.sort((a, b) =>
				dayjs(b.financials_date).isAfter(dayjs(a.financials_date)) ? 1 : -1
			);
			setFinancialsToRender(newFinancials);
		};

		const handleFinancialSettingsChange = ({
			statementType,
			statementDate,
		}: StatementFormProps) => {
			if (!editStatementData) return;

			changeFinancialSettings(editStatementData.statementId, statementType, statementDate);

			setEditStatementData(undefined);
			addChangeHistory({
				type: "editStatement",
				columnId: editStatementData.statementId,
				previousDate: dayjs(editStatementData.statementDate),
				previousType: editStatementData.statementType,
				newDate: statementDate,
				newType: statementType,
			});
		};

		const handleWrapperKeydown = (e: KeyboardEvent) => {
			if (!isEditing) return;

			const ctrlKey = (!isMacOs() && e.ctrlKey) || e.metaKey;

			if ((ctrlKey && e.shiftKey && e.key === "z") || (ctrlKey && e.key === "y")) {
				e.preventDefault();
				handleRedoChange();
				return;
			}

			if (ctrlKey && e.key === "z") {
				e.preventDefault();
				handleUndoChange();
				return;
			}
		};

		const handleHistoryChange = (change: HistoryChange, type: "undo" | "redo") => {
			switch (change.type) {
				case "collapseRow":
					setCollapsedRows((prev) =>
						type === "undo"
							? prev.filter((rowId) => rowId !== change.rowId)
							: [...prev, change.rowId]
					);
					break;
				case "collapseColumn":
					setCollapsedColumns((prev) =>
						type === "undo"
							? prev.filter((columnId) => columnId !== change.columnId)
							: [...prev, change.columnId]
					);
					break;
				case "expandRow":
					setCollapsedRows((prev) =>
						type === "redo"
							? prev.filter((rowId) => rowId !== change.rowId)
							: [...prev, change.rowId]
					);
					break;
				case "expandColumn":
					setCollapsedColumns((prev) =>
						type === "redo"
							? prev.filter((columnId) => columnId !== change.columnId)
							: [...prev, change.columnId]
					);
					break;
				case "deleteStatement":
					setDeletedColumns((prev) =>
						type === "undo"
							? prev.filter((columnId) => columnId !== change.columnId)
							: [...prev, change.columnId]
					);
					break;
				case "editStatement":
					changeFinancialSettings(
						change.columnId,
						type === "undo" ? change.previousType : change.newType,
						type === "undo" ? change.previousDate : change.newDate
					);
					break;
				case "changeInputData":
					setFinancialsToRender((prev) =>
						handleFinancialInputCellChange(
							prev,
							change.rowId,
							change.columnId,
							type === "undo" ? change.previousValue : change.newValue
						)
					);
					break;
				case "bulkChangeInputData":
					setFinancialsToRender((prev) =>
						change.changes.reduce(
							(acc, change) =>
								handleFinancialInputCellChange(
									prev,
									change.rowId,
									change.columnId,
									type === "undo" ? change.previousValue : change.newValue
								),
							[...prev]
						)
					);
					break;
			}
		};

		const handleUndoChange = () => {
			if (!changeHistory.length || changeHistoryIndex >= changeHistory.length - 1) return;

			const newIndex = changeHistoryIndex + 1;
			handleHistoryChange(changeHistory[newIndex], "undo");
			setChangeHistoryIndex(newIndex);
		};

		const handleRedoChange = () => {
			if (!changeHistory.length || changeHistoryIndex < 0) return;

			handleHistoryChange(changeHistory[changeHistoryIndex], "redo");
			setChangeHistoryIndex(changeHistoryIndex - 1);
		};

		const handleColumnResize = (id: Id, width: number) => setDescColumnWidth(width);

		useEffect(renderFromFinancials, [financials]);

		if (columns.length <= 1) return null;

		return (
			<div
				data-cy="financials-table"
				onKeyDown={(e) => handleWrapperKeydown(e as unknown as KeyboardEvent)}
				/* fix for undo/redo when clicking first directly at chevron */
				/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
				tabIndex={0}
			>
				<ReactGrid
					rows={rows}
					columns={columns}
					onCellsChanged={handleChanges}
					onContextMenu={simpleHandleContextMenu}
					onColumnResized={handleColumnResize}
					enableRangeSelection
					enableRowSelection
					enableColumnSelection
					stickyLeftColumns={1}
					stickyTopRows={1}
					customCellTemplates={{
						horizontalChevron: new HorizontalChevronCellTemplate(),
						verticalChevron: new VerticalChevronCellTemplate(),
					}}
				/>
				{editStatementData && (
					<FinancialsModal
						statementType={editStatementData.statementType}
						statementDate={editStatementData.statementDate}
						onCancel={() => setEditStatementData(undefined)}
						onFinish={handleFinancialSettingsChange}
					/>
				)}
			</div>
		);
	}
);

FinancialsTable.displayName = "FinancialsTable";
export default FinancialsTable;
