var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { App, Button, Spin, Tabs } from "antd";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "src/utils/Axios";
import { SettingsRoutes } from "../../../Routes";
import ChecklistConfiguratorTab from "./ChecklistConfiguratorTab";
import ChecklistDetailsTab from "./ChecklistDetailsTab";
import { displayTemplateErrors } from "./checklist-configurator/display-template-errors";
export const NEW_CHECKLIST_ID_URL_QUERY = "new";
const SpinWrapper = styled.div `
	text-align: center;
	margin: 10%;
`;
const ChecklistWrapper = styled.div `
	height: calc(100vh - 175px);
	overflow: auto;
	padding-right: 16px;
`;
const getChecklistData = (checklistId) => __awaiter(void 0, void 0, void 0, function* () {
    if (!checklistId)
        return;
    if (checklistId === NEW_CHECKLIST_ID_URL_QUERY) {
        const newChecklist = {
            id: -1,
            checklist_template: undefined,
            product: undefined,
            type: undefined,
            is_active: false,
        };
        return newChecklist;
    }
    const response = yield Axios.get(`admin/checklists/${checklistId}`);
    return response.data;
});
const createChecklist = (checklist) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Axios.post(`admin/checklists`, checklist);
    return response.data;
});
const ChecklistDetailsPage = () => {
    var _a;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const navigate = useNavigate();
    const params = useParams();
    const configuratorRef = useRef();
    const detailsRef = useRef();
    const isNewChecklistView = params.checklistId === NEW_CHECKLIST_ID_URL_QUERY;
    const { data: checklist, isFetching } = useQuery({
        queryKey: ["getChecklistData"],
        queryFn: () => getChecklistData(params.checklistId),
    });
    const { isPending: isCreatingChecklist, mutate: createNewChecklist } = useMutation({
        mutationKey: ["createChecklist"],
        mutationFn: createChecklist,
        onSuccess: (data) => navigate(generatePath(SettingsRoutes.SettingsChecklistDetails, {
            checklistId: `${data.id}`,
        })),
    });
    const tabItems = [
        {
            key: "details",
            label: t("settings.checklists.details-tab.title"),
            children: _jsx(ChecklistDetailsTab, { checklist: checklist, ref: detailsRef }),
        },
        {
            key: "configuration",
            label: t("settings.checklists.config-tab.title"),
            children: (_jsx(ChecklistWrapper, { children: _jsx(ChecklistConfiguratorTab, { checklistTemplate: (_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklist_template) === null || _a === void 0 ? void 0 : _a.groups, ref: configuratorRef }) })),
            forceRender: true,
        },
    ];
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        const details = yield ((_b = detailsRef.current) === null || _b === void 0 ? void 0 : _b.getDetails().catch(() => void message.error(t("settings.checklists.errors.details-form-contains-errors"))));
        const template = yield ((_c = configuratorRef.current) === null || _c === void 0 ? void 0 : _c.getTemplate().catch((err) => displayTemplateErrors(err, message)));
        if (!details || !template)
            return;
        if (isNewChecklistView) {
            const payload = Object.assign(Object.assign({}, details), (template && {
                checklist_template: {
                    groups: template,
                },
            }));
            createNewChecklist(payload);
            return;
        }
        // TODO: Edit checklist ->  BE not ready yet
    });
    const renderContent = () => {
        if (isFetching) {
            return (_jsx(SpinWrapper, { children: _jsx(Spin, {}) }));
        }
        if (!isNewChecklistView && !isFetching && !checklist) {
            throw Error();
        }
        return _jsx(Tabs, { defaultActiveKey: "details", items: tabItems });
    };
    return (_jsxs(Spin, Object.assign({ spinning: isCreatingChecklist }, { children: [_jsx(MainHeader, { title: isNewChecklistView
                    ? t("settings.checklists.page-title.add-checklist")
                    : t("settings.checklists.page-title.edit-checklist"), extra: _jsx(Button, Object.assign({ type: "primary", onClick: () => void handleSave(), disabled: !isNewChecklistView }, { children: t("common.save") })) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: renderContent() }))] })));
};
export default ChecklistDetailsPage;
