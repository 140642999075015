import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Space, Table, Tag } from "antd";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "src/utils/Axios";
const SettingsRolesPage = () => {
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        Axios.get(`admin/bo_users/roles`)
            .then(({ data }) => {
            setRoles(data);
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setLoading(false));
    }, []);
    const columns = useMemo(() => {
        return [
            {
                dataIndex: "name",
                title: t("settings.roles.overview.name"),
                width: 250,
            },
            {
                dataIndex: "permissions",
                title: t("settings.roles.overview.permissions"),
                render: (permissions) => {
                    const permissionsList = (permissions === null || permissions === void 0 ? void 0 : permissions.map((permission) => {
                        return `${permission.resource}:${permission.action}`;
                    })) || [];
                    const permissionsToDisplay = permissionsList.slice(0, 2);
                    const morePermissions = permissionsList.slice(2);
                    return (_jsxs(Space, Object.assign({ size: [0, 8], wrap: true }, { children: [permissionsToDisplay.map((permission) => (_jsx(Tag, { children: permission }, permission))), morePermissions.length > 0 && (_jsx(Popover, Object.assign({ content: _jsx(Space, Object.assign({ direction: "vertical", size: [0, 8], wrap: true }, { children: morePermissions.map((permission) => (_jsx(Tag, { children: permission }, permission))) })) }, { children: _jsx(Tag, { children: `+ ${morePermissions.length}` }) })))] })));
                },
            },
            {
                dataIndex: "description",
                title: t("settings.roles.overview.description"),
                ellipsis: true,
            },
        ];
    }, [t]);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.roles") }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "id", dataSource: roles, pagination: false }) }))] }));
};
export default SettingsRolesPage;
