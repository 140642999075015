import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { BOUserFeature } from "@teylor-tools/Api";
import { SideActions, useApplication, useLayout, } from "src/layouts/ApplicationLayout/application.types";
import AnalysisQuantitative from "../../../modules/AnalysisQuantitative/AnalysisQuantitative";
const ApplicationQuantitative = () => {
    const { application } = useApplication();
    const { setActiveMenu } = useLayout();
    const { features: boUserFeatures } = useSelector(({ profileState }) => profileState);
    return (_jsx(AnalysisQuantitative, { applicationId: application.applicationId, openAnalystReport: () => setActiveMenu(SideActions.analystReport), apiBasePath: `/admin/applications/${application.applicationId}`, showUnapproveScoringBtn: boUserFeatures === null || boUserFeatures === void 0 ? void 0 : boUserFeatures.includes(BOUserFeature.CompanyScore) }));
};
export default ApplicationQuantitative;
