import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BlockOutlined } from "@ant-design/icons";
import { Card, Space, Typography } from "antd";
import styled from "styled-components";
import { DocumentType, EntityKind } from "@teylor-tools/Api";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { DocumentSearchField } from "src/pages/modules/Documents/DocumentsSearch";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const NoDataWrapper = styled(Card) `
	text-align: center;
	padding: 60px 92px;
	display: flex;
	flex-direction: column;
	margin: 0 70px;

	& .ant-card-body {
		padding: 0;
	}

	@media (max-width: 768px) {
		padding: 30px;
	}
`;
const BlockIcon = styled(BlockOutlined) `
	font-size: 19px;
	color: ${(props) => props.theme.color_primary};
`;
export const ViewExportTab = () => {
    const { t } = useTranslation();
    const { application: { applicationId }, } = useApplication();
    return (_jsx(DocumentsTab, { tabTitle: t("application.factoring.tab-view-exports"), entityKind: EntityKind.Application, entityId: applicationId, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: (search) => Axios.get(`admin/applications/${applicationId}/documents`, Object.assign(Object.assign({}, search), { document_type: DocumentType.FactoringForm })), apiGetArchivePath: `admin/applications/${applicationId}/files_archive`, allowUpload: false, allowDelete: true, editableFields: [], showCategoryTitle: false, showTypeTitle: false, enableToggleClientVisibility: false, emptyScreen: _jsx(NoDataWrapper, { children: _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(BlockIcon, {}), _jsx(Text, Object.assign({ strong: true }, { children: t("application.factoring.view-exports.no-exports-title") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.factoring.view-exports.no-exports-text") }))] })) }), decreaseContentHeightByPx: 46, searchFields: [DocumentSearchField.name] }));
};
