var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { App, Button, Col, Form, Row, Skeleton, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import styled from "styled-components";
import { Currency } from "@teylor-tools/Api";
import useDebounce from "@teylor-tools/hooks/debounce";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import EmptyData from "src/components/ui/EmptyData";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const StatsWrapper = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 8px;
	min-height: 24px;
`;
const TotalWrapper = styled.div `
	display: flex;
	flex-direction: column;
`;
export const DIRECT_OPEN_COLUMN = "direct-open-column";
const StyledTable = styled(Table) `
	.ant-table-title {
		padding-left: 0;
		padding-right: 0;
	}

	.ant-col {
		margin: 0 4px;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.ant-pagination-item-active {
		border-color: ${({ theme }) => theme.colorPrimary};

		a {
			color: ${({ theme }) => theme.colorPrimary};
		}
	}

	.${DIRECT_OPEN_COLUMN} {
		padding: 0 8px !important;
		width: 32px;

		span {
			font-size: 16px;
		}
	}
`;
const DEFAULT_PAGE = "1";
const DEFAULT_PAGE_SIZE = "20";
// eslint-disable-next-line
const filterObjectValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v && (Array.isArray(v) ? v.length : true)));
const SearchList = forwardRef((_a, ref) => {
    var _b, _c, _d;
    var { children, columns, endpoint, showingOfString, savedFiltersStorageKey, formLayout = "horizontal", showAmounts = false, showBalances = false } = _a, props = __rest(_a, ["children", "columns", "endpoint", "showingOfString", "savedFiltersStorageKey", "formLayout", "showAmounts", "showBalances"]);
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [form] = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState();
    const [formValues, setFormValues] = useState();
    const debouncedFormValues = useDebounce(formValues, 500);
    const [allFiltersSet, setAllFiltersSet] = useState(false);
    const saveFiltersEnabled = !!savedFiltersStorageKey;
    const totalRows = pagination === null || pagination === void 0 ? void 0 : pagination.total_rows;
    const statsAmounts = (_b = pagination === null || pagination === void 0 ? void 0 : pagination.stats) === null || _b === void 0 ? void 0 : _b.amount;
    const searchParamsValues = useMemo(() => {
        const values = Object.fromEntries(searchParams);
        return Object.assign(Object.assign({}, values), { page: values.page || DEFAULT_PAGE, page_size: values.page_size || DEFAULT_PAGE_SIZE });
    }, [searchParams]);
    const handleFilterChange = () => {
        setFormValues(Object.assign({}, form.getFieldsValue()));
    };
    const { currency } = useFormatter();
    const handleFilterSave = () => {
        if (!saveFiltersEnabled)
            return;
        localStorage.setItem(savedFiltersStorageKey, JSON.stringify(form.getFieldsValue()));
        void message.success(t("common.filters-saved"));
    };
    const handleTableChange = (pagination, filters, sort) => {
        var _a, _b, _c, _d, _e, _f;
        // reset page to 1 if page size was changed
        const page = ((_a = pagination.pageSize) === null || _a === void 0 ? void 0 : _a.toString()) !== searchParamsValues.page_size
            ? DEFAULT_PAGE
            : (_b = pagination.current) === null || _b === void 0 ? void 0 : _b.toString();
        updateSearchParams(Object.assign(Object.assign({}, searchParamsValues), { page, page_size: (_c = pagination.pageSize) === null || _c === void 0 ? void 0 : _c.toString(), sort_by: (_e = (_d = sort.column) === null || _d === void 0 ? void 0 : _d.dataIndex) === null || _e === void 0 ? void 0 : _e.toString(), sort_order: (_f = sort.order) === null || _f === void 0 ? void 0 : _f.toString() }));
    };
    const showBalance = (statName) => {
        var _a;
        const stat = (_a = pagination === null || pagination === void 0 ? void 0 : pagination.stats) === null || _a === void 0 ? void 0 : _a[statName];
        if (!stat)
            return null;
        const render = (amount, curr) => (_jsx(Text, { children: t(`loans.${statName}`, {
                amount: currency(amount || "0", {
                    currency: curr,
                }),
            }) }));
        return (_jsxs(TotalWrapper, { children: [stat.EUR && render(stat.EUR.total, Currency.EUR), stat.CHF && render(stat.CHF.total, Currency.CHF)] }));
    };
    const getTableData = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const decodedFilter = new URLSearchParams(filterObjectValues(searchParamsValues));
        return Axios.get(`${endpoint}?${decodedFilter}`)
            .then(({ data }) => {
            setTableData(data.result || []);
            setPagination(data.pagination);
            return data.result;
        })
            .finally(() => setLoading(false));
    });
    useImperativeHandle(ref, () => ({
        getTableData,
    }));
    const updateSearchParams = (v) => {
        const filteredConfig = filterObjectValues(v);
        const configKeys = Object.keys(filteredConfig);
        const config = configKeys.reduce((acc, configKey) => {
            if (configKey === "page" && filteredConfig[configKey] === DEFAULT_PAGE) {
                return acc;
            }
            if (configKey === "page_size" && filteredConfig[configKey] === DEFAULT_PAGE_SIZE) {
                return acc;
            }
            // clearing filters
            if (["payout_from", "payout_to"].includes(configKey) &&
                "payout_date" in v &&
                !v["payout_date"]) {
                return acc;
            }
            if (configKey === "payout_date") {
                const dateRange = filteredConfig[configKey];
                if (dateRange === null || dateRange === void 0 ? void 0 : dateRange[0]) {
                    acc["payout_from"] = dayjs(dateRange[0]).format("YYYY-MM-DD");
                }
                if (dateRange === null || dateRange === void 0 ? void 0 : dateRange[1]) {
                    acc["payout_to"] = dayjs(dateRange[1]).format("YYYY-MM-DD");
                }
            }
            else {
                acc[configKey] = filteredConfig[configKey];
            }
            return acc;
        }, {});
        setSearchParams(new URLSearchParams(config).toString());
    };
    const updateFormValues = () => {
        const paramsArray = Object.entries(searchParamsValues);
        if (!paramsArray.length) {
            return form.resetFields();
        }
        const values = paramsArray.reduce((acc, [key, value]) => {
            var _a, _b;
            const val = value;
            if (key === "payout_from") {
                acc["payout_date"] = [dayjs(val, "YYYY-MM-DD"), ((_a = acc["payout_date"]) === null || _a === void 0 ? void 0 : _a[1]) || null];
            }
            else if (key === "payout_to") {
                acc["payout_date"] = [((_b = acc["payout_date"]) === null || _b === void 0 ? void 0 : _b[0]) || null, dayjs(val, "YYYY-MM-DD")];
            }
            else if (val === null || val === void 0 ? void 0 : val.includes(",")) {
                acc[key] = [...val.split(",")];
            }
            else {
                // <Select mode="multiple" /> requires an array
                if (key === "status" || key === "partner_id" || key === "product_id") {
                    acc[key] = [val];
                }
                else {
                    acc[key] = val;
                }
            }
            return acc;
        }, {});
        form.setFieldsValue(Object.assign({}, values));
    };
    const showFiltersBtns = useMemo(() => {
        return [
            searchParamsValues.text,
            searchParamsValues.status,
            searchParamsValues.partner_id,
            searchParamsValues.product_id,
            searchParamsValues.assignee_id,
            searchParamsValues.reporter_id,
            searchParamsValues.priority,
            searchParamsValues.is_eligible,
            searchParamsValues.payout_from,
            searchParamsValues.payout_to,
            searchParamsValues.portfolio_id,
            searchParamsValues.compartment_id,
        ].some((filter) => filter && filter !== "");
    }, [searchParamsValues]);
    useEffect(() => {
        if (!allFiltersSet)
            return;
        updateSearchParams(Object.assign(Object.assign(Object.assign({}, searchParamsValues), formValues), { page: DEFAULT_PAGE }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFormValues]);
    useEffect(() => {
        if (!allFiltersSet)
            return;
        updateFormValues();
        void getTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, allFiltersSet]);
    useEffect(() => {
        // if URL params are available use them
        const searchParamsValues = Object.values(Object.fromEntries(searchParams)).filter((v) => !!v);
        if (searchParamsValues.length) {
            setAllFiltersSet(true);
            return;
        }
        // otherwise check for local storage and update search params
        if (saveFiltersEnabled) {
            const savedSession = localStorage.getItem(savedFiltersStorageKey);
            savedSession &&
                updateSearchParams(Object.assign(Object.assign(Object.assign({}, searchParamsValues), JSON.parse(savedSession)), { page: DEFAULT_PAGE }));
        }
        setAllFiltersSet(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs("div", Object.assign({ style: { padding: "16px 24px" } }, { children: [children && (_jsx(Form, Object.assign({ form: form, onValuesChange: handleFilterChange, layout: formLayout }, { children: _jsxs(Row, Object.assign({ gutter: 8 }, { children: [children, showFiltersBtns && (_jsxs(_Fragment, { children: [saveFiltersEnabled && (_jsx(Col, Object.assign({ span: 2 }, { children: _jsx(Button, Object.assign({ type: "primary", style: { width: "100%" }, onClick: handleFilterSave }, { children: t("common.save") })) }))), _jsx(Col, Object.assign({ span: 2 }, { children: _jsx(Button, Object.assign({ type: "text", style: { color: "rgba(0,0,0,.45)" }, onClick: () => {
                                            form.resetFields();
                                            handleFilterChange();
                                            savedFiltersStorageKey && localStorage.removeItem(savedFiltersStorageKey);
                                        } }, { children: t("common.clear") })) }))] }))] })) }))), loading ? (_jsxs(StatsWrapper, { children: [_jsx(Skeleton, { title: { width: 150 }, paragraph: false }), showAmounts && _jsx(Skeleton, { title: true, paragraph: false })] })) : (_jsxs(StatsWrapper, { children: [_jsx(Text, { children: _jsx(_Fragment, { children: _jsxs(Trans, Object.assign({ i18nKey: showingOfString || "common.search.search-showing" }, { children: [{ items: tableData.length }, " ", { of: totalRows }] })) }) }), showAmounts && (_jsxs(_Fragment, { children: [showBalances && (_jsxs(_Fragment, { children: [showBalance("outstanding_balance_total"), showBalance("outstanding_balance_principal"), showBalance("outstanding_balance_interest")] })), _jsxs(TotalWrapper, { children: [(statsAmounts === null || statsAmounts === void 0 ? void 0 : statsAmounts.EUR) && (_jsx(Text, { children: t("total_amount", {
                                            amount: currency(((_c = statsAmounts === null || statsAmounts === void 0 ? void 0 : statsAmounts.EUR) === null || _c === void 0 ? void 0 : _c.total) || 0, {
                                                currency: Currency.EUR,
                                            }),
                                        }) })), (statsAmounts === null || statsAmounts === void 0 ? void 0 : statsAmounts.CHF) && (_jsx(Text, { children: t("total_amount", {
                                            amount: currency(((_d = statsAmounts === null || statsAmounts === void 0 ? void 0 : statsAmounts.CHF) === null || _d === void 0 ? void 0 : _d.total) || 0, {
                                                currency: Currency.CHF,
                                            }),
                                        }) }))] })] }))] })), _jsx(StyledTable, Object.assign({ loading: loading, columns: columns, onChange: (pagination, filter, sorter) => handleTableChange(pagination, filter, sorter), dataSource: tableData, pagination: {
                    pageSize: Number(searchParamsValues.page_size || DEFAULT_PAGE_SIZE),
                    total: totalRows,
                    current: Number(searchParamsValues.page || DEFAULT_PAGE),
                    position: ["bottomRight"],
                    showSizeChanger: true,
                }, scroll: { x: "100%" }, locale: { emptyText: _jsx(EmptyData, {}) } }, props))] })));
});
SearchList.displayName = "SearchList";
export default SearchList;
