import { CompanyScore } from "@teylor-tools/Api";

import { DataCard, DataCardItem } from "../../data-display/DataCard";
import { useTranslations } from "../../translations/translations";

type Scores = Pick<CompanyScore, "pd_score" | "final_score" | "benchmark_result">;

const OverviewScores = ({ scores }: { scores: Scores }) => {
	const t = useTranslations();

	const ratingScores: { year: number; score: number }[] = (scores.benchmark_result || []).map(
		(result) => ({
			year: result.period_end,
			score: result.total_score,
		})
	);

	return (
		<DataCard title={t.scoring.scores.scores}>
			<DataCardItem label={t.scoring.scores.pdScore} value={scores.pd_score} />
			{ratingScores.map((score) => (
				<DataCardItem
					key={score.year}
					label={t.scoring.scores.ratingScore(score.year)}
					value={score.score}
				/>
			))}
			<DataCardItem label={t.scoring.scores.totalScore} value={scores.final_score} />
		</DataCard>
	);
};

export default OverviewScores;
