var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { App, Button, Col, Input, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import { Axios } from "src/utils/Axios";
import SearchList, { DIRECT_OPEN_COLUMN } from "../../components/SearchList";
import PartnerPreview from "./PartnerPreview";
import PartnerModal from "./subpages/PartnerModal";
const PartnersPage = () => {
    const { message } = App.useApp();
    const { t } = useTranslation();
    const { date, percentToDecimal } = useFormatter();
    const [partner, setPartner] = useState();
    const [addPartner, setAddPartner] = useState(false);
    const ref = useRef();
    const handleAddPartner = (values) => {
        const { bank_name, iban, bic, street_line_1, street_line_2, city, state_province_region, postal_code, country, commission_rate } = values, rest = __rest(values, ["bank_name", "iban", "bic", "street_line_1", "street_line_2", "city", "state_province_region", "postal_code", "country", "commission_rate"]);
        Axios.post(`/admin/partners`, Object.assign(Object.assign({}, rest), { commission_rate: percentToDecimal(commission_rate), type: "", bank_info: { bank_name, iban, bic }, country, address: {
                street_line_1,
                street_line_2,
                city,
                state_province_region,
                postal_code,
                country,
            } }))
            .then(() => {
            var _a;
            void ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getTableData());
            void message.success(t("partners.add_success"));
            setAddPartner(false);
        })
            .catch((err) => Axios.error(err));
    };
    const columns = [
        {
            render: (partner) => (_jsx(Link, Object.assign({ to: generatePath(Routes.Partner, {
                    partnerId: partner.partner_id,
                }), "data-cy": "view-partner-button" }, { children: _jsx(SelectOutlined, {}) }))),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "name",
            title: t("partners.name"),
        },
        {
            dataIndex: "type",
            title: t("partners.type"),
        },
        {
            dataIndex: "is_enabled",
            title: t("partners.status"),
            render(is_enabled) {
                return is_enabled ? (_jsx(Tag, { children: t("partners.status.active") })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: t("partners.status.disabled") })));
            },
        },
        {
            dataIndex: "active_since",
            title: t("partners.active_since"),
            render(active_since) {
                return date(active_since);
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("partners.title"), extra: _jsx(Button, Object.assign({ type: "primary", ghost: true, icon: _jsx(PlusOutlined, {}), onClick: () => setAddPartner(true), "data-cy": "add-partner-button" }, { children: t("partners.add_partner") })) }), _jsx(SearchList, Object.assign({ ref: ref, endpoint: "/admin/partners", columns: columns, showingOfString: "partners.search.search-showing", rowKey: ({ partner_id }) => partner_id, onRow: (record) => {
                    return {
                        onClick(evt) {
                            if (!evt.target.closest(".ant-dropdown-menu")) {
                                setPartner(record);
                            }
                        },
                    };
                } }, { children: _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: "text" }, { children: _jsx(Input.Search, { placeholder: t("partners.search.placeholder"), allowClear: true }) })) })) })), _jsx(PartnerPreview, { partner: partner, onClose: () => setPartner(undefined) }), addPartner && (_jsx(PartnerModal, { onCancel: () => setAddPartner(false), onFinish: handleAddPartner }))] }));
};
export default PartnersPage;
