import { ForwardRefRenderFunction, forwardRef, useState } from "react";

import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import {
	Button as AntdButton,
	Divider as AntdDivider,
	Menu as AntdMenu,
	MenuProps,
	MenuRef,
} from "antd";
import styled, { css } from "styled-components";

import MainHeader from "../main-header/MainHeader";

const MenuWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 100%;
`;

const StyledMenu = styled(AntdMenu)`
	flex-grow: 1;
	${({ inlineCollapsed }) =>
		inlineCollapsed &&
		css`
			width: 54px !important;
		`}
`;

const Collapse = styled.div<{ $isCollapsed: boolean }>`
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: ${({ $isCollapsed }) => ($isCollapsed ? "center" : "flex-start")};
`;

const Divider = styled(AntdDivider)`
	margin: 20px 0;
`;

const Button = styled(AntdButton)`
	color: ${({ theme }) => theme.colorPrimaryText};
`;

const Header = styled(MainHeader)`
	border-right: 1px solid rgba(5, 5, 5, 0.06);
`;

interface BaseMenuProps extends Omit<MenuProps, "inlineCollapsed"> {
	isCollapsible?: never;
	collapseText?: never;
	withHeader?: never;
	headerTitle?: never;
}

interface CollapsedMenuProps extends Omit<MenuProps, "inlineCollapsed"> {
	isCollapsible: true;
	collapseText: string;
	withHeader?: never;
	headerTitle?: never;
}

interface HeaderMenuProps extends Omit<MenuProps, "inlineCollapsed"> {
	isCollapsible?: never;
	collapseText?: never;
	withHeader: true;
	headerTitle: string;
}

interface CollapsedHeaderMenuProps extends Omit<MenuProps, "inlineCollapsed"> {
	isCollapsible: true;
	collapseText: string;
	withHeader: true;
	headerTitle: string;
}

type CustomMenuProps =
	| BaseMenuProps
	| CollapsedMenuProps
	| HeaderMenuProps
	| CollapsedHeaderMenuProps;

const Menu: ForwardRefRenderFunction<MenuRef, CustomMenuProps> = (
	{ isCollapsible, collapseText, withHeader, headerTitle, ...props },
	ref
) => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	return (
		<MenuWrapper>
			{withHeader && <Header title={headerTitle} />}
			<StyledMenu ref={ref} {...props} {...(isCollapsible && { inlineCollapsed: isCollapsed })} />
			{isCollapsible && (
				<>
					{!isCollapsed && <Divider />}
					<Collapse $isCollapsed={isCollapsed}>
						<Button
							type="link"
							icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
							onClick={() => setIsCollapsed(!isCollapsed)}
						>
							{!isCollapsed && collapseText}
						</Button>
					</Collapse>
				</>
			)}
		</MenuWrapper>
	);
};

Menu.displayName = "Menu";
export default forwardRef(Menu);
