import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tabs as AntdTabs } from "antd";
import styled from "styled-components";
import { FactoringTab } from "./tabs/FactoringTab";
import { ViewExportTab } from "./tabs/ViewExportTab";
const Tabs = styled(AntdTabs) `
	.ant-tabs-nav {
		background-color: #fff;
		padding-left: 24px;
		padding-right: 24px;
		margin: 0;
	}
`;
export const ApplicationFactoring = () => {
    const { t } = useTranslation();
    const items = [
        {
            key: "factoring",
            label: t("application.menu.factoring"),
            children: _jsx(FactoringTab, {}),
        },
        {
            key: "view_exports",
            label: t("application.factoring.tab-view-exports"),
            children: _jsx(ViewExportTab, {}),
        },
    ];
    return _jsx(Tabs, { items: items });
};
