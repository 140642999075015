import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "../../../utils/Axios";
import FundingProviderPreview from "./FundingProviderPreview";
const SettingsFundingProviders = () => {
    const { t } = useTranslation();
    const [providers, setProviders] = useState([]);
    const [selectedProviderId, setSelectedProviderId] = useState();
    const [showDrawer, setShowDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const getProviders = useCallback(() => {
        setLoading(true);
        void Axios.get(`admin/funding_providers`)
            .then(({ data }) => setProviders(data), (error) => void Axios.error(error))
            .finally(() => setLoading(false));
    }, []);
    const columns = [
        {
            dataIndex: "name",
            title: t("settings.funding-providers.name"),
        },
        {
            dataIndex: "billing_address",
            title: t("settings.funding-providers.billing-address"),
        },
        {
            dataIndex: "contact_email",
            title: t("settings.funding-providers.email"),
        },
        {
            dataIndex: "phone_number",
            title: t("settings.funding-providers.phone"),
        },
        {
            dataIndex: "is_enabled",
            title: t("settings.funding-providers.is-enabled"),
            render: (v) => (v ? t("common.yes") : t("common.no")),
        },
    ];
    useEffect(getProviders, [getProviders]);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.funding-providers"), extra: _jsx(Button, Object.assign({ type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => setShowDrawer(true) }, { children: t("settings.funding-providers.add-provider") })) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Table, { columns: columns, dataSource: providers, pagination: false, onRow: (provider) => ({
                        onClick: () => {
                            setSelectedProviderId(provider.funding_provider_id);
                            setShowDrawer(true);
                        },
                    }), rowKey: "funding_provider_id", loading: loading }) })), _jsx(FundingProviderPreview, { open: showDrawer, providerId: selectedProviderId, onSave: () => {
                    getProviders();
                    setSelectedProviderId(undefined);
                    setShowDrawer(false);
                }, onClose: () => {
                    setSelectedProviderId(undefined);
                    setShowDrawer(false);
                } })] }));
};
export default SettingsFundingProviders;
