import { FC } from "react";

import { Space, Typography } from "antd";

import { FactoringInfoInvoices } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../translations/translations";
import { InfoCol, InfoRow, InfoTitle } from "./InformationCardComponents";

const { Text } = Typography;

interface InformationCardInvoicesInfoProps {
	info: FactoringInfoInvoices;
	hasInternationalCustomers: boolean;
}

export const InformationCardInvoicesInfo: FC<InformationCardInvoicesInfoProps> = ({
	info,
	hasInternationalCustomers,
}) => {
	const t = useTranslations();
	const { currency, localizedNumber } = useFormatter();

	return (
		<>
			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.invoices.number_of_outgoing_invoices}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.number_of_outgoing_invoices
										? localizedNumber({
												value: info.number_of_outgoing_invoices,
												decimalPlaces: 0,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.number_of_outgoing_invoices_abroad
										? localizedNumber({
												value: info.number_of_outgoing_invoices_abroad,
												decimalPlaces: 0,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.number_of_outgoing_invoices
								? localizedNumber({
										value: info.number_of_outgoing_invoices,
										decimalPlaces: 0,
									})
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.invoices.invoice_currencies}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>{t.factoring.common.in_land(info.invoice_currencies || "-")}</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>{t.factoring.common.abroad(info.invoice_currencies_abroad || "-")}</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>{info.invoice_currencies || "-"}</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<InfoTitle>{t.factoring.invoices.max_invoice_value_previous_year}</InfoTitle>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.max_invoice_value_previous_year
										? currency(info.max_invoice_value_previous_year, { showFractions: false })
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.max_invoice_value_previous_year_abroad
										? currency(info.max_invoice_value_previous_year_abroad, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.max_invoice_value_previous_year
								? currency(info.max_invoice_value_previous_year, { showFractions: false })
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<InfoTitle>{t.factoring.invoices.payment_deadline_for_outgoing_invoices}</InfoTitle>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(info.payment_deadline_for_outgoing_invoices || "-")}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.payment_deadline_for_outgoing_invoices_abroad || "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>{info.payment_deadline_for_outgoing_invoices || "-"}</Text>
					</InfoCol>
				)}
			</InfoRow>
		</>
	);
};
