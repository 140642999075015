var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { App, Button, Drawer, Dropdown, Form, Input, Space, Tabs, Typography } from "antd";
import styled from "styled-components";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import TelephoneFormItem from "@ui/form/form-items/telephone-form-item/TelephoneFormItem";
import { Axios } from "../../../utils/Axios";
import RuleEngineConfigurator from "./RuleEngineConfigurator/RuleEngineConfigurator";
const DrawerTitle = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
var DotsBtnKeys;
(function (DotsBtnKeys) {
    DotsBtnKeys["DISABLE"] = "disable";
    DotsBtnKeys["ENABLE"] = "enable";
})(DotsBtnKeys || (DotsBtnKeys = {}));
var ProviderTab;
(function (ProviderTab) {
    ProviderTab["DETAILS"] = "details";
    ProviderTab["REVENUE"] = "revenue";
})(ProviderTab || (ProviderTab = {}));
const FundingProviderPreview = ({ open, providerId, onSave, onClose }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [provider, setProvider] = useState();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const ref = useRef();
    const [activeTab, setActiveTab] = useState(ProviderTab.DETAILS);
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    const getProvider = useCallback(() => {
        setLoading(true);
        void Axios.get(`admin/funding_providers/${providerId}`)
            .then(({ data }) => {
            setProvider(data);
            form.setFieldsValue(data);
        }, (error) => void Axios.error(error))
            .finally(() => setLoading(false));
    }, [providerId, form]);
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield form.validateFields().catch(() => {
            setActiveTab(ProviderTab.DETAILS);
            void message.error(t("settings.funding-providers.verify-details"));
            throw new Error();
        });
        const revenueConfig = yield ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getRuleConfiguration().catch(() => {
            setActiveTab(ProviderTab.REVENUE);
            throw new Error();
        }));
        if (!revenueConfig) {
            return;
        }
        const payload = Object.assign(Object.assign({}, form.getFieldsValue()), { revenue_config: revenueConfig });
        setLoading(true);
        const updateReq = () => Axios.patch(`admin/funding_providers/${provider === null || provider === void 0 ? void 0 : provider.funding_provider_id}`, payload);
        const createReq = () => Axios.post(`admin/funding_providers`, payload);
        (provider ? updateReq() : createReq())
            .then(() => {
            onSave();
            void message.success(t("common.saved"));
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
    });
    const handleEnable = (isEnabled) => {
        if (!provider)
            return;
        setLoading(true);
        void Axios.patch(`admin/funding_providers/${provider.funding_provider_id}/enable`, {
            is_enabled: isEnabled,
        })
            .then(() => {
            onSave();
            void message.success(t("common.saved"));
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
    };
    const handleDotsBtnClick = (e) => {
        switch (e.key) {
            case DotsBtnKeys.DISABLE:
                handleEnable(false);
                break;
            case DotsBtnKeys.ENABLE:
                handleEnable(true);
                break;
        }
    };
    useEffect(() => {
        if (providerId) {
            getProvider();
        }
        else {
            form.resetFields();
            setProvider(undefined);
        }
        setActiveTab(ProviderTab.DETAILS);
    }, [providerId, form, getProvider]);
    return (_jsx(Drawer, Object.assign({ title: _jsxs(DrawerTitle, { children: [_jsx(Typography.Text, Object.assign({ style: { fontSize: 16 } }, { children: (provider === null || provider === void 0 ? void 0 : provider.name) || t("settings.funding-providers.add-provider") })), _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", loading: loading, onClick: () => void handleSave() }, { children: t("common.save") })), providerId && (_jsx(Dropdown, Object.assign({ menu: {
                                disabled: loading,
                                items: (provider === null || provider === void 0 ? void 0 : provider.is_enabled)
                                    ? [
                                        {
                                            label: t("common.disable"),
                                            key: DotsBtnKeys.DISABLE,
                                        },
                                    ]
                                    : [
                                        {
                                            label: t("common.enable"),
                                            key: DotsBtnKeys.ENABLE,
                                        },
                                    ],
                                onClick: handleDotsBtnClick,
                            } }, { children: _jsx(Button, Object.assign({ disabled: loading }, { children: _jsx(MoreOutlined, {}) })) })))] })] }), open: open, closeIcon: _jsx(LeftOutlined, {}), onClose: onClose, size: "large" }, { children: _jsx(Tabs, { activeKey: activeTab, onChange: (v) => setActiveTab(v), items: [
                {
                    key: ProviderTab.DETAILS,
                    label: t("settings.funding-providers.tab.details"),
                    children: (_jsxs(Form, Object.assign({ layout: "vertical", form: form, disabled: loading }, { children: [_jsx(Form.Item, Object.assign({ name: "name", label: t("settings.funding-providers.name"), rules: [requiredRule] }, { children: _jsx(Input, { max: 255 }) })), _jsx(Form.Item, Object.assign({ name: "contact_name", label: t("settings.funding-providers.contact-name"), rules: [requiredRule] }, { children: _jsx(Input, { max: 255 }) })), _jsx(Form.Item, Object.assign({ name: "billing_address", label: t("settings.funding-providers.billing-address"), rules: [requiredRule] }, { children: _jsx(Input, { max: 255 }) })), _jsx(EmailFormItem, { name: "contact_email", label: t("settings.funding-providers.email"), rules: [requiredRule] }), _jsx(TelephoneFormItem, { name: "phone_number", label: t("settings.funding-providers.phone") })] }))),
                },
                {
                    key: ProviderTab.REVENUE,
                    label: t("settings.funding-providers.tab.revenue"),
                    forceRender: true,
                    children: (_jsx(RuleEngineConfigurator, { ref: ref, rule: provider === null || provider === void 0 ? void 0 : provider.revenue_config, disabled: loading })),
                },
            ] }) })));
};
export default FundingProviderPreview;
