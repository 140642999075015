import { InputNumber, InputNumberProps } from "antd";

import { useLocale } from "@teylor-tools/utils/LocaleProvider";

function reverseFormatNumber(value: string, locale: string) {
	const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
	const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
	let reversedValue = value;
	if (group) {
		reversedValue = value.replace(new RegExp("\\" + group, "g"), "");
	}
	reversedValue = reversedValue.replace(new RegExp("\\" + decimal, "g"), ".");
	return Number.isNaN(reversedValue) ? "" : reversedValue;
}

interface NumberInputLocalizedProps extends InputNumberProps {
	allowEmptyValue?: boolean;
}

const NumberInputLocalized = ({
	precision = 2,
	allowEmptyValue = false,
	...props
}: NumberInputLocalizedProps) => {
	const locale = useLocale();

	const numberFormatter = new Intl.NumberFormat(locale, {
		maximumFractionDigits: precision,
		//@ts-ignore
		roundingMode: "floor",
	});

	return (
		<InputNumber
			formatter={(value) =>
				!value && allowEmptyValue ? "" : numberFormatter.format(Number(value))
			}
			parser={(value) => {
				if (!value) return "";
				return reverseFormatNumber(value, locale);
			}}
			controls={false}
			{...props}
		/>
	);
};

export default NumberInputLocalized;
export type { NumberInputLocalizedProps };
