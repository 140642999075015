import { Empty } from "antd";

import { ScoringResultForPeriod } from "@teylor-tools/Api";

import { useTranslations } from "../../translations/translations";
import { Table } from "../table/Table";
import { getPropertyName } from "../utils";

enum ScoreCategory {
	SolvencyScore = "solvency",
	ProfitabilityScore = "profitability",
	LiquidityScore = "liquidity",
	LeverageScore = "leverage",
	FuturePerformanceScore = "future_performance",
	EfficiencyScore = "efficiency",
	AdditionalLoanScore = "additional_loan",
}

interface Props {
	title: string;
	benchmarkData?: ScoringResultForPeriod[];
}

const BenchmarkResults = ({ title, benchmarkData = [] }: Props) => {
	const t = useTranslations();

	const getBenchmarkCols = () => {
		type ColType = {
			title: string;
			dataIndex: string;
			key: string;
			width?: string;
			children: {
				title: string;
				dataIndex: string;
				key: string;
			}[];
		};
		const columns: ColType[] = [
			{
				title: `${title}`,
				dataIndex: "name",
				key: "name",
				children: [],
				width: "280px",
			},
		];

		benchmarkData.forEach((scoringForPeriod: ScoringResultForPeriod) => {
			columns.push({
				title: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
				dataIndex: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
				key: `Period ${scoringForPeriod.period_start} - ${scoringForPeriod.period_end}`,
				width: "160px",
				children: [
					{
						title: t.scoring.benchmarkResults.score,
						dataIndex: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`,
						key: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`,
					},
					{
						title: t.scoring.benchmarkResults.mark,
						dataIndex: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`,
						key: `${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`,
					},
				],
			});
		});

		return [
			...columns,
			{
				dataIndex: "invisible",
			},
		];
	};

	const getBenchmarkData = () => {
		const scores = Object.values(ScoreCategory).map((scoreCategory: ScoreCategory) => {
			const row = {
				key: scoreCategory,
				name: getPropertyName(scoreCategory),
			};

			benchmarkData.forEach((scoringForPeriod) => {
				if (scoringForPeriod.benchmarking_scores[scoreCategory]) {
					const score = scoringForPeriod.benchmarking_scores[scoreCategory];
					// @ts-ignore take a look at it if you work on this file
					row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`] =
						score?.value;
					// @ts-ignore take a look at it if you work on this file
					row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`] = score?.mark;
				}
			});
			return row;
		});

		// Add total scores row
		const row = {
			key: "total_score",
			name: "Total score",
		};
		benchmarkData.forEach((scoringForPeriod: ScoringResultForPeriod) => {
			// @ts-ignore take a look at it if you work on this file
			row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_score`] =
				scoringForPeriod.total_score;
			// @ts-ignore take a look at it if you work on this file
			row[`${scoringForPeriod.period_start}_${scoringForPeriod.period_end}_mark`] =
				scoringForPeriod.mark;
		});

		return [...scores, row];
	};

	if (!benchmarkData?.length) return <Empty description={t.scoring.benchmarkResults.noData} />;

	return (
		<Table
			bordered
			pagination={false}
			scroll={{ y: `calc(100vh - 300px)` }}
			sticky
			dataSource={getBenchmarkData()}
			columns={getBenchmarkCols()}
		/>
	);
};
export default BenchmarkResults;
