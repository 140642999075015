import { RuleArithmeticOperators, RuleLogicalOperators } from "@teylor-tools/Api";
import { GroupType } from "./rule-engine.types";
export const logicalOperators = Object.values(RuleLogicalOperators);
export const arithmeticOperators = Object.values(RuleArithmeticOperators);
export const logicalOperatorsOptions = logicalOperators.map((v) => ({
    label: v.toUpperCase(),
    value: v,
}));
export const groupTypeOptions = Object.values(GroupType).map((v) => ({
    label: v.toUpperCase(),
    value: v,
}));
export const arithmeticOperatorsOptions = arithmeticOperators.map((v) => ({
    label: v.toUpperCase(),
    value: v,
}));
