import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MinRateSourcesEnum } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
import PricingExternal from "../../../modules/Pricing/PricingExternal";
import PricingStandard from "../../../modules/Pricing/PricingStandard";
import PricingTlb from "../../../modules/Pricing/PricingTlb";
import PricingFeeCalc from "./PricingFeeCalc/PricingFeeCalc";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const LoanPricing = () => {
    const { t } = useTranslation();
    const { loan, productConfig } = useLoan();
    const { decimalToPercent } = useFormatter();
    const [latestGlobalRates, setLatestGlobalRates] = useState([]);
    const showExternalPricing = loan.pricing.external_pricing_allowed;
    const showTlbPricing = (productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet" && !showExternalPricing;
    const showFeeCalc = (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_pricing_calculations) && !showExternalPricing;
    const transactionFeeToLoanAmount = useMemo(() => {
        if (!loan.pricing.total_transaction_fee || !loan.amount) {
            return "-";
        }
        return decimalToPercent({
            percent: Number(loan.pricing.total_transaction_fee) / Number(loan.amount),
            showPercentSymbol: true,
        });
    }, [loan, decimalToPercent]);
    useEffect(() => {
        var _a;
        if (((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _a === void 0 ? void 0 : _a.includes(MinRateSourcesEnum.HedgingRate)) ||
            (productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet") {
            void Axios.get(`/admin/global_rates/latest`)
                .then(({ data }) => setLatestGlobalRates(data))
                .catch((error) => void Axios.error(error));
        }
    }, [productConfig]);
    if (!loan.pricing)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("loan.pricing.pricing") }), _jsx(Wrapper, { children: showTlbPricing ? (_jsx(PricingTlb, { transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, pricing: loan.pricing, isEditable: false, productConfig: productConfig, currency: loan.currency })) : showExternalPricing ? (_jsx(PricingExternal, { isInternalPricingEditable: false, transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, pricing: loan.pricing, productConfig: productConfig, currency: loan.currency, isEditable: false })) : showFeeCalc ? (_jsx(PricingFeeCalc, { rates: latestGlobalRates })) : (_jsx(PricingStandard, { isInternalPricingEditable: false, transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, pricing: loan.pricing, productConfig: productConfig, currency: loan.currency })) })] }));
};
export default LoanPricing;
