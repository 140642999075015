import { Id } from "@silevis/reactgrid";
import dayjs from "dayjs";

import { CompanyFinancialData } from "@teylor-tools/Api";

import { ROW_DESCRIPTION_COLUMN_ID } from "./rows";
import { Column } from "./table.types";

const getHiddenColumns = (financials: CompanyFinancialData[], collapsedColumns: Id[]) => {
	const collapsedYears = collapsedColumns.flatMap((col) => {
		const financial = financials.find((f) => f.company_financial_data_id === col);
		return financial ? [dayjs(financial.financials_date).year()] : [];
	});

	return financials
		.filter(
			(f) =>
				f.statement_type === "interim_financials" &&
				collapsedYears.includes(dayjs(f.financials_date).year())
		)
		.map((f) => f.company_financial_data_id);
};

export const buildColumns = ({
	financials,
	collapsedColumns,
	deletedColumns,
	descColumnWidth,
}: {
	financials: CompanyFinancialData[];
	collapsedColumns: Id[];
	deletedColumns: Id[];
	descColumnWidth: number;
}): Column[] => {
	const hiddenColumns = collapsedColumns.length
		? getHiddenColumns(financials, collapsedColumns)
		: [];

	return [
		{ columnId: ROW_DESCRIPTION_COLUMN_ID, width: descColumnWidth, resizable: true },
		...financials
			.filter((f) => ![...hiddenColumns, ...deletedColumns].includes(f.company_financial_data_id))
			.map((f) => ({
				columnId: f.company_financial_data_id,
				width: 150,
			})),
	];
};
