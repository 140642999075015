import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { App, Button } from "antd";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { usePartner } from "src/layouts/PartnerLayout/PartnerTypes";
import { Axios } from "src/utils/Axios";
import ContactPersonModal from "./ContactPersonModal";
import PartnerContactPersonCard from "./PartnerContactPersonCard";
const Wrapper = styled.div `
	margin: 24px;
	margin-top: 88px;
	display: grid;
	gap: 24px;
	grid-template-rows: auto 1fr;
	grid-template-columns: minmax(460px, 1464px);
`;
const InnerWrapper = styled.div `
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, auto));
`;
const PartnerContactPersons = () => {
    var _a;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { partner, updatePartner } = usePartner();
    const [addPerson, setAddPerson] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const handleAddUser = (data) => {
        setLoadingAdd(true);
        Axios.post(`admin/partners/${partner.partner_id}/contact_persons`, Object.assign({}, data))
            .then(() => {
            void message.success(t("partner.contact_persons.create_user_success"));
            void updatePartner();
            setAddPerson(false);
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setLoadingAdd(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("partners.contact_persons.header") }, { children: _jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => setAddPerson(true) }, { children: t("common.add") })) })), _jsx(Wrapper, { children: _jsx(InnerWrapper, { children: (_a = partner.contact_persons) === null || _a === void 0 ? void 0 : _a.map((person) => {
                        return (_jsx(PartnerContactPersonCard, { person: person }, person.partner_contact_person_id));
                    }) }) }), addPerson && (_jsx(ContactPersonModal, { loading: loadingAdd, onFinish: handleAddUser, onCancel: () => setAddPerson(false) }))] }));
};
export default PartnerContactPersons;
