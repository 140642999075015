import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Select, Space } from "antd";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import Loader from "src/components/ui/Loader";
import { Axios } from "../../../utils/Axios";
import PortfolioOverviewCards from "./PortfolioOverviewCards";
const FiltersWrapper = styled.div `
	padding: 24px 24pc 0px 24px;
`;
const PortfolioOverview = () => {
    var _a;
    const { t } = useTranslation();
    const [isLoadingPortfolios, setIsLoadingPortfolios] = useState(false);
    const [portfolios, setPortfolios] = useState();
    const [form] = Form.useForm();
    const portfolioId = Form.useWatch("portfolioId", form);
    const compartmentId = Form.useWatch("compartmentId", form);
    const getPortfolios = useCallback(() => {
        setIsLoadingPortfolios(true);
        Axios.get(`admin/portfolios`)
            .then(({ data }) => {
            setPortfolios(data);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoadingPortfolios(false));
    }, []);
    useEffect(() => {
        void getPortfolios();
    }, [getPortfolios]);
    if (isLoadingPortfolios) {
        return _jsx(Loader, {});
    }
    if (!portfolios)
        return null;
    const portfolioOptions = portfolios === null || portfolios === void 0 ? void 0 : portfolios.map((portfolio) => ({
        value: portfolio.id,
        label: portfolio.name,
    }));
    const compartments = portfolioId
        ? ((_a = portfolios === null || portfolios === void 0 ? void 0 : portfolios.find((p) => p.id === portfolioId)) === null || _a === void 0 ? void 0 : _a.compartments) || []
        : portfolios === null || portfolios === void 0 ? void 0 : portfolios.flatMap((p) => p.compartments || []);
    const onValuesChange = (changedValue) => {
        var _a;
        if (changedValue.portfolioId && compartmentId) {
            //if previously selected compartmentId does not belong to selected portfolio then set compartment to undefined
            const selectedPortfolio = portfolios === null || portfolios === void 0 ? void 0 : portfolios.find((portfolio) => portfolio.id === changedValue.portfolioId);
            const isCompartmentLinekdToPortfolio = !!((_a = selectedPortfolio === null || selectedPortfolio === void 0 ? void 0 : selectedPortfolio.compartments) === null || _a === void 0 ? void 0 : _a.find((compartment) => compartment.id === compartmentId));
            if (!isCompartmentLinekdToPortfolio) {
                form.setFieldValue("compartmentId", undefined);
            }
        }
        if (!portfolioId && changedValue.compartmentId) {
            //set portfolioId that is linked to selected compartment
            const portfolio = portfolios.find((portfolio) => {
                var _a;
                return !!((_a = portfolio.compartments) === null || _a === void 0 ? void 0 : _a.find((compartment) => compartment.id === changedValue.compartmentId));
            });
            form.setFieldValue("portfolioId", portfolio === null || portfolio === void 0 ? void 0 : portfolio.id);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("portfolio.portfolio-overview") }), _jsx(FiltersWrapper, { children: _jsx(Form, Object.assign({ layout: "vertical", form: form, onValuesChange: onValuesChange }, { children: _jsxs(Space, Object.assign({ align: "start" }, { children: [_jsx(Form.Item, Object.assign({ name: "portfolioId" }, { children: _jsx(Select, { options: portfolioOptions, placeholder: t("portfolio.portfolio"), style: { width: 200 } }) })), _jsx(Form.Item, Object.assign({ name: "compartmentId" }, { children: _jsx(Select, { options: compartments.map((compartment) => ({
                                        value: compartment.id,
                                        label: compartment.compartment_name,
                                    })), placeholder: t("portfolio.compartment"), style: { width: 200 } }) })), (portfolioId || compartmentId) && (_jsx(Button, Object.assign({ onClick: () => form.resetFields() }, { children: t("common.clear") })))] })) })) }), _jsx(PortfolioOverviewCards, { portfolioId: portfolioId, compartmentId: compartmentId })] }));
};
export default PortfolioOverview;
