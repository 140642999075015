import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, DeleteOutlined, EditOutlined, EuroOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Form, Row, Select, Space, Spin, Tag, Tooltip, Typography, } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import { ApplicationStatuses, ConcentrationLimitsCalculationBase, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import { Routes } from "src/Routes";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const Section = styled.div `
	margin: 24px;
`;
const DeleteButton = styled(Button) `
	height: 22px;
	padding: 0;
`;
const MAX_APPLICATIONS = 10;
const poolSizeTypes = Object.values(ConcentrationLimitsCalculationBase);
const ConcentrationLimitsDrawer = ({ isOpen, close, startSession }) => {
    var _a;
    const { t } = useTranslation();
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const [applicationsSearch, setApplicationsSearch] = useState([]);
    const [lastApplications, setLastApplications] = useState([]);
    const [selectedApplications, setSelectedApplications] = useState([]);
    const { currency } = useFormatter();
    const navigate = useNavigate();
    const [loans, setLoans] = useState({ loans: [], total: 0 });
    const { defaultCurrency } = useSelector((state) => state.configState);
    const [poolSizeInEditMode, setPoolSizeInEditMode] = useState(false);
    const [showCustomPoolSizeAmount, setShowCustomPoolSizeAmount] = useState(false);
    const applicationSelectDisabled = selectedApplications.length >= MAX_APPLICATIONS;
    const getApplications = (text) => {
        return Axios.get("admin/applications", { page_size: 10, page: 1, text }).then(({ data }) => (text ? setApplicationsSearch(data.result) : setLastApplications(data.result)), (err) => Axios.error(err));
    };
    const selectApplication = (applicationId) => {
        if (!applicationId)
            return;
        const applicationAlreadySelected = !!selectedApplications.find((a) => a.application_id === applicationId);
        if (!applicationAlreadySelected) {
            const application = [...applicationsSearch, ...lastApplications].find((a) => a.application_id === applicationId);
            application && setSelectedApplications((prev) => [...prev, application]);
        }
        form.resetFields(["applicationId"]);
        setApplicationsSearch([]);
    };
    const handleApplicationsSearch = (searchQuery) => {
        if (!searchQuery) {
            return setApplicationsSearch([]);
        }
        searchQuery.length >= 3 && getApplications(searchQuery);
    };
    const handleApplicationRemove = (id) => setSelectedApplications((prev) => prev.filter((application) => application.application_id !== id));
    const renderApplicationRow = (application, allowRemove = false) => {
        return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, { children: application.company_name }), _jsxs(Col, { children: [_jsx(Tag, { children: currency(application.amount, { currency: application.currency }) }), _jsx(ApplicationStatus, { label: t(`application.status.${application.status}`), value: application.status, short: true, style: { width: 30, textAlign: "center" } }), allowRemove && (_jsx(DeleteButton, { type: "link", onClick: () => handleApplicationRemove(application.application_id), icon: _jsx(DeleteOutlined, {}) }))] })] }), application.application_id));
    };
    const renderLoanRow = (loan) => {
        return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, { children: loan.company.companyName }), _jsxs(Col, { children: [_jsx(Tag, { children: currency(loan.amount, { currency: loan.currency }) }), _jsx(LoanStatus, { label: t(`loans.status.${loan.loan_status}`), value: loan.loan_status, short: true, style: { width: 30, textAlign: "center" } })] })] }), loan.loan_id));
    };
    const getLoans = () => {
        return Axios.get("admin/loans", {
            page: 1,
            page_size: 3,
            is_eligible: true,
        }).then(({ data }) => setLoans({ loans: data.result, total: data.pagination.total_rows }), (err) => Axios.error(err));
    };
    const handleStartSession = (v) => {
        const applications = selectedApplications.map((a) => ({ application_id: a.application_id }));
        setLoading(true);
        startSession(applications, v.poolType, v.poolAmount)
            .then(close)
            .finally(() => setLoading(false));
    };
    const validateCustomPoolAmount = (rule, value) => {
        const min = 1;
        const max = 1000000000;
        if (!value && value !== 0) {
            return Promise.resolve();
        }
        if (value < min || value > max) {
            return Promise.reject(t("portfolio.concentration-limits.sidebar.custom-pool-size-amount-error", {
                min: currency(min, { currency: defaultCurrency, showFractions: false }),
                max: currency(max, { currency: defaultCurrency, showFractions: false }),
            }));
        }
        return Promise.resolve();
    };
    useEffect(() => {
        if (!isOpen)
            return;
        setLoading(true);
        Promise.all([getLoans(), getApplications()]).finally(() => setLoading(false));
        setSelectedApplications([]);
        setPoolSizeInEditMode(false);
        setShowCustomPoolSizeAmount(false);
        form.resetFields();
        Promise.all([getLoans(), getApplications()]).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    return (_jsx(Drawer, Object.assign({ width: 400, placement: "right", mask: false, closable: false, styles: { body: { padding: 0 } }, open: isOpen, destroyOnClose: true, title: _jsxs(Row, Object.assign({ justify: "space-between", wrap: false, style: { wordBreak: "break-all" } }, { children: [_jsx(Typography.Title, Object.assign({ level: 5, style: { marginTop: 8 }, type: loading ? "secondary" : undefined }, { children: t("portfolio.concentration-limits.sidebar.title") })), _jsx(Button, { onClick: close, icon: _jsx(CloseOutlined, {}), disabled: loading })] })) }, { children: _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsxs(Form, Object.assign({ form: form, onFinish: handleStartSession, initialValues: { poolType: "default" } }, { children: [_jsxs(Section, { children: [_jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsx(EuroOutlined, { style: { marginRight: 4 } }), t("portfolio.concentration-limits.pool-size"), ":"] })), poolSizeInEditMode ? (_jsxs(Row, Object.assign({ gutter: 8 }, { children: [_jsx(Col, { children: _jsx(Form.Item, Object.assign({ name: "poolType", style: { width: 140, margin: 0 } }, { children: _jsx(Select, { options: poolSizeTypes.map((type) => ({
                                                    label: t(`portfolio.concentration-limits.pool-size.${type}`),
                                                    value: type,
                                                })), onSelect: (v) => setShowCustomPoolSizeAmount(v === ConcentrationLimitsCalculationBase.Custom) }) })) }), showCustomPoolSizeAmount && (_jsx(Col, { children: _jsx(Form.Item, Object.assign({ name: "poolAmount", style: { margin: 0 }, rules: [
                                                {
                                                    required: true,
                                                    message: t("application_form.field-required"),
                                                },
                                                { validator: validateCustomPoolAmount },
                                            ] }, { children: _jsx(CurrencyInput, { style: { width: 160 } }) })) }))] }))) : (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ strong: true, style: { marginLeft: 4 } }, { children: t(`portfolio.concentration-limits.pool-size.default`) })), _jsx(Button, { type: "link", icon: _jsx(EditOutlined, {}), onClick: () => setPoolSizeInEditMode(true) })] }))] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsxs(Row, Object.assign({ align: "middle", justify: "space-between" }, { children: [_jsx(Text, Object.assign({ strong: true }, { children: `${t("portfolio.concentration-limits.applications")} (${selectedApplications.length})` })), _jsx("div", Object.assign({ style: { height: 32 } }, { children: !!selectedApplications.length && (_jsx(Button, Object.assign({ type: "link", onClick: () => setSelectedApplications([]) }, { children: t("portfolio.concentration-limits.clear-all-applications") }))) }))] })), _jsx(Tooltip, Object.assign({ title: applicationSelectDisabled
                                    ? t("portfolio.concentration-limits.applications-search-max-tooltip", {
                                        max: MAX_APPLICATIONS,
                                    })
                                    : "" }, { children: _jsx(Form.Item, Object.assign({ name: "applicationId" }, { children: _jsx(Select, { showSearch: true, placeholder: t("portfolio.concentration-limits.applications-search-placeholder"), filterOption: false, onSearch: handleApplicationsSearch, onSelect: selectApplication, options: (applicationsSearch.length ? applicationsSearch : lastApplications).map((a) => ({
                                            value: a.application_id,
                                            label: renderApplicationRow(a),
                                            disabled: a.status === ApplicationStatuses.PaidOut,
                                        })), allowClear: true, style: { width: "100%", margin: "16px 0 0" }, disabled: applicationSelectDisabled }) })) })), _jsx(Space, Object.assign({ size: 16, direction: "vertical", style: { width: "100%" } }, { children: selectedApplications.map((application) => renderApplicationRow(application, true)) }))] }), _jsx(Divider, {}), _jsxs(Section, { children: [_jsxs(Row, Object.assign({ align: "middle", justify: "space-between" }, { children: [_jsx(Text, Object.assign({ strong: true }, { children: `${t("portfolio.concentration-limits.loans")} (${loans.total})` })), _jsx(Button, Object.assign({ type: "link", onClick: () => navigate({ pathname: Routes.Loans, search: `?is_eligible=true` }) }, { children: t("portfolio.concentration-limits.see-all-loans") }))] })), _jsx(Space, Object.assign({ size: 16, direction: "vertical", style: { width: "100%", marginTop: 16 } }, { children: (_a = loans.loans) === null || _a === void 0 ? void 0 : _a.map((loan) => renderLoanRow(loan)) }))] }), _jsx(Divider, {}), _jsx(Section, Object.assign({ style: { textAlign: "right" } }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("portfolio.concentration-limits.start-session-btn") })) }))] })) })) })));
};
export default ConcentrationLimitsDrawer;
