import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import Preview from "@ui/preview/Preview";
import SidebarTaskDetails from "src/components/tasks/SidebarTaskDetails";
import { getTaskEntityPath } from "src/components/tasks/tasks.types";
const TaskPreview = ({ task, close, afterDelete, updateTasks }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsx(Preview, Object.assign({ visible: !!task, entityId: (task === null || task === void 0 ? void 0 : task.task_id) || "", header: _jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsx(Col, { children: _jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: t("tasks.sidebar.title.task-details") })) }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: () => task &&
                                    navigate(getTaskEntityPath(task.related_entity_type, task.related_entity_id)) }, { children: t("common.view") })), _jsx(Button, { onClick: close, icon: _jsx(CloseOutlined, {}) })] }) })] })) }, { children: task && (_jsx(SidebarTaskDetails, { data: task, afterDelete: afterDelete, afterUpdate: updateTasks })) })));
};
export default TaskPreview;
