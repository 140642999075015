import { Form, FormItemProps, Input, InputProps } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";

import { useTranslations } from "../../../translations/translations";

interface Props extends FormItemProps {
	inputProps?: InputProps;
	type?: "personal" | "company";
}

export const parseTin = (value?: string) => value?.replace(/[.,:\/\-\\]/g, "") || "";

const TinFormItem = ({ inputProps, type = "company", ...props }: Props) => {
	const t = useTranslations();

	const minLength = type === "company" ? 10 : 5;
	const maxLength = type === "company" ? 11 : 15;

	const validateTin = (_: RuleObject, value: string): Promise<void | string> => {
		if (!value) {
			return Promise.resolve();
		}

		if (
			(type === "company" && !value.match(/^[\d\/]+$/)) ||
			(type === "personal" && !value.match(/^[\da-zA-Z\/\\.,\-:]+$/))
		) {
			return Promise.reject(t.formErrors.fieldContainsInvalidCharacters);
		}

		const tinWithoutSpecialChars = parseTin(value);
		if (tinWithoutSpecialChars.length < minLength || tinWithoutSpecialChars.length > maxLength) {
			const err =
				type === "company"
					? t.formErrors.fieldMustBeBetweenMinMaxNumbers(minLength, maxLength)
					: t.formErrors.fieldMustBeBetweenMinMaxChars(minLength, maxLength);
			return Promise.reject(err);
		}

		return Promise.resolve();
	};

	return (
		<Form.Item
			{...props}
			rules={[
				{
					validator: validateTin,
				},
				...(props.rules || []),
			]}
			validateFirst
		>
			<Input data-cy="tax-number-field" {...inputProps} />
		</Form.Item>
	);
};

export default TinFormItem;
