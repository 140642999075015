import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Collapse, Divider, Modal, Row, Spin, Tag, Typography } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import styled from "styled-components";
import { BankTransactionStatus, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterDescriptiveMonthDayYear } from "@teylor-tools/utils/dateFormats";
import { DataCardItem } from "@ui/data-display/DataCard";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
import PaymentModalForm from "./PaymentModalForm";
const { Text } = Typography;
const ModalBodyWrapper = styled.div `
	padding: 24px;
`;
const statusColorMapping = new Map([
    [BankTransactionStatus.Pending, "blue"],
    [BankTransactionStatus.Posted, "yellow"],
    [BankTransactionStatus.Reconciled, "success"],
    [BankTransactionStatus.Canceled, "error"],
    [BankTransactionStatus.Rejected, "error"],
    [BankTransactionStatus.Error, "error"],
]);
const MANUAL_PAYMENT_KEY = "manual_payment";
export var ModalMode;
(function (ModalMode) {
    ModalMode[ModalMode["VIEW"] = 0] = "VIEW";
    ModalMode[ModalMode["MANUAL_PAYMENT"] = 1] = "MANUAL_PAYMENT";
})(ModalMode || (ModalMode = {}));
var NewTransactionMode;
(function (NewTransactionMode) {
    NewTransactionMode[NewTransactionMode["COLLAPSIBLE_PANEL"] = 0] = "COLLAPSIBLE_PANEL";
    NewTransactionMode[NewTransactionMode["ADD_BUTTON"] = 1] = "ADD_BUTTON";
})(NewTransactionMode || (NewTransactionMode = {}));
const PaymentModal = ({ mode, installment, closeModal }) => {
    const { t } = useTranslation();
    const { currency, date } = useFormatter();
    const [loading, setLoading] = useState(true);
    const { updateLoan } = useLoan();
    const [bankTransactions, setBankTransactions] = useState();
    const [newTransactionMode, setNewTransactionMode] = useState(() => (mode === ModalMode.MANUAL_PAYMENT ? NewTransactionMode.COLLAPSIBLE_PANEL : undefined));
    const totalAmount = useMemo(() => {
        if (!(bankTransactions === null || bankTransactions === void 0 ? void 0 : bankTransactions.length))
            return 0;
        return bankTransactions
            .filter((transaction) => transaction.status &&
            [
                BankTransactionStatus.Pending,
                BankTransactionStatus.Posted,
                BankTransactionStatus.Reconciled,
            ].includes(transaction.status))
            .reduce((prev, curr) => prev + Number(curr.amount), 0);
    }, [bankTransactions]);
    const getTransactions = () => {
        setLoading(true);
        Axios.get(`admin/installments/${installment.installment_id}/payments`)
            .then(({ data }) => setBankTransactions(data.bank_transactions), (err) => Axios.error(err))
            .finally(() => setLoading(false));
    };
    const handleNewTransaction = () => {
        setNewTransactionMode(NewTransactionMode.ADD_BUTTON);
        setLoading(true);
        void updateLoan();
        getTransactions();
    };
    useEffect(getTransactions, [installment]);
    return (_jsx(Modal, Object.assign({ open: !!installment, onCancel: closeModal, title: mode === ModalMode.VIEW
            ? t("loans.payments.modal.view-title")
            : t("loans.payments.modal.manual-payment-title", {
                date: date(installment.due_date, formatterDescriptiveMonthDayYear),
            }), bodyStyle: { padding: 0 }, okButtonProps: { hidden: true }, cancelText: t("common.close") }, { children: _jsxs(Spin, Object.assign({ spinning: loading }, { children: [_jsx(ModalBodyWrapper, { children: _jsxs(Row, Object.assign({ justify: "space-between" }, { children: [_jsx(Col, { children: mode === ModalMode.VIEW ? (_jsxs(Text, { children: [`${t("loans.payments.modal.amount")}: `, _jsx("strong", { children: currency(Number(installment.amount), { currency: installment.currency }) })] })) : (_jsxs(Text, { children: [`${t("loans.payments.modal.total")}: `, _jsx("strong", { children: currency(totalAmount, { currency: installment.currency }) })] })) }), _jsx(Col, { children: mode === ModalMode.VIEW ? (_jsxs(Text, Object.assign({ type: "secondary" }, { children: [`${t("loans.payments.modal.due-date")}: `, _jsx("strong", { children: date(installment.due_date, formatterDescriptiveMonthDayYear) })] }))) : (_jsxs(Text, Object.assign({ type: "secondary" }, { children: [`${t("loans.payments.modal.amount-due")}: `, _jsx("strong", { children: installment.amount &&
                                                currency(installment.amount, { currency: installment.currency }) })] }))) })] })) }), _jsx(Divider, { style: { margin: 0 } }), _jsxs(ModalBodyWrapper, { children: [_jsxs(Collapse, Object.assign({ defaultActiveKey: mode === ModalMode.MANUAL_PAYMENT ? MANUAL_PAYMENT_KEY : undefined }, { children: [bankTransactions === null || bankTransactions === void 0 ? void 0 : bankTransactions.map((transaction, idx) => {
                                    var _a;
                                    return (_jsxs(CollapsePanel, Object.assign({ header: _jsxs(Row, Object.assign({ justify: "space-between" }, { children: [_jsxs(Col, { children: [t("loans.payments.modal.transaction"), " ", idx + 1, ":", " ", _jsx("strong", { children: transaction.amount &&
                                                                currency(transaction.amount, { currency: transaction.currency }) })] }), _jsx(Col, { children: transaction.status && (_jsx(Tag, Object.assign({ color: statusColorMapping.get(transaction.status) }, { children: t(`loans.payment_status.${transaction.status}`) }))) })] })) }, { children: [_jsx(DataCardItem, { label: t("loans.payments.modal.status"), value: transaction.status && t(`loans.payment_status.${transaction.status}`) }), _jsx(DataCardItem, { label: t("loans.payments.modal.amount"), value: transaction.amount &&
                                                    currency(transaction.amount, { currency: transaction.currency }) }), _jsx(DataCardItem, { label: t("loans.payments.modal.booking-date"), value: date(transaction.booking_date) }), _jsx(DataCardItem, { label: t("loans.payments.modal.created-at"), value: date(transaction.created_at) }), _jsx(DataCardItem, { label: t("loans.payments.modal.customer-ref"), value: transaction.customer_reference }), _jsx(DataCardItem, { label: t("loans.payments.modal.debit-or-credit"), value: transaction.deb_cr }), _jsx(DataCardItem, { label: t("loans.payments.modal.transaction-type"), value: transaction.transaction_type }), _jsx(DataCardItem, { label: t("loans.payments.modal.transaction-id"), value: transaction.transaction_id }), _jsx(DataCardItem, { label: t("loans.payments.modal.installment-id"), value: transaction.installment_id }), _jsx(DataCardItem, { label: t("loans.payments.modal.provider-id"), value: transaction.provider_id }), _jsx(DataCardItem, { label: t("loans.payments.modal.provider-ref"), value: transaction.provider_reference }), _jsx(DataCardItem, { label: t("loans.payments.modal.provider-status"), value: transaction.provider_status }), _jsx(DataCardItem, { label: t("loans.payments.modal.provider-transaction-id"), value: transaction.provider_transaction_id }), _jsx(DataCardItem, { label: t("loans.payments.modal.notes"), 
                                                // @ts-ignore take a look at it if you work on this file
                                                value: (_a = transaction.details) === null || _a === void 0 ? void 0 : _a["notes"] })] }), transaction.transaction_id));
                                }), newTransactionMode === NewTransactionMode.COLLAPSIBLE_PANEL && (_jsx(CollapsePanel, Object.assign({ header: t("loans.payments.modal.new-transaction") }, { children: _jsx(PaymentModalForm, { installmentId: installment.installment_id, afterNewTransaction: handleNewTransaction, currency: installment.currency }) }), MANUAL_PAYMENT_KEY))] })), newTransactionMode === NewTransactionMode.ADD_BUTTON && (_jsx("div", Object.assign({ style: { textAlign: "right", marginTop: 16 } }, { children: _jsx(Button, Object.assign({ type: "primary", onClick: () => setNewTransactionMode(NewTransactionMode.COLLAPSIBLE_PANEL) }, { children: t("loans.payments.modal.add-transaction") })) })))] })] })) })));
};
export default PaymentModal;
