import { ChecklistItemTemplateItemType } from "@teylor-tools/Api";
import { getChecklistFlag } from "./utils/mapping-flag-to-option-type";
export const mapTemplateToForm = (templateGroups) => {
    const form = {};
    templateGroups.forEach((templateGroup) => {
        const group = { desc: templateGroup.description };
        form[templateGroup.id] = group;
        templateGroup.items.forEach((templateItem) => {
            switch (templateItem.item_type) {
                case "checkbox":
                    (() => {
                        const option = templateItem.options[0];
                        if (!option)
                            return;
                        const checkbox = {
                            itemType: ChecklistItemTemplateItemType.Checkbox,
                            desc: option.description,
                        };
                        form[option.id] = checkbox;
                    })();
                    break;
                case "select":
                    (() => {
                        const radio = {
                            itemType: ChecklistItemTemplateItemType.Select,
                            desc: templateItem.description,
                            notApplicable: templateItem.not_applicable,
                            options: templateItem.options.map((option) => ({
                                desc: option.description,
                                // Flag is there, checked by form validation
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                flag: getChecklistFlag(option.type),
                            })),
                        };
                        form[templateItem.id] = radio;
                    })();
                    break;
                case "text":
                    (() => {
                        var _a;
                        const text = {
                            itemType: ChecklistItemTemplateItemType.Text,
                            desc: templateItem.description,
                            minLength: (_a = templateItem.requirements) === null || _a === void 0 ? void 0 : _a.min_length,
                        };
                        form[templateItem.id] = text;
                    })();
            }
        });
    });
    return form;
};
