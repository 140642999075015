import { FC } from "react";

import { Col, Form, Input, InputNumber, Row } from "antd";

import { Currency, FactoringInfoInvoices, FactoringInfoInvoicesReq } from "@teylor-tools/Api";

import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import { FactoryDetailsType } from "../information-card/types";
import { useTranslations } from "../translations/translations";
import HeadText from "./FormComponents";

interface InvoicesFormProps {
	details: FactoringInfoInvoices;
	formId: FactoryDetailsType;
	handleUpdateFactoringData: (
		type: FactoryDetailsType,
		values: FactoringInfoInvoicesReq
	) => Promise<void>;
	hasInternationalCustomers: boolean;
	defaultCurrency?: Currency;
	isRequired?: boolean;
}

export const InvoicesForm: FC<InvoicesFormProps> = ({
	details,
	formId,
	handleUpdateFactoringData,
	hasInternationalCustomers,
	defaultCurrency,
	isRequired = true,
}) => {
	const t = useTranslations();
	const [form] = Form.useForm<FactoringInfoInvoices>();

	const handleUpdate = () => {
		void handleUpdateFactoringData(formId, form.getFieldsValue() as FactoringInfoInvoicesReq);
	};

	return (
		<Form
			id={formId}
			form={form}
			layout="vertical"
			onFinish={handleUpdate}
			initialValues={{
				...details,
			}}
		>
			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.invoices.number_of_outgoing_invoices}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="number_of_outgoing_invoices"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<InputNumber style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="number_of_outgoing_invoices_abroad"
							label={t.factoring.common.abroad_text}
							required={isRequired}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.invoices.invoice_currencies}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="invoice_currencies"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<Input style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="invoice_currencies_abroad"
							label={t.factoring.common.abroad_text}
							required={isRequired}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.invoices.max_invoice_value_previous_year}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="max_invoice_value_previous_year"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="max_invoice_value_previous_year_abroad"
							label={t.factoring.common.abroad_text}
							required={isRequired}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.invoices.payment_deadline_for_outgoing_invoices}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="payment_deadline_for_outgoing_invoices"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<Input style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="payment_deadline_for_outgoing_invoices_abroad"
							label={t.factoring.common.abroad_text}
							required={isRequired}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>
		</Form>
	);
};
