import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { App, Button, Col, Row, Table, Tag, Tooltip, Typography } from "antd";
import { Select } from "antd";
import styled from "styled-components";
import { PipelineStatuses, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { MainRoutes } from "src/Routes";
import { Axios } from "src/utils/Axios";
const getRowClassName = (record) => {
    return record.children ? "parent-row" : "child-row";
};
const forbiddenStatuses = [PipelineStatuses.QaDay, PipelineStatuses.KYC];
const StyledTable = styled((Table)) `
	.child-row {
		background-color: #fafafa;
	}

	.action-column {
		padding: 0 !important;
		width: 50px;
	}
`;
const StyledSelect = styled(Select) `
	width: 100%;
`;
const ActionCellWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
`;
const generateButtonOnClickPath = (group, record) => {
    if (group.children) {
        return `${generatePath(MainRoutes.Applications)}?status=${group.status}`;
    }
    else {
        return `${generatePath(MainRoutes.Applications)}?status=${record.parent_status}&product_id=${record.product_id}`;
    }
};
const LOCAL_STORAGE_KEY = "filters_pipeline";
export const PipelineOverview = () => {
    const { message } = App.useApp();
    const savedFilterCookie = localStorage.getItem(LOCAL_STORAGE_KEY);
    const savedFilters = savedFilterCookie
        ? JSON.parse(savedFilterCookie)
        : { statuses: [], products: [] };
    const { t } = useTranslation();
    const [data, setData] = useState();
    const { currency, localizedNumber } = useFormatter();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const [statuses, setStatuses] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState((savedFilters === null || savedFilters === void 0 ? void 0 : savedFilters.statuses) || []);
    const [selectedProductIds, setSelectedProductIds] = useState((savedFilters === null || savedFilters === void 0 ? void 0 : savedFilters.products) || []);
    const navigate = useNavigate();
    useEffect(() => {
        void Axios.get("/admin/statistics/application_pipeline")
            .then(({ data }) => {
            var _a;
            const updatedData = (_a = data === null || data === void 0 ? void 0 : data.children) === null || _a === void 0 ? void 0 : _a.filter((item) => item.status && !forbiddenStatuses.includes(item.status)).map((group, index) => {
                var _a;
                return (Object.assign(Object.assign({}, group), { key: index, children: (_a = group === null || group === void 0 ? void 0 : group.children) === null || _a === void 0 ? void 0 : _a.map((child, childIndex) => (Object.assign(Object.assign({}, child), { key: `${index}-${childIndex}`, parent_status: group.status }))) }));
            });
            setData(updatedData);
        })
            .catch((err) => void Axios.error(err));
    }, []);
    useEffect(() => {
        Axios.get("admin/filter_values")
            .then(({ data }) => {
            setStatuses(data.all_statuses || []);
            setProducts(data.products || []);
        })
            .catch((err) => void Axios.error(err));
    }, []);
    const filteredResults = useMemo(() => {
        let tempData = [...(data || [])];
        if (selectedStatuses.length) {
            tempData = tempData.filter((item) => item.status && selectedStatuses.includes(item.status));
        }
        if (selectedProductIds.length) {
            tempData = tempData
                .filter((item) => {
                var _a;
                return (_a = item.children) === null || _a === void 0 ? void 0 : _a.some((child) => child.product_id && selectedProductIds.includes(child.product_id));
            })
                .map((item) => {
                var _a;
                const filteredChildren = (_a = item.children) === null || _a === void 0 ? void 0 : _a.filter((child) => child.product_id && selectedProductIds.includes(child.product_id));
                return Object.assign(Object.assign({}, item), { children: filteredChildren });
            });
        }
        return tempData;
    }, [data, selectedStatuses, selectedProductIds]);
    const handleSaveFilters = () => {
        const filters = {
            statuses: selectedStatuses,
            products: selectedProductIds,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
        void message.success(t("common.filters-saved"));
    };
    const handleCleanFilters = () => {
        setSelectedStatuses([]);
        setSelectedProductIds([]);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };
    const generateTooltipTitle = (group) => {
        return group.children
            ? t("pipeline.overview.page.action.group.title")
            : t("pipeline.overview.page.action.product.title");
    };
    const columns = [
        {
            title: t("pipeline.overview.column.status"),
            dataIndex: "status",
            key: "status",
            render: (status) => status && _jsx(Typography.Text, { children: t(`application.status.${status}`) }),
        },
        {
            title: t("pipeline.overview.column.product_id"),
            dataIndex: "product_name",
            key: "product_name",
            width: 250,
            ellipsis: {
                showTitle: false,
            },
            render: (product_name) => (_jsx(Tooltip, Object.assign({ placement: "topLeft", title: product_name }, { children: product_name }))),
        },
        {
            title: t("pipeline.overview.column.count"),
            dataIndex: "count",
            key: "count",
            render: (count) => localizedNumber({ value: count, decimalPlaces: 0 }),
        },
        {
            title: t("pipeline.overview.column.sum_of_loans"),
            dataIndex: "sum_of_loans",
            key: "sum_of_loans",
            render: (sum_of_loans) => currency(sum_of_loans, { currency: defaultCurrency, showFractions: false }),
        },
        {
            render: (group, record) => (_jsx(ActionCellWrapper, { children: _jsx(Tooltip, Object.assign({ placement: "left", title: generateTooltipTitle(group) }, { children: _jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: () => navigate(generateButtonOnClickPath(group, record)) }) })) })),
            className: "action-column",
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("pipeline.overview.page.title") }), _jsxs(Row, Object.assign({ gutter: 8, style: { margin: "20px" } }, { children: [_jsx(Col, Object.assign({ span: 4 }, { children: _jsx(StyledSelect, { loading: !statuses.length, disabled: !statuses.length, placeholder: t("application.state"), mode: "multiple", allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", value: selectedStatuses, onChange: (values) => setSelectedStatuses(values), tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: statuses.map((value) => {
                                return {
                                    label: t(`application.status.${value}`),
                                    value: value,
                                };
                            }) }) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(StyledSelect, { loading: !products.length, disabled: !products.length, placeholder: t("application.product"), mode: "multiple", allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", value: selectedProductIds, onChange: (values) => setSelectedProductIds(values), tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: products.map(({ product_id, product_name }) => {
                                return {
                                    label: product_name,
                                    value: product_id,
                                };
                            }) }) })), !!(selectedStatuses.length || selectedProductIds.length) && (_jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ span: 2 }, { children: _jsx(Button, Object.assign({ type: "primary", style: { width: "100%" }, onClick: handleSaveFilters }, { children: t("common.save") })) })), _jsx(Col, Object.assign({ span: 2 }, { children: _jsx(Button, Object.assign({ type: "text", style: { color: "rgba(0,0,0,.45)" }, onClick: handleCleanFilters }, { children: t("common.clear") })) }))] }))] })), _jsx("div", Object.assign({ style: { margin: "40px 24px 0" } }, { children: data && (_jsx(StyledTable, { columns: columns, dataSource: filteredResults, pagination: false, rowClassName: getRowClassName, scroll: { y: "calc(100vh - 250px)" } })) }))] }));
};
