import { FC } from "react";

import { Col, Form, Input, Row } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";

import { Currency, FactoringInfoFinancials, FactoringInfoFinancialsReq } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import { FactoryDetailsType } from "../information-card/types";
import { useTranslations } from "../translations/translations";
import HeadText from "./FormComponents";

const { TextArea } = Input;

interface FinancialsFormProp {
	details: FactoringInfoFinancials;
	formId: FactoryDetailsType;
	maxSales: number;
	handleUpdateFactoringData: (
		type: FactoryDetailsType,
		values: FactoringInfoFinancialsReq
	) => Promise<void>;
	hasInternationalCustomers: boolean;
	defaultCurrency?: Currency;
	isRequired?: boolean;
}

export const FinancialsForm: FC<FinancialsFormProp> = ({
	details,
	formId,
	maxSales,
	handleUpdateFactoringData,
	hasInternationalCustomers,
	defaultCurrency,
	isRequired = true,
}) => {
	const t = useTranslations();
	const { localizedNumber } = useFormatter();
	const [form] = Form.useForm<FactoringInfoFinancials>();

	const handleUpdate = () => {
		void handleUpdateFactoringData(formId, form.getFieldsValue() as FactoringInfoFinancialsReq);
	};

	const validateTotal = (_: RuleObject, value: string, fieldName: string) => {
		const relatedFieldName =
			fieldName === "annual_sales_previous_year"
				? "annual_sales_previous_year_abroad"
				: "annual_sales_previous_year";
		const relatedValue = Number(form.getFieldValue(relatedFieldName)) || 0;
		const total = (Number(value) || 0) + relatedValue;

		if (total !== maxSales) {
			if (total > maxSales) {
				return Promise.reject(
					new Error(
						t.factoring.forms.financials.errors.more(
							localizedNumber({ value: maxSales, decimalPlaces: 0 })
						)
					)
				);
			}
			if (total < maxSales) {
				return Promise.reject(
					new Error(
						t.factoring.forms.financials.errors.less(
							localizedNumber({ value: maxSales, decimalPlaces: 0 })
						)
					)
				);
			}

			if (value === undefined || value === null || value === "") {
				return Promise.reject(new Error(t.formErrors.fieldRequired));
			}
		}
		return Promise.resolve();
	};

	const handleCurrencyInputChange = (changedValue: number, fieldName: string) => {
		form.setFieldsValue({
			[fieldName]: changedValue,
		});

		void form.validateFields(["annual_sales_previous_year", "annual_sales_previous_year_abroad"]);
	};

	return (
		<Form
			id={formId}
			form={form}
			layout="vertical"
			onFinish={() => void handleUpdate()}
			initialValues={{
				...details,
			}}
		>
			<Form.Item
				name="company_portfolio"
				label={t.factoring.forms.financials.company_portfolio}
				required={isRequired}
				rules={
					isRequired
						? [
								{
									required: true,
									message: t.formErrors.fieldRequired,
								},
								{
									min: 40,
									max: 300,
									message: t.formErrors.fieldMustBeBetweenMinMax(40, 300),
								},
							]
						: []
				}
			>
				<TextArea maxLength={300} rows={6} showCount />
			</Form.Item>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.financials.annual_sales_previous_year}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="annual_sales_previous_year"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={[
							{
								required: true,
								message: t.formErrors.fieldRequired,
							},
							{
								validator: async (_, value: string) =>
									validateTotal(_, value, "annual_sales_previous_year"),
							},
						]}
					>
						<CurrencyInput
							currency={defaultCurrency}
							style={{ width: "100%" }}
							allowEmptyValue
							onChange={(value) =>
								handleCurrencyInputChange(Number(value), "annual_sales_previous_year")
							}
						/>
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="annual_sales_previous_year_abroad"
							required={isRequired}
							label={t.factoring.common.abroad_text}
							rules={[
								{
									required: true,
									message: t.formErrors.fieldRequired,
								},
								{
									validator: async (_, value: string) =>
										validateTotal(_, value, "annual_sales_previous_year_abroad"),
								},
							]}
						>
							<CurrencyInput
								currency={defaultCurrency}
								style={{ width: "100%" }}
								allowEmptyValue
								onChange={(value) =>
									handleCurrencyInputChange(Number(value), "annual_sales_previous_year_abroad")
								}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.financials.planned_annual_sales_following_year}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="planned_annual_sales_following_year"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="planned_annual_sales_following_year_abroad"
							required={isRequired}
							label={t.factoring.common.abroad_text}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.financials.planned_annual_sales_factoring}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="planned_annual_sales_factoring"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="planned_annual_sales_factoring_abroad"
							required={isRequired}
							label={t.factoring.common.abroad_text}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>
		</Form>
	);
};
