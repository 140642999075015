var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import { MoreOutlined, SelectOutlined } from "@ant-design/icons";
import { App, Button, Col, DatePicker, Dropdown, Input, Select, Tag, Tooltip, Typography, } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import { BOUserFeature, FeatureName, LoanStatus as LoanStatusType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { Axios } from "src/utils/Axios";
import SearchList, { DIRECT_OPEN_COLUMN } from "../../components/SearchList";
import LoanPreview from "./LoanPreview";
const { RangePicker } = DatePicker;
const Center = styled.div `
	display: flex;
	justify-content: center;
`;
var SearchField;
(function (SearchField) {
    SearchField["text"] = "text";
    SearchField["status"] = "status";
    SearchField["is_eligible"] = "is_eligible";
    SearchField["product_id"] = "product_id";
    SearchField["payout_date"] = "payout_date";
    SearchField["payout_from"] = "payout_from";
    SearchField["payout_to"] = "payout_to";
    SearchField["portfolio_id"] = "portfolio_id";
    SearchField["compartment_id"] = "compartment_id";
})(SearchField || (SearchField = {}));
const LoansPage = () => {
    var _a;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { currency, date } = useFormatter();
    const [loan, setLoan] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [portfolios, setPortfolios] = useState([]);
    const { features: permissions } = useSelector((state) => state.profileState);
    const { features } = useSelector((state) => state.configState);
    const canRefreshMetabase = (permissions === null || permissions === void 0 ? void 0 : permissions.includes(BOUserFeature.Etl)) &&
        ((_a = features.find((f) => f.name === FeatureName.BoUserTriggerEtl)) === null || _a === void 0 ? void 0 : _a.is_active);
    const compartments = portfolios.flatMap((p) => p.compartments || []);
    const exportPortfolio = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield Axios.downloadGeneratedFile("/admin/portfolio_export", "portfolio.zip");
        }
        catch (e) {
            console.error(e);
            setIsLoading(false);
            yield message.error(`${t("common.error-code")} ${e}`);
        }
        setIsLoading(false);
    });
    const refreshMetabase = () => {
        setIsLoading(true);
        void Axios.post("/admin/trigger_bi_etl")
            .then(() => void message.success(t("loans.refresh-metabase.success")), (err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    };
    const columns = [
        {
            render: (v) => (_jsx(Link, Object.assign({ to: generatePath(Routes.Loan, {
                    loanId: v.loan_id,
                }) }, { children: _jsx(SelectOutlined, {}) }))),
            className: DIRECT_OPEN_COLUMN,
        },
        {
            dataIndex: "loan_status",
            title: t("application.state"),
            width: 70,
            render: (item) => {
                return (_jsx(Tooltip, Object.assign({ title: t(`loans.status.${item}`) }, { children: _jsx(Center, { children: _jsx(LoanStatus, { label: item[0].toUpperCase(), value: item }) }) })));
            },
        },
        {
            dataIndex: ["company", "companyName"],
            title: t("application.company"),
        },
        {
            dataIndex: "amount",
            title: t("application.amount"),
            sorter: true,
            render(amount, loan) {
                return amount ? currency(amount, { currency: loan.currency }) : "-";
            },
        },
        {
            dataIndex: "payment",
            title: t("loans.payment"),
            sorter: true,
            render(_, loan) {
                var _a;
                return ((_a = loan.payment_plan) === null || _a === void 0 ? void 0 : _a.payment)
                    ? currency(loan.payment_plan.payment, { currency: loan.currency })
                    : "-";
            },
        },
        {
            dataIndex: "interest_rate",
            title: t("loans.interest-rate"),
            render(rate) {
                return `${(Number(rate) * 100).toFixed(2)}%`;
            },
        },
        {
            dataIndex: "duration_months",
            title: t("loans.duration"),
            render(duration) {
                return `${duration}m`;
            },
        },
        {
            dataIndex: "payout_date",
            title: t("loans.paid-out-date"),
            render(payout_date) {
                return _jsx(Typography.Text, { children: date(payout_date) });
            },
            sorter: true,
        },
        {
            dataIndex: ["product", "product_name"],
            title: t("loans.product"),
        },
    ];
    const extraMenuItems = [
        {
            label: t("loans.export-portfolio"),
            key: "export-portfolio",
            disabled: isLoading,
            onClick: () => void exportPortfolio(),
        },
        ...(canRefreshMetabase
            ? [
                {
                    label: t("loans.refresh-metabase"),
                    key: "refresh-metabase",
                    disabled: isLoading,
                    onClick: () => refreshMetabase(),
                },
            ]
            : []),
    ];
    useEffect(() => {
        Axios.get("admin/filter_values")
            .then(({ data }) => {
            setProducts(data.products || []);
            setPortfolios(data.portfolios || []);
        })
            .catch((err) => void Axios.error(err));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("loans.title"), extra: _jsx(Dropdown, Object.assign({ menu: { items: extraMenuItems } }, { children: _jsx(Button, Object.assign({ loading: isLoading, "data-cy": "export-portfolio-button" }, { children: _jsx(MoreOutlined, {}) })) })) }), _jsxs(SearchList, Object.assign({ endpoint: "/admin/loans", columns: columns, showingOfString: "loans.search.search-showing", rowKey: ({ loan_id }) => loan_id, onRow: (record) => {
                    return {
                        onClick() {
                            setLoan(record);
                        },
                    };
                }, savedFiltersStorageKey: "filters_loans", showAmounts: true, showBalances: true }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.text }, { children: _jsx(Input.Search, { placeholder: t("loans.placeholder"), allowClear: true }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.portfolio_id }, { children: _jsx(Select, { placeholder: t("loans.portfolio"), mode: "multiple", optionFilterProp: "label", maxTagCount: "responsive", allowClear: true, options: portfolios.map((portfolio) => {
                                    return {
                                        label: portfolio.name,
                                        value: portfolio.id,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.status }, { children: _jsx(Select, { placeholder: t("loans.status"), mode: "multiple", optionFilterProp: "label", maxTagCount: "responsive", allowClear: true, tagRender: ({ label, value, closable, onClose }) => (_jsx(LoanStatus, { label: label, value: value, closable: closable, onClose: onClose })), options: Object.values(LoanStatusType).map((value) => {
                                    return {
                                        label: t(`loans.status.${value}`),
                                        value: value,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.product_id }, { children: _jsx(Select, { loading: !products.length, disabled: !products.length, placeholder: t("loans.product"), mode: "multiple", allowClear: true, maxTagCount: "responsive", optionFilterProp: "label", tagRender: ({ label, closable, onClose }) => (_jsx(Tag, Object.assign({ closable: closable, onClose: onClose }, { children: label }))), options: products.map(({ product_id, product_name }) => {
                                    return {
                                        label: product_name,
                                        value: product_id,
                                    };
                                }) }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.is_eligible }, { children: _jsx(Select, { placeholder: t("loans.eligible"), optionFilterProp: "label", allowClear: true, options: [
                                    {
                                        label: t("common.yes"),
                                        value: "true",
                                    },
                                    {
                                        label: t("common.no"),
                                        value: "false",
                                    },
                                ] }) })) })), _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.payout_date }, { children: _jsx(RangePicker, { allowEmpty: [true, true], placeholder: [t("loans.payout_form"), t("loans.payout_to")], style: { width: "100%" } }) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(FormItem, Object.assign({ name: SearchField.compartment_id }, { children: _jsx(Select, { placeholder: t("loans.compartment"), mode: "multiple", optionFilterProp: "label", maxTagCount: "responsive", allowClear: true, options: compartments.map((compartment) => {
                                    return {
                                        label: compartment.compartment_name,
                                        value: compartment.id,
                                    };
                                }) }) })) }))] })), _jsx(LoanPreview, { loan: loan, onClose: () => setLoan(undefined) })] }));
};
export default LoansPage;
