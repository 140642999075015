import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCard, DataCardDivider, DataCardItem } from "@ui/data-display/DataCard";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
import { GridRate, HedgingRate, MinimumInterestRate, } from "../../../../modules/Pricing/pricing-common-fields";
import FeeCalcSwitchRow from "./FeeCalcSwitchRow";
const { Text } = Typography;
const getCalcValues = (pricing, feesConfig, loanAmount, decimalToPercent) => ({
    interest_rate: pricing.interest_rate
        ? Number(decimalToPercent({
            percent: pricing.interest_rate,
        }))
        : undefined,
    transaction_fee: isValidNumericValue(pricing.transaction_fee)
        ? Number(pricing.transaction_fee)
        : Number(feesConfig.transaction_fee_min) * Number(loanAmount),
    agio_fee: Number(decimalToPercent({
        percent: isValidNumericValue(pricing.agio_fee) ? pricing.agio_fee : feesConfig.agio_fee_min,
    })),
    disagio_fee: Number(decimalToPercent({
        percent: isValidNumericValue(pricing.disagio_fee)
            ? pricing.disagio_fee
            : feesConfig.disagio_fee_min,
    })),
    payment_processing_fee: Number(decimalToPercent({
        percent: isValidNumericValue(pricing.payment_processing_fee)
            ? pricing.payment_processing_fee
            : feesConfig.payment_processing_fee_min,
    })),
});
const PricingFeeCalc = ({ rates }) => {
    const { t } = useTranslation();
    const { loan, productConfig } = useLoan();
    const { decimalToPercent, currency } = useFormatter();
    const [loading, setLoading] = useState(true);
    const [pricingForm, setPricingForm] = useState({});
    const pricing = loan.pricing;
    const initialPrepaymentRateValue = isValidNumericValue(pricing.prepayment_rate)
        ? Number(decimalToPercent({
            percent: pricing.prepayment_rate,
        }))
        : 0;
    const renderBasedOnMinimumRateName = (field) => {
        const fieldValue = pricing[field];
        if (!isValidNumericValue(fieldValue))
            return;
        const minimumFieldName = productConfig === null || productConfig === void 0 ? void 0 : productConfig.minimum_rate_comparator;
        const valueToDisplay = decimalToPercent({
            percent: fieldValue,
            showPercentSymbol: true,
        });
        if (minimumFieldName !== field)
            return valueToDisplay;
        return fieldValue < pricing.minimum_interest_rate ? (_jsx(Text, Object.assign({ strong: true, type: "danger" }, { children: valueToDisplay }))) : (valueToDisplay);
    };
    useEffect(() => {
        if (!(productConfig === null || productConfig === void 0 ? void 0 : productConfig.product_id))
            return;
        setLoading(true);
        void Axios.get(`/admin/products/${productConfig.product_id}/config/${productConfig.product_config_id}/fees_config`)
            .then(({ data }) => {
            setPricingForm((prev) => (Object.assign(Object.assign(Object.assign({}, prev), getCalcValues(pricing, data, loan.amount ? loan.amount : "", decimalToPercent)), { visible_rate_name: pricing.visible_rate_name, prepayment_rate: initialPrepaymentRateValue })));
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productConfig === null || productConfig === void 0 ? void 0 : productConfig.product_id]);
    return (_jsx(Spin, Object.assign({ spinning: loading }, { children: _jsxs(DataCard, { children: [_jsx(GridRate, { gridRate: loan.pricing.grid_rate, productConfig: productConfig }), _jsx(HedgingRate, { isEditable: false, rates: rates, hedgingRate: loan.pricing.hedging_rate, productConfig: productConfig }), _jsx(MinimumInterestRate, { minimumInterestRate: loan.pricing.minimum_interest_rate }), _jsx(DataCardDivider, {}), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.pricing.origination-fee"), value: isValidNumericValue(pricing.transaction_fee)
                        ? currency(pricing.transaction_fee, { currency: loan.currency })
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.agio"), value: isValidNumericValue(pricing.agio_fee_absolute)
                        ? currency(pricing.agio_fee_absolute, { currency: loan.currency })
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.disagio"), value: isValidNumericValue(pricing.disagio_fee_absolute)
                        ? currency(pricing.disagio_fee_absolute, { currency: loan.currency })
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.monthly-processing-fee"), value: isValidNumericValue(pricing.payment_processing_fee_absolute)
                        ? currency(pricing.payment_processing_fee_absolute, {
                            currency: loan.currency,
                        })
                        : "" }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.pricing.monthly-payment"), value: pricing.monthly_payment
                        ? currency(pricing.monthly_payment, { currency: loan.currency })
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.first-payment-date"), value: pricing.estimated_first_payment_date
                        ? dayjs(pricing.estimated_first_payment_date).format(dayMonthYear)
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.amount-paid-out"), value: pricing.amount_paid_out
                        ? currency(pricing.amount_paid_out, { currency: loan.currency })
                        : "" }), _jsx(DataCardItem, { label: t("application.pricing.principal-to-be-repaid"), value: pricing.principal ? currency(pricing.principal, { currency: loan.currency }) : "" }), _jsx(DataCardDivider, {}), _jsxs(Row, Object.assign({ style: { marginBottom: 8 } }, { children: [_jsx(Col, { flex: "200px" }), _jsx(Col, Object.assign({ flex: 1 }, { children: _jsx(Row, Object.assign({ gutter: 16 }, { children: _jsx(Col, Object.assign({ span: 16, offset: 8, style: { textAlign: "center" } }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.display-to-user") })) })) })) }))] })), _jsx(FeeCalcSwitchRow, { label: t("application.pricing.nominal-interest-rate"), value: renderBasedOnMinimumRateName("total_interest_rate"), visibility: pricingForm.visible_rate_name === "total_interest_rate" }), _jsx(FeeCalcSwitchRow, { label: t("application.pricing.effective-interest-rate"), value: renderBasedOnMinimumRateName("apr"), visibility: pricingForm.visible_rate_name === "apr" }), _jsx(FeeCalcSwitchRow, { label: t("application.pricing.financing-costs"), value: pricing.financing_costs
                        ? decimalToPercent({
                            percent: pricing.financing_costs,
                            showPercentSymbol: true,
                        })
                        : "", visibility: pricingForm.visible_rate_name === "financing_costs" }), _jsx(DataCardDivider, {}), _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "200px" }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.prepayment-fee") })) })), _jsx(Col, Object.assign({ flex: 1 }, { children: _jsx(Text, { children: isValidNumericValue(pricing.prepayment_rate)
                                    ? decimalToPercent({ percent: pricing.prepayment_rate, showPercentSymbol: true })
                                    : "-" }) }))] }))] }) })));
};
export default PricingFeeCalc;
