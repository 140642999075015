import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
const ChecklistCheckbox = ({ id }) => {
    const { t } = useTranslation();
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    return (_jsx(Form.Item, Object.assign({ label: t("settings.checklists.config.item.description"), name: [id, "desc"], rules: [requiredRule] }, { children: _jsx(TextArea, { rows: 1 }) })));
};
export default ChecklistCheckbox;
