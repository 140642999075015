import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { DataCardItem } from "@ui/data-display/DataCard";
import { DataCard, DataCardDivider } from "../../../components/DataCard";
import { GridRate } from "./pricing-common-fields";
import { HedgingRate, InterestRate, MinimumInterestRate, PrepaymentRate, TransactionFee, } from "./pricing-common-fields";
const PricingStandard = ({ update, isInternalPricingEditable, transactionFeeToLoanAmount, rates, pricing, productConfig, currency, }) => {
    const { t } = useTranslation();
    return (_jsxs(DataCard, { children: [_jsx(GridRate, { gridRate: pricing.grid_rate, productConfig: productConfig }), _jsx(HedgingRate, { isEditable: isInternalPricingEditable, onUpdate: update, rates: rates, hedgingRate: pricing.hedging_rate, productConfig: productConfig }), _jsx(MinimumInterestRate, { minimumInterestRate: pricing.minimum_interest_rate }), _jsx(DataCardDivider, {}), _jsx(InterestRate, { isEditable: isInternalPricingEditable, onUpdate: update, interestRate: pricing.interest_rate, minimumInterestRate: pricing.minimum_interest_rate, productConfig: productConfig }), _jsx(DataCardDivider, {}), _jsx(TransactionFee, { onUpdate: update, isEditable: isInternalPricingEditable, transactionFee: pricing.transaction_fee, currency: currency }), _jsx(DataCardItem, { label: t("application.pricing.transactionFeeToLoanAmount"), value: _jsx(Typography.Text, { children: transactionFeeToLoanAmount }) }), _jsx(PrepaymentRate, { onUpdate: update, isEditable: isInternalPricingEditable, prepaymentRate: pricing.prepayment_rate, productConfig: productConfig })] }));
};
export default PricingStandard;
