var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Background, Controls, MarkerType, ReactFlow, ReactFlowProvider, applyNodeChanges, useEdgesState, useReactFlow, } from "reactflow";
import "reactflow/dist/style.css";
import Dagre from "@dagrejs/dagre";
import { App, Button, Select, Spin } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import MainHeader from "@ui/main-header/MainHeader";
import { Axios } from "src/utils/Axios";
import { WorkflowStatusNode } from "./WorkflowStatusNode";
import WorkflowTransitionDrawer from "./WorkflowTransitionDrawer";
import { BASE_NODE_WIDTH } from "./workflow-configurator.utils";
export const TEMPORARY_ID_PREFIX = "temporary";
const defaultEdgeOptions = { markerEnd: { type: MarkerType.ArrowClosed, width: 30, height: 30 } };
const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (nodes, edges) => {
    g.setGraph({ rankdir: "TB" });
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    /* @ts-ignore */
    nodes.forEach((node) => g.setNode(node.id, node));
    Dagre.layout(g);
    return {
        nodes: nodes.map((node) => {
            const { x, y } = g.node(node.id);
            return Object.assign(Object.assign({}, node), { position: { x, y } });
        }),
        edges,
    };
};
const GraphWrapper = styled.div `
	width: calc(100vw - 280px);
	height: calc(100vh - 150px);
`;
const generateId = () => Math.floor(Math.random() * 999999).toString();
const statuses = Object.values(ApplicationStatuses);
const WorkflowConfigurator = ({ product }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState("");
    const connectingNodeId = useRef(null);
    const reactFlowWrapper = useRef(null);
    const { project, fitView } = useReactFlow();
    const [selectedEdge, setSelectedEdge] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const onLayout = useCallback((nodes, edges) => {
        const layouted = getLayoutedElements(nodes, edges);
        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);
        window.requestAnimationFrame(() => {
            fitView();
        });
    }, [setNodes, setEdges, fitView]);
    const getStatusChangeConfig = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/admin/products/${product.product_id}/status_change_config`).then(({ data }) => {
            const nodes = data.map(({ status_change_config_id, status, possible_statuses }) => ({
                id: (status_change_config_id === null || status_change_config_id === void 0 ? void 0 : status_change_config_id.toString()) || "",
                position: { x: 0, y: 0 },
                width: BASE_NODE_WIDTH,
                height: 62,
                type: "statusNode",
                data: {
                    id: status_change_config_id,
                    value: status,
                    possibleStatuses: possible_statuses,
                },
            }));
            const edges = data.reduce((acc, node) => {
                const edges = (node.possible_statuses || []).reduce((acc, status) => {
                    const target = data.find((s) => s.status === status);
                    if (target) {
                        acc.push({
                            id: `${node.status_change_config_id}-${target.status}`,
                            source: node.status_change_config_id.toString(),
                            target: target.status_change_config_id.toString(),
                        });
                    }
                    return acc;
                }, []);
                return [...acc, ...edges];
            }, []);
            onLayout(nodes, edges);
        }, (err) => void Axios.error(err));
    }), [product.product_id, onLayout]);
    const updateStatusChangeConfig = useCallback((id, statusChangeConfigRequest) => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.patch(`/admin/products/${product.product_id}/status_change_config/${id}`, statusChangeConfigRequest).then(() => {
            void message.success("Status change config was added");
            void getStatusChangeConfig();
        }, (err) => void Axios.error(err));
    }), [product.product_id, getStatusChangeConfig, message]);
    const createStatusChangeConfig = useCallback((status) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield Axios.post(`/admin/products/${product.product_id}/status_change_config`, {
            status,
            possible_statuses: null,
        })
            .then(() => {
            void message.success("Status change config was added");
            void getStatusChangeConfig();
        }, (err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }), [product.product_id, getStatusChangeConfig, message]);
    const deleteStatusChangeConfig = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield Axios.delete(`/admin/products/${product.product_id}/status_change_config/${id}`)
            .then(() => {
            void message.success("Status change config was removed");
            void getStatusChangeConfig();
        }, (err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }), [product.product_id, getStatusChangeConfig, setIsLoading, message]);
    const updateNodeValue = useCallback(({ id, status, possibleStatuses, sourceForNewNode }) => {
        if (id.startsWith(TEMPORARY_ID_PREFIX)) {
            void createStatusChangeConfig(status);
            if (sourceForNewNode) {
                void updateStatusChangeConfig(sourceForNewNode.data.id.toString(), {
                    possible_statuses: [...(sourceForNewNode.data.possibleStatuses || []), status],
                });
            }
        }
        else {
            void updateStatusChangeConfig(id, {
                status,
                possible_statuses: possibleStatuses,
            });
        }
    }, [createStatusChangeConfig, updateStatusChangeConfig]);
    const removeNode = useCallback((id) => {
        if (id.startsWith(TEMPORARY_ID_PREFIX)) {
            setNodes((nds) => nds.filter((node) => node.id !== id));
            setEdges((edgs) => edgs.filter((edge) => edge.source !== id && edge.target !== id));
        }
        else {
            void deleteStatusChangeConfig(id);
        }
    }, [setEdges, setNodes, deleteStatusChangeConfig]);
    const nodeTypes = useMemo(() => ({
        statusNode: (props) => (_jsx(WorkflowStatusNode, Object.assign({}, props, { update: updateNodeValue, remove: removeNode, statuses: statuses }))),
    }), [removeNode, updateNodeValue]);
    const updateNodeToolbarVisibility = () => {
        setNodes((nds) => nds.map((node) => {
            node.data = Object.assign(Object.assign({}, node.data), { toolbarVisible: !!selectedNode && selectedNode === node.id });
            return node;
        }));
    };
    const onNodesChange = useCallback((changes) => {
        setNodes((nds) => applyNodeChanges(changes, nds));
    }, [setNodes]);
    const onConnect = useCallback((params) => {
        const sourceNode = nodes.find((node) => node.data.id.toString() === params.source);
        const targetNode = nodes.find((node) => node.data.id.toString() === params.target);
        if (sourceNode && (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.value)) {
            void updateStatusChangeConfig(sourceNode.data.id.toString(), {
                possible_statuses: [...(sourceNode.data.possibleStatuses || []), targetNode.data.value],
            });
        }
    }, [nodes, updateStatusChangeConfig]);
    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);
    const onConnectEnd = useCallback((event) => {
        var _a;
        const targetIsPane = (_a = event.target) === null || _a === void 0 ? void 0 : _a.classList.contains("react-flow__pane");
        if (targetIsPane) {
            const { x, y } = (reactFlowWrapper.current &&
                reactFlowWrapper.current.getBoundingClientRect()) || { x: 0, y: 0 };
            const id = `${TEMPORARY_ID_PREFIX}-${generateId()}`;
            const newNode = {
                id,
                position: project({
                    x: event.clientX - x - BASE_NODE_WIDTH / 2,
                    y: event.clientY - y,
                }),
                type: "statusNode",
                data: {
                    id,
                    value: undefined,
                    possibleStatuses: [],
                },
            };
            setNodes((nds) => nds.concat(newNode));
            setEdges((eds) => eds.concat({ id, source: connectingNodeId.current, target: id }));
        }
    }, [project, setEdges, setNodes]);
    useEffect(() => {
        setIsLoading(true);
        Promise.all([getStatusChangeConfig()]).finally(() => setIsLoading(false));
    }, [getStatusChangeConfig]);
    useEffect(updateNodeToolbarVisibility, [selectedNode]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ disabled: isLoading, onClick: () => onLayout(nodes, edges), style: { margin: 16 } }, { children: t("workflow_configurator.auto_fit_layout") })), _jsxs(Spin, Object.assign({ spinning: isLoading }, { children: [_jsx(GraphWrapper, Object.assign({ ref: reactFlowWrapper }, { children: _jsxs(ReactFlow, Object.assign({ nodes: nodes, edges: edges, onNodesChange: onNodesChange, onEdgesChange: onEdgesChange, onConnect: onConnect, nodeTypes: nodeTypes, onEdgeClick: (e, edge) => setSelectedEdge(edge), onPaneClick: () => setSelectedNode(""), onNodeClick: (e, node) => setSelectedNode(node.id), onConnectStart: onConnectStart, onConnectEnd: onConnectEnd, defaultEdgeOptions: defaultEdgeOptions, fitView: true, translateExtent: [
                                [-1500, -1500],
                                [2500, 2500],
                            ] }, { children: [_jsx(Controls, {}), _jsx(Background, { gap: 12, size: 1 })] })) })), _jsx(WorkflowTransitionDrawer, { sourceNode: nodes.find((node) => node.id === (selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.source)), targetNode: nodes.find((node) => node.id === (selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.target)), productId: product.product_id, close: () => setSelectedEdge(null), refetchStatusChangeConfig: getStatusChangeConfig })] }))] }));
};
const SettingsWorkflowConfigurator = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const { t } = useTranslation();
    const getProducts = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/admin/products`).then(({ data }) => {
            setProducts(data);
            setSelectedProduct(data[0]);
        }, (err) => void Axios.error(err));
    });
    useEffect(() => {
        getProducts().finally(() => setLoading(false));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("workflow_configurator"), extra: _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(Select, { disabled: loading, style: { width: 200 }, onChange: (value) => setSelectedProduct(products === null || products === void 0 ? void 0 : products.find((product) => product.product_id === value)), value: selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.product_id, options: products === null || products === void 0 ? void 0 : products.map((product) => ({
                            value: product.product_id,
                            label: product.name,
                        })) }) })) }), _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(ReactFlowProvider, { children: selectedProduct && _jsx(WorkflowConfigurator, { product: selectedProduct }) }) }))] }));
};
export default SettingsWorkflowConfigurator;
