export const financialTableRowsToTranslate = [
	"accounts",
	"inventory_change",
	"other_income",
	"extraordinary_income",
	"costs_of_materials",
	"external_services",
	"personnel_costs",
	"extraordinary_expense",
	"taxes",
	"other_taxes",
	"assets",
	"intangible_assets",
	"self_made_intangible_assets",
	"long_term_assets",
	"long_term_properties",
	"long_term_securities",
	"other_costs",
	"long_term_intangible_assets",
	"financial_assets",
	"accruals_aktiva",
	"losses_not_covered_by_equity",
	"equity-and-liabilities",
	"subscribed_capital",
	"capital_reserve",
	"retained_income",
	"profit_carried_forward",
	"annual_profit",
	"other_equity_position",
	"accrued_liabilities_short_term",
	"accrued_liabilities_long_term",
	"liabilities",
	"bonds",
	"bank_debts",
	"bank_debts_short_term",
	"bank_debts_long_term",
	"deposits_received",
	"other_liabilities_short_term",
	"other_debts_short_term",
	"other_liabilities_long_term",
	"loan_from_related_companies",
	"other_debts_long_term",
	"accruals_passiva",
	"total_liabilities_and_equity",
	"adjustments",
	"total_debt_service",
	"revenue",
	"activated_costs",
	"depreciations",
	"interest_income",
	"interest_expense",
	"other_finance_income",
	"other_finance_costs",
	"net_income",
	"investments_in_related_companies",
	"goodwill",
	"current_assets",
	"inventories",
	"accounts_receivable",
	"other_current_assets",
	"loan_to_related_companies",
	"loan_to_shareholders",
	"cash",
	"deferred_tax_assets",
	"total_assets_published",
	"equity",
	"equity_published",
	"accounts_payable",
	"loan_from_shareholders",
	"deferred_tax_liabilities",
	"total_liabilities_published",
] as const;

export type FinancialsTableRowsTranslation = Record<
	(typeof financialTableRowsToTranslate)[number],
	string
>;
