import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { App } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, MinRateSourcesEnum, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useApplication } from "../../../../layouts/ApplicationLayout/application.types";
import { Axios } from "../../../../utils/Axios";
import PricingExternal from "../../../modules/Pricing/PricingExternal";
import PricingStandard from "../../../modules/Pricing/PricingStandard";
import PricingTlb from "../../../modules/Pricing/PricingTlb";
import PricingFeeCalc from "./PricingFeeCalc/PricingFeeCalc";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const ApplicationPricing = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { application, updateApplication, productConfig, updatePendingItems } = useApplication();
    const { decimalToPercent } = useFormatter();
    const [latestGlobalRates, setLatestGlobalRates] = useState([]);
    const showExternalPricing = application.pricing.external_pricing_allowed;
    const showTlbPricing = (productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet" && !showExternalPricing;
    const showFeeCalc = (productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_pricing_calculations) && !showExternalPricing;
    const isInternalPricingEditable = useMemo(() => [
        ApplicationStatuses.Pricing,
        ApplicationStatuses.InternalPricing,
        ApplicationStatuses.OfferAccepted,
        ApplicationStatuses.OfferAvailable,
    ].includes(application.status), [application.status]);
    const transactionFeeToLoanAmount = useMemo(() => {
        if (!application.pricing.total_transaction_fee || !application.loanSize) {
            return "-";
        }
        return decimalToPercent({
            percent: Number(application.pricing.total_transaction_fee) / Number(application.loanSize),
            showPercentSymbol: true,
        });
    }, [application, decimalToPercent]);
    const update = (update) => {
        Axios.post(`/admin/applications/${application.applicationId}/pricing`, update)
            .then(() => {
            void updateApplication();
            void message.success(t("application.pricing.saved"));
        }, (error) => void Axios.error(error, t("application.pricing.validation.error.be-update")))
            .finally(updatePendingItems);
    };
    useEffect(() => {
        var _a;
        if (((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.min_rate_sources) === null || _a === void 0 ? void 0 : _a.includes(MinRateSourcesEnum.HedgingRate)) ||
            (productConfig === null || productConfig === void 0 ? void 0 : productConfig.type) === "loan_bullet") {
            void Axios.get(`/admin/global_rates/latest`)
                .then(({ data }) => setLatestGlobalRates(data))
                .catch((error) => void Axios.error(error));
        }
    }, [productConfig]);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.pricing.pricing") }), _jsx(Wrapper, { children: showTlbPricing ? (_jsx(PricingTlb, { update: update, transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, isEditable: application.status === ApplicationStatuses.Pricing, pricing: application.pricing, productConfig: productConfig, currency: application.currency })) : showExternalPricing ? (_jsx(PricingExternal, { update: update, isInternalPricingEditable: isInternalPricingEditable, transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, pricing: application.pricing, productConfig: productConfig, currency: application.currency, isEditable: application.status === ApplicationStatuses.ExternalPricing, partnerId: application.partner_id })) : showFeeCalc ? (_jsx(PricingFeeCalc, { update: update, isInternalPricingEditable: isInternalPricingEditable, rates: latestGlobalRates })) : (_jsx(PricingStandard, { update: update, isInternalPricingEditable: isInternalPricingEditable, transactionFeeToLoanAmount: transactionFeeToLoanAmount, rates: latestGlobalRates, pricing: application.pricing, productConfig: productConfig, currency: application.currency })) })] }));
};
export default ApplicationPricing;
