import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { App, Button, Dropdown, Menu, Select, Typography } from "antd";
import styled from "styled-components";
import { ApplicationStatuses, FeatureName, } from "@teylor-tools/Api";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";
import { languages } from "@teylor-tools/utils/i18n/Languages";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import Loader from "src/components/ui/Loader";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
import BeforeSignatureView from "./BeforeSignatureView";
import SignatureView from "./SignatureView";
const Wrapper = styled.div `
	margin-top: 64px;
`;
const LanguageWrapper = styled.div `
	display: grid;
	grid-template-columns: auto auto;
	gap: 8px;
	align-items: center;
`;
const ButtonsWrapper = styled.div `
	display: flex;
	gap: 16px;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const statesBeforeSignature = [
    ApplicationStatuses.Open,
    ApplicationStatuses.PreCheck,
    ApplicationStatuses.InternalReview,
    ApplicationStatuses.Pricing,
    ApplicationStatuses.InternalPricing,
    ApplicationStatuses.ExternalPricing,
];
const ApplicationSignatures = () => {
    var _a;
    const { message, modal } = App.useApp();
    const { t } = useTranslation();
    const { application, updateApplication, updatePendingItems } = useApplication();
    const [signatories, setSignatories] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingContract, setIsLoadingContract] = useState(false);
    const [isGeneratingContract, setIsGeneratingContract] = useState(false);
    const [applicationLanguages, setApplicationLanguages] = useState();
    const isBeforeSignature = statesBeforeSignature.includes(application.status);
    const isEditable = [ApplicationStatuses.Signature, ApplicationStatuses.FinalReview].includes(application.status);
    const getSignatories = useCallback(() => {
        setIsLoading(true);
        return Axios.get(`/admin/applications/${application.applicationId}/signatories`)
            .then(({ data }) => {
            if (isBeforeSignature) {
                setSignatories(data.signatories);
            }
            else {
                // Display only signatories that need to sign
                setSignatories(data.signatories.filter(({ requirements, kyc_status }) => {
                    const hasRequiredOtherDocuments = (requirements === null || requirements === void 0 ? void 0 : requirements.required_other_documents) &&
                        requirements.required_other_documents.length > 0;
                    if (hasRequiredOtherDocuments)
                        return true;
                    if (!kyc_status)
                        return false;
                    const kycStatusItems = Object.keys(kyc_status);
                    return kycStatusItems.some((item) => { var _a; return !!((_a = kyc_status[item]) === null || _a === void 0 ? void 0 : _a.is_required); });
                }));
            }
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
        });
    }, [application.applicationId, isBeforeSignature]);
    const getConfig = useCallback(() => {
        setIsLoading(true);
        return Axios.get(`/config`)
            .then(({ data }) => {
            setApplicationLanguages(data.public_features.find(({ name }) => name === FeatureName.ApplicationLanguages));
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoading(false);
        });
    }, []);
    const handleChangeLanguage = (language) => {
        Axios.patch(`/admin/applications/${application.applicationId}`, { language })
            .then(() => {
            void updateApplication();
            void message.success(t("application.contract.language-change-success"));
        })
            .catch((err) => Axios.error(err))
            .finally(updatePendingItems);
    };
    const generateContract = useCallback(() => {
        setIsGeneratingContract(true);
        Axios.post(`/admin/applications/${application.applicationId}/generate_contract`)
            .then(() => {
            void message.success(t("application.signatures.contract-generated-success"));
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsGeneratingContract(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.applicationId]);
    useEffect(() => {
        void getSignatories();
        void getConfig();
    }, [getSignatories, getConfig]);
    const showRegenerateContactModal = () => {
        void modal.confirm({
            title: t("application.signatures.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("application.signatures.re-generate-contract-modal-content"),
            okButtonProps: { danger: true },
            okText: t("application.contract.re-generate"),
            onOk: generateContract,
        });
    };
    const getLoanContact = () => {
        setIsLoadingContract(true);
        Axios.get(`/admin/applications/${application.applicationId}/loan_contract`)
            .then(({ data }) => {
            downloadFileFromUrl(data.download_url, data.filename);
        })
            .catch((err) => Axios.error(err))
            .finally(() => {
            setIsLoadingContract(false);
        });
    };
    if (isLoading && !signatories)
        return _jsx(Loader, {});
    if (!signatories)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs(SecondaryHeader, Object.assign({ title: t("application.signatures.title") }, { children: [!isBeforeSignature && (_jsxs(ButtonsWrapper, { children: [_jsx(Button, Object.assign({ icon: _jsx(DownloadOutlined, {}), onClick: getLoanContact, loading: isLoadingContract }, { children: t("application.signatures.download-loan-contract") })), isEditable && (_jsx(Dropdown, Object.assign({ overlay: _jsx(Menu, { items: [
                                        {
                                            label: t("application.signatures.re-generate-loan-contract"),
                                            key: "regenerate-loan-contract",
                                            onClick: showRegenerateContactModal,
                                        },
                                    ] }) }, { children: _jsx(Button, { icon: _jsx(MoreOutlined, {}), loading: isGeneratingContract }) })))] })), application.status === ApplicationStatuses.InternalReview && applicationLanguages && (_jsxs(LanguageWrapper, { children: [_jsx(Typography.Text, { children: t("application.contract.generate-documents-in") }), _jsx(Select, { style: { minWidth: "150px" }, value: application.language || applicationLanguages.value.default, onChange: handleChangeLanguage, options: (_a = applicationLanguages.value.supported) === null || _a === void 0 ? void 0 : _a.map((language) => {
                                    return {
                                        label: (_jsx(Typography.Text, { children: `${languages[language].flag} ${t(`languages.${language}`)}` })),
                                        value: language,
                                    };
                                }) })] }))] })), _jsx(Wrapper, { children: isBeforeSignature ? (signatories.map((signatory) => (_jsx(BeforeSignatureView, { signatory: signatory, onUpdate: getSignatories }, signatory.signatory_id)))) : (_jsx(SignatureView, { signatories: signatories, onUpdate: getSignatories, isEditable: isEditable })) })] }));
};
export default ApplicationSignatures;
