import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table, Tag, Typography } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
const getOperator = (operator) => {
    switch (operator) {
        case "eq":
            return "=";
        case "neq":
            return "!=";
        case "gt":
            return ">";
        case "gte":
            return ">=";
        case "lt":
            return "<";
        case "lte":
            return "<=";
    }
};
const ConcentrationLimitsTable = ({ data, type, isSessionInProgress }) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState();
    const { decimalToPercent, currency, localizedNumber } = useFormatter();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const getParameterToDisplay = useCallback((value, parameter) => {
        if (!isValidNumericValue(value))
            return "-";
        switch (parameter) {
            case "currency":
                return currency(value, { currency: defaultCurrency });
            case "percent":
                return decimalToPercent({ percent: value, decimalPlaces: 2, showPercentSymbol: true });
            case "integer":
                return localizedNumber({ value: value, decimalPlaces: 2 });
            default:
                return value;
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultCurrency]);
    const createCommonColumns = () => {
        return [
            {
                title: t("portfolio.concentration-limits.operator"),
                render: ({ operator }) => operator,
                width: 55,
            },
            {
                title: t("portfolio.concentration-limits.limit"),
                render: ({ limit, parameterType }) => getParameterToDisplay(limit, parameterType),
                width: 130,
                align: "right",
            },
            {
                title: t("portfolio.concentration-limits.actual"),
                render: ({ actual, parameterType, actualDifference, actualDifferenceImproved, }) => {
                    return (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ style: { marginRight: 4 } }, { children: getParameterToDisplay(actual, parameterType) })), actualDifference ? (_jsxs(Typography.Text, Object.assign({ type: actualDifferenceImproved ? "success" : "danger" }, { children: ["(", Number(actualDifference) > 0 ? "+" : "", getParameterToDisplay(actualDifference, parameterType), ")"] }))) : ("")] }));
                },
                width: isSessionInProgress ? 160 : 130,
                align: "right",
            },
            {
                title: t("portfolio.concentration-limits.excess-amount"),
                render: ({ excessAmount }) => isValidNumericValue(excessAmount)
                    ? currency(excessAmount, { currency: defaultCurrency })
                    : "-",
                width: 160,
                align: "right",
            },
            {
                title: t("portfolio.concentration-limits.test"),
                render: ({ conditionMet }) => conditionMet ? (_jsx(Tag, Object.assign({ color: "success" }, { children: t("portfolio.concentration-limits.general.test.pass") }))) : (_jsx(Tag, Object.assign({ color: "error" }, { children: t("portfolio.concentration-limits.general.test.fail") }))),
                width: 120,
            },
        ];
    };
    const columns = [
        ...(type === "general"
            ? [
                {
                    title: t("portfolio.concentration-limits.parameter"),
                    render: ({ desc }) => {
                        // @ts-ignore dynamic values from BE
                        return t(`portfolio.concentration-limits.descriptions.${desc}`);
                    },
                    ellipsis: true,
                },
            ]
            : [
                {
                    title: t("portfolio.concentration-limits.nace_code"),
                    render: ({ nace_code }) => nace_code,
                    width: 100,
                },
                {
                    title: t("portfolio.concentration-limits.industry"),
                    render: ({ desc }) => 
                    // @ts-ignore dynamic values from BE
                    t(`portfolio.concentration-limits.descriptions.${desc}`),
                    ellipsis: true,
                },
            ]),
        ...createCommonColumns(),
    ];
    useEffect(() => {
        const mappedData = (data || [])
            .filter((check) => check.is_condition_met !== null)
            .map((check) => ({
            key: check.concentration_limit_id,
            nace_code: check.parameter_name,
            desc: check.concentration_limit_description,
            operator: getOperator(check.operator),
            parameterType: check.parameter_type,
            limit: check.parameter_limit,
            actual: check.parameter_value,
            actualDifference: check.parameter_difference_based_on_what_if_analysis,
            actualDifferenceImproved: check.parameter_improved_based_on_what_if_analysis,
            excessAmount: check.parameter_excess_amount_value,
            conditionMet: check.is_condition_met,
        }));
        setTableData(mappedData);
    }, [data]);
    return _jsx(Table, { columns: columns, dataSource: tableData, rowKey: "key", pagination: false });
};
export default ConcentrationLimitsTable;
