var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, BankOutlined, CheckCircleOutlined, DatabaseOutlined, EuroCircleOutlined, FileProtectOutlined, FileSearchOutlined, GoldOutlined, LinkOutlined, PercentageOutlined, ShopOutlined, TeamOutlined, WalletOutlined, } from "@ant-design/icons";
import { AllBanksLoanPurposes, ApplicationStatuses, } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { ApplicationRoutes } from "src/Routes";
import isMinimumStatus from "src/utils/isMinimumStatus";
const ApplicationMenu = ({ applicationId, applicationStatus, statusFlow, allBanksLoanPurposes, showChecklists, productConfig, }) => {
    var _a;
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [openKeys, setOpenKeys] = useState();
    const isAkf = allBanksLoanPurposes === AllBanksLoanPurposes.PurposeAkfBike;
    const isObjectFinancing = allBanksLoanPurposes === AllBanksLoanPurposes.ObjectFinancing;
    const isFactoring = allBanksLoanPurposes === AllBanksLoanPurposes.Factoring;
    const externalOffer = [
        "prd_loan_ing",
        "prd_loan_iwoca_25_plus",
        "prd_loan_iwoca_sub_25",
    ].includes((_a = productConfig === null || productConfig === void 0 ? void 0 : productConfig.product_id) !== null && _a !== void 0 ? _a : "");
    const menuItemsWithId = useMemo(() => {
        const menuItems = [
            {
                icon: _jsx(AppstoreOutlined, { "data-cy": "overview-tab-link" }),
                label: t("application.menu.overview"),
                key: ApplicationRoutes.Application,
            },
            {
                icon: _jsx(LinkOutlined, { "data-cy": "documents-tab-link" }),
                label: t("application.menu.documents"),
                key: ApplicationRoutes.ApplicationDocuments,
            },
            { type: "divider" },
            {
                icon: _jsx(ShopOutlined, { "data-cy": "company-tab-link" }),
                label: t("application.menu.company"),
                key: ApplicationRoutes.ApplicationCompanies,
            },
            {
                icon: _jsx(TeamOutlined, { "data-cy": "representatives-tab-link" }),
                label: t("application.menu.representatives"),
                key: ApplicationRoutes.ApplicationRepresentatives,
            },
            {
                icon: _jsx(EuroCircleOutlined, { "data-cy": "financials-tab-link" }),
                label: t("application.menu.financials"),
                key: ApplicationRoutes.ApplicationFinancials,
            },
            { type: "divider" },
            {
                icon: _jsx(GoldOutlined, {}),
                label: t("application.menu.leasing_details"),
                key: ApplicationRoutes.ApplicationLeasingDetails,
                condition: isAkf,
            },
            {
                icon: _jsx(GoldOutlined, { "data-cy": "loans-tab-link" }),
                label: t("application.menu.loan"),
                key: ApplicationRoutes.ApplicationLoans,
                condition: !isAkf,
            },
            {
                icon: _jsx(PercentageOutlined, { "data-cy": "pricing-tab-link" }),
                label: t("application.menu.pricing"),
                key: ApplicationRoutes.ApplicationPricing,
                condition: !externalOffer,
            },
            {
                icon: _jsx(PercentageOutlined, { "data-cy": "externalOffer-tab-link" }),
                label: t("application.menu.external_offer"),
                key: ApplicationRoutes.ApplicationExternalOffer,
                condition: externalOffer,
            },
            {
                icon: _jsx(WalletOutlined, { "data-cy": "repayment-tab-link" }),
                label: t("application.menu.repayment"),
                key: ApplicationRoutes.ApplicationRepayment,
                condition: isMinimumStatus(ApplicationStatuses.AwaitingPayout, statusFlow),
            },
            {
                icon: _jsx(FileProtectOutlined, { "data-cy": "signatures-tab-link" }),
                label: t("application.menu.signatures"),
                key: ApplicationRoutes.ApplicationSignatures,
            },
            {
                type: "divider",
                condition: isObjectFinancing,
            },
            {
                icon: _jsx(DatabaseOutlined, { "data-cy": "objects-tab-link" }),
                label: t("application.menu.objects"),
                key: ApplicationRoutes.ApplicationObjects,
                condition: isObjectFinancing,
            },
            {
                type: "divider",
                condition: isFactoring,
            },
            {
                icon: _jsx(BankOutlined, { "data-cy": "factoring-tab-link" }),
                key: ApplicationRoutes.ApplicationFactoring,
                label: t("application.menu.factoring"),
                condition: isFactoring,
            },
            {
                type: "divider",
            },
            {
                icon: _jsx(FileSearchOutlined, { "data-cy": "analysis-tab-link" }),
                key: "Analysis",
                label: t("application.menu.analysis"),
                children: [
                    {
                        label: (_jsx("span", Object.assign({ "data-cy": "quantitative-tab-link" }, { children: t("application.menu.quantitative") }))),
                        key: ApplicationRoutes.ApplicationQuantitative,
                    },
                    {
                        label: _jsx("span", Object.assign({ "data-cy": "qualitative-tab-link" }, { children: t("application.menu.qualitative") })),
                        key: ApplicationRoutes.ApplicationQualitative,
                    },
                    ...((productConfig === null || productConfig === void 0 ? void 0 : productConfig.allow_loan_eligibility_assessment)
                        ? [
                            {
                                label: t("application.menu.eligibility"),
                                key: ApplicationRoutes.ApplicationEligibility,
                            },
                        ]
                        : []),
                ],
            },
            ...(showChecklists
                ? [
                    {
                        icon: _jsx(CheckCircleOutlined, {}),
                        key: "Checklists",
                        label: t("application.menu.checklists"),
                        condition: isMinimumStatus(ApplicationStatuses.FinalReview, statusFlow),
                        children: [
                            {
                                label: t("application.menu.final-review"),
                                key: ApplicationRoutes.ApplicationFinalReviewChecklist,
                            },
                            ...(isMinimumStatus(ApplicationStatuses.AwaitingPayout, statusFlow)
                                ? [
                                    {
                                        label: t("application.menu.payout"),
                                        key: ApplicationRoutes.ApplicationPayoutChecklist,
                                    },
                                ]
                                : []),
                        ],
                    },
                ]
                : []),
        ];
        return menuItems
            .filter((item) => (item.condition === undefined ? true : item.condition))
            .map((item) => {
            if ("key" in item) {
                // element is a menu item
                const menuItem = Object.assign(Object.assign({}, item), { children: item.children
                        ? item.children.map((_a) => {
                            var { key } = _a, other = __rest(_a, ["key"]);
                            return Object.assign({ key: generatePath(key, { applicationId }) }, other);
                        })
                        : undefined, key: generatePath(item.key, { applicationId }) });
                delete menuItem.condition;
                return menuItem;
            }
            else {
                return item;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId, applicationStatus, externalOffer, productConfig]);
    useEffect(() => {
        setOpenKeys(menuItemsWithId.reduce((acc, item) => {
            var _a;
            if ("key" in item) {
                const menuItem = item;
                if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.some((child) => child.key === location.pathname)) {
                    acc.push(menuItem.key);
                }
            }
            return acc;
        }, []));
    }, [menuItemsWithId, location.pathname]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", 
        // default opened submenus set
        openKeys: openKeys, onClick: (menuItem) => navigate(menuItem.key), onOpenChange: setOpenKeys, items: menuItemsWithId, isCollapsible: true, collapseText: t("menu.collapse") }));
};
export default ApplicationMenu;
