import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import RateCreation from "src/pages/settings/RateCreation";
import { Axios } from "src/utils/Axios";
export const RATE_PRECISION = 4;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;
const SettingsRatesPage = () => {
    const { t } = useTranslation();
    const { decimalToPercent, date } = useFormatter();
    const [loading, setLoading] = useState(true);
    const [rates, setRates] = useState([]);
    const [pagination, setPagination] = useState();
    const [createRate, setCreateRate] = useState(false);
    const getRates = useCallback((page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE) => {
        setLoading(true);
        Axios.get("admin/global_rates", { page, page_size: pageSize })
            .then(({ data }) => {
            setRates(data.result);
            setPagination(data.pagination);
        }, (err) => Axios.error(err, t("common.request-failed")))
            .finally(() => setLoading(false));
    }, [t]);
    const columns = [
        {
            dataIndex: "global_rate_name",
            title: t("settings.rates.type"),
            render: (value) => t(`settings.rates.types.${value}`),
        },
        {
            dataIndex: "global_rate_period",
            title: t("settings.rates.frequency"),
            render: (value) => t(`settings.rates.frequencies.${value}`),
        },
        {
            dataIndex: "global_rate_value",
            title: t("settings.rates.value"),
            render: (value) => decimalToPercent({
                percent: value,
                decimalPlaces: RATE_PRECISION,
                showPercentSymbol: true,
            }),
        },
        {
            dataIndex: "global_rate_effective_date",
            title: t("settings.rates.date"),
            render: (value) => date(value),
        },
    ];
    useEffect(() => getRates(DEFAULT_PAGE, DEFAULT_PAGE_SIZE), [getRates]);
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("settings.rates"), extra: _jsx(Button, Object.assign({ icon: _jsx(PlusOutlined, {}), type: "primary", onClick: () => setCreateRate(true) }, { children: t("settings.rates.add-btn") })) }), _jsx("div", Object.assign({ style: { padding: "16px 24px" } }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "global_rate_id", dataSource: rates, pagination: {
                        showSizeChanger: true,
                        pageSizeOptions: [15, 30, 60],
                        current: pagination === null || pagination === void 0 ? void 0 : pagination.current_page,
                        pageSize: pagination === null || pagination === void 0 ? void 0 : pagination.items_per_page,
                        total: pagination === null || pagination === void 0 ? void 0 : pagination.total_rows,
                    }, onChange: (pagination) => getRates(pagination.current, pagination.pageSize) }) })), _jsx(RateCreation, { createRate: createRate, updateRates: () => getRates(DEFAULT_PAGE, DEFAULT_PAGE_SIZE), close: () => setCreateRate(false) })] }));
};
export default SettingsRatesPage;
