import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import styled from "styled-components";
import { LoanStatus } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardItem } from "@ui/data-display/DataCard";
import { Routes } from "src/Routes";
import Loader from "src/components/ui/Loader";
import { DataCard, DataCardDivider } from "../../../components/DataCard";
import { Axios } from "../../../utils/Axios";
const Wrapper = styled.div `
	padding: 24px;
	display: grid;
	column-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const getQueryParams = ({ status, portfolioId, compartmentId }) => {
    let params = `status=${status}`;
    if (portfolioId) {
        params = params + `&portfolio_id=${portfolioId}`;
    }
    if (compartmentId) {
        params = params + `&compartment_id=${compartmentId}`;
    }
    return params;
};
const PortfolioOverviewCards = ({ portfolioId, compartmentId }) => {
    const { t } = useTranslation();
    const { currency, decimalToPercent } = useFormatter();
    const [isLoading, setIsLoading] = useState(false);
    const [portfolioOverview, setPortfolioOverview] = useState();
    const getPortfolioOverview = useCallback(({ portfolioId, compartmentId }) => {
        setIsLoading(true);
        Axios.get(`admin/portfolio_monitoring_overview`, {
            portfolio_id: portfolioId,
            compartment_id: compartmentId,
        })
            .then(({ data }) => {
            setPortfolioOverview(data);
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setIsLoading(false));
    }, []);
    useEffect(() => {
        void getPortfolioOverview({ portfolioId, compartmentId });
    }, [getPortfolioOverview, portfolioId, compartmentId]);
    if (isLoading) {
        return _jsx(Loader, {});
    }
    if (!portfolioOverview)
        return null;
    const { summary, statistics, defaulted, distressed } = portfolioOverview;
    return (_jsxs(Wrapper, { children: [_jsxs("div", { children: [_jsxs(DataCard, Object.assign({ title: t("portfolio.portfolio-summary") }, { children: [_jsx(DataCardItem, { label: `# ${t("portfolio.summary.loans-in-portfolio")}`, value: summary === null || summary === void 0 ? void 0 : summary.loans_in_portfolio }), _jsx(DataCardItem, { label: `# ${t("portfolio.summary.number-of-companies")}`, value: summary === null || summary === void 0 ? void 0 : summary.number_of_companies }), _jsx(DataCardItem, { label: t("portfolio.summary.notional-amount"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.notional_amount)
                                    ? currency(summary.notional_amount, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: `# ${t("portfolio.summary.loans-outstanding")}`, value: summary === null || summary === void 0 ? void 0 : summary.loans_outstanding }), _jsx(DataCardItem, { label: `# ${t("portfolio.summary.number-of-companies")}`, value: summary.number_of_companies_outstanding }), _jsx(DataCardItem, { label: t("portfolio.summary.notional-amount"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.notional_amount_outstanding)
                                    ? currency(summary.notional_amount_outstanding, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.summary.principal-outstanding"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.principal_outstanding)
                                    ? currency(summary.principal_outstanding, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.summary.interest-outstanding"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.interest_outstanding)
                                    ? currency(summary.interest_outstanding, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.summary.total-outstanding"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.total_outstanding)
                                    ? currency(summary.total_outstanding, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: `# ${t("portfolio.summary.fully-repaid-loans")}`, value: summary === null || summary === void 0 ? void 0 : summary.fully_repaid_loans }), _jsx(DataCardItem, { label: t("portfolio.summary.principal-repaid"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.repaid_principal)
                                    ? currency(summary.repaid_principal, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.summary.interest-repaid"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.repaid_interest)
                                    ? currency(summary.repaid_interest, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.summary.total-repaid"), value: isValidNumericValue(summary === null || summary === void 0 ? void 0 : summary.repaid_total)
                                    ? currency(summary.repaid_total, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined })] })), _jsxs(DataCard, Object.assign({ title: _jsxs(Space, { children: [t("portfolio.summary-statistics"), _jsx(Tooltip, Object.assign({ title: t("portfolio.summary-statistics.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }) }, { children: [_jsx(DataCardItem, { label: t("portfolio.statistics.avg-loan-amount"), value: isValidNumericValue(statistics === null || statistics === void 0 ? void 0 : statistics.avg_loan_amount)
                                    ? currency(statistics.avg_loan_amount, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.statistics.wa-apr"), value: isValidNumericValue(statistics === null || statistics === void 0 ? void 0 : statistics.wa_apr)
                                    ? decimalToPercent({ percent: statistics.wa_apr, showPercentSymbol: true })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.statistics.wa-interest-rate"), value: isValidNumericValue(statistics === null || statistics === void 0 ? void 0 : statistics.wa_interest_rate)
                                    ? decimalToPercent({
                                        percent: statistics.wa_interest_rate,
                                        showPercentSymbol: true,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.statistics.wa-maturity"), value: (statistics === null || statistics === void 0 ? void 0 : statistics.wa_maturity) &&
                                    t("portfolio.statistics.months", { months: statistics.wa_maturity }) }), _jsx(DataCardItem, { label: t("portfolio.statistics.wa-outstanding-duration"), value: (statistics === null || statistics === void 0 ? void 0 : statistics.wa_outstanding_duration) &&
                                    t("portfolio.statistics.months", { months: statistics.wa_outstanding_duration }) }), _jsx(DataCardItem, { label: _jsxs(Space, { children: [t("portfolio.statistics.wa-margin-over-swap-rate"), _jsx(Tooltip, Object.assign({ title: t("portfolio.statistics.wa-margin-over-swap-rate.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), value: isValidNumericValue(statistics === null || statistics === void 0 ? void 0 : statistics.wa_margin_over_swap_rate)
                                    ? decimalToPercent({
                                        percent: statistics.wa_margin_over_swap_rate,
                                        showPercentSymbol: true,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.statistics.wa-crefo-pd"), value: isValidNumericValue(statistics === null || statistics === void 0 ? void 0 : statistics.wa_crefo_pd)
                                    ? decimalToPercent({ percent: statistics.wa_crefo_pd, showPercentSymbol: true })
                                    : undefined })] }))] }), _jsxs("div", { children: [_jsxs(DataCard, Object.assign({ title: _jsxs(Space, { children: [t("portfolio.defaulted-assets"), _jsx(Tooltip, Object.assign({ title: t("portfolio.defaulted-assets.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), extra: _jsx(Link, Object.assign({ to: `${generatePath(Routes.Loans)}?${getQueryParams({ status: LoanStatus.Default, portfolioId, compartmentId })}` }, { children: t("loans.overview.view-all") })) }, { children: [_jsx(DataCardItem, { label: `# ${t("portfolio.defaulted.defaulted-loans")}`, value: defaulted === null || defaulted === void 0 ? void 0 : defaulted.defaulted_loans }), _jsx(DataCardItem, { label: `# ${t("portfolio.defaulted.companies")}`, value: defaulted === null || defaulted === void 0 ? void 0 : defaulted.companies }), _jsx(DataCardItem, { label: t("portfolio.defaulted.total-defaulted-loan-amount"), value: isValidNumericValue(defaulted === null || defaulted === void 0 ? void 0 : defaulted.total_defaulted_amount)
                                    ? currency(defaulted.total_defaulted_amount, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.defaulted.defaulted-principal"), value: isValidNumericValue(defaulted === null || defaulted === void 0 ? void 0 : defaulted.defaulted_principal)
                                    ? currency(defaulted.defaulted_principal, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: t("portfolio.defaulted.defaulted-interest"), value: isValidNumericValue(defaulted === null || defaulted === void 0 ? void 0 : defaulted.defaulted_interest)
                                    ? currency(defaulted.defaulted_interest, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: _jsxs(Space, { children: [t("portfolio.defaulted.defaulted-rate-count"), _jsx(Tooltip, Object.assign({ title: t("portfolio.defaulted.defaulted-rate-count.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), value: isValidNumericValue(defaulted === null || defaulted === void 0 ? void 0 : defaulted.default_rate_count)
                                    ? decimalToPercent({
                                        percent: defaulted.default_rate_count,
                                        showPercentSymbol: true,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: _jsxs(Space, { children: [t("portfolio.defaulted.defaulted-rate-op"), _jsx(Tooltip, Object.assign({ title: t("portfolio.defaulted.defaulted-rate-op.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), value: isValidNumericValue(defaulted === null || defaulted === void 0 ? void 0 : defaulted.default_rate_op)
                                    ? decimalToPercent({
                                        percent: defaulted.default_rate_op,
                                        showPercentSymbol: true,
                                    })
                                    : undefined })] })), _jsxs(DataCard, Object.assign({ title: _jsxs(Space, { children: [t("portfolio.distressed-assets"), _jsx(Tooltip, Object.assign({ title: t("portfolio.distressed-assets.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), extra: _jsx(Link, Object.assign({ to: `${generatePath(Routes.Loans)}?${getQueryParams({ status: LoanStatus.Delay, portfolioId, compartmentId })}` }, { children: t("loans.overview.view-all") })) }, { children: [_jsx(DataCardItem, { label: `# ${t("portfolio.distressed.loans-overdue")}`, value: distressed.loans_overdue }), _jsx(DataCardItem, { label: `# ${t("portfolio.distressed.companies")}`, value: distressed === null || distressed === void 0 ? void 0 : distressed.companies }), _jsx(DataCardItem, { label: t("portfolio.distressed.overdue-payments-sum"), value: isValidNumericValue(distressed === null || distressed === void 0 ? void 0 : distressed.sum_of_overdue_payments)
                                    ? currency(distressed.sum_of_overdue_payments, {
                                        currency: portfolioOverview.currency,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: _jsxs(Space, { children: [t("portfolio.distressed.delinquency-rate-count"), _jsx(Tooltip, Object.assign({ title: t("portfolio.distressed.delinquency-rate-count.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), value: isValidNumericValue(distressed === null || distressed === void 0 ? void 0 : distressed.delinquency_rate_count)
                                    ? decimalToPercent({
                                        percent: distressed.delinquency_rate_count,
                                        showPercentSymbol: true,
                                    })
                                    : undefined }), _jsx(DataCardItem, { label: _jsxs(Space, { children: [t("portfolio.distressed.delinquency-rate-op"), _jsx(Tooltip, Object.assign({ title: t("portfolio.distressed.delinquency-rate-op.tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] }), value: isValidNumericValue(distressed === null || distressed === void 0 ? void 0 : distressed.delinquency_rate_op)
                                    ? decimalToPercent({
                                        percent: distressed.delinquency_rate_op,
                                        showPercentSymbol: true,
                                    })
                                    : undefined })] }))] })] }));
};
export default PortfolioOverviewCards;
