var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CommentOutlined, ExclamationCircleOutlined, FileProtectOutlined, FileSearchOutlined, HistoryOutlined, InfoCircleOutlined, LeftOutlined, MoreOutlined, UnorderedListOutlined, } from "@ant-design/icons";
import { App, Badge, Button, Dropdown, Layout, Menu, Row, Space, Spin, Tag, Typography, } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import { AllBanksLoanPurposes, ApplicationStatuses, ChecklistType, FeatureName, PartnerType, TaskRelatedEntityType, TaskStatus, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { LayoutContext, SideActions, } from "src/layouts/ApplicationLayout/application.types";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import ErrorBoundaryWrapper from "../ErrorBoundaryWrapper";
import { generatePendingItems } from "./generate-pending-items";
import ApplicationMenu from "./modules/ApplicationMenu";
import ApplicationOutlet from "./modules/ApplicationOutlet";
import CloneApplication from "./modules/CloneApplication";
import MoveApplication from "./modules/MoveApplication";
import ShareApplication from "./modules/ShareApplication";
const uploadButtonStyles = {
    backgroundColor: "#ff4d4f",
    borderColor: "#ff4d4f",
    color: "#fff",
};
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const ApplicationLayout = () => {
    var _a;
    const { t } = useTranslation();
    const { modal } = App.useApp();
    const { applicationId } = useParams();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const [activeMenu, setActiveMenu] = useState();
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState();
    const [pendingItems, setPendingItems] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [logs, setLogs] = useState([]);
    const [productConfig, setProductConfig] = useState();
    const [editableFields, setEditableFields] = useState([]);
    const { lastMainRoute } = useLastMainRoute();
    const { tasksEnabled, features } = useSelector((state) => state.configState);
    const [shareApplicationModalOpen, setShareApplicationModalOpen] = useState(false);
    const [cloneApplicationModalOpen, setCloneApplicationModalOpen] = useState(false);
    const pendingItemsAndTasksBadgeCount = useMemo(() => {
        if (!tasksEnabled)
            return pendingItems.length;
        const tasksCount = tasks.filter((task) => task.status !== TaskStatus.Done).length;
        return tasksCount + pendingItems.length;
    }, [pendingItems, tasks, tasksEnabled]);
    const uploadRaisingAvailable = useMemo(() => { var _a; return !!((_a = features.find((f) => f.name === FeatureName.RaisinUploadButton)) === null || _a === void 0 ? void 0 : _a.is_active); }, [features]);
    const canCloneApplication = useMemo(() => { var _a; return !!((_a = features.find((f) => f.name === FeatureName.ApplicationCloning)) === null || _a === void 0 ? void 0 : _a.is_active); }, [features]);
    const updateApplication = (params) => {
        return params
            ? Axios.patch(`/admin/applications/${applicationId}`, params).then(() => {
                void getApplication();
                void getApplicationLogs();
            }, (err) => Axios.error(err))
            : getApplication().then(() => Promise.resolve());
    };
    const getApplicationLogs = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`admin/applications/${applicationId}/logs`).then(({ data }) => setLogs(data.logs || []), (err) => {
            void Axios.error(err);
        });
    }), [applicationId]);
    const getEditableFields = () => {
        return Axios.get(`admin/applications/${applicationId}/application_update_constraints`).then(({ data }) => {
            const editableFields = data.reduce((acc, constraint) => {
                var _a;
                (_a = constraint.allowed_fields) === null || _a === void 0 ? void 0 : _a.forEach((field) => {
                    var _a;
                    // @ts-ignore
                    if (acc.has[field] && ((_a = constraint.actions) === null || _a === void 0 ? void 0 : _a.length)) {
                        const currentActions = 
                        // @ts-ignore
                        acc.get[field] || [];
                        acc.set(field, [...currentActions, ...constraint.actions]);
                    }
                    else {
                        acc.set(field, constraint.actions || []);
                    }
                });
                return acc;
            }, new Map());
            setEditableFields(Array.from(editableFields).map(([key, value]) => ({ field: key, actions: value })));
        }, (err) => void Axios.error(err));
    };
    const getProductConfig = (application) => {
        return Axios.get(`/admin/products/${application.product.product_id}/config/${application.product.product_config_id}`).then(({ data }) => setProductConfig(data), (err) => void Axios.error(err));
    };
    const updateProductConfig = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const application = yield getApplication(false);
        if (!application) {
            setLoading(false);
            return Promise.reject();
        }
        Promise.all([getProductConfig(application), getEditableFields()]).finally(() => setLoading(false));
    });
    const getTasks = () => {
        if (!tasksEnabled)
            return Promise.reject();
        return Axios.get("admin/tasks", {
            related_entity_id: applicationId,
            related_entity_type: TaskRelatedEntityType.Application,
        }).then(({ data }) => setTasks(data.result || []), (err) => void Axios.error(err));
    };
    const getApplication = (withLoading = true) => {
        withLoading && setLoading(true);
        return Axios.get(`/admin/applications/${applicationId}`)
            .then(({ data }) => {
            setApplication(data);
            return data;
        }, (err) => void Axios.error(err))
            .finally(() => withLoading && setLoading(false));
    };
    const getPendingItems = () => {
        return Axios.get(`admin/applications/${applicationId}/next_status_validate`).then(({ data }) => {
            if (!applicationId)
                return;
            setPendingItems(generatePendingItems({
                data,
                t,
                applicationId,
                navigate,
                setActiveMenu,
                uploadRaisingAvailable,
                setShareApplicationModalOpen,
                getPendingItems,
                showRaisinUploadModal,
            }));
        });
    };
    const showRaisinUploadModal = () => {
        void modal.confirm({
            title: t("application.raising-upload.modal.title"),
            icon: _jsx(ExclamationCircleOutlined, { style: { color: "#ff4d4f" } }),
            content: t("application.raising-upload.modal.content"),
            okText: t("application.raising-upload.modal.okText"),
            okType: "danger",
            okButtonProps: { style: uploadButtonStyles },
            className: "custom-confirm",
            cancelText: t("common.cancel"),
            onOk: () => handleSFTPDocumentUpload(),
        });
    };
    const handleSFTPDocumentUpload = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield Axios.post(`/admin/applications/${applicationId}/crm/raisin/documents_upload`);
            yield getPendingItems();
        }
        catch (err) {
            void Axios.error(err);
        }
    });
    const showPayoutChecklist = useMemo(() => {
        var _a;
        return !!((_a = application === null || application === void 0 ? void 0 : application.checklists) === null || _a === void 0 ? void 0 : _a.find(({ checklist_type }) => checklist_type === ChecklistType.Payout));
    }, [application === null || application === void 0 ? void 0 : application.checklists]);
    const showFinalReviewChecklist = useMemo(() => {
        var _a;
        return !!((_a = application === null || application === void 0 ? void 0 : application.checklists) === null || _a === void 0 ? void 0 : _a.find(({ checklist_type }) => checklist_type === ChecklistType.FourEyeCheck));
    }, [application === null || application === void 0 ? void 0 : application.checklists]);
    const showChecklists = showPayoutChecklist || showFinalReviewChecklist;
    const sideMenuItems = useMemo(() => {
        const collection = [
            {
                icon: _jsx(InfoCircleOutlined, {}),
                key: SideActions.applicationInformation,
            },
            {
                icon: (_jsx("div", Object.assign({ "data-cy": "pending-items-and-tasks-btn" }, { children: _jsx(Badge, Object.assign({ count: pendingItemsAndTasksBadgeCount, size: "small", offset: [-4, 24] }, { children: _jsx(FileProtectOutlined, {}) })) }))),
                key: SideActions.tasksAndPendingItems,
            },
            {
                icon: _jsx(CommentOutlined, {}),
                key: SideActions.sharedNotes,
            },
            {
                icon: _jsx(HistoryOutlined, {}),
                key: SideActions.activityLog,
            },
            {
                icon: _jsx(FileSearchOutlined, {}),
                key: SideActions.analystReport,
                condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.InternalReview,
            },
            {
                type: "divider",
                condition: (application === null || application === void 0 ? void 0 : application.status) &&
                    [ApplicationStatuses.FinalReview, ApplicationStatuses.AwaitingPayout].includes(application.status),
            },
            ...(showFinalReviewChecklist
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ dot: true, offset: [2, 12] }, { children: _jsx(UnorderedListOutlined, {}) }))),
                        key: SideActions.finalReviewChecklist,
                        condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.FinalReview,
                    },
                ]
                : []),
            ...(showPayoutChecklist
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ dot: true, offset: [2, 12] }, { children: _jsx(UnorderedListOutlined, {}) }))),
                        key: SideActions.payoutChecklist,
                        condition: (application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.AwaitingPayout,
                    },
                ]
                : []),
        ];
        return collection.reduce((acc, _a) => {
            var { condition } = _a, restItem = __rest(_a, ["condition"]);
            if (typeof condition === "undefined" || condition) {
                acc.push(restItem);
            }
            return acc;
        }, []);
    }, [
        application === null || application === void 0 ? void 0 : application.status,
        showPayoutChecklist,
        showFinalReviewChecklist,
        pendingItemsAndTasksBadgeCount,
    ]);
    const handleSiderClick = (key) => {
        if (!activeMenu) {
            return setActiveMenu(key);
        }
        if (key === SideActions.tasksAndPendingItems &&
            (activeMenu === SideActions.createTask || activeMenu === SideActions.taskDetails)) {
            return setActiveMenu(undefined);
        }
        setActiveMenu(key === activeMenu ? undefined : key);
    };
    useEffect(() => {
        setLoading(true);
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            const application = yield getApplication(false);
            if (!application)
                return Promise.reject();
            yield Promise.all([
                getProductConfig(application),
                getEditableFields(),
                getPendingItems(),
                getTasks(),
                getApplicationLogs(),
            ]);
        });
        fetchData().finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);
    const extraMenuItems = [
        {
            label: t("application.share.share"),
            key: "share-application",
            disabled: loading,
            onClick: () => setShareApplicationModalOpen(true),
        },
        ...(canCloneApplication
            ? [
                {
                    label: t("application.clone-application"),
                    key: "clone-application",
                    disabled: loading,
                    onClick: () => setCloneApplicationModalOpen(true),
                },
            ]
            : []),
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: application && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                            navigate(lastMainRoute || Routes.Applications);
                                        } }), _jsx(Typography.Title, Object.assign({ level: 5, style: { marginBottom: 0 } }, { children: application.companyName })), _jsxs(Row, { children: [application.allBanksLoanPurpose === AllBanksLoanPurposes.Factoring ? (_jsx(Tag, { children: ((_a = application.factoring) === null || _a === void 0 ? void 0 : _a.annual_sales_previous_year)
                                                    ? currency(application.factoring.annual_sales_previous_year, {
                                                        currency: application.currency,
                                                    })
                                                    : "" })) : (_jsx(Tag, { children: application.loanSize
                                                    ? currency(application.loanSize, { currency: application.currency })
                                                    : "" })), application.partner_type && application.partner_type !== PartnerType.None && (_jsx(Tag, Object.assign({ color: "gold" }, { children: t(`partner.type.${application.partner_type}`) }))), _jsx(ApplicationStatus, { label: t(`application.status.${application.status}`), value: application.status })] })] })) })), extra: application && (_jsxs(Space, { children: [_jsx(MoveApplication, { applicationId: application.applicationId, partnerId: application.partner_id, possibleStates: application.possible_states, statusFlow: application.status_flow, update: () => {
                                        void getApplication();
                                        void getPendingItems();
                                        void getApplicationLogs();
                                        void getEditableFields();
                                    } }), _jsx(Dropdown, Object.assign({ menu: { items: extraMenuItems } }, { children: _jsx(Button, Object.assign({ loading: loading }, { children: _jsx(MoreOutlined, {}) })) }))] })) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [application && (_jsx(ApplicationMenu, { applicationId: application.applicationId, applicationStatus: application.status, statusFlow: application.status_flow, allBanksLoanPurposes: application.allBanksLoanPurpose, showChecklists: showChecklists, productConfig: productConfig })), _jsxs(ErrorBoundaryWrapper, { children: [_jsx(Content, Object.assign({ className: "application-layout-content" }, { children: _jsx(LayoutContext.Provider, Object.assign({ value: {
                                                activeMenu,
                                                setActiveMenu,
                                                openShareApplicationModal: () => setShareApplicationModalOpen(true),
                                            } }, { children: application ? (_jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(ApplicationOutlet, { application: application, pendingItems: pendingItems, tasks: tasks, updateTasks: getTasks, productConfig: productConfig, updateProductConfig: updateProductConfig, updateApplication: updateApplication, updatePendingItems: () => void getPendingItems(), showApproveModal: showRaisinUploadModal, logs: logs, editableFields: editableFields }) }))) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })) })), _jsx(Sider, Object.assign({ theme: "light", width: 54, style: { borderLeft: "solid 1px #F0F0F0" }, onCollapse: () => {
                                            setActiveMenu(undefined);
                                        } }, { children: _jsx(Menu, { inlineCollapsed: true, theme: "light", mode: "inline", onClick: ({ key }) => handleSiderClick(key), items: sideMenuItems, selectedKeys: activeMenu ? [activeMenu] : [] }) }))] })] }))] })), cloneApplicationModalOpen && application && (_jsx(CloneApplication, { application: application, onClose: () => setCloneApplicationModalOpen(false) })), shareApplicationModalOpen && application && (_jsx(ShareApplication, { applicationId: application.applicationId, close: () => setShareApplicationModalOpen(false) }))] }));
};
export default ApplicationLayout;
