var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CommentOutlined, FileProtectOutlined, LeftOutlined, PlusOutlined, } from "@ant-design/icons";
import { Badge, Button, Layout, Menu, Spin } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import { FeatureName, TaskRelatedEntityType, } from "@teylor-tools/Api";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import ErrorBoundaryWrapper from "../ErrorBoundaryWrapper";
import { LayoutContext, SideActions } from "./CompanyTypes";
import CompanyMenu from "./modules/CompanyMenu";
import CompanyOutlet from "./modules/CompanyOutlet";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const CompanyLayout = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState();
    const [tasks, setTasks] = useState([]);
    const [activeMenu, setActiveMenu] = useState();
    const { lastMainRoute } = useLastMainRoute();
    const { t } = useTranslation();
    const { features, tasksEnabled } = useSelector((state) => state.configState);
    const boCreateAppEnabled = useMemo(() => {
        var _a;
        return ((_a = features.find((feature) => feature.name === FeatureName.BoUserCreateApplication)) === null || _a === void 0 ? void 0 : _a.is_active) ||
            false;
    }, [features]);
    const updateCompany = (params) => {
        if (params) {
            return Axios.patch(`/admin/companies/${companyId}`, params).then(() => {
                void getCompany();
            }, (err) => {
                void Axios.error(err);
            });
        }
        else {
            return getCompany();
        }
    };
    const getCompany = useCallback(() => {
        return Axios.get(`/admin/companies/${companyId}`).then(({ data }) => {
            setCompany(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, [companyId]);
    const getTasks = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!tasksEnabled)
            return;
        return Axios.get("admin/tasks", {
            related_entity_id: companyId,
            related_entity_type: TaskRelatedEntityType.Company,
        }).then(({ data }) => setTasks(data.result || []), (err) => void Axios.error(err));
    }), [companyId, tasksEnabled]);
    useEffect(() => {
        setLoading(true);
        getCompany().finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCompany]);
    useEffect(() => {
        if (!tasksEnabled)
            return;
        setLoading(true);
        getTasks().finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTasks]);
    const sideMenuItems = useMemo(() => {
        const collection = [
            ...(tasksEnabled
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ count: tasks.length, size: "small", offset: [-4, 24] }, { children: _jsx(FileProtectOutlined, {}) }))),
                        key: SideActions.tasks,
                    },
                ]
                : []),
            {
                icon: _jsx(CommentOutlined, {}),
                key: SideActions.sharedNotes,
            },
        ];
        return collection.map(({ icon, key }) => {
            return {
                icon,
                key,
            };
        });
    }, [tasks, tasksEnabled]);
    const handleSiderClick = (key) => {
        if (!activeMenu) {
            return setActiveMenu(key);
        }
        if (key === SideActions.tasks &&
            (activeMenu === SideActions.createTask || activeMenu === SideActions.taskDetails)) {
            return setActiveMenu(undefined);
        }
        setActiveMenu(key === activeMenu ? undefined : key);
    };
    return (_jsx(_Fragment, { children: _jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: company && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                        navigate(lastMainRoute || Routes.Companies);
                                    } }), company.companyName] })) })), extra: boCreateAppEnabled && (_jsx(Button, Object.assign({ onClick: () => navigate(generatePath(`${Routes.CreateApplication}?comp_id=${companyId}`)), icon: _jsx(PlusOutlined, {}) }, { children: t("company.preview.new-application") }))) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [company && _jsx(CompanyMenu, { companyId: company.companyId }), _jsxs(ErrorBoundaryWrapper, { children: [_jsx(Content, { children: _jsx(LayoutContext.Provider, Object.assign({ value: {
                                            activeMenu,
                                            setActiveMenu,
                                        } }, { children: company ? (_jsx(CompanyOutlet, { company: company, updateCompany: updateCompany, tasks: tasks, updateTasks: getTasks, activeMenu: activeMenu, setActiveMenu: setActiveMenu })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })) }), _jsx(Sider, Object.assign({ theme: "light", width: 54, style: { borderLeft: "solid 1px #F0F0F0" }, onCollapse: () => {
                                        setActiveMenu(undefined);
                                    } }, { children: _jsx(Menu, { inlineCollapsed: true, theme: "light", mode: "inline", onClick: ({ key }) => handleSiderClick(key), items: sideMenuItems, selectedKeys: activeMenu ? [activeMenu] : [] }) }))] })] }))] })) }));
};
export default CompanyLayout;
