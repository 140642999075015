var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { App, Form, Input, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import { ApplicationStatuses, DocumentCategory, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardItem } from "@ui/data-display/DataCard";
import IbanFormItem from "@ui/form/form-items/iban-form-item/IbanFormItem";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { Routes } from "../../../../Routes";
import CompanyIdWithLink from "../../../../components/CompanyIdWithLink";
import CreditScoreReport from "../../../../components/CreditScoreReport";
import { DataCard, DataCardDivider, DataCardItemEditable } from "../../../../components/DataCard";
import { useApplication } from "../../../../layouts/ApplicationLayout/application.types";
import { Axios } from "../../../../utils/Axios";
import { getCompanyAddress } from "../../../../utils/get-company-address";
import { getCompanyAge } from "../../../../utils/getCompanyAge";
import CompanyApplicationsCard from "./CompanyApplicationsCard";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const ApplicationCompany = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState();
    const { application, updateApplication, updatePendingItems } = useApplication();
    const isPaidOut = application.status === ApplicationStatuses.PaidOut;
    const [creditScores, setCreditScores] = useState([]);
    const [signatoriesTins, setSignatoriesTins] = useState([]);
    const { documentCategories } = useSelector((state) => state.configState);
    const { decimalToPercent } = useFormatter();
    const getCompany = useCallback(() => {
        setLoading(true);
        Axios.get(`/admin/companies/${application.companyId}`)
            .then(({ data }) => {
            setCompany(data);
        }, (err) => void Axios.error(err))
            .finally(() => {
            setLoading(false);
        });
    }, [application.companyId]);
    const fullReportPath = useMemo(() => {
        var _a, _b;
        const types = (_b = (_a = documentCategories
            .find((cat) => cat.name === DocumentCategory.ExternalReports)) === null || _a === void 0 ? void 0 : _a.document_types) === null || _b === void 0 ? void 0 : _b.map((type) => type.name);
        return `${generatePath(Routes.ApplicationDocuments, {
            applicationId: application.applicationId,
        })}?${new URLSearchParams({
            document_type: (types === null || types === void 0 ? void 0 : types.length) ? types.join(",") : "",
        }).toString()}`;
    }, [documentCategories, application.applicationId]);
    const updateApplicationData = (values) => {
        Axios.patch(`/admin/companies/${application.companyId}`, values)
            .then(() => {
            void message.success(t("common.saved"));
            void updateApplication();
            void getCompany();
        }, (err) => void Axios.error(err))
            .finally(updatePendingItems);
    };
    const updateBankName = (values) => {
        Axios.patch(`/admin/applications/${application.applicationId}/bankinfo`, values)
            .then(() => {
            void message.success(t("common.saved"));
            void updateApplication();
        }, (err) => void Axios.error(err))
            .finally(updatePendingItems);
    };
    const onHandleManualUpdate = ({ company_industry_code_set_id, approve, }) => {
        if (approve) {
            Axios.patch(`/admin/companies/${application.companyId}/industry_codes_set/${company_industry_code_set_id}/approve`)
                .then(() => {
                void message.success(t("common.saved"));
                void getCompany();
            }, (err) => void Axios.error(err))
                .finally(updatePendingItems);
        }
        else {
            Axios.patch(`/admin/companies/${application.companyId}/industry_codes_set/${company_industry_code_set_id}/reject`)
                .then(() => {
                void message.success(t("common.saved"));
                void getCompany();
            }, (err) => void Axios.error(err))
                .finally(updatePendingItems);
        }
    };
    const getCreditScores = useCallback(() => {
        Axios.get(`admin/companies/${application.companyId}/credit_score`).then(({ data }) => setCreditScores(data), (err) => {
            void Axios.error(err, t("application.company.error.error-fetching-report"));
            setCreditScores([]);
        });
    }, [application.companyId, t]);
    const getSignatoriesTins = useCallback(() => {
        Axios.get(`admin/applications/${application.applicationId}/signatories`).then(({ data }) => setSignatoriesTins(data.signatories.map((signatory) => signatory.taxid)), (err) => {
            void Axios.error(err);
            setSignatoriesTins([]);
        });
    }, [application.applicationId]);
    const updateIndustryCodes = (values) => {
        return Axios.patch(`/admin/companies/${application.companyId}/industry_codes`, values).then(() => {
            void getCompany();
            updatePendingItems();
        }, (err) => void Axios.error(err));
    };
    const getPdScore = () => {
        var _a;
        const pd = (_a = creditScores[0]) === null || _a === void 0 ? void 0 : _a.pd;
        return isValidNumericValue(pd)
            ? decimalToPercent({ percent: pd, showPercentSymbol: true })
            : "-";
    };
    useEffect(() => {
        getCompany();
        getCreditScores();
        getSignatoriesTins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("application.menu.company") }), _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsxs(Wrapper, { children: [_jsxs("div", { children: [_jsxs(DataCard, Object.assign({ title: t("application.company.company-details") }, { children: [_jsx(DataCardItem, { label: t("application.company.name"), value: (company === null || company === void 0 ? void 0 : company.companyName) && _jsx("strong", { children: company.companyName }) }), isPaidOut ? (_jsx(DataCardItem, { label: t("application.company.purpose"), value: company === null || company === void 0 ? void 0 : company.businessPurpose })) : (_jsx(DataCardItemEditable, { label: t("application.company.purpose"), displayedValue: company === null || company === void 0 ? void 0 : company.businessPurpose, formItemValue: company === null || company === void 0 ? void 0 : company.businessPurpose, onChange: (value) => updateApplicationData({ businessPurpose: value }), customFormItem: (_a) => {
                                                var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                                return (_jsx(Form.Item, Object.assign({}, props, { children: _jsx(TextArea, Object.assign({ rows: 6 }, inputProps)) })));
                                            }, e2eId: "business-purpose" })), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.address"), value: company && getCompanyAddress(company, t) }), _jsx(DataCardItem, { label: t("application.company.country"), value: (company === null || company === void 0 ? void 0 : company.address_country) && t(`common.countries.${company.address_country}`) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.hr-number"), value: company === null || company === void 0 ? void 0 : company.hrNumber }), isPaidOut ? (_jsx(DataCardItem, { label: t("application.company.tin"), value: company === null || company === void 0 ? void 0 : company.taxIdNumber })) : (_jsx(DataCardItemEditable, { label: t("application.company.tin"), displayedValue: company === null || company === void 0 ? void 0 : company.taxIdNumber, onChange: (value) => updateApplicationData({ taxIdNumber: parseTin(value) }), customFormItem: (props) => (_jsx(TinFormItem, Object.assign({}, props, { rules: [
                                                    {
                                                        validator: (_, value) => validateValueDifferentThan({
                                                            value: parseTin(value),
                                                            differentThan: signatoriesTins,
                                                            error: t("application_form.errors.companyTinMustBeDifferentThanPeronalTin"),
                                                        }),
                                                    },
                                                ] }))), formItemValue: company === null || company === void 0 ? void 0 : company.taxIdNumber, e2eId: "tin" })), _jsx(DataCardItem, { label: t("common.short-company-id"), value: (company === null || company === void 0 ? void 0 : company.company_id_short) && (_jsx(Typography.Text, Object.assign({ copyable: true }, { children: company.company_id_short }))) }), _jsx(DataCardItem, { label: t("common.company-id"), value: (company === null || company === void 0 ? void 0 : company.companyId) && _jsx(CompanyIdWithLink, { companyId: company.companyId }) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("application.company.legal-form"), value: company === null || company === void 0 ? void 0 : company.companyType }), _jsx(DataCardItem, { label: t("application.company.founded"), value: (company === null || company === void 0 ? void 0 : company.companyFoundationDate) &&
                                                new Date(company.companyFoundationDate).getFullYear() }), _jsx(DataCardItem, { label: t("application.company.age"), value: (company === null || company === void 0 ? void 0 : company.companyFoundationDate) && getCompanyAge(company.companyFoundationDate, t) }), isPaidOut ? (_jsx(DataCardItem, { label: t("application.company.employees"), value: company === null || company === void 0 ? void 0 : company.numberOfEmployees })) : (_jsx(DataCardItemEditable, { label: t("application.company.employees"), displayedValue: company === null || company === void 0 ? void 0 : company.numberOfEmployees, onChange: (value) => updateApplicationData({ employees_count: Number(value) }), formItemValue: (company === null || company === void 0 ? void 0 : company.numberOfEmployees) || "", customFormItem: (_a) => {
                                                var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                                                return (_jsx(Form.Item, Object.assign({}, props, { rules: [
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value || /^[0-9]+$/.test(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(t("application_form.errors.fieldContainsInvalidCharacters"));
                                                            },
                                                        },
                                                    ], validateFirst: true }, { children: _jsx(Input, Object.assign({ autoFocus: true }, inputProps)) })));
                                            }, e2eId: "edit-employees" }))] })), _jsx(CompanyApplicationsCard, { companyId: application.companyId })] }), _jsxs("div", { children: [_jsx(IndustryCodesCard, { industryCodes: company === null || company === void 0 ? void 0 : company.industry_codes, loading: loading, showEditButton: !isPaidOut, onSave: updateIndustryCodes, onHandleManualUpdate: onHandleManualUpdate, withManualApprovalWarning: true }), _jsxs(DataCard, Object.assign({ title: t("application.company.credit-score") }, { children: [_jsx(CreditScoreReport, { getCreditScore: getCreditScores, creditScores: creditScores, requestCreditCheck: application.status !== ApplicationStatuses.PaidOut
                                                ? (credit_type) => Axios.patch(`admin/applications/${application.applicationId}/credit_check`, {
                                                    credit_type,
                                                }).then(({ data }) => Promise.resolve(data), (err) => Promise.reject(err))
                                                : undefined, documentsPath: fullReportPath }), _jsx(DataCardItem, { label: t("company.pd-rate"), value: getPdScore() })] })), _jsx(DataCard, Object.assign({ title: t("application.company.bank-details") }, { children: isPaidOut ? (_jsxs(_Fragment, { children: [_jsx(DataCardItem, { label: t("application.company.bank"), value: application.bankName }), _jsx(DataCardItem, { label: t("application.company.iban"), value: application.iban })] })) : (_jsxs(_Fragment, { children: [_jsx(DataCardItemEditable, { label: t("application.company.bank"), displayedValue: application.bankName, formItemValue: application.bankName, onChange: (v) => updateBankName({ bankName: v }), e2eId: "bank-name" }), _jsx(DataCardItemEditable, { label: t("application.company.iban"), onChange: (v) => updateBankName({ iban: v }), displayedValue: application.iban, customFormItem: (props) => _jsx(IbanFormItem, Object.assign({}, props)), formItemValue: application.iban, e2eId: "iban" })] })) }))] })] }) }))] }));
};
export default ApplicationCompany;
