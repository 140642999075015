import { FactoringInfoDebitor } from "../Api";

const MAX_DEBITOR_LENGTH = 5;

export const fillDebitors = (debitors?: FactoringInfoDebitor[]) => {
	if (!debitors) {
		return Array(MAX_DEBITOR_LENGTH).fill({
			company_name: null,
			company_country: null,
			annual_sales_previous_year: null,
		}) as FactoringInfoDebitor[];
	}

	if (debitors.length === MAX_DEBITOR_LENGTH) return debitors;

	const additionalDebitors = Array(MAX_DEBITOR_LENGTH - debitors.length).fill({
		company_name: null,
		company_country: null,
		annual_sales_previous_year: null,
	});

	return [...debitors, ...additionalDebitors] as FactoringInfoDebitor[];
};
