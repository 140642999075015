import { FC } from "react";

import { Col, Form, Input, InputNumber, Row, Select, Typography } from "antd";

import {
	Currency,
	FactoringInfoDebitor,
	FactoringInfoDebitors,
	FactoringInfoDebitorsReq,
} from "@teylor-tools/Api";
import { fillDebitors } from "@teylor-tools/utils/fillDebitors";
import { CountriesList } from "@teylor-tools/utils/getCountries";

import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import { FactoryDetailsType } from "../information-card/types";
import { useTranslations } from "../translations/translations";
import HeadText from "./FormComponents";

const { Text } = Typography;

interface DebitorFormProps {
	details: FactoringInfoDebitors;
	formId: FactoryDetailsType;
	handleUpdateFactoringData: (
		type: FactoryDetailsType,
		values: FactoringInfoDebitorsReq
	) => Promise<void>;
	hasInternationalCustomers: boolean;
	defaultCurrency?: Currency;
	isRequired?: boolean;
	countries: CountriesList[];
}

export const DebitorForm: FC<DebitorFormProps> = ({
	details,
	formId,
	handleUpdateFactoringData,
	hasInternationalCustomers,
	defaultCurrency,
	isRequired = true,
	countries,
}) => {
	const t = useTranslations();
	const [form] = Form.useForm<FactoringInfoDebitors>();
	const handleUpdate = () => {
		void handleUpdateFactoringData(formId, form.getFieldsValue() as FactoringInfoDebitorsReq);
	};

	const debtorEntries: FactoringInfoDebitor[] = fillDebitors(details.debitors);

	const createDebtorFields = (index: number) => {
		return (
			<>
				<Col span={10}>
					<Form.Item
						label={index === 0 ? t.factoring.forms.debitors.company_name : ""}
						required={isRequired && index === 0}
						name={["debitors", index, "company_name"]}
						rules={
							isRequired
								? [
										{
											required: index < 3,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
						style={{ width: "100%" }}
					>
						<Input style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={index === 0 ? t.factoring.forms.debitors.country : ""}
						required={isRequired && index === 0}
						name={["debitors", index, "company_country"]}
						rules={
							isRequired
								? [
										{
											required: index < 3,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
						style={{ width: "100%" }}
					>
						<Select
							showSearch
							allowClear
							filterOption={(inputValue, options) =>
								String(options?.children).toLowerCase().includes(inputValue.toLowerCase())
							}
						>
							{countries.map(({ code, countryLabel }) => (
								<Select.Option key={code} value={code} data-cy={`country-list-${formId}-option`}>
									{countryLabel}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label={index === 0 ? t.factoring.forms.debitors.annual_sales_previous_year : ""}
						required={isRequired && index === 0}
						name={["debitors", index, "annual_sales_previous_year"]}
						rules={
							isRequired
								? [
										{
											required: index < 3,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
						style={{ width: "100%" }}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
			</>
		);
	};

	return (
		<Form
			id={formId}
			form={form}
			layout="vertical"
			onFinish={handleUpdate}
			initialValues={{
				...details,
				debitors: debtorEntries,
			}}
		>
			<Row gutter={14}>
				<Col span={24}>
					<HeadText $isRequired={isRequired}>
						{t.factoring.forms.debitors.number_of_debitors}
					</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="number_of_debitors"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<InputNumber style={{ width: "100%" }} controls={false} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="number_of_debitors_abroad"
							label={t.factoring.common.abroad_text}
							required={isRequired}
							rules={
								isRequired
									? [
											{
												required: true,
												message: t.formErrors.fieldRequired,
											},
										]
									: []
							}
						>
							<InputNumber style={{ width: "100%" }} controls={false} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Form.Item label={t.factoring.forms.debitors.debitors}>
				{debtorEntries.map((_, index) => (
					<div
						style={{ display: "flex", alignItems: index === 0 ? "center" : "baseline" }}
						key={index}
					>
						<Text style={{ width: "20px", textAlign: "right" }}>
							{isRequired && index < 3 && <span style={{ color: "red" }}>*</span>}
							{`${index + 1}.`}
						</Text>
						<Row gutter={16} style={{ width: "100%" }}>
							{createDebtorFields(index)}
						</Row>
					</div>
				))}
			</Form.Item>
		</Form>
	);
};
