import { FC } from "react";
import ReactMarkdown from "react-markdown";

import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { MarkdownComponents } from "../components/MarkdownComponents";

interface MarkdownViewerProps {
	content?: string;
}

export const MarkdownViewer: FC<MarkdownViewerProps> = ({ content }) => {
	return (
		<ReactMarkdown
			rehypePlugins={[rehypeRaw]}
			remarkPlugins={[remarkGfm]}
			components={MarkdownComponents}
		>
			{content}
		</ReactMarkdown>
	);
};
