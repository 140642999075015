import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalculatorOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Slider, Tooltip, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { CardRow } from "@ui/data-display/DataCard";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
const STEP = 0.05;
const FeeCalcSliderRow = ({ name, label, tooltipTxt, min, max, onChange, onCalculate, disabled, }) => {
    const form = Form.useFormInstance();
    const inputVal = Form.useWatch(name, form);
    const onSliderChange = (v) => {
        form.setFieldValue(name, v);
        onChange();
    };
    const handleValidation = (_, value) => value < min || value > max ? Promise.reject() : Promise.resolve();
    return (_jsxs(CardRow, Object.assign({ style: { alignItems: "center" }, gutter: 16, wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "200px" }, { children: _jsxs(Typography.Text, Object.assign({ type: "secondary" }, { children: [_jsx("span", Object.assign({ style: { display: "inline-block", marginRight: 4 } }, { children: label })), _jsx(Tooltip, Object.assign({ title: tooltipTxt }, { children: _jsx(InfoCircleOutlined, {}) }))] })) })), _jsx(Col, Object.assign({ flex: 1 }, { children: _jsxs(Row, Object.assign({ gutter: 16 }, { children: [_jsx(Col, Object.assign({ span: 15 }, { children: _jsx(Slider, { min: min, max: max, onChange: onSliderChange, value: inputVal || 0, step: STEP, tooltip: { open: false }, disabled: disabled }) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(FormItem, Object.assign({ name: name, rules: [{ validator: handleValidation }] }, { children: _jsx(PercentInput, { style: { width: "100%" }, step: STEP, prefix: "%", disabled: disabled, onChange: onChange, allowEmptyValue: true }) })) })), _jsx(Col, Object.assign({ span: 2, style: { textAlign: "right" } }, { children: _jsx(Button, { icon: _jsx(CalculatorOutlined, {}), onClick: onCalculate, type: "link", style: { width: "auto" }, disabled: disabled }) }))] })) }))] })));
};
export default FeeCalcSliderRow;
