var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, CheckCircleOutlined, EuroCircleOutlined, FileSearchOutlined, LinkOutlined, PercentageOutlined, ShopOutlined, SwapOutlined, TeamOutlined, WalletOutlined, } from "@ant-design/icons";
import { LoanStatus } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { LoanRoutes } from "src/Routes";
const LoanMenu = ({ loanId, loanStatus }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [openKeys, setOpenKeys] = useState();
    const showLoanPricing = loanStatus !== LoanStatus.Pending;
    const menuItemsWithId = useMemo(() => {
        const menuItems = [
            {
                icon: _jsx(AppstoreOutlined, {}),
                label: t("loans.menu.overview"),
                key: LoanRoutes.Loan,
            },
            {
                icon: _jsx(WalletOutlined, {}),
                label: t("loans.menu.payments"),
                key: LoanRoutes.LoanPayments,
            },
            {
                icon: _jsx(SwapOutlined, {}),
                label: t("loans.menu.transactions"),
                key: LoanRoutes.LoanTransactions,
            },
            { type: "divider" },
            {
                icon: _jsx(LinkOutlined, {}),
                label: t("loans.menu.documents"),
                key: LoanRoutes.LoanDocuments,
            },
            {
                icon: _jsx(ShopOutlined, {}),
                label: t("loans.menu.company-details"),
                key: LoanRoutes.LoanCompanyDetails,
            },
            {
                icon: _jsx(TeamOutlined, {}),
                label: t("loans.menu.representatives"),
                key: LoanRoutes.LoanRepresentatives,
            },
            { type: "divider" },
            {
                icon: _jsx(EuroCircleOutlined, {}),
                label: t("loans.menu.financials"),
                key: LoanRoutes.LoanFinancials,
            },
            ...(showLoanPricing
                ? [
                    {
                        icon: _jsx(PercentageOutlined, {}),
                        label: t("loans.menu.pricing"),
                        key: LoanRoutes.LoanPricing,
                    },
                ]
                : []),
            {
                icon: _jsx(FileSearchOutlined, {}),
                key: "Analysis",
                label: t("loans.menu.analysis"),
                children: [
                    {
                        label: t("loans.menu.quantitative"),
                        key: LoanRoutes.LoanQuantitative,
                    },
                    {
                        label: t("loans.menu.qualitative"),
                        key: LoanRoutes.LoanQualitative,
                    },
                ],
            },
            {
                icon: _jsx(CheckCircleOutlined, {}),
                key: "Checklists",
                label: t("application.menu.checklists"),
                children: [
                    {
                        label: t("application.menu.final-review"),
                        key: LoanRoutes.LoanApplicationFinalReviewChecklist,
                    },
                    {
                        label: t("application.menu.payout"),
                        key: LoanRoutes.LoanApplicationPayoutChecklist,
                    },
                ],
            },
        ];
        return menuItems
            .filter((item) => {
            if ("key" in item) {
                return typeof item.condition === "undefined" || item.condition;
            }
            else {
                return true;
            }
        })
            .map((item) => {
            if ("key" in item) {
                // element is a menu item
                return Object.assign(Object.assign({}, item), { children: item.children
                        ? item.children.map((_a) => {
                            var { key } = _a, other = __rest(_a, ["key"]);
                            return Object.assign({ key: generatePath(key, { loanId }) }, other);
                        })
                        : undefined, key: generatePath(item.key, { loanId }) });
            }
            else {
                return item;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanId, loanStatus]);
    useEffect(() => {
        setOpenKeys(menuItemsWithId.reduce((acc, item) => {
            var _a;
            if ("key" in item) {
                const menuItem = item;
                if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.some((child) => child.key === location.pathname)) {
                    acc.push(menuItem.key);
                }
            }
            return acc;
        }, []));
    }, [menuItemsWithId, location.pathname]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", onClick: (menuItem) => navigate(menuItem.key), items: menuItemsWithId, isCollapsible: true, collapseText: t("menu.collapse"), openKeys: openKeys, onOpenChange: setOpenKeys }));
};
export default LoanMenu;
