import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { BankOutlined, BorderlessTableOutlined, CalendarOutlined, ClockCircleOutlined, CloseOutlined, FlagOutlined, MailOutlined, ShoppingOutlined, UserOutlined, } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Typography } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import Preview, { Cell } from "@ui/preview/Preview";
import TimePassed from "@ui/time-passed/TimePassed";
import { Routes } from "src/Routes";
import RepresentativeList from "src/components/RepresentativeList";
import { Assigned } from "src/components/ui/Assigned";
import { Axios } from "src/utils/Axios";
import CompanyIdWithLink from "../../components/CompanyIdWithLink";
import ApplicationStatus from "../../components/ui/statuses/ApplicationStatus";
const { Text } = Typography;
const ApplicationPreview = ({ application, onClose }) => {
    const [signatoriesLoading, setSignatoriesLoading] = useState(true);
    const [signatories, setSignatories] = useState([]);
    const { currency } = useFormatter();
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        if (application === null || application === void 0 ? void 0 : application.application_id) {
            setSignatoriesLoading(true);
            Axios.get(`/admin/applications/${application === null || application === void 0 ? void 0 : application.application_id}/signatories`)
                .then(({ data }) => {
                setSignatories(data.signatories);
            }, (err) => Axios.error(err))
                .finally(() => {
                setSignatoriesLoading(false);
            });
        }
    }, [application === null || application === void 0 ? void 0 : application.application_id]);
    const lineData = useMemo(() => {
        if (!application)
            return [];
        return [
            ...(application.duration
                ? [
                    {
                        icon: _jsx(ClockCircleOutlined, { size: 12 }),
                        label: t("application.duration"),
                        value: `${application.duration}M`,
                    },
                ]
                : []),
            {
                icon: _jsx(ShoppingOutlined, {}),
                label: t("application.preview.product"),
                value: application.product.product_name,
            },
            {
                icon: _jsx(CalendarOutlined, {}),
                label: t("application.created"),
                value: application.created_at && _jsx(TimePassed, { date: application.created_at }),
            },
            {
                icon: _jsx(BorderlessTableOutlined, {}),
                label: t("common.id"),
                value: (_jsx(Text, Object.assign({ copyable: { tooltips: false }, ellipsis: { tooltip: application.application_id } }, { children: application.application_id }))),
            },
            {
                icon: _jsx(MailOutlined, {}),
                label: t("application.created_by"),
                value: application.created_by_email || "-",
            },
            {
                icon: _jsx(UserOutlined, {}),
                label: t("application.user-type"),
                value: application.created_by_type ? t(`common.${application.created_by_type}`) : "-",
            },
            {
                icon: _jsx(FlagOutlined, {}),
                label: t("application.partner"),
                value: application.partner_name || "-",
            },
            {
                icon: _jsx(BankOutlined, {}),
                label: t("common.company-id"),
                value: _jsx(CompanyIdWithLink, { companyId: application.company_id }),
            },
        ];
    }, [application, t]);
    return (_jsx(Preview, Object.assign({ visible: !!application, entityId: (application === null || application === void 0 ? void 0 : application.application_id) || "", header: application && (_jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsxs(Col, { children: [_jsx(ApplicationStatus, { label: t(`application.status.${application.status}`), value: application.status }), _jsx(Typography.Title, Object.assign({ level: 5, style: { marginTop: 8 } }, { children: application.company_name })), _jsx(Typography.Text, Object.assign({ strong: true, style: { fontSize: 16 } }, { children: application.amount
                                ? currency(application.amount, { currency: application.currency })
                                : "-" }))] }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: () => navigate(generatePath(Routes.Application, {
                                    applicationId: application.application_id,
                                })) }, { children: t("common.view") })), _jsx(Button, { onClick: onClose, icon: _jsx(CloseOutlined, {}) })] }) })] }))), lineData: lineData }, { children: _jsxs(_Fragment, { children: [_jsx(Cell, { children: application && (_jsx(_Fragment, { children: _jsxs(Row, Object.assign({ gutter: 16 }, { children: [_jsx(Assigned, { label: "application.preview.asignee", span: 12, name: application.status_owner_name }), _jsx(Assigned, { label: "application.preview.account-manager", span: 12, name: application.account_manager_name })] })) })) }), _jsx(Cell, { children: _jsx(Spin, Object.assign({ spinning: signatoriesLoading }, { children: _jsx(RepresentativeList, { representatives: signatories }) })) })] }) })));
};
export default ApplicationPreview;
