import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { App, Button, Form, Input, Select, Space, Tag, Typography } from "antd";
import { BoUserStatus } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Axios } from "../../../utils/Axios";
import { getUserStatusColor } from "../helpers";
const TeamUserProfile = ({ user }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [isLoadingInvitation, setIsLoadingInvitation] = useState(false);
    const [isLoadingRoles, setIsLoadingRoles] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const requiredRule = { required: true, message: t("settings.field_required") };
    useEffect(() => {
        setIsLoadingRoles(true);
        Axios.get(`admin/bo_users/roles`)
            .then(({ data }) => setRoleOptions(data.map((role) => ({ value: role.id || "", label: role.name || "" }))), (error) => void Axios.error(error))
            .finally(() => setIsLoadingRoles(false));
    }, [setIsLoadingRoles]);
    const handleResendInvite = () => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        setIsLoadingInvitation(true);
        void Axios.post(`/admin/bo_users/${user.id}/resend_invite`)
            .then(() => void message.success(t("settings.team.profile.invitation_sent_success_message")), (error) => void Axios.error(error))
            .finally(() => setIsLoadingInvitation(false));
    };
    return (_jsxs(_Fragment, { children: [user && (_jsxs(Space, Object.assign({ style: { marginBottom: 16 } }, { children: [_jsx(Typography.Text, { children: t("settings.team.profile.status") }), user.status && (_jsx(Tag, Object.assign({ color: getUserStatusColor(user.status), style: { margin: 0 } }, { children: t(`settings.team.overview.status.${user.status}`) }))), user.status === BoUserStatus.Invited && (_jsx(Button, Object.assign({ type: "link", onClick: handleResendInvite, loading: isLoadingInvitation }, { children: t("settings.team.profile.resend_invite") })))] }))), _jsx(Form.Item, Object.assign({ name: "name", label: t("settings.team.overview.name"), rules: [requiredRule] }, { children: _jsx(Input, {}) })), _jsx(EmailFormItem, { rules: [requiredRule] }), _jsx(Form.Item, Object.assign({ name: "roles", label: t("settings.team.overview.roles"), rules: [requiredRule] }, { children: _jsx(Select, { mode: "multiple", placeholder: t("settings.team.overview.roles"), style: { width: "100%" }, options: roleOptions, loading: isLoadingRoles }) }))] }));
};
export default TeamUserProfile;
