export class AuthState {
	private static instance: AuthState;
	private sessionExpiredShown = false;

	private constructor() {}

	public static getInstance(): AuthState {
		if (!AuthState.instance) {
			AuthState.instance = new AuthState();
		}
		return AuthState.instance;
	}

	public setSessionExpiredShown(value: boolean) {
		this.sessionExpiredShown = value;
	}

	public getSessionExpiredShown(): boolean {
		return this.sessionExpiredShown;
	}
}
