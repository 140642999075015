var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, Input, Select, Switch } from "antd";
const ChecklistDetailsTab = forwardRef(({ checklist }, ref) => {
    var _a;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { filterValues } = useSelector((state) => state.configState);
    const products = (filterValues === null || filterValues === void 0 ? void 0 : filterValues.products) || [];
    const checklistTypes = (filterValues === null || filterValues === void 0 ? void 0 : filterValues.checklist_types) || [];
    const requiredRule = {
        required: true,
        message: t("partner.modal.required-field"),
    };
    const initialValues = {
        checklist_name: checklist === null || checklist === void 0 ? void 0 : checklist.checklist_name,
        type: checklist === null || checklist === void 0 ? void 0 : checklist.type,
        product_id: (_a = checklist === null || checklist === void 0 ? void 0 : checklist.product) === null || _a === void 0 ? void 0 : _a.product_id,
        is_active: checklist === null || checklist === void 0 ? void 0 : checklist.is_active,
    };
    useImperativeHandle(ref, () => ({
        getDetails: () => __awaiter(void 0, void 0, void 0, function* () {
            yield form.validateFields();
            return form.getFieldsValue();
        }),
    }));
    return (_jsxs(Form, Object.assign({ form: form, layout: "vertical", style: { maxWidth: 460 }, initialValues: initialValues }, { children: [_jsx(Form.Item, Object.assign({ name: "checklist_name", label: t("settings.checklists.checklist_name"), rules: [requiredRule] }, { children: _jsx(Input, { maxLength: 255 }) })), _jsx(Form.Item, Object.assign({ name: "type", label: t("settings.checklists.type"), rules: [requiredRule] }, { children: _jsx(Select, { options: checklistTypes.map((type) => ({ label: type, value: type })) }) })), _jsx(Form.Item, Object.assign({ name: "product_id", label: t("settings.checklists.product"), rules: [requiredRule] }, { children: _jsx(Select, { options: products.map((product) => ({
                        label: product.product_name,
                        value: product.product_id,
                    })) }) })), _jsx(Form.Item, Object.assign({ name: "is_active", label: t("settings.checklists.is_active") }, { children: _jsx(Switch, {}) }))] })));
});
ChecklistDetailsTab.displayName = "ChecklistDetailsTab";
export default ChecklistDetailsTab;
