import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, List, Row, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterFullDateTime } from "@teylor-tools/utils/dateFormats";
import Avatar from "@ui/data-display/Avatar";
import { MarkdownEditor } from "@ui/markdown/editor/MarkdownEditor";
import { MarkdownViewer } from "@ui/markdown/viewer/MarkdownViewer";
import { Cell } from "@ui/preview/Preview";
const { Text } = Typography;
const NoteHeader = styled.div `
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 10px 0;
`;
const AddButton = styled(Button) `
	margin: 9px;
	max-width: max-content;
`;
const NoteForm = styled(Form) `
	padding: 24px;
`;
const createAbbr = (note) => {
    var _a, _b;
    // if there's a dot or a symbol in the email
    const variableToUse = ((_b = (_a = note === null || note === void 0 ? void 0 : note.creator) === null || _a === void 0 ? void 0 : _a.email) === null || _b === void 0 ? void 0 : _b.replace(/[^a-zA-Z0-9 ]/g, "")) || note.created_by;
    return `${variableToUse.charAt(0)} ${variableToUse.charAt(1)}`;
};
const Notes = ({ notes, handleAddNote, loading }) => {
    const [noteForm] = useForm();
    const [noteFormOpen, setNoteFormOpen] = useState(false);
    const { date } = useFormatter();
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [noteFormOpen ? (_jsxs(NoteForm, Object.assign({ form: noteForm }, { children: [_jsx(Form.Item, Object.assign({ name: "newNote" }, { children: _jsx(MarkdownEditor, { onChange: (value) => noteForm.setFieldsValue({ newNote: value }) }) })), _jsxs(Row, Object.assign({ justify: "end", gutter: 24 }, { children: [_jsx(Col, { children: _jsx(Button, Object.assign({ onClick: () => {
                                        setNoteFormOpen(false);
                                        noteForm.resetFields();
                                    } }, { children: t("common.cancel") })) }), _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", loading: loading, onClick: () => handleAddNote({ noteForm, newNote: noteForm.getFieldValue("newNote") }) }, { children: t("application.notes.add-note") })) })] }))] }))) : (_jsx(AddButton, Object.assign({ type: "text", icon: _jsx(PlusOutlined, {}), onClick: () => setNoteFormOpen(true) }, { children: t("application.notes.add-note") }))), _jsx(Cell, { children: _jsx(List, { loading: loading, dataSource: notes, itemLayout: "vertical", renderItem: (item) => {
                        var _a, _b;
                        return (_jsxs(_Fragment, { children: [_jsx(Avatar, { name: createAbbr(item), size: "small" }), _jsxs(NoteHeader, { children: [_jsx(Text, Object.assign({ strong: true }, { children: ((_a = item === null || item === void 0 ? void 0 : item.creator) === null || _a === void 0 ? void 0 : _a.name) || ((_b = item === null || item === void 0 ? void 0 : item.creator) === null || _b === void 0 ? void 0 : _b.email) || item.created_by })), _jsx(Text, Object.assign({ type: "secondary" }, { children: date(item.created_at, formatterFullDateTime) }))] }), _jsx(MarkdownViewer, { content: item.message }), _jsx(Divider, {})] }));
                    } }) })] }));
};
export default Notes;
