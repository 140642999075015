import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Col, Row, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { DOCUMENTS_CARD_MAX_WIDTH, DOCUMENTS_CARD_MIN_WIDTH, } from "@teylor-tools/utils/documents/documents.variables";
const { Text } = Typography;
const HEADER_MIN_WIDTH = DOCUMENTS_CARD_MIN_WIDTH + 48; // 48px padding
const Wrapper = styled.div `
	background-color: #fff;
	min-width: ${HEADER_MIN_WIDTH}px;
	padding: 12px 48px;

	.ant-col:not(:first-child) {
		text-align: center;
	}

	.ant-col:last-child {
		text-align: right;
	}
`;
const HeaderRow = styled(Row) `
	max-width: ${DOCUMENTS_CARD_MAX_WIDTH - 48 * 2}px;
`;
const DocumentsHeader = ({ allDocsIds, docIdsInSelection, setDocIdsInSelection, selectAllDisabled, }) => {
    const { t } = useTranslation();
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const onCheckAllChange = (e) => {
        if (e.target.checked) {
            setDocIdsInSelection(allDocsIds);
            setCheckAll(true);
        }
        else {
            setDocIdsInSelection([]);
            setCheckAll(false);
        }
        setIndeterminate(false);
    };
    useEffect(() => {
        if (docIdsInSelection.length === 0) {
            setIndeterminate(false);
            setCheckAll(false);
            return;
        }
        if (docIdsInSelection.length !== allDocsIds.length) {
            setIndeterminate(true);
            setCheckAll(false);
            return;
        }
        setIndeterminate(false);
        setCheckAll(true);
    }, [docIdsInSelection, allDocsIds.length]);
    return (_jsx(Wrapper, { children: _jsxs(HeaderRow, { children: [_jsx(Col, Object.assign({ span: 14 }, { children: _jsx(Tooltip, Object.assign({ title: selectAllDisabled && "Please wait for the upload process to finish" }, { children: _jsx(Checkbox, Object.assign({ indeterminate: indeterminate, onChange: onCheckAllChange, checked: checkAll, disabled: selectAllDisabled || !allDocsIds.length }, { children: _jsx(Text, Object.assign({ style: { marginLeft: 24 } }, { children: t("application.documents.name") })) })) })) })), _jsx(Col, Object.assign({ span: 4 }, { children: _jsx(Text, { children: t("application.documents.created") }) })), _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Text, { children: t("application.documents.actions") }) }))] }) }));
};
export default DocumentsHeader;
