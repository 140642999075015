import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ArrowsAltOutlined, DeleteOutlined, HolderOutlined, ShrinkOutlined, } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Flex, Form, Input, Tooltip, Typography } from "antd";
const ChecklistGroup = ({ children, remove, id, items, collapsed, onCollapse, onExpand, }) => {
    const { t } = useTranslation();
    const { attributes, isDragging, listeners, transition, setNodeRef, transform } = useSortable({
        id,
        data: {
            type: "group",
            children: items,
        },
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 99 : undefined,
    };
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    return (_jsxs(Card, Object.assign({ ref: setNodeRef, style: Object.assign(Object.assign({}, style), { backgroundColor: "rgba(0, 0, 0, 0.03)", margin: "16px 0" }) }, attributes, { children: [_jsxs(Flex, Object.assign({ justify: "space-between", style: { marginBottom: 16 } }, { children: [_jsx(Form.Item, Object.assign({ name: [id, "desc"], label: _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("settings.checklists.config.item.group-name") })), style: { margin: 0, width: "100%" }, rules: [requiredRule] }, { children: _jsx(Input, {}) })), _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [collapsed ? (_jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.expand-group") }, { children: _jsx(Button, { type: "link", onClick: () => onExpand(id), icon: _jsx(ArrowsAltOutlined, {}) }) }))) : (_jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.collapse-group") }, { children: _jsx(Button, { type: "link", onClick: () => onCollapse(id), icon: _jsx(ShrinkOutlined, {}) }) }))), _jsx(Tooltip, Object.assign({ title: t("settings.checklists.config.item.remove-group") }, { children: _jsx(Button, { type: "link", onClick: remove, icon: _jsx(DeleteOutlined, {}) }) })), _jsx(Button, Object.assign({ type: "link", icon: _jsx(HolderOutlined, {}), style: { color: "#787878" } }, listeners))] }))] })), !isDragging && !collapsed && children] })));
};
export default ChecklistGroup;
