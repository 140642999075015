import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { App, Menu, Modal } from "antd";
import { PartnerUserStatus } from "@teylor-tools/Api";
import { Axios } from "src/utils/Axios";
import PartnerUserModal from "./PartnerUserModal";
const UsersMenu = ({ user, getUsers }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const handleDeleteUser = () => {
        setLoading(true);
        void Axios.delete(`/admin/pusers/${user.entity_id}`)
            .then(() => {
            void message.success(t("partners.team.user_was_deleted"));
            getUsers();
            setShowDeleteModal(false);
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setLoading(false));
    };
    const handleUpdateUser = (data) => {
        setLoadingEdit(true);
        void Axios.patch(`/admin/pusers/${user.entity_id}`, data)
            .then(() => {
            void message.success(t("partners.team.user_was_updated"));
            getUsers();
            setShowEditModal(false);
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => setLoadingEdit(false));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Menu, { onClick: ({ key }) => {
                    if (user.entity_id) {
                        switch (key) {
                            case "edit":
                                setShowEditModal(true);
                                break;
                            case "resend-invite":
                                Axios.post(`/admin/pusers/${user.entity_id}/resend_invite`).then(() => {
                                    void message.success(t("partners.actions.user_invited"));
                                    getUsers();
                                }, (error) => {
                                    void Axios.error(error);
                                });
                                break;
                            case "disable":
                                Axios.post(`/admin/pusers/${user.entity_id}/disable`).then(() => {
                                    void message.success(t("partners.actions.user_disable_success"));
                                    getUsers();
                                }, (error) => {
                                    void Axios.error(error);
                                });
                                break;
                            case "enable":
                                Axios.post(`/admin/pusers/${user.entity_id}/enable`).then(() => {
                                    void message.success(t("partners.actions.user_enable_success"));
                                    getUsers();
                                }, (error) => {
                                    void Axios.error(error);
                                });
                                break;
                            case "delete":
                                setShowDeleteModal(true);
                                break;
                        }
                    }
                }, items: [
                    {
                        label: t("partners.actions.edit"),
                        key: "edit",
                    },
                    ...(user.status === PartnerUserStatus.Invited
                        ? [
                            {
                                label: t("partners.actions.resend-invite"),
                                key: "resend-invite",
                            },
                        ]
                        : []),
                    { type: "divider" },
                    ...(user.status === PartnerUserStatus.Active
                        ? [
                            {
                                label: t("partners.actions.disable"),
                                key: "disable",
                            },
                        ]
                        : []),
                    ...(user.status === PartnerUserStatus.Disabled
                        ? [
                            {
                                label: t("partners.actions.enable"),
                                key: "enable",
                            },
                        ]
                        : []),
                    {
                        label: t("partners.actions.delete"),
                        key: "delete",
                        danger: true,
                    },
                ] }), _jsx(Modal, Object.assign({ title: t("partners.team.delete_user_modal_title"), open: showDeleteModal, okButtonProps: { danger: true, loading }, okText: t("common.delete"), onOk: handleDeleteUser, onCancel: () => setShowDeleteModal(false) }, { children: _jsx("p", { children: t("partners.team.delete_user_modal_message") }) })), showEditModal && (_jsx(PartnerUserModal, { user: user, loading: loadingEdit, onFinish: handleUpdateUser, onCancel: () => setShowEditModal(false) }))] }));
};
export default UsersMenu;
