import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CalculatorOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Slider, Tooltip, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { CardRow } from "@ui/data-display/DataCard";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
const STEP = 0.05;
export const nominalToPercent = (v, loanAmount) => (v / loanAmount) * 100;
export const percentToNominal = (v, loanAmount) => (v * loanAmount) / 100;
const FeeCalcOriginationFeeField = ({ minPercent, maxPercent, onCalculate, onChange, disabled, loanAmount, }) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();
    const percentVal = Form.useWatch("transaction_fee", form);
    const onPercentChange = (v) => {
        form.setFieldValue("transaction_fee", v);
        form.setFieldValue("transaction_fee_nominal", percentToNominal(v, loanAmount));
        void form.validateFields();
        onChange();
    };
    const onNominalChange = (v) => {
        form.setFieldValue("transaction_fee", nominalToPercent(v, loanAmount));
        form.setFieldValue("transaction_fee_nominal", v);
        void form.validateFields();
        onChange();
    };
    const handleNominalValidation = (_, value) => value < percentToNominal(minPercent, loanAmount) ||
        value > percentToNominal(maxPercent, loanAmount)
        ? Promise.reject()
        : Promise.resolve();
    const handlePercentValidation = (_, value) => value < minPercent || value > maxPercent ? Promise.reject() : Promise.resolve();
    return (_jsxs(_Fragment, { children: [_jsxs(CardRow, Object.assign({ style: { alignItems: "center" }, gutter: 16, wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "200px" }, { children: _jsxs(Typography.Text, Object.assign({ type: "secondary" }, { children: [_jsx("span", Object.assign({ style: {
                                        display: "inline-block",
                                        marginRight: 4,
                                    } }, { children: t("application.pricing.origination-fee") })), _jsx(Tooltip, Object.assign({ title: t("application.pricing.origination-fee-tooltip") }, { children: _jsx(InfoCircleOutlined, {}) }))] })) })), _jsx(Col, Object.assign({ flex: 1 }, { children: _jsxs(Row, Object.assign({ gutter: 16 }, { children: [_jsx(Col, Object.assign({ span: 15 }, { children: _jsx(Slider, { min: minPercent, max: maxPercent, onChange: onPercentChange, value: percentVal, step: STEP, tooltip: { open: false }, disabled: disabled }) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(FormItem, Object.assign({ name: "transaction_fee", rules: [{ validator: handlePercentValidation }] }, { children: _jsx(PercentInput, { style: { width: "100%" }, step: STEP, onChange: (v) => onPercentChange(v), prefix: "%", disabled: disabled, allowEmptyValue: true }) })) })), _jsx(Col, Object.assign({ span: 2, style: { textAlign: "right" } }, { children: _jsx(Button, { icon: _jsx(CalculatorOutlined, {}), onClick: onCalculate, type: "link", style: { width: "auto" }, disabled: disabled }) }))] })) }))] })), _jsxs(CardRow, Object.assign({ style: { alignItems: "center" }, gutter: 16, wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "200px" }, { children: _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("application.pricing.origination-fee-nominal") })) })), _jsx(Col, Object.assign({ flex: 1 }, { children: _jsx(Row, Object.assign({ gutter: 16 }, { children: _jsx(Col, Object.assign({ span: 7, offset: 15 }, { children: _jsx(FormItem, Object.assign({ name: "transaction_fee_nominal", rules: [{ validator: handleNominalValidation }] }, { children: _jsx(CurrencyInput, { style: { width: "100%" }, disabled: disabled, onChange: (v) => onNominalChange(v), allowEmptyValue: true }) })) })) })) }))] }))] }));
};
export default FeeCalcOriginationFeeField;
