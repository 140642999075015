import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, generatePath } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import styled from "styled-components";
import { CompanyRoutes } from "../Routes";
const CompanyIdWrapper = styled.div `
	display: flex;
	gap: 4px;
`;
const CompanyIdWithLink = ({ companyId }) => {
    return (_jsxs(CompanyIdWrapper, { children: [_jsx(Typography.Text, Object.assign({ copyable: { tooltips: false }, ellipsis: { tooltip: companyId } }, { children: companyId })), _jsx(Link, Object.assign({ to: generatePath(CompanyRoutes.Company, { companyId }), target: "_blank" }, { children: _jsx(SelectOutlined, {}) }))] }));
};
export default CompanyIdWithLink;
