import { FC } from "react";

import { Space, Typography } from "antd";

import { FactoringInfoFinancials } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../translations/translations";
import { InfoCol, InfoRow, InfoTitle } from "./InformationCardComponents";

const { Text } = Typography;

interface InformationCardFinancialsInfoProps {
	info: FactoringInfoFinancials;
	hasInternationalCustomers: boolean;
}

export const InformationCardFinancialsInfo: FC<InformationCardFinancialsInfoProps> = ({
	info,
	hasInternationalCustomers,
}) => {
	const t = useTranslations();
	const { currency } = useFormatter();

	return (
		<>
			<InfoRow>
				<InfoCol span={24}>
					<InfoTitle>{t.factoring.financials.company_portfolio}</InfoTitle>
				</InfoCol>
				<InfoCol span={24}>
					<Text>
						{info.company_portfolio && info.company_portfolio?.length > 0
							? info.company_portfolio
							: "-"}
					</Text>
				</InfoCol>
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.financials.annual_sales_previous_year}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.annual_sales_previous_year
										? currency(info.annual_sales_previous_year, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.annual_sales_previous_year_abroad
										? currency(info.annual_sales_previous_year_abroad, { showFractions: false })
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.annual_sales_previous_year
								? currency(info.annual_sales_previous_year, {
										showFractions: false,
									})
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<InfoTitle>{t.factoring.financials.planned_annual_sales_following_year}</InfoTitle>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.planned_annual_sales_following_year
										? currency(info.planned_annual_sales_following_year, { showFractions: false })
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.planned_annual_sales_following_year_abroad
										? currency(info.planned_annual_sales_following_year_abroad, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.planned_annual_sales_following_year
								? currency(info.planned_annual_sales_following_year, { showFractions: false })
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<InfoTitle>{t.factoring.financials.planned_annual_sales_factoring}</InfoTitle>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.planned_annual_sales_factoring
										? currency(info.planned_annual_sales_factoring, { showFractions: false })
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.planned_annual_sales_factoring_abroad
										? currency(info.planned_annual_sales_factoring_abroad, { showFractions: false })
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.planned_annual_sales_factoring
								? currency(info.planned_annual_sales_factoring, { showFractions: false })
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>
		</>
	);
};
