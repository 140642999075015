import { FC } from "react";

import { Space, Typography } from "antd";
import styled from "styled-components";

import { FactoringInfoRiskProtection } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../translations/translations";
import { InfoCol, InfoRow, InfoTitle } from "./InformationCardComponents";

const { Text } = Typography;

const InfoColAbove = styled(InfoCol)`
	display: flex;
	flex-direction: column;
`;

interface InformationCardRiskInfo {
	info: FactoringInfoRiskProtection;
	hasInternationalCustomers: boolean;
}

export const InformationCardRiskInfo: FC<InformationCardRiskInfo> = ({
	info,
	hasInternationalCustomers,
}) => {
	const t = useTranslations();
	const { currency } = useFormatter();

	return (
		<>
			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.risk.receivables_default_amount_previous_year}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.receivables_default_amount_previous_year
										? currency(info.receivables_default_amount_previous_year, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.receivables_default_amount_previous_year_abroad
										? currency(info.receivables_default_amount_previous_year_abroad, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.receivables_default_amount_previous_year
								? currency(info.receivables_default_amount_previous_year, { showFractions: false })
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoCol span={24}>
					<Space>
						<InfoTitle>{t.factoring.risk.receivables_default_amount_current_year}</InfoTitle>
					</Space>
				</InfoCol>
				{hasInternationalCustomers ? (
					<>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.in_land(
									info.receivables_default_amount_current_year
										? currency(info.receivables_default_amount_current_year, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
						<InfoCol span={12}>
							<Text>
								{t.factoring.common.abroad(
									info.receivables_default_amount_current_year_abroad
										? currency(info.receivables_default_amount_current_year_abroad, {
												showFractions: false,
											})
										: "-"
								)}
							</Text>
						</InfoCol>
					</>
				) : (
					<InfoCol span={12}>
						<Text>
							{info.receivables_default_amount_current_year
								? currency(info.receivables_default_amount_current_year, { showFractions: false })
								: "-"}
						</Text>
					</InfoCol>
				)}
			</InfoRow>

			<InfoRow>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.risk.are_receivables_assigned}</InfoTitle>
					</Space>
					<Text>{info.are_receivables_assigned ? t.yes : t.no}</Text>
				</InfoColAbove>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.common.comment}</InfoTitle>
					</Space>
					<Text>{info.are_receivables_assigned_comment}</Text>
				</InfoColAbove>
			</InfoRow>

			<InfoRow>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.risk.has_commercial_credit_insurance}</InfoTitle>
					</Space>
					<Text>{info.has_commercial_credit_insurance ? t.yes : t.no}</Text>
				</InfoColAbove>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.common.comment}</InfoTitle>
					</Space>
					<Text>{info.has_commercial_credit_insurance_comment}</Text>
				</InfoColAbove>
			</InfoRow>

			<InfoRow>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.risk.already_has_factoring}</InfoTitle>
					</Space>
					<Text>{info.already_has_factoring ? t.yes : t.no}</Text>
				</InfoColAbove>
				<InfoColAbove span={12}>
					<Space>
						<InfoTitle>{t.factoring.common.comment}</InfoTitle>
					</Space>
					<Text>
						{info.already_has_factoring_comment ? info.already_has_factoring_comment : "-"}
					</Text>
				</InfoColAbove>
			</InfoRow>
		</>
	);
};
