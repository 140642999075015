import { generate } from "@ant-design/colors";
import { CellStyle, Id } from "@silevis/reactgrid";
import dayjs from "dayjs";

import {
	CompanyFinancialData,
	FinancialInputData,
	FinancialStatementType,
	FinancialSummaryData,
} from "@teylor-tools/Api";

import { Translations } from "../translations/translations";
import {
	Accounts,
	Adjustments,
	Assets,
	EquityAndLiabilities,
	RowId,
	rowsWithoutValue,
	summaryData,
} from "./rows.types";
import { CellTypes, Column, Row } from "./table.types";

interface SectionWithRows {
	section: RowId;
	rows: (RowId | SectionWithRows)[];
}

interface RowData {
	rowId: RowId;
	indent: number;
	parentId?: string;
	hasChildren?: boolean;
}

export const HEADER_ROW_ID = "header";
export const ROW_DESCRIPTION_COLUMN_ID = "desc";

const sectionsAndRows: SectionWithRows[] = [
	{
		section: Accounts.accounts,
		rows: [
			Accounts.revenue,
			Accounts.inventory_change,
			Accounts.activated_costs,
			Accounts.other_income,
			Accounts.extraordinary_income,
			Accounts.costs_of_materials,
			Accounts.external_services,
			Accounts.personnel_costs,
			Accounts.depreciations,
			Accounts.other_costs,
			Accounts.extraordinary_expense,
			Accounts.interest_income,
			Accounts.interest_expense,
			Accounts.other_finance_income,
			Accounts.other_finance_costs,
			Accounts.taxes,
			Accounts.other_taxes,
			Accounts.net_income,
		],
	},
	{
		section: Assets.assets,
		rows: [
			{
				section: Assets.intangible_assets,
				rows: [Assets.long_term_intangible_assets, Assets.self_made_intangible_assets],
			},
			{
				section: Assets.long_term_assets,
				rows: [
					Assets.long_term_properties,
					Assets.long_term_securities,
					Assets.investments_in_related_companies,
					Assets.goodwill,
				],
			},
			{
				section: Assets.current_assets,
				rows: [
					Assets.inventories,
					Assets.accounts_receivable,
					{
						section: Assets.other_current_assets,
						rows: [Assets.loan_to_related_companies, Assets.loan_to_shareholders],
					},
				],
			},
			{
				section: Assets.financial_assets,
				rows: [Assets.cash],
			},

			Assets.accruals_aktiva,
			Assets.deferred_tax_assets,
			Assets.losses_not_covered_by_equity,
			Assets.total_assets_published,
		],
	},
	{
		section: EquityAndLiabilities.equity_and_liabilities,
		rows: [
			{
				section: EquityAndLiabilities.equity,
				rows: [
					EquityAndLiabilities.subscribed_capital,
					EquityAndLiabilities.capital_reserve,
					EquityAndLiabilities.retained_income,
					EquityAndLiabilities.profit_carried_forward,
					EquityAndLiabilities.annual_profit,
					EquityAndLiabilities.other_equity_position,
				],
			},
			EquityAndLiabilities.equity_published,
			EquityAndLiabilities.accrued_liabilities_short_term,
			EquityAndLiabilities.accrued_liabilities_long_term,
			{
				section: EquityAndLiabilities.liabilities,
				rows: [
					EquityAndLiabilities.bonds,
					{
						section: EquityAndLiabilities.bank_debts,
						rows: [
							EquityAndLiabilities.bank_debts_short_term,
							EquityAndLiabilities.bank_debts_long_term,
						],
					},
					EquityAndLiabilities.deposits_received,
					EquityAndLiabilities.accounts_payable,
					{
						section: EquityAndLiabilities.other_liabilities_short_term,
						rows: [EquityAndLiabilities.other_debts_short_term],
					},
					{
						section: EquityAndLiabilities.other_liabilities_long_term,
						rows: [
							EquityAndLiabilities.loan_from_related_companies,
							EquityAndLiabilities.loan_from_shareholders,
							EquityAndLiabilities.other_debts_long_term,
						],
					},
				],
			},
			EquityAndLiabilities.accruals_passiva,
			EquityAndLiabilities.deferred_tax_liabilities,
			EquityAndLiabilities.total_liabilities_published,
			EquityAndLiabilities.total_liabilities_and_equity,
		],
	},
	{
		section: Adjustments.adjustments,
		rows: [Adjustments.total_debt_service],
	},
];

const createHeaderRow = (
	columns: Column[],
	collapsedColumns: Id[],
	deletedColumns: Id[],
	financials: CompanyFinancialData[],
	primaryColor: string
): Row => ({
	rowId: HEADER_ROW_ID,
	height: 30,
	cells: columns.flatMap<CellTypes>((column, idx) => {
		if (idx === 0) {
			return [
				{
					type: "text",
					text: "",
					columnId: column.columnId,
				},
			];
		}

		const financial = financials.find((f) => f.company_financial_data_id === column.columnId);

		if (!financial) return [];

		const date = dayjs(financial.financials_date);

		return [
			{
				type: "horizontalChevron",
				columnId: column.columnId,
				statementType: financial?.statement_type,
				origin: financial.origin,
				className: "column-header",
				isExpanded: !collapsedColumns.includes(column.columnId),
				...(financial.statement_type === FinancialStatementType.AnnualStatement
					? {
							text: `${date.year()}`,
							style: {
								background: primaryColor,
							} as CellStyle,
							hasChildren: financials
								.filter(
									(f) =>
										f.statement_type === FinancialStatementType.InterimFinancials &&
										!deletedColumns.includes(f.company_financial_data_id)
								)
								.some((f) => dayjs(f.financials_date).year() === date.year()),
						}
					: {
							text: `${date.year()}.${date.month() + 1}`,
							style: {
								background: generate(primaryColor)[3],
							} as CellStyle,
						}),
			},
		];
	}),
});

const createDataRow = (
	row: RowData,
	financials: CompanyFinancialData[],
	columns: Column[],
	collapsedRows: Id[],
	isEditing: boolean,
	t: Translations
): Row => {
	const dataCells: CellTypes[] = financials.map((financial, idx) => {
		const value = summaryData.includes(row.rowId)
			? financial.summary_data?.[row.rowId as keyof FinancialSummaryData]
			: financial.input_data[row.rowId as keyof FinancialInputData];

		return {
			type: "number",
			value: value ? Number(value) : undefined,
			columnId: columns[idx + 1].columnId,
			nonEditable:
				summaryData.includes(row.rowId) || rowsWithoutValue.includes(row.rowId) ? true : !isEditing,
			...(idx === 0 && rowsWithoutValue.includes(row.rowId) && { colspan: financials.length }),
		};
	});

	return {
		rowId: row.rowId,
		height: 30,
		cells: [
			{
				type: "verticalChevron",
				// @ts-ignore dynamic keys
				text: t.financialsTable.rows[row.rowId],
				parentId: row.parentId,
				indent: row.indent,
				columnId: columns[0].columnId,
				hasChildren: row.hasChildren,
				isExpanded: !collapsedRows.includes(row.rowId),
				className: row.hasChildren ? `text-bold` : "",
			},
			...dataCells,
		],
	};
};

const buildSectionTree = (
	section: SectionWithRows,
	collapsedRows: Id[],
	indent: number,
	parentId?: string
): RowData[] =>
	[
		{ rowId: section.section, indent, parentId: parentId, hasChildren: true },
		...(!collapsedRows.includes(section.section)
			? section.rows.flatMap((row) =>
					typeof row === "string"
						? { rowId: row, indent: indent + 1, parentId: section.section }
						: buildSectionTree(row, collapsedRows, indent + 1, section.section)
				)
			: []),
	].flat();

const createDataRows = (
	financials: CompanyFinancialData[],
	columns: Column[],
	collapsedRows: Id[],
	isEditing: boolean,
	t: Translations
): Row[] => {
	const treeData: RowData[] = sectionsAndRows
		.flatMap((section) => buildSectionTree(section, collapsedRows, 0))
		.flat();

	const displayedColumns = columns.map((col) => col.columnId);
	const displayedFinancials = financials.filter((f) =>
		displayedColumns.includes(f.company_financial_data_id)
	);

	return treeData.map((row) =>
		createDataRow(row, displayedFinancials, columns, collapsedRows, isEditing, t)
	);
};

export const buildRows = ({
	financials,
	columns,
	collapsedRows,
	collapsedColumns,
	deletedColumns,
	isEditing,
	primaryColor,
	t,
}: {
	financials: CompanyFinancialData[];
	columns: Column[];
	collapsedRows: Id[];
	collapsedColumns: Id[];
	deletedColumns: Id[];
	isEditing: boolean;
	primaryColor: string;
	t: Translations;
}): Row[] => {
	return [
		createHeaderRow(columns, collapsedColumns, deletedColumns, financials, primaryColor),
		...createDataRows(financials, columns, collapsedRows, isEditing, t),
	];
};
