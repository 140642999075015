import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tag } from "antd";
import styled from "styled-components";
import { LoanTransactionType } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useLoan } from "src/layouts/LoanLayout/loan.types";
import { Axios } from "src/utils/Axios";
const ContentWrapper = styled.div `
	margin-top: 64px;
	padding: 24px;
`;
const typeColorMapping = new Map([
    [LoanTransactionType.Payment, "success"],
    [LoanTransactionType.InitialPayout, "purple"],
    [LoanTransactionType.PaymentReversal, "red"],
]);
const LoanTransactions = () => {
    const { t } = useTranslation();
    const { currency, date } = useFormatter();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState();
    const { loan: { loan_id }, } = useLoan();
    useEffect(() => {
        Axios.get(`admin/loans/${loan_id}/transactions`)
            .then(({ data }) => {
            if (!data.transactions)
                return Promise.reject();
            const tableData = data.transactions.map((t) => ({
                key: t.transaction_id,
                date: t.booking_date,
                type: t.transaction_type,
                amount: currency(t.amount_total, { currency: t.currency }),
                principal: currency(t.amount_principal, { currency: t.currency }),
                interest: currency(t.amount_interest, { currency: t.currency }),
            }));
            setTransactions(tableData);
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loan_id]);
    const columns = [
        {
            title: t("loans.transactions.date"),
            dataIndex: "date",
            render: (v) => date(v),
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            title: t("loans.transactions.type"),
            render: (v) => (_jsx(Tag, Object.assign({ color: typeColorMapping.get(v) }, { children: t(`loans.transactions.${v}`) }))),
            dataIndex: "type",
        },
        {
            title: t("loans.transactions.amount"),
            dataIndex: "amount",
        },
        {
            title: t("loans.transactions.principal"),
            dataIndex: "principal",
        },
        {
            title: t("loans.transactions.interest"),
            dataIndex: "interest",
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("loans.transactions.title") }), _jsx(ContentWrapper, { children: _jsx(Table, { loading: loading, columns: columns, dataSource: transactions }) })] }));
};
export default LoanTransactions;
