var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Fragment, forwardRef, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { App, Form } from "antd";
import RuleEngineConfiguratorGroup from "./RuleEngineConfiguratorGroup";
import { mapFormValuesToRule, mapRuleToFormValues } from "./map-rule-and-form-values";
import { GroupType, } from "./rule-engine.types";
const newEmptyChild = {
    type: GroupType.LOGICAL,
    val: undefined,
    left: undefined,
    right: undefined,
};
export const FORM_LIST_NAME = "rules";
const updateNestedValue = (obj, index, newVal, path) => {
    if (index === path.length - 1) {
        Object.keys(newVal).forEach((key) => {
            // @ts-ignore
            obj[path[index]][key] = newVal[key];
        });
    }
    else {
        // @ts-ignore
        updateNestedValue(obj[path[index]], index + 1, newVal, path);
    }
};
const RuleEngineConfigurator = forwardRef(({ rule, disabled }, ref) => {
    const { message } = App.useApp();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        getRuleConfiguration,
    }));
    const handleAddSubgroups = (path) => {
        const { left, right } = form.getFieldValue([FORM_LIST_NAME, ...path]);
        let update;
        if (left && right) {
            update = {
                left: newEmptyChild,
                right: Object.assign(Object.assign({}, newEmptyChild), { left,
                    right }),
            };
        }
        else {
            update = { left: newEmptyChild, right: newEmptyChild };
        }
        updateForm(path, update);
    };
    const handleRemoveSubgroups = (path) => {
        const update = { left: undefined, right: undefined };
        updateForm(path, update);
    };
    const handleChangeGroupType = (path) => {
        const update = { val: undefined };
        updateForm(path, update);
    };
    const handleChangeGroupTypeWithSubgroupsValue = (path) => {
        const { left, right } = form.getFieldValue([FORM_LIST_NAME, ...path]);
        if (left && right)
            return;
        const update = { left: newEmptyChild, right: newEmptyChild };
        updateForm(path, update);
    };
    const updateForm = (formPath, update) => {
        const fieldsValueCopy = form.getFieldsValue();
        const path = [FORM_LIST_NAME, ...formPath];
        updateNestedValue(fieldsValueCopy, 0, update, path);
        form.setFieldsValue(fieldsValueCopy);
    };
    const getRuleConfiguration = () => __awaiter(void 0, void 0, void 0, function* () {
        yield form.validateFields().catch(() => {
            void message.error(t("rule-engine-configurator.verify-syntax"));
            return Promise.reject();
        });
        try {
            const config = mapFormValuesToRule(form.getFieldValue([FORM_LIST_NAME, 0]));
            return Promise.resolve(config);
        }
        catch (_a) {
            void message.error(t("rule-engine-configurator.verify-syntax"));
            return Promise.reject();
        }
    });
    const renderInitialView = () => {
        const formValues = rule ? mapRuleToFormValues(rule) : newEmptyChild;
        form.setFieldsValue({ [FORM_LIST_NAME]: [formValues] });
    };
    const renderGroup = (group, level, formParentPath, isLastChild) => {
        const left = group.left;
        const right = group.right;
        return (_jsx(RuleEngineConfiguratorGroup, Object.assign({ level: level, formParentPath: [...formParentPath], isLastChild: isLastChild, onAdd: (path) => handleAddSubgroups(path), onDelete: (path) => handleRemoveSubgroups(path), onTypeChange: (path) => handleChangeGroupType(path), onTypeWithSubgroupsValueChange: (path) => handleChangeGroupTypeWithSubgroupsValue(path), disabled: disabled }, { children: left && right && (_jsxs(_Fragment, { children: [renderGroup(left, level + 1, [...formParentPath, "left"]), renderGroup(right, level + 1, [...formParentPath, "right"], true)] })) })));
    };
    useEffect(renderInitialView, [rule, form]);
    return (_jsx(Form, Object.assign({ form: form, disabled: disabled }, { children: _jsx(Form.List, Object.assign({ name: FORM_LIST_NAME }, { children: (rules) => rules.map((rule, idx) => (_jsx(Fragment, { children: renderGroup(form.getFieldsValue()[FORM_LIST_NAME][idx], 0, [rule.name]) }, idx))) })) })));
});
RuleEngineConfigurator.displayName = "RuleEngineConfigurator";
export default RuleEngineConfigurator;
