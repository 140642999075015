import { Document, DocumentCategory } from "../../Api";
import { CategoryData } from "./documents.types";

export const categorizeDocuments = <T extends Document>(documents: T[]): CategoryData<T>[] => {
	return documents.reduce<CategoryData<T>[]>((acc, doc) => {
		const category = acc.find((cat) => cat.categoryName === doc.document_category);

		if (category) {
			const type = category.types.find((type) => type.typeName === doc.document_type);
			if (type) {
				type.docs.push(doc);
			} else {
				category.types.push({
					typeName: doc.document_type,
					docs: [doc],
				});
			}
		} else {
			const categoryData: CategoryData<T> = {
				categoryName: doc.document_category,
				types: [
					{
						typeName: doc.document_type,
						docs: [doc],
					},
				],
			};
			doc.document_category === DocumentCategory.None
				? acc.unshift(categoryData)
				: acc.push(categoryData);
		}

		return acc;
	}, []);
};
