import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Select, Tag, Typography } from "antd";
import styled from "styled-components";
import { AccountManagerType } from "@teylor-tools/Api";
import Avatar from "@ui/data-display/Avatar";
import { filterSelectByIdAndLabel } from "../../utils/filterSelectByIdAndLabel";
const AssigneeWrapper = styled.div `
	display: flex;
	align-items: center;
	gap: 8px;
	height: 30px;
`;
const UserSelect = ({ users, usersLoading, onChange, value }) => {
    const { t } = useTranslation();
    const renderAssignee = (id) => {
        const assignee = users.find((user) => user.id === id);
        if (!assignee)
            return;
        const { name, type } = assignee;
        return (_jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: name, size: "small" }), _jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: name })), type === AccountManagerType.Puser && _jsx(Tag, { children: t("puser_label") })] }));
    };
    return (_jsx(Select, { loading: usersLoading, disabled: usersLoading, options: users.map((assignee) => ({
            value: assignee.id,
            label: assignee.name,
        })), labelRender: ({ value }) => renderAssignee(value), optionRender: ({ value }) => renderAssignee(value), filterOption: filterSelectByIdAndLabel, allowClear: true, showSearch: true, onChange: onChange, style: { width: "100%" }, value: value }));
};
export default UserSelect;
