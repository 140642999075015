import { FC } from "react";

import { Col, Form, Input, Radio, Row } from "antd";

import {
	Currency,
	FactoringInfoRiskProtection,
	FactoringInfoRiskProtectionReq,
} from "@teylor-tools/Api";

import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import { FactoryDetailsType } from "../information-card/types";
import { useTranslations } from "../translations/translations";
import HeadText from "./FormComponents";

const { TextArea } = Input;

interface RiskFormProps {
	details: FactoringInfoRiskProtection;
	formId: FactoryDetailsType;
	handleUpdateFactoringData: (
		type: FactoryDetailsType,
		values: FactoringInfoRiskProtectionReq
	) => Promise<void>;
	hasInternationalCustomers: boolean;
	defaultCurrency?: Currency;
	isRequired?: boolean;
}

export const RiskForm: FC<RiskFormProps> = ({
	details,
	formId,
	handleUpdateFactoringData,
	hasInternationalCustomers,
	defaultCurrency,
	isRequired = true,
}) => {
	const t = useTranslations();
	const [form] = Form.useForm<FactoringInfoRiskProtection>();

	const handleUpdate = () => {
		void handleUpdateFactoringData(formId, form.getFieldsValue() as FactoringInfoRiskProtectionReq);
	};

	return (
		<Form
			id={formId}
			form={form}
			layout="vertical"
			onFinish={handleUpdate}
			initialValues={{
				...details,
			}}
		>
			<Row gutter={14}>
				<Col span={24}>
					<HeadText>{t.factoring.forms.risk.receivables_default_amount_previous_year}</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="receivables_default_amount_previous_year"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="receivables_default_amount_previous_year_abroad"
							label={t.factoring.common.abroad_text}
						>
							<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>

			<Row gutter={14}>
				<Col span={24}>
					<HeadText>{t.factoring.forms.risk.receivables_default_amount_current_year}</HeadText>
				</Col>
				<Col span={12}>
					<Form.Item
						name="receivables_default_amount_current_year"
						label={hasInternationalCustomers && t.factoring.common.in_land_text}
					>
						<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				{hasInternationalCustomers && (
					<Col span={12}>
						<Form.Item
							name="receivables_default_amount_current_year_abroad"
							label={t.factoring.common.abroad_text}
						>
							<CurrencyInput allowEmptyValue currency={defaultCurrency} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row>
				<Col span={12}>
					<Form.Item
						name="are_receivables_assigned"
						label={t.factoring.forms.risk.are_receivables_assigned}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<Radio.Group>
							<Radio value={true}>{t.factoring.forms.risk.yes}</Radio>
							<Radio value={false}>{t.factoring.forms.risk.no}</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="are_receivables_assigned_comment" label={t.factoring.common.comment}>
						<TextArea rows={3} />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Form.Item
						name="has_commercial_credit_insurance"
						label={t.factoring.forms.risk.has_commercial_credit_insurance}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<Radio.Group>
							<Radio value={true}>{t.factoring.forms.risk.yes}</Radio>
							<Radio value={false}>{t.factoring.forms.risk.no}</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="has_commercial_credit_insurance_comment"
						label={t.factoring.common.comment}
					>
						<TextArea rows={3} />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Form.Item
						name="already_has_factoring"
						label={t.factoring.forms.risk.already_has_factoring}
						required={isRequired}
						rules={
							isRequired
								? [
										{
											required: true,
											message: t.formErrors.fieldRequired,
										},
									]
								: []
						}
					>
						<Radio.Group>
							<Radio value={true}>{t.factoring.forms.risk.yes}</Radio>
							<Radio value={false}>{t.factoring.forms.risk.no}</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="already_has_factoring_comment" label={t.factoring.common.comment}>
						<TextArea rows={3} />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				name="centralized_settlement_entity"
				label={t.factoring.forms.risk.centralized_settlement_entity}
				required={isRequired}
				rules={
					isRequired
						? [
								{
									required: true,
									message: t.formErrors.fieldRequired,
								},
							]
						: []
				}
			>
				<TextArea rows={5} />
			</Form.Item>
		</Form>
	);
};
