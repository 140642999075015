import {
	FactoringInfo,
	FactoringInfoDebitors,
	FactoringInfoFinancials,
	FactoringInfoInvoices,
	FactoringInfoRiskProtection,
} from "../Api";

export const splitFactoringData = (factoring?: FactoringInfo) => {
	if (!factoring) {
		return {
			financialDetails: {},
			invoiceDetails: {},
			debitorDetails: {},
			riskProtectionDetails: {},
		};
	}

	const financialDetails: FactoringInfoFinancials = {
		company_portfolio: factoring.company_portfolio,
		planned_annual_sales_following_year: factoring.planned_annual_sales_following_year,
		planned_annual_sales_following_year_abroad:
			factoring.planned_annual_sales_following_year_abroad,
		planned_annual_sales_factoring: factoring.planned_annual_sales_factoring,
		planned_annual_sales_factoring_abroad: factoring.planned_annual_sales_factoring_abroad,
		annual_sales_previous_year: factoring.annual_sales_previous_year,
		annual_sales_previous_year_abroad: factoring.annual_sales_previous_year_abroad,
	};

	const invoiceDetails: FactoringInfoInvoices = {
		number_of_outgoing_invoices: factoring.number_of_outgoing_invoices,
		number_of_outgoing_invoices_abroad: factoring.number_of_outgoing_invoices_abroad,
		invoice_currencies: factoring.invoice_currencies,
		invoice_currencies_abroad: factoring.invoice_currencies_abroad,
		max_invoice_value_previous_year: factoring.max_invoice_value_previous_year,
		max_invoice_value_previous_year_abroad: factoring.max_invoice_value_previous_year_abroad,
		payment_deadline_for_outgoing_invoices: factoring.payment_deadline_for_outgoing_invoices,
		payment_deadline_for_outgoing_invoices_abroad:
			factoring.payment_deadline_for_outgoing_invoices_abroad,
	};

	const debitorDetails: FactoringInfoDebitors = {
		number_of_debitors: factoring.number_of_debitors,
		number_of_debitors_abroad: factoring.number_of_debitors_abroad,
		debitors: factoring.debitors,
	};

	const riskProtectionDetails: FactoringInfoRiskProtection = {
		receivables_default_amount_previous_year: factoring.receivables_default_amount_previous_year,
		receivables_default_amount_previous_year_abroad:
			factoring.receivables_default_amount_previous_year_abroad,
		receivables_default_amount_current_year: factoring.receivables_default_amount_current_year,
		receivables_default_amount_current_year_abroad:
			factoring.receivables_default_amount_current_year_abroad,
		are_receivables_assigned: factoring.are_receivables_assigned,
		are_receivables_assigned_comment: factoring.are_receivables_assigned_comment,
		has_commercial_credit_insurance: factoring.has_commercial_credit_insurance,
		has_commercial_credit_insurance_comment: factoring.has_commercial_credit_insurance_comment,
		already_has_factoring: factoring.already_has_factoring,
		already_has_factoring_comment: factoring.already_has_factoring_comment,
		centralized_settlement_entity: factoring.centralized_settlement_entity,
	};

	return {
		financialDetails,
		invoiceDetails,
		debitorDetails,
		riskProtectionDetails,
	};
};
