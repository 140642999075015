import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import * as React from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, Row, Typography, } from "antd";
import styled, { css } from "styled-components";
import { CardRow } from "@ui/data-display/DataCard";
const { Text } = Typography;
const EditIcon = styled(EditOutlined) `
	color: ${({ theme }) => theme.color_link};
	margin-left: 8px;

	&:hover {
		cursor: pointer;
	}
`;
const ActionBtn = styled(Button) `
	width: 32px;
	height: 32px;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;

	span {
		margin-top: 2px;
	}

	${({ $isOk, theme }) => $isOk
    ? css `
					span {
						color: ${theme.colorPrimary};
					}
				`
    : css `
					&:hover {
						border-color: #d9d9d9;
					}
				`}
`;
export const DataCard = styled(Card) `
	margin-bottom: 24px;
	min-width: 420px;
	max-width: 720px;
`;
export const DataCardDivider = styled(Divider) `
	margin: 24px 0;
	left: -24px;
	position: relative;
	width: calc(100% + 48px);
`;
const FORM_ITEM = "item";
const DataCardItemEditable = ({ label, displayedValue, onChange, customFormItem, formItemValue, restrictions, isEditable = true, extra, e2eId = "", }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const onFinish = (v) => {
        onChange(v[FORM_ITEM]);
        setIsEditMode(false);
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            form.submit();
        }
        if (e.key === "Escape") {
            setIsEditMode(false);
        }
    };
    return (_jsxs(CardRow, Object.assign({ align: "top", gutter: 16, wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "200px" }, { children: _jsx(Text, Object.assign({ type: "secondary" }, { children: label })) })), _jsx(Col, Object.assign({ flex: 1 }, { children: isEditMode ? (_jsx(Form, Object.assign({ form: form, onFinish: onFinish, onKeyDown: handleKeyDown, preserve: false }, { children: _jsxs(Row, Object.assign({ gutter: 8, justify: "space-between", wrap: false }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: customFormItem ? (customFormItem({
                                    name: FORM_ITEM,
                                    label: "",
                                    style: { margin: 0 },
                                    inputProps: { style: { width: "100%" }, "data-cy": e2eId },
                                    initialValue: formItemValue,
                                })) : (_jsx(Form.Item, Object.assign({ name: FORM_ITEM, style: { margin: 0 }, initialValue: formItemValue }, { children: _jsx(Input, Object.assign({}, restrictions, { style: { width: "100%" }, "data-cy": e2eId })) }))) })), _jsx(Col, { children: _jsxs(Row, Object.assign({ gutter: 8, wrap: false }, { children: [_jsx(Col, { children: _jsx(ActionBtn, Object.assign({ "$isOk": true, type: "primary", ghost: true, onClick: () => form.submit() }, { children: _jsx(CheckOutlined, {}) })) }), _jsx(Col, { children: _jsx(ActionBtn, Object.assign({ onClick: () => setIsEditMode(false) }, { children: _jsx(CloseOutlined, {}) })) })] })) })] })) }))) : (_jsxs("div", Object.assign({ style: { display: "flex", alignItems: "center", justifyContent: "space-between" } }, { children: [_jsx("div", { children: _jsxs(Typography.Text, { children: [displayedValue || "-", " ", isEditable && (_jsx(EditIcon, { "data-cy": `${e2eId}-edit-btn`, onClick: () => setIsEditMode(true) }))] }) }), extra && _jsx("div", { children: extra })] }))) }))] })));
};
export { DataCardItemEditable };
