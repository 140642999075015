import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row as AntdRow, Col, Form, Modal, Select, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import debounce from "lodash/debounce";
import styled from "styled-components";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardDivider } from "@ui/data-display/DataCard";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const Row = styled(AntdRow) `
	margin-bottom: 16px;
`;
const FormItem = styled(Form.Item) `
	margin: 0;

	.ant-form-item-label {
		width: 200px;
	}
`;
const LabelCol = styled(Col) `
	width: 200px;
`;
const FORM_ID = "funding_provider_form";
const getValue = (v) => (isValidNumericValue(v) ? Number(v) : undefined);
const FundingProviderModal = ({ fundingProviders, onSave, onClose }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const { application } = useApplication();
    const [currency, setCurrency] = useState((_a = application.funding_provider_info) === null || _a === void 0 ? void 0 : _a.currency);
    const requiredRule = {
        required: true,
        message: t("common.required"),
    };
    const inputDisabled = !form.getFieldValue("funding_provider") &&
        !((_b = application.funding_provider_info) === null || _b === void 0 ? void 0 : _b.funding_provider_id);
    const initialValues = {
        funding_provider: (_c = application.funding_provider_info) === null || _c === void 0 ? void 0 : _c.funding_provider_id,
        revenue: getValue((_d = application.funding_provider_info) === null || _d === void 0 ? void 0 : _d.revenue),
        discount: getValue((_e = application.funding_provider_info) === null || _e === void 0 ? void 0 : _e.discount),
        total: getValue((_f = application.funding_provider_info) === null || _f === void 0 ? void 0 : _f.total),
    };
    const recalculateTotal = () => {
        const { revenue, discount } = form.getFieldsValue();
        if (!isValidNumericValue(revenue) || !isValidNumericValue(discount)) {
            form.setFieldValue("total", undefined);
            return;
        }
        setLoading(true);
        Axios.get(`/admin/applications/${application.applicationId}/funding_provider/total`, {
            revenue: revenue.toString(),
            discount: discount.toString(),
        })
            .then(({ data }) => form.setFieldValue("total", getValue(data.total)), (err) => {
            void Axios.error(err);
            form.setFieldValue("total", undefined);
        })
            .finally(() => setLoading(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRecalculateTotal = useCallback(debounce(recalculateTotal, 500), []);
    const handleProviderChange = (providerId) => {
        setLoading(true);
        void Axios.get(`/admin/applications/${application.applicationId}/funding_provider`, {
            funding_provider_id: providerId,
        })
            .then(({ data }) => {
            form.setFieldsValue({
                revenue: getValue(data.revenue),
                discount: getValue(data.discount),
                total: getValue(data.total),
            });
            setCurrency(data.currency);
        }, (err) => {
            void Axios.error(err);
            form.setFieldsValue({ revenue: undefined, discount: undefined, total: undefined });
        })
            .finally(() => setLoading(false));
    };
    const handleSave = () => {
        setLoading(true);
        const { funding_provider, revenue, discount } = form.getFieldsValue();
        const payload = {
            funding_provider_id: funding_provider,
            revenue: revenue.toString(),
            discount: discount.toString(),
        };
        void Axios.post(`/admin/applications/${application.applicationId}/funding_provider`, payload)
            .then(onSave)
            .finally(() => setLoading(false));
    };
    return (_jsx(Modal, Object.assign({ title: t("application.overview.funding-provider-and-revenue-card-title"), open: true, okText: t("common.save"), okButtonProps: { disabled: loading, htmlType: "submit", form: FORM_ID }, onCancel: onClose, maskClosable: false }, { children: _jsx(Spin, Object.assign({ spinning: loading }, { children: _jsxs(Form, Object.assign({ id: FORM_ID, form: form, style: { margin: "40px 0" }, onChange: debouncedRecalculateTotal, onFinish: handleSave, initialValues: initialValues }, { children: [_jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(LabelCol, { children: _jsx(Typography.Text, { children: t("application.overview.funding-provider") }) }), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(FormItem, Object.assign({ name: "funding_provider", rules: [requiredRule] }, { children: _jsx(Select, { options: fundingProviders.map((provider) => ({
                                            value: provider.funding_provider_id,
                                            label: provider.funding_provider_name,
                                        })), onChange: handleProviderChange }) })) }))] })), _jsx(DataCardDivider, {}), _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(LabelCol, { children: _jsx(Typography.Text, { children: t("application.overview.revenue") }) }), _jsx(Col, { children: _jsx(FormItem, Object.assign({ name: "revenue", rules: [requiredRule] }, { children: _jsx(CurrencyInput, { disabled: inputDisabled, currency: currency || "", style: { width: 120 }, allowEmptyValue: true }) })) })] })), _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(LabelCol, { children: _jsx(Typography.Text, { children: t("application.overview.discount") }) }), _jsx(Col, { children: _jsx(FormItem, Object.assign({ name: "discount", rules: [requiredRule] }, { children: _jsx(CurrencyInput, { disabled: inputDisabled, currency: currency || "", style: { width: 120 }, allowEmptyValue: true }) })) })] })), _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(LabelCol, { children: _jsx(Typography.Text, { children: t("application.overview.total") }) }), _jsx(Col, { children: _jsx(FormItem, Object.assign({ name: "total" }, { children: _jsx(CurrencyInput, { disabled: true, currency: currency || "", style: { width: 120 }, allowEmptyValue: true }) })) })] }))] })) })) })));
};
export default FundingProviderModal;
