import { useEffect, useMemo, useState } from "react";

import {
	CheckCircleOutlined,
	DownOutlined,
	ExclamationCircleOutlined,
	FileOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import {
	DatePicker as BaseDatePicker,
	Button,
	Col,
	Dropdown,
	Form,
	Modal,
	Row,
	Tag,
	Tooltip,
	Typography,
	theme,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import {
	Admin,
	DocumentType,
	EntityKind,
	OcrFileDocumentType,
	OcrFilePeriodType,
	OcrFileResponse,
} from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import {
	OcrDoc,
	OcrDocumentStatus,
	finalStates,
	scanningStates,
} from "@teylor-tools/utils/fin-cr/fincr.types";

import { useTranslations } from "../translations/translations";
import FinancialsIssues from "./FinancialsIssues";

const { Text } = Typography;

const DocRow = styled.div`
	padding: 20px 24px;
	margin: 0 !important;

	&:hover {
		background-color: #fafafa;
	}
`;

const FilenameCol = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 8px;
`;

const FileIcon = styled(FileOutlined)`
	color: ${({ theme }) => theme.colorPrimary};
`;

const DataText = styled(Text)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	word-break: break-all !important;
`;

const FormItem = styled(Form.Item)`
	margin-bottom: 0;

	.ant-form-item-control-input {
		min-height: 22px;
	}
`;

const DatePicker = styled(BaseDatePicker)`
	height: 22px;

	.ant-picker-header {
		display: none;
	}
`;

const ActionsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
`;

const IssueTag = styled(Tag)`
	cursor: pointer;
`;

const LinkButton = styled(Button)`
	padding: 0;
`;

enum DownloadFormats {
	json = "json",
	xlsx = "xlsx",
}

const getStatusIcon = (isScanning: boolean, errorColor: string, status?: OcrDocumentStatus) => {
	if (isScanning) return <LoadingOutlined />;

	if (!status) return <CheckCircleOutlined style={{ color: "gray" }} />;

	switch (status) {
		case "error":
		case "request_failed":
			return <ExclamationCircleOutlined style={{ color: errorColor }} />;
		case "upload_pending":
		case "scanning":
			return <LoadingOutlined />;
		case "completed":
			return <CheckCircleOutlined style={{ color: "green" }} />;
	}
};

interface PickersForm {
	financials_date: Dayjs;
}

export interface BaseFinCRDocumentRowProps {
	getDocs: (showIssuesError?: boolean) => void;
	isEditable: boolean;
	download: (docId: string, format: string) => void;
	scan: (
		payload: Admin.StartOcrFileScan.RequestBody
	) => Promise<Admin.StartOcrFileScan.ResponseBody>;
	rescan: (docId: string) => Promise<void>;
	save: ({}: {
		financialsDate: string;
		docId: string;
		entityKind: EntityKind;
		entityId: string;
	}) => Promise<Admin.ApplicationsDocumentsPartialUpdate.ResponseBody>;
	pollStatus: (docId: string) => Promise<Admin.GetOcrFileInfo.ResponseBody | undefined>;
}

interface FinCRDocumentRowProps extends BaseFinCRDocumentRowProps {
	doc: OcrDoc;
}

const FinCRDocumentRow = ({
	doc,
	getDocs,
	isEditable,
	download,
	scan,
	rescan,
	save,
	pollStatus,
}: FinCRDocumentRowProps) => {
	const t = useTranslations();
	const { token } = theme.useToken();
	const [form] = Form.useForm<PickersForm>();
	const [isInEditMode, setIsInEditMode] = useState(false);
	const [showIssuesModal, setShowIssuesModal] = useState(false);
	const [isScanning, setIsScanning] = useState(false);
	const [ocrResponse, setOcrResponse] = useState<OcrFileResponse>();
	const { date } = useFormatter();

	const isInterim = useMemo(() => {
		return !!(
			doc.document_type &&
			[
				DocumentType.CompanyDocBwa,
				DocumentType.CompanyDocBwaandsusa,
				DocumentType.CompanyDocSusa,
				// @ts-ignore dynamic types
			].includes(doc.document_type)
		);
	}, [doc.document_type]);

	const disableEditMode = () => {
		form.resetFields();
		setIsInEditMode(false);
	};

	const enableEditMode = () => {
		form.setFieldsValue({
			financials_date: doc.as_of_date ? dayjs(doc.as_of_date) : undefined,
		});
		setIsInEditMode(true);
	};

	const renderAdminActions = () => {
		const downloadItems = [
			{
				label: t.fincr.json,
				key: DownloadFormats.json,
			},
			{
				label: t.fincr.xlsx,
				key: DownloadFormats.xlsx,
			},
		];

		return isInEditMode ? (
			<ActionsWrapper>
				<LinkButton type="link" onClick={disableEditMode}>
					{t.cancel}
				</LinkButton>
				<LinkButton type="link" onClick={form.submit}>
					{t.save}
				</LinkButton>
			</ActionsWrapper>
		) : (
			<ActionsWrapper>
				{!!doc.warnings?.length && !isScanning && (
					<IssueTag onClick={() => setShowIssuesModal(true)} color="error">
						{t.fincr.issues}
					</IssueTag>
				)}
				{(doc.status === "completed" || doc.status === "error") && (
					<Dropdown
						disabled={isScanning}
						menu={{
							items: downloadItems,
							onClick: ({ key }) => {
								handleDownload(doc.document_id, key as DownloadFormats);
							},
						}}
					>
						<LinkButton type="link">
							{t.fincr.download}
							<DownOutlined />
						</LinkButton>
					</Dropdown>
				)}
				<LinkButton type="link" disabled={isScanning || !isEditable} onClick={enableEditMode}>
					{t.edit}
				</LinkButton>
				{doc.status && finalStates.includes(doc.status) ? (
					<LinkButton type="link" disabled={isScanning || !isEditable} onClick={handleRescan}>
						{t.fincr.rescan}
					</LinkButton>
				) : (
					<Button
						type="primary"
						ghost
						size="small"
						disabled={!doc.as_of_date || isScanning}
						onClick={handleScan}
					>
						{t.fincr.scan}
					</Button>
				)}
			</ActionsWrapper>
		);
	};

	const handleDownload = (docId: string, format: DownloadFormats) => download(docId, format);

	const handleScan = () => {
		if (!doc.as_of_date) return;

		setIsScanning(true);

		void scan({
			document_id: doc.document_id,
			period_type: isInterim ? OcrFilePeriodType.Interim : OcrFilePeriodType.FullYear,
			document_type: isInterim
				? OcrFileDocumentType.DeBwaSusa
				: OcrFileDocumentType.AnnualStatement,
		}).then((data) => {
			if (!finalStates.includes(data.status)) {
				pollFileStatus();
			}
		});
	};

	const handleRescan = () => {
		Modal.confirm({
			icon: <ExclamationCircleOutlined style={{ color: token.colorError }} />,
			title: t.fincr.rescanModalTitle,
			content: t.fincr.rescanModalContent,
			centered: true,
			cancelText: t.cancel,
			okText: t.fincr.rescan,
			okButtonProps: { style: { backgroundColor: token.colorError } },
			maskClosable: true,
			onOk: () => {
				setIsScanning(true);
				void rescan(doc.document_id).then(() => {
					pollFileStatus();
				});
			},
		});
	};

	const handleSave = ({ financials_date }: PickersForm) => {
		save({
			financialsDate: financials_date.format(rawDateFormat),
			docId: doc.document_id,
			entityKind: doc.entity_kind,
			entityId: doc.entity_id,
		})
			.then(() => {
				getDocs(false);
				disableEditMode();
			})
			.finally(disableEditMode);
	};

	const pollFileStatus = (): void => {
		pollStatus(doc.document_id).then((data) => setOcrResponse(data));
	};

	useEffect(() => {
		doc.status && setIsScanning(scanningStates.includes(doc.status));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doc]);

	useEffect(() => {
		if (ocrResponse?.status && finalStates.includes(ocrResponse.status)) {
			return getDocs();
		}

		let pollingTimer: ReturnType<typeof setTimeout>;

		if (
			(ocrResponse?.status && scanningStates.includes(ocrResponse.status)) ||
			(doc.status && scanningStates.includes(doc.status))
		) {
			pollingTimer = setTimeout(pollFileStatus, 5000);
		}

		return () => clearTimeout(pollingTimer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ocrResponse]);

	return (
		<>
			<DocRow key={doc.document_id}>
				<Row gutter={16} justify="space-between" align="middle">
					<Col span={16}>
						<Form
							form={form}
							onFinish={handleSave}
							// initialValues={{ financials_date: doc.as_of_date || '' }}
						>
							<Row gutter={[4, 8]} align="middle">
								<FilenameCol>
									{getStatusIcon(isScanning, token.colorError, doc.status)}
									<FileIcon />
									<Tooltip title={doc.document_name}>
										<DataText>{doc.document_name}</DataText>
									</Tooltip>
								</FilenameCol>
								<Col>
									<Tag style={{ marginRight: 4 }}>
										{isInterim ? t.fincr.interim : t.fincr.annual}
									</Tag>
								</Col>
								{isInEditMode ? (
									<Col>
										<FormItem name="financials_date" rules={[{ required: true, message: "" }]}>
											<DatePicker format={dayMonthYear} style={{ minWidth: 80 }} />
										</FormItem>
									</Col>
								) : (
									<Col>{doc.as_of_date && <Tag>{date(doc.as_of_date)}</Tag>}</Col>
								)}
							</Row>
						</Form>
					</Col>
					<Col span={8}>{renderAdminActions()}</Col>
				</Row>
			</DocRow>

			{!!doc.warnings?.length && (
				<Modal
					title={t.fincr.issuesModalTitle}
					open={showIssuesModal}
					closable
					cancelText={t.close}
					onCancel={() => setShowIssuesModal(false)}
					okText={t.fincr.rescan}
					onOk={() => {
						handleRescan();
						setShowIssuesModal(false);
					}}
				>
					<FinancialsIssues warnings={doc.warnings} />
				</Modal>
			)}
		</>
	);
};

export default FinCRDocumentRow;
