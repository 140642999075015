import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { BorderlessTableOutlined, CalendarOutlined, CloseOutlined, FileDoneOutlined, MailOutlined, SettingOutlined, } from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Space, Spin, Tag, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import Preview, { Cell, Heading, StyledListRow } from "@ui/preview/Preview";
import TimePassed from "@ui/time-passed/TimePassed";
import { Routes } from "src/Routes";
import ApplicationStatus from "src/components/ui/statuses/ApplicationStatus";
import { Axios } from "src/utils/Axios";
import UsersMenu from "./UsersMenu";
const StyledLink = styled(Link) `
	all: unset;
	cursor: pointer;

	&:hover {
		color: unset;
	}
`;
const UserPreview = ({ user, onClose }) => {
    const [applicationsLoading, setApplicationLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [companiesLoading, setCompaniesLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const { currency: formatCurrency } = useFormatter();
    const { t } = useTranslation();
    useEffect(() => {
        if (user === null || user === void 0 ? void 0 : user.user_id) {
            Axios.get(`/admin/users/${user.user_id}/applications`)
                .then(({ data }) => setApplications(data.result || []), (error) => {
                void Axios.error(error, t("users.preview.error-applications-fetch"));
            })
                .finally(() => {
                setApplicationLoading(false);
            });
            Axios.get(`/admin/users/${user.user_id}/companies`)
                .then(({ data }) => {
                setCompanies(data.result);
            }, (error) => {
                void Axios.error(error, t("users.preview.error-companies-fetch"));
            })
                .finally(() => {
                setCompaniesLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user === null || user === void 0 ? void 0 : user.user_id]);
    const lineData = useMemo(() => {
        if (!user)
            return [];
        return [
            {
                icon: _jsx(MailOutlined, { size: 12 }),
                label: t("users.email"),
                value: (_jsxs(_Fragment, { children: [_jsx(Typography.Text, Object.assign({ style: { paddingRight: 8 }, ellipsis: { tooltip: user.email } }, { children: user.email })), user.emailVerified ? (_jsx(Tag, { children: String(t("users.verified")) })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: String(t("users.not-verified")) })))] })),
            },
            {
                icon: _jsx(CalendarOutlined, {}),
                label: t("application.created"),
                value: _jsx(TimePassed, { date: user.created || "" }),
            },
            {
                icon: _jsx(FileDoneOutlined, {}),
                label: t("users.preview.terms"),
                value: user.hasAcceptedTermsAndConditions ? (_jsx(Tag, { children: String(t("users.accepted")) })) : (_jsx(Tag, Object.assign({ color: "red" }, { children: String(t("users.not-accepted")) }))),
            },
            {
                icon: _jsx(BorderlessTableOutlined, {}),
                label: t("common.id"),
                value: (_jsx(Typography.Text, Object.assign({ copyable: { tooltips: false }, ellipsis: { tooltip: user.user_id } }, { children: user.user_id }))),
            },
        ];
    }, [user, t]);
    return (_jsx(Preview, Object.assign({ entityId: (user === null || user === void 0 ? void 0 : user.user_id) || "", visible: !!user, header: user && (_jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsx(Col, { children: _jsx(Typography.Title, Object.assign({ level: 5, ellipsis: { tooltip: user.email }, style: { margin: 0 } }, { children: user.email })) }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Dropdown, Object.assign({ overlay: _jsx(UsersMenu, { user_id: user.user_id }), placement: "bottomLeft" }, { children: _jsx(Button, { icon: _jsx(SettingOutlined, {}), "data-cy": "users-option-button" }) })), _jsx(Button, { onClick: onClose, icon: _jsx(CloseOutlined, {}), "data-cy": "users-close-button" })] }) })] }))), lineData: lineData }, { children: _jsxs(_Fragment, { children: [_jsxs(Cell, { children: [_jsx(Heading, { children: t("users.preview.applications", { amount: applications.length }) }), _jsx(Spin, Object.assign({ spinning: applicationsLoading }, { children: _jsx(Row, Object.assign({ gutter: [0, 8] }, { children: applications.map(({ application_id, company_name, amount, status, currency }) => {
                                    return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(StyledLink, Object.assign({ to: generatePath(Routes.Application, { applicationId: application_id }), target: "_blank" }, { children: _jsxs(Row, Object.assign({ gutter: 8, align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "1" }, { children: company_name })), _jsx(Col, { children: amount ? formatCurrency(amount, { currency }) : "-" }), _jsx(Col, { children: _jsx(ApplicationStatus, { label: t(`application.status.${status}`), value: status, short: true }) })] })) })) }), application_id));
                                }) })) }))] }), _jsxs(Cell, { children: [_jsx(Heading, { children: t("users.preview.companies", { amount: companies.length }) }), _jsx(Spin, Object.assign({ spinning: companiesLoading }, { children: companies.map(({ company_name, company_id }) => {
                                return (_jsx(StyledListRow, { children: _jsx(Col, { children: _jsx(StyledLink, Object.assign({ to: generatePath(Routes.Company, { companyId: company_id }), target: "_blank" }, { children: company_name })) }) }, company_id));
                            }) }))] })] }) })));
};
export default UserPreview;
