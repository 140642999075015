import { ComponentPropsWithoutRef, useState } from "react";

import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Alert, Button, Card, Modal, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import {
	IndustryCodeUpdateTypeEnum,
	IndustryCodesCreateRequest,
	IndustryCodesResponse,
} from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";

import IndustryCodesEditModal from "../industry-codes-edit-modal/CompanyIndustryCodesModal";
import { useTranslations } from "../translations/translations";

export const DataCard = styled(Card)`
	margin-bottom: 24px;
	min-width: 420px;
	max-width: 720px;
`;

interface ManualUpdateParams {
	company_industry_code_set_id: string;
	approve: boolean;
}
interface Props extends ComponentPropsWithoutRef<"div"> {
	industryCodes?: IndustryCodesResponse;
	loading?: boolean;
	showEditButton?: boolean;
	onSave?: (values: IndustryCodesCreateRequest) => Promise<void>;
	withManualApprovalWarning?: boolean;
	onHandleManualUpdate?: ({ company_industry_code_set_id, approve }: ManualUpdateParams) => void;
}

const IndustryCodesCard = ({
	industryCodes,
	loading,
	showEditButton,
	onSave,
	withManualApprovalWarning = false,
	onHandleManualUpdate,
	...props
}: Props) => {
	const [modal, contextHolder] = Modal.useModal();

	const { decimalToPercent } = useFormatter();
	const [editIndustryCodes, setEditIndustryCodes] = useState(false);
	const t = useTranslations();

	const columns = [
		{
			dataIndex: "code",
			title: t.industryCodes.industryCode,
			width: 140,
		},
		{
			dataIndex: "description",
			title: t.industryCodes.description,
		},
		{
			dataIndex: "weight",
			title: t.industryCodes.weight,
			render: (weight?: number) => {
				return weight
					? decimalToPercent({ percent: weight, decimalPlaces: 0, showPercentSymbol: true })
					: "-";
			},
			width: 100,
		},
	];

	const confirm = () => {
		modal.confirm({
			title: t.industryCodes.modalApproveTitle,
			icon: <ExclamationCircleOutlined />,
			content: t.industryCodes.modalApproveDescription,
			okText: t.approve,
			cancelText: t.cancel,
			onOk: () =>
				onHandleManualUpdate &&
				industryCodes?.company_industry_code_set_id &&
				onHandleManualUpdate({
					company_industry_code_set_id: industryCodes.company_industry_code_set_id,
					approve: true,
				}),
		});
	};

	return (
		<>
			<DataCard
				title={t.industryCodes.industryCodes}
				loading={loading}
				extra={
					showEditButton && (
						<Button type="link" onClick={() => setEditIndustryCodes(true)}>
							<EditOutlined />
						</Button>
					)
				}
				{...props}
			>
				{withManualApprovalWarning && (
					<>
						{industryCodes?.approved_by && industryCodes?.approved_at && (
							<Alert
								message={t.industryCodes.approvedBy({
									approvedBy: industryCodes.approved_by,
									approvedAt: dayjs(industryCodes.approved_at).format(dayMonthYear),
								})}
								type="warning"
								style={{ marginBottom: 16 }}
								showIcon
							/>
						)}
						{industryCodes?.update_type === IndustryCodeUpdateTypeEnum.Manual &&
							!industryCodes?.approved_by && (
								<Alert
									message={t.industryCodes.updatedManually}
									type="warning"
									style={{ marginBottom: 16 }}
									action={
										<Space>
											<Button onClick={confirm}>
												<CheckOutlined />
											</Button>
											<Button
												onClick={() =>
													onHandleManualUpdate &&
													onHandleManualUpdate({
														company_industry_code_set_id:
															industryCodes.company_industry_code_set_id,
														approve: false,
													})
												}
											>
												<CloseOutlined />
											</Button>
										</Space>
									}
									showIcon
								/>
							)}
					</>
				)}

				<Table
					columns={columns}
					rowKey="company_industry_code_id"
					dataSource={industryCodes?.codes}
					pagination={false}
				/>
				<Space size="large" style={{ marginTop: 16 }}>
					<Typography.Text type="secondary">
						{t.industryCodes.lastUpdatedOn(
							industryCodes?.created_at ? dayjs(industryCodes.created_at).format(dayMonthYear) : "-"
						)}
					</Typography.Text>

					<Typography.Text type="secondary">
						{t.industryCodes.source(industryCodes?.source || "-")}
					</Typography.Text>

					<Typography.Text type="secondary">
						{t.industryCodes.codeType(
							industryCodes?.kind ? t.industryCodesEditModal.codeEnum[industryCodes?.kind] : "-"
						)}
					</Typography.Text>
				</Space>
			</DataCard>
			{editIndustryCodes && onSave && (
				<IndustryCodesEditModal
					industryCodes={industryCodes}
					onClose={() => setEditIndustryCodes(false)}
					onSave={onSave}
				/>
			)}
			{contextHolder}
		</>
	);
};

export default IndustryCodesCard;
