import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import Notes from "src/components/Notes";
import { Axios } from "src/utils/Axios";
const CompanyNotes = ({ companyId, showXLatest }) => {
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState([]);
    const getNotes = useCallback(() => {
        setLoading(true);
        Axios.get(`/admin/companies/${companyId}/notes`, Object.assign({ include_users: true }, (showXLatest && { page: 1, page_size: showXLatest })))
            .then(({ data }) => {
            var _a;
            setNotes((_a = data === null || data === void 0 ? void 0 : data.result) !== null && _a !== void 0 ? _a : []);
        })
            .catch((error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    }, [companyId, showXLatest]);
    useEffect(() => {
        getNotes();
    }, [getNotes]);
    const handleAddNote = ({ newNote, noteForm }) => {
        if (!newNote)
            return;
        setLoading(true);
        Axios.post(`/admin/companies/${companyId}/notes`, {
            message: newNote,
        })
            .then(() => {
            getNotes();
            noteForm.resetFields();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    return _jsx(Notes, { notes: notes, loading: loading, handleAddNote: handleAddNote });
};
export default CompanyNotes;
