import { generatePath } from "react-router-dom";
import { TaskPriority, TaskStatus } from "@teylor-tools/Api";
import { Routes } from "src/Routes";
export const TaskPriorities = Object.values(TaskPriority);
export const TaskStatuses = Object.values(TaskStatus);
export const getTaskEntityPath = (entityType, entityId) => {
    if (!entityType || !entityId)
        return "";
    switch (entityType) {
        case "application":
            return generatePath(Routes.Application, {
                applicationId: entityId,
            });
        case "loan":
            return generatePath(Routes.Loan, {
                loanId: entityId,
            });
        case "company":
            return generatePath(Routes.Company, {
                companyId: entityId,
            });
        default:
            return "";
    }
};
