import { Button as AntdButton, Divider as AntdDivider, Menu as AntdMenu } from "antd";
import styled, { css } from "styled-components";
export const MenuWrapper = styled.div `
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 100%;
`;
export const Collapse = styled.div `
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: ${({ isCollapsed }) => (isCollapsed ? "center" : "flex-start")};
`;
export const Divider = styled(AntdDivider) `
	margin: 20px 0;
`;
export const Button = styled(AntdButton) `
	color: ${({ theme }) => theme.colorPrimaryText};
`;
export const Menu = styled(AntdMenu) `
	flex-grow: 1;
	${({ inlineCollapsed }) => inlineCollapsed &&
    css `
			width: 54px !important;
		`}
`;
