import { Document, DocumentType, OcrFilePeriodType, OcrFileResponse, OcrWarning } from "../../Api";

export const finalStates: OcrDocumentStatus[] = ["error", "request_failed", "completed"];

export const scanningStates: OcrDocumentStatus[] = ["scanning", "upload_pending"];
export type OcrDocumentStatus = OcrFileResponse["status"];

export interface OcrDoc extends Document {
	period_type?: OcrFilePeriodType;
	financials_date?: string;
	status?: OcrDocumentStatus;
	fincr_file_id?: string;
	warnings?: OcrWarning[];
}

export const finCrTypes = [
	DocumentType.CompanyDocBwa,
	DocumentType.CompanyDocBwaandsusa,
	DocumentType.CompanyDocSusa,
	DocumentType.AnnualStatement20,
	DocumentType.AnnualStatement21,
	DocumentType.AnnualStatement22,
	DocumentType.AnnualStatement23,
	DocumentType.AnnualStatement24,
];
