import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PaperClipOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import styled from "styled-components";
const { Text, Title } = Typography;
const EmptyScreenWrapper = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 60px;
`;
const UploadBtn = styled(Button) `
	display: block;
	margin-inline: auto;
	margin-top: 24px;
`;
const DocumentsEmptyScreen = ({ customEmptyScreen, allowUpload, uploadRef, isSearchResult, }) => {
    const { t } = useTranslation();
    if (isSearchResult) {
        return (_jsx(EmptyScreenWrapper, { children: _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(SearchOutlined, { style: { fontSize: "42px", color: "#d9d9d9" } }), _jsx(Title, Object.assign({ level: 5 }, { children: t("documents.no-search-results-title") })), _jsx(Text, Object.assign({ type: "secondary", style: { marginTop: "8px" } }, { children: t("documents.no-search-results-undertitle") }))] })) }));
    }
    return (_jsx(EmptyScreenWrapper, { children: customEmptyScreen || (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(PaperClipOutlined, { style: { fontSize: "42px", color: "#d9d9d9" } }), _jsx(Title, Object.assign({ level: 5 }, { children: t("documents.empty") })), _jsx(Text, Object.assign({ type: "secondary", style: { marginTop: "8px" } }, { children: t("documents.drag-drop") })), _jsx(UploadBtn, Object.assign({ onClick: () => { var _a; return (_a = uploadRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, type: "primary", icon: _jsx(PlusOutlined, { style: { fontSize: "16px" } }), disabled: !allowUpload }, { children: t("documents.upload-documents") }))] }))) }));
};
export default DocumentsEmptyScreen;
