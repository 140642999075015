var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CommentOutlined, FileProtectOutlined, HistoryOutlined, InfoCircleOutlined, LeftOutlined, } from "@ant-design/icons";
import { Badge, Layout, Menu, Spin, Tag } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import { TaskRelatedEntityType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import MainHeader from "@ui/main-header/MainHeader";
import { Routes } from "src/Routes";
import LoanStatus from "src/components/ui/statuses/LoanStatus";
import { LayoutContext, SideActions } from "src/layouts/LoanLayout/loan.types";
import { useLastMainRoute } from "src/layouts/MenuLayout/MenuLayout";
import { Axios } from "src/utils/Axios";
import ErrorBoundaryWrapper from "../ErrorBoundaryWrapper";
import LoanMenu from "./modules/LoanMenu";
import LoanOutlet from "./modules/LoanOutlet";
import MoveLoan from "./modules/MoveLoan";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const NoMarginTag = styled(Tag) `
	margin: 0;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const LoanLayout = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const { loanId } = useParams();
    const navigate = useNavigate();
    const { currency } = useFormatter();
    const [activeMenu, setActiveMenu] = useState();
    const [loading, setLoading] = useState(true);
    const [loan, setLoan] = useState();
    const { lastMainRoute } = useLastMainRoute();
    const [tasks, setTasks] = useState([]);
    const { tasksEnabled } = useSelector((state) => state.configState);
    const [productConfig, setProductConfig] = useState();
    const updateLoan = (params) => {
        if (params) {
            return Axios.patch(`/admin/loans/${loanId}`, params).then(() => {
                void getLoan();
            }, (err) => {
                void Axios.error(err);
            });
        }
        else {
            return getLoan();
        }
    };
    const getLoan = () => {
        return Axios.get(`/admin/loans/${loanId}`).then(({ data }) => {
            setLoan(data);
        }, (err) => {
            void Axios.error(err);
        });
    };
    const getTasks = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!tasksEnabled)
            return;
        return Axios.get("admin/tasks", {
            related_entity_id: loanId,
            related_entity_type: TaskRelatedEntityType.Loan,
        }).then(({ data }) => setTasks(data.result || []), (err) => void Axios.error(err));
    });
    const sideMenuItems = useMemo(() => {
        const collection = [
            {
                icon: _jsx(InfoCircleOutlined, {}),
                key: SideActions.loanInformation,
            },
            ...(tasksEnabled
                ? [
                    {
                        icon: (_jsx(Badge, Object.assign({ count: tasks.length, size: "small", offset: [-4, 24] }, { children: _jsx(FileProtectOutlined, {}) }))),
                        key: SideActions.tasks,
                    },
                ]
                : []),
            {
                icon: _jsx(CommentOutlined, {}),
                key: SideActions.sharedNotes,
            },
            {
                icon: _jsx(HistoryOutlined, {}),
                key: SideActions.activityLog,
            },
        ];
        return collection.map(({ icon, key }) => {
            return {
                icon,
                key,
            };
        });
    }, [tasks, tasksEnabled]);
    const handleSiderClick = (key) => {
        if (!activeMenu) {
            return setActiveMenu(key);
        }
        if (key === SideActions.tasks &&
            (activeMenu === SideActions.createTask || activeMenu === SideActions.taskDetails)) {
            return setActiveMenu(undefined);
        }
        setActiveMenu(key === activeMenu ? undefined : key);
    };
    const getProductConfig = (loan) => {
        if (!(loan === null || loan === void 0 ? void 0 : loan.product))
            return;
        return Axios.get(`/admin/products/${loan.product.product_id}/config/${loan.product.product_config_id}`).then(({ data }) => setProductConfig(data), (err) => void Axios.error(err));
    };
    useEffect(() => {
        setLoading(true);
        Promise.all([getLoan(), tasksEnabled ? getTasks() : Promise.resolve()]).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanId]);
    useEffect(() => {
        if (!loan)
            return;
        void getProductConfig(loan);
    }, [loan]);
    return (_jsx(_Fragment, { children: _jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { ref: ref, title: _jsx(Spin, Object.assign({ spinning: loading }, { children: loan && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                        navigate(lastMainRoute || Routes.Loans);
                                    } }), _jsx(_Fragment, { children: loan.company.companyName }), _jsx(NoMarginTag, { children: currency(loan.amount, { currency: loan.currency }) }), _jsx(LoanStatus, { label: t(`loans.status.${loan.loan_status}`), value: loan.loan_status })] })) })), extra: loan && (_jsx(MoveLoan, { loanId: loan.loan_id, loanStatus: loan.loan_status, update: updateLoan })) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [loan && _jsx(LoanMenu, { loanId: loan.loan_id, loanStatus: loan.loan_status }), _jsxs(ErrorBoundaryWrapper, { children: [_jsx(Content, { children: _jsx(LayoutContext.Provider, Object.assign({ value: {
                                            activeMenu,
                                            setActiveMenu,
                                        } }, { children: loan ? (_jsx(LoanOutlet, { loan: loan, updateLoan: updateLoan, activeMenu: activeMenu, setActiveMenu: setActiveMenu, tasks: tasks, updateTasks: getTasks, productConfig: productConfig })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })) }), _jsx(Sider, Object.assign({ theme: "light", width: 54, style: { borderLeft: "solid 1px #F0F0F0" }, onCollapse: () => {
                                        setActiveMenu(undefined);
                                    } }, { children: _jsx(Menu, { inlineCollapsed: true, theme: "light", mode: "inline", onClick: ({ key }) => handleSiderClick(key), items: sideMenuItems, selectedKeys: activeMenu ? [activeMenu] : [] }) }))] })] }))] })) }));
};
export default LoanLayout;
