import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCard, DataCardDivider, DataCardItem } from "@ui/data-display/DataCard";
import { useCompany } from "src/layouts/CompanyLayout/CompanyTypes";
const CompanyCreditRating = () => {
    const { t } = useTranslation();
    const { date, decimalToPercent } = useFormatter();
    const { company } = useCompany();
    return (_jsxs(DataCard, Object.assign({ title: t("company.credit-rating") }, { children: [_jsx(DataCardItem, { label: t("company.credit-rating.crefo-score"), value: company.crefo_score }), _jsx(DataCardItem, { label: t("company.credit-rating.crefo-pd"), value: company.crefo_pd &&
                    decimalToPercent({ percent: company.crefo_pd, showPercentSymbol: true }) }), _jsx(DataCardItem, { label: t("company.credit-rating.crefo-date"), value: company.crefo_date && date(company.crefo_date) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.credit-rating.latest-rating"), value: company.latest_rating }), _jsx(DataCardItem, { label: t("company.credit-rating.scorecard"), value: company.scoring_config_name }), _jsx(DataCardItem, { label: t("company.credit-rating.rating-date"), value: company.rating_date && date(company.rating_date) })] })));
};
export default CompanyCreditRating;
