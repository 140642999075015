var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { App, Button, Spin, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import { DocumentType, RelatedEntityKind } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import { DataCardItemEditable } from "src/components/DataCard";
import { useApplication } from "src/layouts/ApplicationLayout/application.types";
import { Axios } from "src/utils/Axios";
const LinkButton = styled(Button) `
	padding: 0;
	height: 0;
`;
const NetAssetValue = ({ signatoryId, netAssetValue, refetchRepresentatives, documentsPath, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { currency: currencyFormat } = useFormatter();
    const [hasStatementsOfAssets, setHasStatementsOfAssets] = useState(false);
    const { application: { applicationId, currency }, } = useApplication();
    const updateNetAssetValue = (value) => {
        setLoading(true);
        void Axios.patch(`/admin/signatories/${signatoryId}/net_asset_value`, { net_asset_value: value })
            .then(refetchRepresentatives, (err) => Axios.error(err))
            .finally(() => setLoading(false));
    };
    const getSignatoryDocuments = useCallback(() => {
        setLoading(true);
        void Axios.get(`admin/applications/${applicationId}/documents`, {
            document_type: DocumentType.StatementOfAssets,
        })
            .then(({ data }) => {
            const signatoryDoc = data.result.find((doc) => doc.related_entity_id === signatoryId);
            setHasStatementsOfAssets(!!signatoryDoc);
        }, (err) => Axios.error(err))
            .finally(() => setLoading(false));
    }, [applicationId, signatoryId]);
    useEffect(() => {
        void getSignatoryDocuments();
    }, [getSignatoryDocuments]);
    const getUploadInfo = (file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!applicationId)
            return;
        const uploadResponse = yield Axios.post(`/admin/applications/${applicationId}/documents`, {
            file_name: file.name,
            document_type: DocumentType.StatementOfAssets,
            related_entity_kind: RelatedEntityKind.Signatory,
            related_entity_id: signatoryId,
        });
        return uploadResponse === null || uploadResponse === void 0 ? void 0 : uploadResponse.data;
    });
    const uploadFile = (file, url) => __awaiter(void 0, void 0, void 0, function* () {
        if (!url)
            return;
        yield Axios.put(url, file, {
            headers: {
                "Content-Type": file.type,
                "x-ms-blob-type": "BlockBlob",
            },
        });
    });
    const updateFileStatus = ({ documentId, isSuccessful }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!documentId)
            return;
        yield Axios.patch(`/admin/applications/${applicationId}/documents/${documentId}/upload_success`, {
            is_upload_successful: isSuccessful,
        });
    });
    const updateFileVisibility = (documentId) => {
        if (!documentId)
            return;
        return Axios.patch(`/admin/applications/${applicationId}/documents/${documentId}`, {
            is_client_visible: true,
        });
    };
    const uploadNetAssetFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const uploadInfo = yield getUploadInfo(file);
            const { document_id, upload_url } = uploadInfo || {};
            try {
                yield uploadFile(file, upload_url);
                yield updateFileStatus({
                    documentId: document_id,
                    isSuccessful: true,
                });
                yield updateFileVisibility(document_id);
                void getSignatoryDocuments();
                void message.success(t("application.company-representatives.file-upload-success"));
            }
            catch (err) {
                yield updateFileStatus({
                    documentId: document_id,
                    isSuccessful: false,
                });
                void Axios.error(err, t("application.company-representatives.file-upload-fail"));
            }
        }
        catch (err) {
            void Axios.error(err, t("application.company-representatives.file-upload-fail"));
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsx(Spin, Object.assign({ spinning: loading }, { children: _jsx(DataCardItemEditable, { label: t("application.company-representatives.net-asset-value"), displayedValue: netAssetValue ? currencyFormat(netAssetValue, { currency }) : "-", formItemValue: netAssetValue, onChange: (v) => updateNetAssetValue(v), customFormItem: (_a) => {
                var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(CurrencyInput, Object.assign({ currency: currency }, inputProps)) })));
            }, extra: hasStatementsOfAssets ? (_jsx(LinkButton, Object.assign({ type: "link", onClick: () => navigate(documentsPath) }, { children: t("common.view") }))) : (_jsx(Upload, Object.assign({ customRequest: ({ file }) => {
                    void uploadNetAssetFile(file);
                }, multiple: true, fileList: [] }, { children: _jsx(LinkButton, Object.assign({ type: "link" }, { children: t("common.upload") })) }))) }) })));
};
export default NetAssetValue;
