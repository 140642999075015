export const downloadFileFromUrl = (url: string, fileName: string, newTab = false): void => {
	const linkSource = url;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;

	if (!url.includes(window.location.origin) || newTab) {
		// if the file's url doesn't match the website's origin
		// the download API is disabled, so open the file in the new
		// tab instead to create a fallback
		downloadLink.target = "_blank";
	}

	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};
