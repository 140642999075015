import { Empty } from "antd";
import dayjs from "dayjs";

import { CompanyKPIs } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";

import { useTranslations } from "../../translations/translations";
import { Table } from "../table/Table";
import { getPropertyName, roundStringNumber } from "../utils";

interface Props {
	title: string;
	data?: CompanyKPIs[];
}

interface IDataSourceType {
	fieldName: string;
	[key: string]: string | JSX.Element;
}

type ValueType = string | { [key: string]: string };

const getYearName = (kpi: CompanyKPIs) => {
	const startYear = dayjs(kpi.start_date).get("year");
	const endYear = dayjs(kpi.end_date).get("year");

	return startYear === endYear ? startYear : `${startYear} - ${endYear}`;
};

const KpiData = ({ title, data = [] }: Props) => {
	const t = useTranslations();
	const { localizedNumber } = useFormatter();
	const getDataSource = () => {
		const dataSource: IDataSourceType[] = [];
		data.forEach((kpisEntry) => {
			Object.keys(kpisEntry.kpis).forEach((key) => {
				const value: ValueType = kpisEntry.kpis[key as keyof CompanyKPIs["kpis"]];
				const yearName = getYearName(kpisEntry);
				// because of how kpis are returned, it's either an object of objects or a plain object with strings

				if (typeof value !== "object") {
					const foundElementIdx = dataSource.findIndex((item) => item.fieldName === key);

					if (~foundElementIdx) {
						dataSource[foundElementIdx][yearName] = isNaN(Number(value))
							? value
							: roundStringNumber(value);
					} else {
						dataSource.push({
							fieldName: key,
							name: getPropertyName(key),
							[yearName]: isNaN(Number(value)) ? value : roundStringNumber(value),
							key: `${key}`,
						});
					}
				} else {
					const foundCategoryIndex = dataSource.findIndex(
						(item) => item.fieldName === `category-${key}`
					);

					// category descriptor row
					if (!~foundCategoryIndex) {
						dataSource.push({
							fieldName: `category-${key}`,
							name: <b>{getPropertyName(key)}</b>,
							key: `${key}`,
						});
					}

					// @ts-ignore take a look at it if you work on this file
					for (const prop in value) {
						// find the object if it exists add to it or push a new one.
						const foundElementIdx = dataSource.findIndex((item) => item.fieldName === prop);

						if (~foundElementIdx) {
							dataSource[foundElementIdx][yearName] = isNaN(Number(value[prop]))
								? value[prop]
								: roundStringNumber(value[prop]);
						} else {
							dataSource.push({
								fieldName: prop,
								name: getPropertyName(prop),
								[yearName]: isNaN(Number(value[prop]))
									? value[prop]
									: roundStringNumber(value[prop]),
								key: `${prop}`,
							});
						}
					}
				}
			});
		});

		return dataSource;
	};
	const getTableCols = () => {
		return [
			{
				title: `${title}`,
				dataIndex: "name",
				key: "name",
				width: "280px",
			},
			...data.map((kpisEntry) => {
				const columnHeader = getYearName(kpisEntry);
				return {
					title: columnHeader,
					dataIndex: columnHeader,
					key: columnHeader,
					width: "160px",
					render: (value: string) => {
						if (!value) return "";
						return <span>{localizedNumber({ value })}</span>;
					},
				};
			}),
			{
				dataIndex: "invisible",
			},
		];
	};

	if (!data.length) return <Empty description={t.scoring.kpiData.noData} />;

	return (
		<Table
			bordered
			scroll={{ y: `calc(100vh - 300px)` }}
			sticky
			pagination={false}
			dataSource={getDataSource()}
			columns={getTableCols()}
		/>
	);
};

export default KpiData;
