var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { App, Button, Drawer, Form, Input, Select, Space, Typography, theme } from "antd";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Axios } from "../../../utils/Axios";
const DrawerTitle = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const TEAM_GROUP_FORM_ID = "team_group_form";
const TeamGroupPreview = ({ group, open, getGroups, onClose }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { message, modal } = App.useApp();
    const [form] = Form.useForm();
    const { features } = useSelector((state) => state.configState);
    const taskAssigneeGroup = (_a = features.find((f) => f.name === FeatureName.Tasks)) === null || _a === void 0 ? void 0 : _a.value.task_bo_user_group_id;
    const requiredRule = { required: true, message: t("settings.field_required") };
    const { data: boUsersList, isPending: isLoadingBouUsers } = useQuery({
        queryKey: ["getBoUsers"],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.get(`/admin/bo_users?page_size=100`);
            return response.data;
        }),
    });
    const { isPending: isLoadingCreate, mutate: createGroup } = useMutation({
        mutationKey: ["createGroup"],
        mutationFn: (form) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.post(`/admin/bo_user_groups`, form);
            return response.data;
        }),
        onSuccess: () => {
            void message.success(t("settings.team.group.group_was_created"));
            onClose();
            void getGroups();
        },
        onError: (error) => {
            void Axios.error(error);
        },
    });
    const { isPending: isLoadingUpdate, mutate: updateGroup } = useMutation({
        mutationKey: ["updateGroup"],
        mutationFn: (form) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.patch(`/admin/bo_user_groups/${group === null || group === void 0 ? void 0 : group.bouser_group_id}`, Object.assign(Object.assign({}, form), { is_assignable: group === null || group === void 0 ? void 0 : group.is_assignable }));
            return response.data;
        }),
        onSuccess: () => {
            void message.success(t("settings.team.group.group_was_updated"));
            onClose();
            void getGroups();
        },
        onError: (error) => {
            void Axios.error(error);
        },
    });
    const { isPending: isLoadingDelete, mutate: deleteGroup } = useMutation({
        mutationKey: ["deleteGroup"],
        mutationFn: (id) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Axios.delete(`/admin/bo_user_groups/${id}`);
            return response.data;
        }),
        onSuccess: () => {
            void message.success(t("settings.team.group.group_was_deleted"));
            onClose();
            void getGroups();
        },
        onError: (error) => {
            void Axios.error(error);
        },
    });
    const handleDeleteGroup = () => {
        if (!(group === null || group === void 0 ? void 0 : group.bouser_group_id))
            return;
        deleteGroup(group.bouser_group_id);
    };
    const confirmDelete = () => {
        void modal.confirm({
            title: t("settings.team.groups.delete_group_modal_title"),
            icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }),
            content: t("settings.team.groups.delete_group_modal_message"),
            okText: t("common.delete"),
            okButtonProps: { danger: true },
            onOk: handleDeleteGroup,
        });
    };
    const onSave = (values) => {
        if (!(group === null || group === void 0 ? void 0 : group.bouser_group_id)) {
            createGroup(values);
        }
        else {
            updateGroup(values);
        }
    };
    useEffect(() => {
        var _a;
        form.setFieldsValue({
            group_name: (group === null || group === void 0 ? void 0 : group.group_name) || "",
            email: (group === null || group === void 0 ? void 0 : group.email) || "",
            description: (group === null || group === void 0 ? void 0 : group.description) || "",
            bo_user_ids: ((_a = group === null || group === void 0 ? void 0 : group.bo_users) === null || _a === void 0 ? void 0 : _a.map((user) => user.id)) || [],
        });
    }, [group, form]);
    return (_jsx(_Fragment, { children: _jsxs(Drawer, Object.assign({ destroyOnClose: true, title: _jsxs(DrawerTitle, { children: [_jsx(Typography.Text, Object.assign({ style: { fontSize: 16 } }, { children: (group === null || group === void 0 ? void 0 : group.group_name) || t("settings.team.groups.name") })), _jsx(Space, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", form: TEAM_GROUP_FORM_ID, loading: isLoadingCreate || isLoadingUpdate }, { children: t("common.save") })) })] }), closeIcon: _jsx(LeftOutlined, {}), placement: "right", onClose: onClose, open: open, size: "large" }, { children: [_jsxs(Form, Object.assign({ form: form, id: TEAM_GROUP_FORM_ID, layout: "vertical", name: "profile", onFinish: (values) => void onSave(values), disabled: isLoadingCreate }, { children: [_jsx(Form.Item, Object.assign({ name: "group_name", label: t("settings.team.groups.name"), rules: [requiredRule] }, { children: _jsx(Input, {}) })), _jsx(Form.Item, Object.assign({ name: "description", label: t("settings.team.groups.description"), rules: [requiredRule] }, { children: _jsx(Input, {}) })), _jsx(EmailFormItem, { rules: [requiredRule], label: t("settings.team.groups.group-email") }), _jsx(Form.Item, Object.assign({ name: "bo_user_ids", label: t("settings.team.groups.members"), rules: [requiredRule] }, { children: _jsx(Select, { mode: "multiple", placeholder: t("settings.team.groups.members"), style: { width: "100%" }, loading: isLoadingBouUsers, options: (_b = boUsersList === null || boUsersList === void 0 ? void 0 : boUsersList.result) === null || _b === void 0 ? void 0 : _b.map((boUser) => ({
                                    value: boUser.id,
                                    label: boUser.name,
                                })) }) }))] })), group && (_jsx(Button, Object.assign({ onClick: () => confirmDelete(), danger: true, loading: isLoadingDelete, disabled: taskAssigneeGroup === (group === null || group === void 0 ? void 0 : group.bouser_group_id) }, { children: t("settings.team.groups.delete-group") })))] })) }));
};
export default TeamGroupPreview;
