import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, Dropdown, Space, Tag, Typography } from "antd";
import styled from "styled-components";
import { CreditType, ReportStateEnum, ReportTypeEnum, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCardItem } from "@ui/data-display/DataCard";
import { Axios } from "src/utils/Axios";
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorWarning};
`;
const DotsBtnDropdown = styled(Dropdown.Button) `
	button {
		padding: 0;

		&.ant-dropdown-trigger {
			border-start-start-radius: 4px !important;
			border-end-start-radius: 4px !important;
		}

		&:not(.ant-dropdown-trigger) {
			display: none;
			border-radius: 2px;
		}
	}
`;
const CreditScoreReport = ({ getCreditScore, creditScores, requestCreditCheck, documentsPath, isSignatoryReport = false, }) => {
    const { t } = useTranslation();
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const { date } = useFormatter();
    const lastCreditScore = useMemo(() => creditScores[0], [creditScores]);
    const requestReportDropdownItems = useMemo(() => {
        var _a, _b;
        return [
            {
                label: ((_a = lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.lastReport) === null || _a === void 0 ? void 0 : _a.type) === ReportTypeEnum.Full
                    ? (lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.reportState) === ReportStateEnum.Ready
                        ? t("application.company.credit_report.request-new-full-report")
                        : (lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.reportState) === ReportStateEnum.NotReady
                            ? t("application.company.credit_report.request-new-full-report-in-progress")
                            : t("application.company.full-report")
                    : t("application.company.full-report"),
                key: ReportTypeEnum.Full,
                onClick: () => showConfirmModal(CreditType.Full),
            },
            ...(!isSignatoryReport
                ? [
                    {
                        label: ((_b = lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.lastReport) === null || _b === void 0 ? void 0 : _b.type) === ReportTypeEnum.Compact
                            ? (lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.reportState) === ReportStateEnum.Ready
                                ? t("application.company.credit_report.request-new-compact-report")
                                : (lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.reportState) === ReportStateEnum.NotReady
                                    ? t("application.company.credit_report.request-new-full-report-in-progress")
                                    : t("application.company.compact-report")
                            : t("application.company.compact-report"),
                        key: ReportTypeEnum.Compact,
                        onClick: () => showConfirmModal(CreditType.Compact),
                    },
                ]
                : []),
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastCreditScore, isSignatoryReport]);
    const requestNewCreditCheck = (credit_type) => {
        requestCreditCheck &&
            requestCreditCheck(credit_type).then(() => getCreditScore(), (err) => Axios.error(err, t("application.company.error.error-requesting-report")));
    };
    const showConfirmModal = (credit_type) => {
        void modal.confirm({
            title: t("application.company.credit_report.request-full-report"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Typography.Text, Object.assign({ strong: true }, { children: t("application.company.credit_report.request-full-report-modal-content-1") })), _jsx(Typography.Text, { children: t("application.company.credit_report.request-report-modal-content-2") })] }))),
            okText: t("application.company.credit_report.request-report"),
            cancelText: t("common.cancel"),
            onOk: () => requestNewCreditCheck(credit_type),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { marginBottom: 8 } }, { children: _jsx(DataCardItem, { label: t("application.company.credit_report.credit-information"), alignItems: "center", value: (lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.lastReport) ? (_jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "link", onClick: () => navigate(documentsPath), style: { padding: 0 } }, { children: t("application.company.full-report") })), lastCreditScore.lastReport.date && (_jsx(Tag, { children: t("application.company.report-date", {
                                    date: date(lastCreditScore.lastReport.date),
                                }) })), requestCreditCheck && (_jsx(DotsBtnDropdown, { size: "small", menu: {
                                    items: requestReportDropdownItems,
                                } }))] })) : requestCreditCheck ? (_jsx(Dropdown, Object.assign({ menu: {
                            items: requestReportDropdownItems,
                        } }, { children: _jsx(Button, Object.assign({ type: "link", style: { padding: 0 } }, { children: _jsxs(Space, { children: [t("application.company.credit_report.request-report"), _jsx(DownOutlined, {})] }) })) }))) : (_jsx(_Fragment, {})) }) })), isValidNumericValue(lastCreditScore === null || lastCreditScore === void 0 ? void 0 : lastCreditScore.score) && (_jsx(DataCardItem, { label: t("application.company.credit_report.credit-score"), value: lastCreditScore.score }))] }));
};
export default CreditScoreReport;
