import { ReactNode, forwardRef } from "react";

import styled, { css } from "styled-components";

export const MAIN_HEADER_HEIGHT = 80;

interface Props {
	title: string | ReactNode;
	extra?: string | ReactNode;
	withBorderBottom?: boolean;
}

const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 20px;
	line-height: 36px;
`;

const Wrapper = styled.div<{ $withBorderBottom: boolean }>`
	background-color: white;
	padding: 16px 24px;
	height: ${MAIN_HEADER_HEIGHT}px;
	min-height: ${MAIN_HEADER_HEIGHT}px;
	${({ $withBorderBottom }) =>
		$withBorderBottom &&
		css`
			border-bottom: 1px solid #f0f0f0;
		`}
`;

const MainHeader = forwardRef<HTMLDivElement, Props>(
	({ title, extra, withBorderBottom = true, ...props }, ref) => {
		return (
			<Wrapper ref={ref} $withBorderBottom={withBorderBottom} {...props}>
				<StyledHeader>
					<div>{title}</div>
					{extra && <div>{extra}</div>}
				</StyledHeader>
			</Wrapper>
		);
	}
);

MainHeader.displayName = "MainHeader";
export default MainHeader;
