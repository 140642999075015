import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Col, Form, Input, Row, TreeSelect } from "antd";
import styled from "styled-components";
import useDebounce from "@teylor-tools/hooks/debounce";
import { DOCUMENTS_CARD_MAX_WIDTH } from "@teylor-tools/utils/documents/documents.variables";
const FiltersWrapper = styled.div `
	margin: 20px 25px;
	max-width: ${DOCUMENTS_CARD_MAX_WIDTH}px;
`;
const FormItem = styled(Form.Item) `
	margin: 0;
`;
export var DocumentSearchField;
(function (DocumentSearchField) {
    DocumentSearchField["name"] = "name";
    DocumentSearchField["type"] = "type";
})(DocumentSearchField || (DocumentSearchField = {}));
export const ALL_DOCUMENT_SEARCH_FIELDS = Object.values(DocumentSearchField).map((v) => DocumentSearchField[v]);
const DocumentsSearch = ({ fields, onSearch, disabled }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState();
    const debouncedFormValues = useDebounce(formValues, 500);
    const { documentCategories } = useSelector((state) => state.configState);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const types = useMemo(() => documentCategories.map((cat) => {
        var _a;
        return {
            // @ts-ignore dynamic names
            title: t(`documents.category.${cat.name}.name`),
            value: cat.document_category_id,
            children: (_a = cat.document_types) === null || _a === void 0 ? void 0 : _a.map((type) => ({
                // @ts-ignore dynamic names
                title: t(`documents.type.${type.name}.name`),
                value: type.name,
            })),
        };
    }), [documentCategories, t]);
    useEffect(() => {
        if (isInitialRender)
            return;
        const { document_name, document_type } = form.getFieldsValue();
        const searchParams = Object.assign(Object.assign({}, (document_name && { document_name })), ((document_type === null || document_type === void 0 ? void 0 : document_type.length) && { document_type: document_type.join(",") }));
        setSearchParams(searchParams);
        onSearch(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFormValues]);
    useEffect(() => {
        if (!isInitialRender)
            return;
        setIsInitialRender(false);
        const { document_name, document_type } = Object.fromEntries(searchParams);
        const formValues = {
            document_name: document_name || "",
            document_type: document_type === null || document_type === void 0 ? void 0 : document_type.split(","),
        };
        form.setFieldsValue(formValues);
        const params = Object.assign(Object.assign({}, (document_name && { document_name })), (document_type && { document_type }));
        onSearch(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    return (_jsx(FiltersWrapper, { children: _jsx(Form, Object.assign({ form: form, onValuesChange: (_, val) => setFormValues(val), disabled: disabled }, { children: _jsxs(Row, Object.assign({ gutter: 8 }, { children: [fields.includes(DocumentSearchField.name) && (_jsx(Col, Object.assign({ span: 10, xl: 8 }, { children: _jsx(FormItem, Object.assign({ name: "document_name" }, { children: _jsx(Input.Search, { placeholder: t("documents.search-by-name"), allowClear: true }) })) }))), fields.includes(DocumentSearchField.type) && (_jsx(Col, Object.assign({ span: 10, xl: 8 }, { children: _jsx(FormItem, Object.assign({ name: "document_type" }, { children: _jsx(TreeSelect, { treeData: types, treeCheckable: true, placeholder: t("documents.type-search-placeholder"), treeNodeFilterProp: "title", treeDefaultExpandAll: true, maxTagCount: "responsive", allowClear: true, autoClearSearchValue: true }) })) })))] })) })) }));
};
export default DocumentsSearch;
