var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { FeatureName, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { DataCard, DataCardDivider, DataCardItem } from "@ui/data-display/DataCard";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import { DataCardItemEditable } from "../../../components/DataCard";
import { GridRate, HedgingRate, InterestRate, MinimumInterestRate, PrepaymentRate, TransactionFee, } from "./pricing-common-fields";
const PricingExternal = ({ update, isInternalPricingEditable, transactionFeeToLoanAmount, rates, partnerId, pricing, productConfig, currency, isEditable, }) => {
    const { t } = useTranslation();
    const { currency: currencyFormatter, decimalToPercent, percentToDecimal } = useFormatter();
    const { features } = useSelector((state) => state.configState);
    const showPartnerInfo = useMemo(() => { var _a; return ((_a = features.find((f) => f.name === FeatureName.DefaultPartner)) === null || _a === void 0 ? void 0 : _a.value) !== partnerId; }, [partnerId, features]);
    const handleExternalInterestRateUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        update && update({ external_interest_rate: percentToDecimal(v) });
    };
    const handleExternalTransactionFeeUpdate = (v) => {
        if (!isValidNumericValue(v))
            return;
        update && update({ external_transaction_fee: v.toString() });
    };
    return (_jsxs(_Fragment, { children: [showPartnerInfo && (_jsx(Alert, { message: t("application.pricing.partner-info"), style: { marginBottom: 24 }, type: "info" })), _jsxs(DataCard, { children: [_jsx(GridRate, { gridRate: pricing.grid_rate, productConfig: productConfig }), _jsx(HedgingRate, { isEditable: isInternalPricingEditable, onUpdate: update, rates: rates, hedgingRate: pricing.hedging_rate, productConfig: productConfig }), _jsx(MinimumInterestRate, { minimumInterestRate: pricing.minimum_interest_rate }), _jsx(DataCardDivider, {}), _jsx(InterestRate, { isEditable: isInternalPricingEditable, onUpdate: update, interestRate: pricing.interest_rate, minimumInterestRate: pricing.minimum_interest_rate, productConfig: productConfig }), _jsx(DataCardItemEditable, { label: t("application.pricing.external-interest-rate"), displayedValue: isValidNumericValue(pricing.external_interest_rate)
                            ? decimalToPercent({
                                percent: pricing.external_interest_rate,
                                showPercentSymbol: true,
                            })
                            : "", isEditable: isEditable, onChange: handleExternalInterestRateUpdate, customFormItem: (_a) => {
                            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                            return (_jsx(FormItem, Object.assign({}, props, { rules: [
                                    {
                                        type: "number",
                                        max: 99,
                                        message: t("application_form.errors.fieldMustBeMax", {
                                            max: `99%`,
                                        }),
                                    },
                                ] }, { children: _jsx(PercentInput, Object.assign({ min: 0 }, inputProps)) })));
                        }, formItemValue: isValidNumericValue(pricing.external_interest_rate)
                            ? Number(decimalToPercent({
                                percent: pricing.external_interest_rate,
                                withLocale: false,
                            }))
                            : 0 }), _jsx(DataCardItem, { label: t("application.pricing.total-interest-rate"), value: isValidNumericValue(pricing.total_interest_rate)
                            ? decimalToPercent({
                                percent: pricing.total_interest_rate,
                                showPercentSymbol: true,
                            })
                            : "" }), _jsx(DataCardDivider, {}), _jsx(TransactionFee, { onUpdate: update, isEditable: isInternalPricingEditable, transactionFee: pricing.transaction_fee, currency: currency }), _jsx(DataCardItemEditable, { label: t("application.pricing.external-transaction-fee"), displayedValue: isValidNumericValue(pricing.external_transaction_fee)
                            ? currencyFormatter(pricing.external_transaction_fee, {
                                currency: currency,
                            })
                            : "", isEditable: isEditable, onChange: handleExternalTransactionFeeUpdate, customFormItem: (_a) => {
                            var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                            return (_jsx(FormItem, Object.assign({}, props, { children: _jsx(CurrencyInput, Object.assign({ min: 0, step: 0.01 }, inputProps, { currency: currency })) })));
                        }, formItemValue: pricing.external_transaction_fee }), _jsx(DataCardItem, { label: t("application.pricing.total-transaction-fee"), value: isValidNumericValue(pricing.total_transaction_fee)
                            ? currencyFormatter(pricing.total_transaction_fee, {
                                currency: currency,
                            })
                            : "" }), _jsx(DataCardItem, { label: t("application.pricing.transactionFeeToLoanAmount"), value: _jsx(Typography.Text, { children: transactionFeeToLoanAmount }) }), _jsx(PrepaymentRate, { onUpdate: update, isEditable: isInternalPricingEditable, prepaymentRate: pricing.prepayment_rate, productConfig: productConfig })] })] }));
};
export default PricingExternal;
