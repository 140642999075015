import { Components } from "react-markdown";

import styled from "styled-components";

const StyledTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1em;
`;

const StyledTh = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	background-color: #f2f2f2;
	text-align: left;
`;

const StyledTd = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
`;

const StyledBlockquote = styled.blockquote`
	border-left: 4px solid #ddd;
	padding: 0 15px;
	color: #666;
	margin: 20px 0;

	> p {
		white-space: pre-wrap;
	}
`;

export const MarkdownComponents: Components = {
	table: ({ children }) => <StyledTable>{children}</StyledTable>,
	th: ({ children }) => <StyledTh>{children}</StyledTh>,
	td: ({ children }) => <StyledTd>{children}</StyledTd>,
	blockquote: ({ children }) => <StyledBlockquote>{children}</StyledBlockquote>,
	span: ({ children }) => <span>{children}</span>,
	br: () => <br />,
};
